import { piCover } from "../../../../common/components/datatable/columns/offerColumns";
import {
    EntityType,
    OfferOnlyType,
    OfferType,
    UserType,
} from "../../../../types";

export const acceptedOffer: OfferOnlyType = {
    id: 1,
    enquiryId: 1234,
    status: "ACCEPTED",
} as OfferOnlyType;

export const rejectedOffer: OfferOnlyType = {
    id: 2,
    enquiryId: 1234,
    status: "REJECTED",
} as OfferOnlyType;

export const withdrawnOffer: OfferOnlyType = {
    id: 3,
    enquiryId: 1234,
    status: "WITHDRAWN",
} as OfferOnlyType;

export const pendingOffer: OfferOnlyType = {
    id: 4,
    enquiryId: 1235,
    status: "PENDING",
} as OfferOnlyType;

export const rejectedOffer2: OfferType = {
    id: 2,
    enquiryId: 1234,
    status: "REJECTED",
} as OfferType;

export const withdrawnOffer2: OfferType = {
    id: 3,
    enquiryId: 1234,
    status: "WITHDRAWN",
} as OfferType;

export const pendingOffer2: OfferType = {
    id: 4,
    enquiryId: 1235,
    status: "PENDING",
} as OfferType;

export const acceptedOffer2: OfferType = {
    id: 5,
    enquiryId: 1235,
    status: "ACCEPTED",
    piCoverCurrency: "euro",
    piCoverValue: 1000,
    piCoverPercentage: 70,
    offerComments: [],
    fromEntity: {} as EntityType,
    fromValuer: {} as UserType,
} as unknown as OfferType;

export const testOffers: OfferOnlyType[] = [
    acceptedOffer,
    rejectedOffer,
    withdrawnOffer,
    pendingOffer,
];

export const testOffers2: OfferType[] = [
    acceptedOffer2,
    rejectedOffer2,
    withdrawnOffer2,
    pendingOffer2,
];
