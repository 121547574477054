import {
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMyEntity } from '../../../../../utils/api/hooks';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import storage from '../../../../../utils/storage/storage';
import UploadCertificate from '../../../../../common/components/UploadCertificate';
import { WVWTitle } from '../../../../../common/components/typography';

const EditCompanyPiDocs = () => {
    const { t } = useTranslation('wizard');

    const [loadingUpload, setLoadingUpload] = useState(false);

    const {
        data: entity,
        isLoading,
        entityCertificates = [],
        entityCertificatesIsLoading,
        entityCertificatesRefresh,
    } = useMyEntity();

    if (isLoading || entityCertificatesIsLoading) {
        return <LoadingSpinner />;
    }

    const files = entityCertificates
        .filter(i => i.type.toLowerCase() === 'pi')
        .map(i => ({
            name: i.label,
            fileUrl: i.file.fullPath,
        }));

    return (
        <VStack
            align="start"
            padding="1rem"
            spacing="4"
            w="100%"
        >
            <WVWTitle
                content={t('uploadPICerts')}
                level="2"
                color="black"
            />

            <UploadCertificate
                defaultFileName={files.length === 0 ? 'PI Certificate' : undefined}
                files={files}
                loading={loadingUpload}
                onFileDelete={fileUrl => storage.deleteUserFile(fileUrl)
                    .then(entityCertificatesRefresh)}
                onRename={(fileUrl, label) => storage.updateFileMetadata(fileUrl, label)
                    .then(entityCertificatesRefresh)}
                onUpload={fileList => {
                    fileList.forEach(file => {
                        storage.uploadEntityCertificate(
                            entity?.id,
                            file,
                            'pi',
                        )
                            .then(entityCertificatesRefresh)
                            .finally(() => setLoadingUpload(false));
                    });
                }}
            />
        </VStack>
    );
};

EditCompanyPiDocs.defaultProps = {
    onSave: () => { },
};

export default EditCompanyPiDocs;
