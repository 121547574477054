import {
    Divider,
    Heading,
    Highlight,
    Link,
    ListItem,
    Text,
    UnorderedList,
    VStack,
} from "@chakra-ui/react";
// import i18n from '../../i18n';

const Privacy = () => (
    // <>
    //     {i18n.language === 'de' && (
    <VStack
        align="left"
        spacing="1rem"
    >
        <Text>
            {/* eslint-disable-next-line max-len */}
            Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
            EU-Datenschutzgrundverordnung (DSGVO), ist die als
            Vertriebsgesellschaft für die Plattform agierende: TL Investment &
            Service GmbH, Franziskaner 2a, 81669 München, vertreten durch den
            Geschäftsführer Thorsten Lamberty.
            {/* Wer Bewertet Was
            GmbH i. Gr., Franziskaner 2a, 81669 München vertreten durch den
            Geschäftsführer Thorsten Lamberty. */}
        </Text>

        <Heading size="md">Ihre Rechte als Betroffener</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
            können Sie jederzeit folgende Rechte ausüben:
        </Text>

        <UnorderedList paddingLeft="4">
            <ListItem>
                {/* eslint-disable-next-line max-len */}
                Auskunft über Ihre bei uns gespeicherten Daten und deren
                Verarbeitung (Art. 15 DSGVO),
            </ListItem>

            <ListItem>
                Berichtigung unrichtiger personenbezogener Daten (Art. 16
                DSGVO),
            </ListItem>

            <ListItem>
                Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
            </ListItem>

            <ListItem>
                {/* eslint-disable-next-line max-len */}
                Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
                aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art.
                18 DSGVO),
            </ListItem>

            <ListItem>
                {/* eslint-disable-next-line max-len */}
                Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
                DSGVO) und
            </ListItem>

            <ListItem>
                {/* eslint-disable-next-line max-len */}
                Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
                eingewilligt haben oder einen Vertrag mit uns abgeschlossen
                haben (Art. 20 DSGVO).
            </ListItem>
        </UnorderedList>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
            jederzeit mit Wirkung für die Zukunft widerrufen.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Sie können sich jederzeit mit einer Beschwerde an eine
            Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde
            des Bundeslands Ihres Wohnsitzes oder an die für uns als
            verantwortliche Stelle zuständige Behörde.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
            mit Anschrift finden Sie unter:
            <br />
            <Link
                color="wvwYellow"
                href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
                isExternal
                target="_blank"
            >
                https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html
            </Link>
        </Text>

        <Heading size="md">
            Erfassung allgemeiner Informationen beim Besuch unserer Website
        </Heading>

        <Heading size="sm">Art und Zweck der Verarbeitung:</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
            registrieren oder anderweitig Informationen übermitteln, werden
            automatisch Informationen allgemeiner Natur erfasst. Diese
            Informationen (Server-Logfiles) beinhalten etwa die Art des
            Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
            Internet-Service-Providers, Ihre IP-Adresse und ähnliches.
        </Text>

        <Text>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</Text>

        <UnorderedList paddingLeft="4">
            <ListItem>
                Sicherstellung eines problemlosen Verbindungsaufbaus der
                Website,
            </ListItem>

            <ListItem>
                Sicherstellung einer reibungslosen Nutzung unserer Website,
            </ListItem>

            <ListItem>
                Auswertung der Systemsicherheit und -stabilität sowie
            </ListItem>

            <ListItem>zur Optimierung unserer Website.</ListItem>
        </UnorderedList>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
            ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert
            statistisch ausgewertet, um unseren Internetauftritt und die
            dahinterstehende Technik zu optimieren.
        </Text>

        <Heading size="sm">Rechtsgrundlage und berechtigtes Interesse:</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
            unseres berechtigten Interesses an der Verbesserung der Stabilität
            und Funktionalität unserer Website.
        </Text>

        <Heading size="sm">Empfänger:</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Empfänger der Daten sind ggf. technische Dienstleister, die für den
            Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter
            tätig werden.
        </Text>

        <Heading size="sm">Speicherdauer:</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
            nicht mehr erforderlich sind. Dies ist für die Daten, die der
            Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die
            jeweilige Sitzung beendet ist.
        </Text>

        <Heading size="sm">
            Bereitstellung vorgeschrieben oder erforderlich:
        </Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Die Bereitstellung der vorgenannten personenbezogenen Daten ist
            weder gesetzlich noch vertraglich vorgeschrieben. Ohne die
            IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer
            Website nicht gewährleistet. Zudem können einzelne Dienste und
            Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund
            ist ein Widerspruch ausgeschlossen.
        </Text>

        <Heading size="md">Kontaktformular</Heading>

        <Heading size="sm">Art und Zweck der Verarbeitung:</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
            Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer
            validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient
            der Zuordnung der Anfrage und der anschließenden Beantwortung
            derselben. Die Angabe weiterer Daten ist optional.
        </Text>

        <Heading size="sm">Rechtsgrundlage:</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs.
            1 lit. f DSGVO).
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
            unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben
            werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
            Anschlussfragen gespeichert.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen,
            erfolgt die Verarbeitung der in das Kontaktformular eingegebenen
            Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1
            lit. b DSGVO).
        </Text>

        <Heading size="sm">Empfänger:</Heading>

        <Text>Empfänger der Daten sind ggf. Auftragsverarbeiter.</Text>

        <Heading size="sm">Speicherdauer:</Heading>

        <Text>
            Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage
            gelöscht.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den
            gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten
            nach Ablauf dieser Fristen.
        </Text>

        <Heading size="sm">
            Bereitstellung vorgeschrieben oder erforderlich:
        </Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
            Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren
            Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
        </Text>

        <Heading size="sm">Verwendung von Google Analytics</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website
            Google Analytics eingesetzt, ein Webanalysedienst der Google LLC,
            1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend:
            „Google“). Google Analytics verwendet sog. „Cookies“, also
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch
            das Cookie erzeugten Informationen über Ihre Benutzung dieser
            Webseite werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert. Aufgrund der Aktivierung der
            IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von
            Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
            oder in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
            IP-Adresse an einen Server von Google in den USA übertragen und dort
            gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser
            übermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            {
                "Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter "
            }
            <Link
                color="wvwYellow"
                href="https://www.google.com/analytics/terms/de.html"
                isExternal
                target="_blank"
            >
                https://www.google.com/analytics/terms/de.html
            </Link>

            {" und unter "}

            <Link
                color="wvwYellow"
                href="https://policies.google.com/?hl=de"
                isExternal
                target="_blank"
            >
                https://policies.google.com/?hl=de
            </Link>
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Im Auftrag des Betreibers dieser Website wird Google diese
            Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um
            Reports über die Webseitenaktivitäten zusammenzustellen und um
            weitere mit der Websitenutzung und der Internetnutzung verbundene
            Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B.
            User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten
            automatisch gelöscht. Die Löschung von Daten, deren
            Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im
            Monat.
        </Text>

        <Heading size="sm">Widerruf der Einwilligung:</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Sie können das Tracking durch Google Analytics auf unserer Website
            unterbinden, indem Sie diesen Link anklicken. Dabei wird ein
            Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung
            durch Google Analytics für diese Website und für diesen Browser
            zukünftig verhindert, solange das Cookie in Ihrem Browser
            installiert bleibt.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Sie können darüber hinaus die Speicherung der Cookies durch eine
            entsprechende Einstellung Ihrer Browser-Software verhindern; wir
            weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
            nicht sämtliche Funktionen dieser Website vollumfänglich werden
            nutzen können.
        </Text>

        <Text>
            {
                "Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: "
            }

            <Link
                color="wvwYellow"
                href="http://tools.google.com/dlpage/gaoptout?hl=de"
                isExternal
                target="_blank"
            >
                Browser Add On zur Deaktivierung von Google Analytics.
            </Link>
        </Text>

        <Heading size="sm">
            Verwendung von Scriptbibliotheken (Google Webfonts)
        </Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Um unsere Inhalte browserübergreifend korrekt und grafisch
            ansprechend darzustellen, verwenden wir auf dieser Website „Google
            Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View,
            CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.
        </Text>

        <Text>
            {"Weitere Informationen zu Google Web Fonts finden Sie unter "}

            <Link
                color="wvwYellow"
                href="https://developers.google.com/fonts/faq"
                isExternal
                target="_blank"
            >
                https://developers.google.com/fonts/faq
            </Link>

            {" und in der Datenschutzerklärung von Google: "}

            <Link
                color="wvwYellow"
                href="https://www.google.com/policies/privacy/"
                isExternal
                target="_blank"
            >
                https://www.google.com/policies/privacy/
            </Link>
        </Text>

        <Heading size="sm">
            Verwendung von Typekit-Webschriftarten von Adobe Fonts
        </Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Wir setzen Typekit-Webschriftarten von Adobe Fonts zur visuellen
            Gestaltung unserer Website ein. Adobe Fonts ist ein Dienst der Adobe
            Systems Software Ireland Companies (4-6 Riverwalk, Citywest Business
            Campus, Dublin 24, Republic of Ireland; nachfolgend „Adobe“), der
            uns den Zugriff auf eine Schriftartenbibliothek gewährt.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Zur Einbindung der von uns benutzten Schriftarten muss Ihr Browser
            eine Verbindung zu einem Server von Adobe in den USA aufbauen und
            die für unsere Website benötigte Schriftart herunterladen. Adobe
            erhält hierdurch die Information, dass von Ihrer IP-Adresse unsere
            Website aufgerufen wurde.
        </Text>

        <Text>
            {
                "Weitere Informationen zu Adobe Fonts finden Sie in den Datenschutzhinweisen von Adobe Fonts, die Sie hier abrufen können: "
            }

            <Link
                color="wvwYellow"
                href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html"
                isExternal
                target="_blank"
            >
                https://www.adobe.com/de/privacy/policies/adobe-fonts.html
            </Link>
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Wenn Ihr Browser Web Fonts nicht unterstützt, oder Sie Ihre
            Einwilligung nicht erteilen, wird eine Standardschrift von Ihrem
            Computer genutzt.
        </Text>

        <Heading size="sm">Widerruf der Einwilligung:</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
            Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
            eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
            wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre
            Einwilligung für die entsprechende Cookie-Kategorie oder alle
            technisch nicht notwendigen Cookies und Datenübertragungen. In
            diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur
            eingeschränkt nutzen.
        </Text>

        <Heading size="sm">SSL-Verschlüsselung</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
            verwenden wir dem aktuellen Stand der Technik entsprechende
            Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </Text>

        <Divider />

        <Heading size="md">
            Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO
        </Heading>

        <Heading size="sm">Einzelfallbezogenes Widerspruchsrecht</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
            personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
            (Datenverarbeitung auf der Grundlage einer Interessenabwägung)
            erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
            Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
        </Text>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten
            nicht mehr verarbeiten, es sei denn, wir können zwingende
            schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
            Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
            dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.
        </Text>

        <Heading size="sm">Empfänger eines Widerspruchs</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            TL Investment & Service GmbH, Franziskaner 2a, 81669 München
        </Text>

        <Divider />

        <Heading size="md">Änderung unserer Datenschutzbestimmungen</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit
            sie stets den aktuellen rechtlichen Anforderungen entspricht oder um
            Änderungen unserer Leistungen in der Datenschutzerklärung
            umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren
            erneuten Besuch gilt dann die neue Datenschutzerklärung.
        </Text>

        <Heading size="md">Fragen an den Datenschutzbeauftragten</Heading>

        <Text>
            {/* eslint-disable-next-line max-len */}
            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
            E-Mail oder wenden Sie sich direkt an die für den Datenschutz
            verantwortliche Person in unserer Organisation:
        </Text>

        <Text>
            TL Investment & Service GmbH, Franziskaner 2a, 81669 München
            <br />
            <Highlight
                query="E-Mail:"
                styles={{ fontWeight: "bold" }}
            >
                {"E-Mail: "}
            </Highlight>
            <Link
                color="wvwYellow"
                href="mailto:platform@wer-bewertet-was.de"
                isExternal
            >
                platform@wer-bewertet-was.de
            </Link>
        </Text>

        <Text as="i">
            {
                "Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für "
            }

            <Link
                color="wvwYellow"
                href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
                isExternal
                target="_blank"
            >
                externe Datenschutzbeauftragte
            </Link>

            {" (Version #2020-09-30)."}
        </Text>
    </VStack>
    //     )}

    //     {i18n.language === 'en' && (
    //         <VStack
    //             align="left"
    //             spacing="1rem"
    //         >
    //             <Text>
    //                 English translation to come.
    //             </Text>
    //         </VStack>

    //     )}
    // </>
);

export default Privacy;
