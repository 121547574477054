import { useNavigate, useParams } from 'react-router-dom';
import GeneralEnquiryOverview from '../../../common/components/display/enquiry-form/overview/GeneralEnquiryOverview';
import { useEnquiry, useEnquirySubmit } from '../../../utils/api/hooks';
import { EnquiryType } from '../../../types';
import EnquiryFormBox from '../common/EnquiryFormBox';
import useEnquirySteps from '../common/hooks/useEnquirySteps';
import { FormikForm } from '../../../common/forms';

const EnquiryOverviewInfo = () => {
    const { enquiryId = '' } = useParams();
    const navigate = useNavigate();

    const { onBack } = useEnquirySteps();

    const {
        enquiry: enquiryData = {} as unknown as EnquiryType,
    } = useEnquiry({ enquiryId });

    const { update: submitEnquiry } = useEnquirySubmit({
        onSuccess: () => {
            navigate(`/enquiry-success/${enquiryId}`);
        },
    });

    return (
        <FormikForm
            initialValues={{}}
            onSubmit={() => submitEnquiry(enquiryId)}
        >
            <EnquiryFormBox
                variant="none"
                onBack={onBack}
                nextButtonTextKey="button.submit"
                content={[
                    {
                        key: 'enquiryOverview',
                        header: undefined,
                        element: <GeneralEnquiryOverview variant="white" overviewData={enquiryData} />,
                    },
                ]}
            />
        </FormikForm>
    );
};

export default EnquiryOverviewInfo;
