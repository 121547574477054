import {
    Box,
    Center,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import WvwHStack from '../../../../../common/components/display/WvwHStack';
import { WvwButton } from '../../../../../common/components/inputs';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import { useNavigateUpUrl, useRole, useSmartTranslation } from '../../../../../common/hooks';
import { CaseContractType } from '../../../../../types';
import DownloadAllFiles from '../../documents/common/request/common/DownloadAllFiles';
import CaseContractRespondModal from './CaseContractRespondModal';

type PropsType = {
    document: CaseContractType | undefined;
    contractType: string | undefined;
};

const CaseContractDocumentView = (props: PropsType) => {
    const { document, contractType } = props;

    const t = useSmartTranslation('casecontract');

    const { caseId, contractId } = useParams();
    const caseIdNumber = Number(caseId);
    const contractIdNumber = Number(contractId);

    const { userIsValuer } = useRole();

    const navigateUpUrl = useNavigateUpUrl();

    const [respondingToContract, setRespondingToContract] = useState<'ACCEPTED' | 'REJECTED' | undefined>(undefined);

    const unsignedDocumentPath = document?.unsignedContractUrl || '';
    const unsignedDocumentName = unsignedDocumentPath.split('2F').pop() || '';
    const signedDocumentPath = document?.signedContractUrl || '';
    const signedDocumentName = signedDocumentPath.split('2F').pop() || '';

    const documentPathToUse = contractType === 'unsigned' ? unsignedDocumentPath : signedDocumentPath;
    const documentNameToUse = contractType === 'unsigned' ? unsignedDocumentName : signedDocumentName;

    const title = contractType === 'unsigned' ? t('title.unsignedContract') : t('title.signedContract');

    if (!contractType) return null;

    const documentToDisplay = contractType === 'unsigned' ? document?.unsignedContractUrl : document?.signedContractUrl;

    const valuerResponseSection = (
        <WvwHStack
            h="100%"
            w="100%"
            justifyContent="flex-end"
            alignItems="flex-end"
        >
            <WvwButton
                variant="danger"
                content={t('button.reject', { ns: 'common' })}
                onClick={() => setRespondingToContract('REJECTED')}
            />

            <WvwButton
                variant="success"
                content={t('button.accept', { ns: 'common' })}
                onClick={() => setRespondingToContract('ACCEPTED')}
            />
        </WvwHStack>
    );

    const renderDocument = () => {
        const fileType = documentToDisplay?.split('.').pop();
        const isPdf = fileType === 'pdf' || fileType === 'PDF';

        if (isPdf) {
            return (
                <Box h="50rem" width="50rem">
                    <iframe
                        src={documentToDisplay}
                        width="100%"
                        height="100%"
                        title={`${t('contract')}`}
                    />
                </Box>
            );
        }

        return (
            <Center
                w="80%"
                h="50%"
                overflow="auto"
            >
                <Box h="100%">
                    <img
                        src={documentToDisplay}
                        style={{ objectFit: 'contain' }}
                        alt={`${t('contract')} `}
                    />
                </Box>
            </Center>
        );
    };

    return (
        <>
            <ModalDynamic
                isOpen
                header={title}
                onClose={() => navigateUpUrl(1)}
            >
                <VStack spacing="6" minHeight="40rem">
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        paddingRight="1rem"
                    >
                        <DownloadAllFiles
                            filesToDownload={[
                                { fileName: documentNameToUse, url: documentPathToUse },
                            ]}
                        />
                    </Box>

                    {renderDocument()}

                    {(userIsValuer && document?.signedContractReview === 'AWAITING_REVIEW' && contractType === 'signed') && valuerResponseSection}
                </VStack>
            </ModalDynamic>

            <CaseContractRespondModal
                caseId={caseIdNumber}
                contractId={contractIdNumber}
                respondingToContract={respondingToContract === 'ACCEPTED' ? 'ACCEPTED' : 'REJECTED'}
                isOpen={respondingToContract !== undefined}
                onCancel={() => setRespondingToContract(undefined)}
                onContinue={() => {
                    setRespondingToContract(undefined);
                }}
            />
        </>
    );
};

export default CaseContractDocumentView;
