import { documentRequestMarkDocumentRequestCommentAsRead } from '../../endpoints';
import useDocumentRequestGetComments from './useDocumentRequestGetComments';
import useMutationHook from '../useMutationHook';

type ParamsType = {
    requestId: number | string;
    onSuccess?: () => void;
    onError?: (error?: Error) => void;
};

const useDocumentRequestMarkDocumentRequestCommentAsRead = (params: ParamsType) => {
    const {
        requestId,
        onSuccess,
        onError,
    } = params;

    const { refresh } = useDocumentRequestGetComments(requestId);

    const mutation = useMutationHook({
        mutationFunction: (rId: number | string) => (
            documentRequestMarkDocumentRequestCommentAsRead(rId)
        ),
        refreshes: [refresh],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestMarkDocumentRequestCommentAsRead;
