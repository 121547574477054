import { VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { WvwText } from '../typography';

type PropTypes = {
    label?: string; // The label
    input: ReactNode; // The input, such as a text input or a select input
};

const LabelAndInput = (props: PropTypes) => {
    const { label, input } = props;

    return (
        <VStack
            align="start"
            spacing="2"
            w="100%"
        >
            {label && (
                <WvwText>
                    {label}
                </WvwText>
            )}

            {input}
        </VStack>
    );
};

LabelAndInput.defaultProps = {
    label: undefined,
};

export default LabelAndInput;
