import offerColumns from '../../../../common/components/datatable/columns/offerColumns';
import DataTable from '../../../../common/components/datatable/DataTable';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { useRole } from '../../../../common/hooks';
import { useMyEntity, useOfferGetAllMyOffers } from '../../../../utils/api/hooks';

const LatestOffers = () => {
    const {
        data,
        isLoading: offerLoading,
    } = useOfferGetAllMyOffers();

    const {
        userIsClient,
        roleLoading,
    } = useRole();

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    const entityFilter = userIsClient ? 'toEntityId' : 'fromEntityId';

    const filteredOffers = data?.filter(offer => offer[entityFilter] === entity?.id);

    const sortedOfferList = filteredOffers
        .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        .slice(0, 5);

    if (offerLoading || roleLoading || entityLoading) {
        return <LoadingSpinner />;
    }

    return (
        <DataTable
            columns={[
                offerColumns.price,
                offerColumns.availability,
                offerColumns.status,
                offerColumns.piCover,
                offerColumns.viewButton,
            ]}
            data={sortedOfferList}
            initialSorting={{
                column: 0,
            }}
        />
    );
};

export default LatestOffers;
