import { Box, Tabs, Tab, TabList, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { useRole } from "../../../common/hooks";
import PageWithTitleLayout from "../../../common/components/display/PageWithTitleLayout";

type TabType = {
    label: string;
    url: string;
    disabled?: boolean;
    disabledPopover?: string;
};

const Account = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { t } = useTranslation(["account", "common"]);

    const { userIsApproved, roleLoading, userIsIndividual, userIsValuer } =
        useRole();

    if (roleLoading) return <LoadingSpinner />;

    const tabs: TabType[] = [
        {
            label: t("accountSectionLink.companyDetails"),
            url: "",
        },
        {
            label: t("accountSectionLink.team"),
            url: "team",
        },
        {
            label: userIsValuer
                ? t("accountSectionLink.customers")
                : t("accountSectionLink.panelValuers"),
            url: "valuer-panel",
        },
    ];

    if (userIsIndividual) {
        tabs.splice(1, 2);
    }

    if (userIsValuer) {
        tabs.push({
            label: t("accountSectionLink.sharedDocs"),
            url: "documents",
        });
        tabs.push({
            label: t("accountSectionLink.integration"),
            url: "integration",
            disabled: !userIsApproved,
            disabledPopover: t("accountDoesNotHaveAccessToFeature", {
                ns: "common",
            }),
        });
    }

    const index = tabs.findIndex((tab) => {
        if (tab.url === "") return pathname === "/dashboard/account";

        return pathname.search(tab.url) !== -1;
    });

    return (
        <PageWithTitleLayout
            allowUnapprovedUser
            title={t("title", { ns: "account" })}
        >
            <Box
                alignContent="center"
                bg="white"
                borderRadius="15"
                paddingBlock="1rem"
                paddingInline="1.5rem"
                w="100%"
            >
                <Tabs
                    index={index === -1 ? 0 : index}
                    padding="0"
                    variant="wvwGreen"
                    width="100%"
                >
                    <TabList>
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.url}
                                isDisabled={tab.disabled}
                                onClick={() => navigate(tab.url)}
                            >
                                {tab.disabled && tab.disabledPopover && (
                                    <Tooltip
                                        label={tab.disabledPopover}
                                        placement="bottom"
                                    >
                                        {tab.label}
                                    </Tooltip>
                                )}

                                {!tab.disabled && tab.label}
                            </Tab>
                        ))}
                    </TabList>
                </Tabs>

                <Outlet />
            </Box>
        </PageWithTitleLayout>
    );
};

export default Account;
