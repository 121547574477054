import { Circle, Spinner } from "@chakra-ui/react";
import { useMyEntity } from "../../../../../utils/api/hooks";
import ProfilePicture from "./ProfilePicture";

type PropTypes = {
    size: string; // The size of the profile picture
};

const ProfilePictureCompany = (props: PropTypes) => {
    const { size } = props;

    const {
        entityProfilePictureUrl,
        entityProfilePictureUrlIsLoading,
        entityProfilePictureUrlError,
    } = useMyEntity();

    if (entityProfilePictureUrlIsLoading) {
        return (
            <Circle size={size}>
                <Spinner />
            </Circle>
        );
    }

    let profilePictureUrl;

    if (entityProfilePictureUrl && !entityProfilePictureUrlError)
        profilePictureUrl = entityProfilePictureUrl;

    return (
        <ProfilePicture
            size={size}
            url={profilePictureUrl}
        />
    );
};

export default ProfilePictureCompany;
