import { useTranslation } from "react-i18next";
import { useAdminCases } from "../../../utils/api/hooks";
import { WvwText } from "../../../common/components/typography";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import DataTable from "../../../common/components/datatable/DataTable";
import { CaseType, EnquiryType } from "../../../types";
import delimitNumber from "../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../common/functions/displayDateInLocale";
import PageWithTitleLayout from "../../../common/components/display/PageWithTitleLayout";

const AdminCases = () => {
    const { t } = useTranslation("dashboard");

    const { data: cases = [], isLoading } = useAdminCases({});

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const headers = [
        {
            title: "#",
            sorter: (a: CaseType, b: CaseType) => a.id - b.id,
            render: (i: CaseType) => <WvwText>{i.id}</WvwText>,
        },
        {
            title: t("enquiriesTable.header.client"),
            sorter: (a: CaseType, b: CaseType) => {
                const aName = `${a.clientUser.firstName} ${a.clientUser.lastName}`;
                const bName = `${b.clientUser.firstName} ${b.clientUser.lastName}`;

                return aName.localeCompare(bName || "");
            },

            render: (i: CaseType) =>
                `${i.clientUser?.firstName} ${i.clientUser?.lastName}`,
        },
        {
            title: t("enquiriesTable.header.valuer"),
            sorter: (a: CaseType, b: CaseType) => {
                const aValuerName = `${a.valuerUser.firstName} ${a.valuerUser.lastName}`;
                const bValuerName = `${b.valuerUser.firstName} ${b.valuerUser.lastName}`;

                return aValuerName.localeCompare(bValuerName);
            },

            render: (i: CaseType) =>
                `${i.valuerUser?.firstName} ${i.valuerUser?.lastName}`,
        },
        {
            title: t("enquiriesTable.header.property"),
            sorter: (a: CaseType, b: CaseType) => {
                const aProperty =
                    a?.enquiry?.properties?.[0]?.street ||
                    `${a?.enquiry?.properties?.length || 0} properties`;
                const bProperty =
                    b?.enquiry?.properties?.[0]?.street ||
                    `${b?.enquiry?.properties?.length || 0} properties`;

                return aProperty.localeCompare(bProperty);
            },
            render: (i: CaseType) => {
                const { properties = [] } = i.enquiry || ({} as EnquiryType);

                if (properties.length === 1) {
                    return <WvwText>{properties[0].street}</WvwText>;
                }

                return (
                    <WvwText>
                        {t("activeCasesTable.property_wc", {
                            count: properties.length,
                            ns: "dashboard",
                        })}
                    </WvwText>
                );
            },
        },
        {
            title: t("activeCasesTable.header.dateAccepted"),
            sorter: (a: CaseType, b: CaseType) =>
                a.createdAt.localeCompare(b.createdAt),
            render: (i: CaseType) => (
                <WvwText>{displayDateWithCurrentLocale(i.createdAt)}</WvwText>
            ),
        },
        {
            title: t("enquiriesTable.header.estimateValue"),
            sorter: (a: CaseType, b: CaseType) => {
                const { properties: aProperties = [] } = a.enquiry;
                const { properties: bProperties = [] } = b.enquiry;

                const aEstimateValue = aProperties.reduce(
                    (acc, property) => acc + property.estimateValue,
                    0
                );

                const bEstimateValue = bProperties.reduce(
                    (acc, property) => acc + property.estimateValue,
                    0
                );

                return aEstimateValue - bEstimateValue;
            },
            render: (i: CaseType) => {
                const { properties = [] } = i.enquiry;

                const estimateValue = properties.reduce(
                    (acc, property) => acc + property.estimateValue,
                    0
                );

                return (
                    <WvwText>
                        {`${t("unitSymbol.currency", {
                            ns: "common",
                        })} ${delimitNumber(estimateValue)}`}
                    </WvwText>
                );
            },
        },
        {
            title: t("activeCasesTable.header.price", {
                ns: "dashboard",
                defaultValue: "Price",
            }),
            sorter: (a: CaseType, b: CaseType) =>
                a.acceptedOffer.price - b.acceptedOffer.price,
            render: (i: CaseType) => (
                <WvwText>
                    {`${t("unitSymbol.currency", {
                        ns: "common",
                    })} ${delimitNumber(i.acceptedOffer.price)}`}
                </WvwText>
            ),
        },
        {
            title: t("enquiriesTable.header.dueDate"),
            sorter: (a: CaseType, b: CaseType) => {
                const aDueDate = new Date(a.acceptedOffer.availability);
                const bDueDate = new Date(b.acceptedOffer.availability);

                aDueDate.setDate(
                    aDueDate.getDate() + Number(a.acceptedOffer.timeline)
                );
                bDueDate.setDate(
                    bDueDate.getDate() + Number(b.acceptedOffer.timeline)
                );

                return aDueDate.getTime() - bDueDate.getTime();
            },
            render: (i: CaseType) => {
                const dueDate = new Date(i.acceptedOffer.availability);

                dueDate.setDate(
                    dueDate.getDate() + Number(i.acceptedOffer.timeline)
                );

                return displayDateWithCurrentLocale(dueDate);
            },
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Cases">
            <DataTable
                columns={headers}
                data={cases}
            />
        </PageWithTitleLayout>
    );
};

export default AdminCases;
