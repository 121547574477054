import { useParams } from 'react-router-dom';
import { ContentBox, WvwIcon } from '../../../../../common/components/display';
import { WvwLink } from '../../../../../common/components/inputs';
import WvwHStack from '../../../../../common/components/display/WvwHStack';
import { CaseContractType } from '../../../../../types';
import { useSmartTranslation } from '../../../../../common/hooks';
import CaseContractDocumentView from '../view-contract/CaseContractDocumentView';

type PropsType = {
    contract: CaseContractType;
};

const CaseContractDocumentList = (props: PropsType) => {
    const { contract } = props;

    const { contractType = '' } = useParams();

    const t = useSmartTranslation('casecontract');

    return (
        <>
            {contract.signedContractUrl && (
                <ContentBox key={contract.signedContractUrl}>
                    <WvwHStack>
                        <WvwHStack w="100%">
                            <WvwIcon icon="document" />

                            <WvwLink
                                to="signed"
                                label={t('title.signedContract')}
                            />
                        </WvwHStack>

                        <WvwLink
                            to="signed"
                            label={t('button.view')}
                        />
                    </WvwHStack>
                </ContentBox>
            )}

            {contract.unsignedContractUrl && (
                <ContentBox key={contract.unsignedContractUrl}>
                    <WvwHStack>
                        <WvwHStack w="100%">
                            <WvwIcon icon="document" />

                            <WvwLink
                                to="unsigned"
                                label={t('title.unsignedContract')}
                            />
                        </WvwHStack>

                        <WvwLink
                            to="unsigned"
                            label={t('button.view')}
                        />
                    </WvwHStack>
                </ContentBox>
            )}

            <CaseContractDocumentView document={contract} contractType={contractType} />
        </>
    );
};

export default CaseContractDocumentList;
