import { VStack } from "@chakra-ui/react";
import { LabelAndData } from "../../../../common/components/display";
import { EnquiryType } from "../../../../types";

type PropTypes = {
    enquiry: EnquiryType;
};

const AdminEnquiryDetails = (props: PropTypes) => {
    const { enquiry } = props;

    const {
        id,
        fromEntityId,
        fromUserId,
        toEntityIds,
        toUserIds,
        allowFullMatchmaking,
        createdOnBehalfOf,
        createdAt,
        status,
        valuationType,
        valuationSpecial,
        valuationOther,
        valuationStandards,
        valuationStandardsExtra,
        valuationStandardsOther,
        valuationPurposes,
        valuationPurposesOther,
        valuerExperience,
        valuerMinimumPartners,
        valuerMaxOfficeDistance,
        valuerMinimumPICover,
        valuerMinimumPICoverPercentage,
        completionDate,
        additionalRequirements,
        invoiceFName,
        invoiceLName,
        invoiceEmail,
        invoiceEmailCc,
        invoiceMobileDialCode,
        invoiceMobile,
        billingStreet,
        billingCity,
        billingCountry,
        billingPostalCode,
        updatedAt,
        // properties,
    } = enquiry;

    return (
        <VStack
            align="start"
            spacing="3"
            w="100%"
        >
            <LabelAndData
                label="Enquiry ID"
                data={id}
            />

            <LabelAndData
                label="From Entity"
                data={fromEntityId}
            />

            <LabelAndData
                label="From User"
                data={fromUserId}
            />

            <LabelAndData
                label="To Entity"
                data={toEntityIds}
            />

            <LabelAndData
                label="To User"
                data={toUserIds}
            />

            <LabelAndData
                label="Allow Full Matchmaking"
                data={allowFullMatchmaking}
            />

            <LabelAndData
                label="Created On Behalf Of"
                data={createdOnBehalfOf}
            />

            <LabelAndData
                label="Created At"
                data={createdAt}
            />

            <LabelAndData
                label="Status"
                data={status}
            />

            <LabelAndData
                label="Valuation Type"
                data={valuationType}
            />

            <LabelAndData
                label="Special Valuation Standards"
                data={valuationSpecial}
            />

            <LabelAndData
                label="Other Valuation Standards"
                data={valuationOther}
            />

            <LabelAndData
                label="Valuation Standards"
                data={valuationStandards}
            />

            <LabelAndData
                label="Extra Valuation Standards"
                data={valuationStandardsExtra}
            />

            <LabelAndData
                label="Other Valuation Standards"
                data={valuationStandardsOther}
            />

            <LabelAndData
                label="Valuation Purposes"
                data={valuationPurposes}
            />

            <LabelAndData
                label="Other Valuation Purposes"
                data={valuationPurposesOther}
            />

            <LabelAndData
                label="Valuer Experience"
                data={valuerExperience}
            />

            <LabelAndData
                label="Minimum Partners"
                data={valuerMinimumPartners}
            />

            <LabelAndData
                label="Max Operating Distance"
                data={valuerMaxOfficeDistance}
            />

            <LabelAndData
                label="Minimum PI Cover"
                data={valuerMinimumPICover}
            />

            <LabelAndData
                label="Minimum PI Cover Percentage"
                data={valuerMinimumPICoverPercentage}
            />

            <LabelAndData
                label="Completion Date"
                data={completionDate}
            />

            <LabelAndData
                label="Additional Requirements"
                data={additionalRequirements}
            />

            <LabelAndData
                label="Invoice First Name"
                data={invoiceFName}
            />

            <LabelAndData
                label="Invoice Last Name"
                data={invoiceLName}
            />

            <LabelAndData
                label="Invoice Email"
                data={invoiceEmail}
            />

            <LabelAndData
                label="Invoice Email CC"
                data={invoiceEmailCc}
            />

            <LabelAndData
                label="Invoice Mobile Dial Code"
                data={invoiceMobileDialCode}
            />

            <LabelAndData
                label="Invoice Mobile"
                data={invoiceMobile}
            />

            <LabelAndData
                label="Billing Street"
                data={billingStreet}
            />

            <LabelAndData
                label="Billing City"
                data={billingCity}
            />

            <LabelAndData
                label="Billing Country"
                data={billingCountry}
            />

            <LabelAndData
                label="Billing Postal Code"
                data={billingPostalCode}
            />

            <LabelAndData
                label="Updated At"
                data={updatedAt}
            />

            {/* <LabelAndData
                label="Max Operating Distance"
                data={properties}
            /> */}
        </VStack>
    );
};

export default AdminEnquiryDetails;
