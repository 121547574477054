import { AxiosResponse } from 'axios';
import { ClientInviteType } from '../../../../../types';
import { ClientInvitesCreateDto } from '../../../dto';
import { post } from '../../../request';

export type ClientInviteCreateResultType = {
    invite?: ClientInviteType;
    error?: string;
};

const clientInvitesCreate = (dto: ClientInvitesCreateDto): Promise<void | AxiosResponse<ClientInviteCreateResultType, any>> => post('/client-invites/create', dto);

export default clientInvitesCreate;
