import { Box, Center, VStack } from '@chakra-ui/react';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import { useNavigateUpUrl, useSmartTranslation } from '../../../../../common/hooks';
import { CaseReportDocumentType } from '../../../../../types';

type PropsType = {
    document: CaseReportDocumentType | undefined;
};

const CaseDocumentModal = (props: PropsType) => {
    const { document } = props;

    const t = useSmartTranslation('casereport');
    const navigateUpUrl = useNavigateUpUrl();

    if (!document) return null;

    const {
        name,
        url,
    } = document;

    const renderDocument = () => {
        const fileType = url.split('.').pop();
        const isPdf = fileType === 'pdf' || fileType === 'PDF';

        if (isPdf) {
            return (
                <Box h="50rem" w="100%">
                    <iframe
                        src={url}
                        width="100%"
                        height="100%"
                        title={name}
                    />
                </Box>
            );
        }

        return (
            <Center
                w="100%"
                h="100%"
                overflow="auto"
            >
                <Box h="100%">
                    <img
                        src={url}
                        style={{ objectFit: 'contain' }}
                        alt={name}
                    />
                </Box>
            </Center>
        );
    };

    return (
        <ModalDynamic
            isOpen
            header={t('title.document')}
            onClose={() => navigateUpUrl(2)}
        >
            <VStack spacing="6">
                {name}

                {renderDocument()}
            </VStack>
        </ModalDynamic>
    );
};

export default CaseDocumentModal;
