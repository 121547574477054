import {
    Heading,
    VStack,
    Text,
    Button,
    HStack,
    Box,
    Spacer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRole } from '../../../../../common/hooks';

const DraftEnquiryBanner = () => {
    const navigate = useNavigate();
    const { enquiryId } = useParams();

    const { userIsClient } = useRole();

    const { t } = useTranslation(['enquiries']);

    return (
        <VStack
            bg="red10"
            outline="red"
            borderRadius="10"
            pt="1rem"
            pb=".5rem"
            align="left"
        >
            <HStack>
                <VStack
                    align="flex-start"
                    paddingInline="1rem"
                    pt=".2rem"
                    pb=".5rem"
                >
                    <Heading
                        as="h4"
                        size="md"
                        color="red"
                    >
                        {t('enquiryBanner.cancelledBanner.sectionTitle', { ns: 'enquiries' })}
                    </Heading>

                    <Text>
                        {t('enquiryBanner.cancelledBanner.sectionContent', { ns: 'enquiries' })}
                    </Text>
                </VStack>

                <Spacer />

                {userIsClient && (
                    <Box paddingInline="3rem">
                        <Button
                            width="8rem"
                            borderRadius="30"
                            variant="danger"
                            padding=".5rem"
                            onClick={() => {
                                navigate(`/create-client-enquiry/enquiry-valuation-info/${enquiryId}`);
                            }}
                        >
                            {t('enquiryBanner.cancelledBanner.sectionButton', { ns: 'enquiries' })}
                        </Button>
                    </Box>
                )}
            </HStack>
        </VStack>
    );
};

export default DraftEnquiryBanner;
