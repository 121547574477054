import { Route } from "react-router-dom";
import ContactUs from "../../../../views/contact-us/ContactUs";
import FbAction from "../../../../views/fb-action/FbAction";
import LanguageRedirect from "../../../../views/language-redirect/LanguageRedirect";
import ProccessNotification from "../../../../views/process-notification/ProcessNotification";
import { ThemeV1Wrapper } from "../ThemeV1Wrapper";
import { directEnquiry } from "./directEnquiry";

export const global_routes = (
    <Route element={<ThemeV1Wrapper />}>
        <Route
            path="process-notification/:notificationId"
            element={<ProccessNotification />}
        />

        <Route
            path="fb-action"
            element={<FbAction />}
        />

        <Route
            path="contact-us"
            element={<ContactUs />}
        />

        <Route
            path="language-redirect/:lang/:destination"
            element={<LanguageRedirect />}
        />

        <Route
            path="language-redirect/:lang"
            element={<LanguageRedirect />}
        />

        {directEnquiry}
    </Route>
);
