import {
    Box,
    Button,
    createStandaloneToast,
    Flex,
    Heading,
    Input,
    Spacer,
    VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { EntityType } from '../../types';
import {
    useEntityGetValuerEntitiesBySearch,
    useValuerInvitesInvite,
    useValuerInvitesInviteEntity,
    useValuerInvitesSent,
} from '../../utils/api/hooks';
import { FormikForm, FormikInput } from '../forms';
import LoadingSpinner from './LoadingSpinner';
import { WvwText } from './typography';

type PropTypes = {
    onInviteSuccess?: () => void;
};

const ValuerInviteForm = (props: PropTypes) => {
    const { onInviteSuccess = () => { } } = props;

    const { t } = useTranslation(['account', 'profile', 'common', 'formik']);
    const { toast } = createStandaloneToast();

    const validationSchema = Yup.object({
        valuerCompanyName: Yup.string()
            .required(t('errors.required')),
        name: Yup.string()
            .required(t('errors.required')),
        email: Yup.string()
            .required(t('errors.required')),
    });

    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<'search' | 'invite'>('search');
    const [search, setSearch] = useState('');
    const [valuersList, setValuersList] = useState([] as EntityType[]);

    const {
        data: sentInvites = [],
        refresh,
    } = useValuerInvitesSent();

    const {
        update: getResults,
    } = useEntityGetValuerEntitiesBySearch({
        onSuccess: data => {
            setLoading(false);

            setValuersList(data.filter(
                (valuer: EntityType) => !sentInvites.some(
                    invite => invite.toEntityId === valuer.id,
                ),
            ));
        },
    });

    const { update } = useValuerInvitesInvite({
        onSuccess: () => {
            refresh();
            onInviteSuccess();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message || t('error.generic', { ns: 'common' }),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const { update: inviteEntity } = useValuerInvitesInviteEntity({
        onSuccess: () => {
            refresh();
            onInviteSuccess();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    useEffect(() => {
        if (search.length < 3) return;

        getResults(search);
    }, [search]);

    if (mode === 'invite') {
        return (
            <FormikForm
                initialValues={{
                    valuerCompanyName: '',
                    name: '',
                    email: '',
                }}
                validationSchema={validationSchema}
                onSubmit={values => {
                    update({
                        valuerCompanyName: values.valuerCompanyName,
                        valuerEmail: values.email,
                        valuerName: values.name,
                    });
                }}
            >
                <VStack
                    align="left"
                >
                    <Box
                        width="100%"
                        paddingBottom="1rem"
                    >
                        <WvwText color='wvwGrey60'>
                            {t('valuerPanelDetails.valuerExplainerText')}
                        </WvwText>
                    </Box>

                    <FormikInput
                        placeholder={t('companyDetails.name')}
                        name="valuerCompanyName"
                        type="text"
                        label={t('companyDetails.name')}
                    />

                    <Flex gap={2}>
                        <FormikInput
                            placeholder={t('profileDetails.name', { ns: 'profile' })}
                            name="name"
                            type="text"
                            label={t('profileDetails.name', { ns: 'profile' })}
                        />

                        <FormikInput placeholder={t('companyDetails.email')} name="email" type="email" label={t('companyDetails.email')} />
                    </Flex>

                    <Flex>
                        <Spacer />

                        <Button
                            width="15rem"
                            variant="primaryYellow"
                            alignSelf="flex-end"
                            type="submit"
                        >
                            {t('button.add', { ns: 'common' })}
                        </Button>
                    </Flex>
                </VStack>
            </FormikForm>
        );
    }

    return (
        <VStack
            align="left"
            spacing=".5rem"
        >
            <Box
                width="100%"
                paddingBottom="1rem"
            >
                <WvwText color='wvwGrey60'>
                    {t('valuerPanelDetails.valuerExplainerText')}
                </WvwText>
            </Box>

            <WvwText>
                {t('companyDetails.name')}
            </WvwText>
            <Input
                borderRadius="8"
                bg="white"
                h="3rem"
                placeholder={t('companyDetails.name')}
                value={search}
                onChange={e => {
                    setSearch(e.target.value);

                    if (e.target.value.length < 3) {
                        setLoading(false);
                    } else {
                        setLoading(true);
                    }
                }}
            />

            {loading && (
                <LoadingSpinner noText />
            )}

            {!loading && valuersList.length === 0 && search !== '' && (
                <Flex
                    alignItems="center"
                    borderRadius="8"
                    h="4rem"
                    paddingInline="1rem"
                >
                    <Heading
                        size="sm"
                    >
                        {t('noValuersFound')}
                    </Heading>

                    <Spacer />

                    <Button
                        variant="primaryYellow"
                        width="15rem"
                        onClick={() => setMode('invite')}
                    >
                        {t('inviteByEmail')}
                    </Button>
                </Flex>
            )}

            <VStack
                spacing="1rem"
                w="100%"
            >
                {!loading && valuersList.map(i => (
                    <Flex
                        alignItems="center"
                        bg="wvwGreen"
                        borderRadius="8"
                        key={i.id}
                        h="4rem"
                        paddingInline="1rem"
                        w="100%"
                    >
                        <Heading
                            color="white"
                            size="sm"
                        >
                            {i.name}
                        </Heading>

                        <Spacer />

                        <Button
                            variant="primaryYellow"
                            onClick={() => {
                                const alreadySent = sentInvites.some(
                                    invite => invite.toEntityId === i.id,
                                );

                                setValuersList(valuersList.filter(
                                    valuer => valuer.id !== i.id,
                                ));

                                if (!alreadySent) {
                                    inviteEntity(i.id);
                                } else {
                                    toast({
                                        title: t('error.error', { ns: 'common' }),
                                        description: t('error.alreadyInvited', { ns: 'common' }),
                                        status: 'error',
                                        duration: 5000,
                                        isClosable: true,
                                    });
                                }
                            }}
                        >
                            {t('button.add', { ns: 'common' })}
                        </Button>
                    </Flex>
                ))}
            </VStack>
        </VStack>
    );
};

ValuerInviteForm.defaultProps = {
    onInviteSuccess: () => { },
};

export default ValuerInviteForm;
