import {
    deleteObject,
    getDownloadURL,
    getMetadata,
    getStorage,
    listAll,
    ref,
    updateMetadata,
    uploadBytes,
} from "firebase/storage";
import cryptoRandomString from "crypto-random-string";
import { app } from "../firebase";
import { standardSystemStandardsOffered } from "../../common/vars/valuationsAndMembershipTypes";

const firebaseStorage = getStorage(app);

const CERTIFICATES = "certificates";
const LOGO = "logo";
const PROFILE_PICTURE = "profile-picture";
const SAMPLE_VALUATIONS = "sample-valuations";

export type FirebaseCertificateType = {
    type: string;
    file: any;
    label: string;
    meta: any;
    url: string;
};

const storage = {
    deleteEntityFile: async (fileUrl: string) => {
        const storageRef = ref(firebaseStorage, fileUrl);

        const result = await deleteObject(storageRef);

        return result;
    },
    deleteUserFile: async (fileUrl: string) => {
        const storageRef = ref(firebaseStorage, fileUrl);

        const result = await deleteObject(storageRef);

        return result;
    },
    getEntityCertificates: async (entityId: number | string) => {
        const storageRef = ref(
            firebaseStorage,
            `entity-${entityId}/${CERTIFICATES}`
        );

        const result = await listAll(storageRef);

        const filesByCategory = await Promise.all(
            result.prefixes.map(async (prefix) => {
                const type = prefix.fullPath.split("/").pop();

                const prefixResult = await listAll(prefix);

                const all = await Promise.all(
                    prefixResult.items?.map(async (item) => {
                        const downloadUrl = await getDownloadURL(item);
                        const meta = await getMetadata(item);

                        return {
                            type:
                                standardSystemStandardsOffered.find(
                                    (membership) =>
                                        membership.toLowerCase() === type
                                ) ||
                                type ||
                                "other",
                            file: item,
                            label: meta.customMetadata?.label || item.name,
                            meta,
                            url: downloadUrl,
                        };
                    })
                );

                return all;
            })
        );

        return filesByCategory.flat();
    },
    getEntityLogo: async (entityId: number | string): Promise<string> => {
        const storageRef = ref(firebaseStorage, `entity-${entityId}/${LOGO}`);

        const result = await listAll(storageRef);

        if (!result.items || result.items.length === 0) {
            return "";
        }

        const url = await getDownloadURL(result.items[0]);

        return url;
    },
    getEntityProfilePictureUrl: async (
        entityId: number | string
    ): Promise<string> => {
        const storageRef = ref(
            firebaseStorage,
            `entity-${entityId}/${PROFILE_PICTURE}`
        );

        const result = await listAll(storageRef);

        if (!result.items || result.items.length === 0) {
            return "";
        }

        const url = await getDownloadURL(result.items[0]);

        return url;
    },
    getEntitySampleValuations: async (entityId: number | string) => {
        const storageRef = ref(
            firebaseStorage,
            `entity-${entityId}/${SAMPLE_VALUATIONS}`
        );

        const result = await listAll(storageRef);

        const filesByCategory = await Promise.all(
            result.prefixes.map(async (prefix) => {
                const type = prefix.fullPath.split("/").pop();

                const prefixResult = await listAll(prefix);

                const all = await Promise.all(
                    prefixResult.items?.map(async (item) => {
                        const downloadUrl = await getDownloadURL(item);
                        const meta = await getMetadata(item);

                        return {
                            type,
                            file: item,
                            label: meta.customMetadata?.label || item.name,
                            meta,
                            url: downloadUrl,
                        };
                    })
                );

                return all;
            })
        );

        return filesByCategory.flat();
    },
    getUserCertificates: async (userId: number | string) => {
        const storageRef = ref(
            firebaseStorage,
            `user-${userId}/${CERTIFICATES}`
        );

        const result = await listAll(storageRef);

        const filesByCategory = await Promise.all(
            result.prefixes.map(async (prefix) => {
                const type = prefix.fullPath.split("/").pop();

                const prefixResult = await listAll(prefix);

                const all = await Promise.all(
                    prefixResult.items?.map(async (item) => {
                        const downloadUrl = await getDownloadURL(item);
                        const meta = await getMetadata(item);

                        return {
                            type:
                                standardSystemStandardsOffered.find(
                                    (membership) =>
                                        membership.toLowerCase() === type
                                ) ||
                                type ||
                                "other",
                            file: item,
                            label: meta.customMetadata?.label || item.name,
                            meta,
                            url: downloadUrl,
                        };
                    })
                );

                return all;
            })
        );

        return filesByCategory.flat();
    },
    getUserProfilePictureUrl: async (
        userId: number | string
    ): Promise<string> => {
        const storageRef = ref(
            firebaseStorage,
            `user-${userId}/${PROFILE_PICTURE}`
        );

        const result = await listAll(storageRef);

        if (!result.items || result.items.length === 0) {
            return "";
        }

        const url = await getDownloadURL(result.items[0]);

        return url;
    },
    updateFileMetadata: async (fileUrl: string, label: string) => {
        const storageRef = ref(firebaseStorage, fileUrl);

        const result = await updateMetadata(storageRef, {
            customMetadata: {
                label,
            },
        });

        return result;
    },
    uploadDocumentRequestDocument: async (
        caseId: string | number,
        requestId: string | number,
        file: File
    ) => {
        const storageRef = ref(
            firebaseStorage,
            `case-${caseId}/document-requests/${requestId}/${cryptoRandomString(
                { length: 10, type: "url-safe" }
            )}/${file.name}`
        );

        const snapshot = await uploadBytes(storageRef, file);

        return getDownloadURL(snapshot.ref);
    },
    uploadEnquiryDocumentsRetypeDocuments: async (
        enquiryId: string | number,
        files: File[]
    ) => {
        const uploadedFiles = await Promise.all(
            files.map(async (file) => {
                const storageRef = ref(
                    firebaseStorage,
                    `retype-documents/${cryptoRandomString({
                        length: 10,
                        type: "url-safe",
                    })}/${file.name}`
                );

                const snapshot = await uploadBytes(storageRef, file);

                return {
                    url: await getDownloadURL(snapshot.ref),
                    fileName: file.name,
                };
            })
        );

        return uploadedFiles;
    },
    uploadEnquiryDocumentsPropertyDocuments: async (files: File[]) => {
        const uploadedFiles = await Promise.all(
            files.map(async (file) => {
                const storageRef = ref(
                    firebaseStorage,
                    `property-documents/${cryptoRandomString({
                        length: 10,
                        type: "url-safe",
                    })}/${file.name}`
                );

                const snapshot = await uploadBytes(storageRef, file);

                return {
                    url: await getDownloadURL(snapshot.ref),
                    name: file.name,
                };
            })
        );

        return uploadedFiles;
    },
    uploadDocumentDirectDocument: async (
        caseId: string | number,
        folderId: string | number,
        file: File
    ) => {
        const storageRef = ref(
            firebaseStorage,
            `case-${caseId}/document-requests/${folderId}/direct-upload/${cryptoRandomString(
                { length: 10, type: "url-safe" }
            )}/${file.name}`
        );

        const snapshot = await uploadBytes(storageRef, file);

        return getDownloadURL(snapshot.ref);
    },
    uploadEntityCertificate: async (
        entityId: number,
        file: File,
        certificateType = "other",
        label?: string
    ) => {
        const storageRef = ref(
            firebaseStorage,
            `entity-${entityId}/${CERTIFICATES}/${certificateType.toLowerCase()}/${
                file.name
            }`
        );

        const snapshot = await uploadBytes(storageRef, file, {
            customMetadata: {
                label: label || file.name,
            },
        });

        return snapshot;
    },
    uploadEntityLogo: async (entityId: number, file: File) => {
        const listRef = ref(firebaseStorage, `entity-${entityId}/${LOGO}`);

        const result = await listAll(listRef);

        result.items.forEach((itemRef) => {
            deleteObject(itemRef);
        });

        const storageRef = ref(
            firebaseStorage,
            `entity-${entityId}/${LOGO}/${file.name}`
        );

        const snapshot = await uploadBytes(storageRef, file);

        return snapshot;
    },
    uploadEntityProfilePicture: async (entityId: number, file: File) => {
        const listRef = ref(firebaseStorage, "files/uid");

        const result = await listAll(listRef);

        result.items.forEach((itemRef) => {
            deleteObject(itemRef);
        });

        const storageRef = ref(
            firebaseStorage,
            `entity-${entityId}/${PROFILE_PICTURE}/${file.name}`
        );

        const snapshot = await uploadBytes(storageRef, file);

        return snapshot;
    },
    uploadEntitySampleValuations: async (
        entityId: number,
        file: File,
        valuationType: string,
        label?: string
    ) => {
        const storageRef = ref(
            firebaseStorage,
            `entity-${entityId}/${SAMPLE_VALUATIONS}/${valuationType.toLowerCase()}/${
                file.name
            }`
        );

        const snapshot = await uploadBytes(storageRef, file, {
            customMetadata: {
                label: label || file.name,
            },
        });

        return snapshot;
    },
    uploadUserCertificate: async (
        userId: number,
        file: File,
        certificateType = "other",
        label?: string
    ) => {
        const storageRef = ref(
            firebaseStorage,
            `user-${userId}/${CERTIFICATES}/${certificateType.toLocaleLowerCase()}/${
                file.name
            }`
        );

        const snapshot = await uploadBytes(storageRef, file, {
            customMetadata: {
                label: label || file.name,
            },
        });

        return snapshot;
    },
    uploadUserProfilePicture: async (userId: number, file: File) => {
        const listRef = ref(firebaseStorage, "files/uid");

        const result = await listAll(listRef);

        result.items.forEach((itemRef) => {
            deleteObject(itemRef);
        });

        const storageRef = ref(
            firebaseStorage,
            `user-${userId}/${PROFILE_PICTURE}/${file.name}`
        );

        const snapshot = await uploadBytes(storageRef, file);

        return snapshot;
    },
    uploadUserSampleValuations: async (
        userId: number,
        file: File,
        valuationType: string,
        label?: string
    ) => {
        const storageRef = ref(
            firebaseStorage,
            `user-${userId}/${SAMPLE_VALUATIONS}/${valuationType}/${file.name}`
        );

        const snapshot = await uploadBytes(storageRef, file, {
            customMetadata: {
                label: label || file.name,
            },
        });

        return snapshot;
    },
};

export default storage;
