/* eslint-disable max-len */
import { useFormikContext } from 'formik';
import { FormikCheckbox } from '.';

type PropTypes = {
    name: string;
    label: string;
    formFields: {
        name: string,
        value: any;
    }[];
};

const FormikFieldAutoFillCheckBox = (props: PropTypes) => {
    const {
        name,
        label,
        formFields,
    } = props;

    const context = useFormikContext();

    return (
        <FormikCheckbox
            name={name}
            label={label}
            onChange={async (event, field) => {
                const newValue = !field?.value;

                async function updateFieldValue(fieldName: string, value: any) {
                    await field?.onChange({ target: { name: fieldName, value } });
                    await context.setFieldTouched(fieldName, true, true);
                }

                if (newValue) {
                    formFields.map(async formField => {
                        await updateFieldValue(formField.name, formField.value);
                    });
                } else {
                    formFields.map(async formField => {
                        await updateFieldValue(formField.name, '');
                    });
                }
            }}
        />
    );
};

export default FormikFieldAutoFillCheckBox;
