import { useEffect, useState } from "react";

type ParamsType = {
    onEnd?: () => void;
};

type ReturnType = {
    startCountdown: (time: number) => void;
    timeLeft: number;
};
/**
 * Provides a countdown zero, with a fuction onEnd
 */
export const useCountdownTimer = (params?: ParamsType): ReturnType => {
    const { onEnd } = params || {};

    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        if (timeLeft < 1) return;

        const timerInterval = setInterval(() => {
            const nextTime = timeLeft - 1;

            if (nextTime < 1) {
                clearInterval(timerInterval);
                onEnd?.();
            }

            setTimeLeft(nextTime);
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [timeLeft]);

    const startCountdown = (time: number) => setTimeLeft(time);

    return {
        timeLeft,
        startCountdown,
    };
};
