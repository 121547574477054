import { FormikCloudSelect } from '../../../../common/forms';
import { useSmartTranslation } from '../../../../common/hooks';
import { standardPropertyTypes } from '../../../../common/vars/valuationsAndMembershipTypes';
import FormSectionProfileEdit from './FormSectionProfileEdit';

const ProfilePropertyTypesEdit = () => {
    const t = useSmartTranslation('profile');

    return (
        <FormSectionProfileEdit
            header={t('heading.propertyTypes')}
            subHeader={t('subHeading.propertyTypes')}
        >
            <FormikCloudSelect
                name="valuationPropertyTypes"
                options={standardPropertyTypes.map(propertyType => ({
                    label: t(propertyType, { ns: 'formik', defaultValue: propertyType }),
                    value: propertyType.toUpperCase(),
                }))}
            />
        </FormSectionProfileEdit>
    );
};

export default ProfilePropertyTypesEdit;
