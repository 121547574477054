import {
    HStack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    VStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { usePublicUser } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { WVWTitle } from '../../../../common/components/typography';
import AdminUserDetails from './common/AdminUserDetails';
import PublicUserProfilePicture from '../../../../common/components/PublicUserProfilePicture';
import AdminCompanyDetails from './common/AdminCompanyDetails';

const AdminUser = () => {
    const { userId = '' } = useParams<{ userId: string }>();

    const {
        data: user,
        isLoading: userLoading,
    } = usePublicUser(userId);

    if (userLoading || !user) {
        return <LoadingSpinner />;
    }

    const {
        email,
        entityId,
        firstName,
        lastName,
    } = user;

    return (
        <VStack
            align="start"
            spacing="4"
            w="100%"
        >
            <WVWTitle
                content="User Details"
                level="1"
            />

            <HStack spacing="5">
                <PublicUserProfilePicture
                    size="6rem"
                    entityId={entityId}
                    userId={userId}
                />

                <WVWTitle
                    content={`${firstName} ${lastName} - ${email}`}
                    level="2"
                />
            </HStack>

            <Tabs w="100%">
                <TabList>
                    <Tab>Details</Tab>
                    <Tab>Company</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <AdminUserDetails user={user} />
                    </TabPanel>

                    <TabPanel>
                        <AdminCompanyDetails entity={user.entity} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    );
};

export default AdminUser;
