const widgetInformationDateFilter = (
    currentMonthTimeFrame: { monthStartDate: Date; monthEndDate: Date },
    previousMonthTimeFrame: { monthStartDate: Date; monthEndDate: Date },
    dataList: {
        createdAt: string;
    }[],
) => {
    const currentMonthData = dataList.filter(data => {
        const valueDate = new Date(data.createdAt);
        return valueDate >= currentMonthTimeFrame.monthStartDate
            && valueDate <= currentMonthTimeFrame.monthEndDate;
    });

    const previousMonthEnquiries = dataList.filter(data => {
        const valueDate = new Date(data.createdAt);
        return valueDate >= previousMonthTimeFrame.monthStartDate
            && valueDate <= previousMonthTimeFrame.monthEndDate;
    });

    const currentMonthCount = currentMonthData.length;
    const previousMonthCount = previousMonthEnquiries.length;
    let dataDifferencePercentage = 0;
    if (previousMonthCount === 0 && currentMonthCount === 0) {
        dataDifferencePercentage = 0;
    } else if (previousMonthCount === 0) {
        dataDifferencePercentage = 100;
    } else {
        dataDifferencePercentage = (
            (currentMonthCount - previousMonthCount) / previousMonthCount) * 100;
    }

    return {
        dataDifferencePercentage: dataDifferencePercentage.toFixed(2),
        currentMonthCount,
        previousMonthCount,
    };
};

export default widgetInformationDateFilter;
