import { PropertyType } from "../../../../../../types";
import { DataTableColumnType } from "../DataTable";
import { Heading, Text, VStack } from "@chakra-ui/react";

type ColumnType = DataTableColumnType<PropertyType>;

const translationNameSpace =
    "common.components.dataTable.columns.propertyColumns";

export const propertyId: ColumnType = {
    title: () => "#",
    render: (p) => <Text>{p.id}</Text>,
};

export const propertyAddress: ColumnType = {
    title: (t) => t(`${translationNameSpace}.address`),
    render: (p) => {
        const { street, city, postalCode } = p;
        return (
            <VStack
                align="left"
                spacing={0}
            >
                <Heading size="xs">{street}</Heading>
                <Text
                    fontSize="14px"
                    variant="secondary"
                >
                    {`${postalCode} ${city}`}
                </Text>
            </VStack>
        );
    },
};

export const propertyType: ColumnType = {
    title: (t) => t(`${translationNameSpace}.propertyType`),
    render: (p, t) => {
        const { propertyTypes } = p;

        const propertyTypesLength = propertyTypes.length;
        return (
            <VStack
                align="left"
                spacing={0}
            >
                <Text>{propertyTypes[0]}</Text>
                <Text
                    fontSize="14px"
                    variant="secondary"
                >
                    {propertyTypesLength > 1
                        ? t(`${translationNameSpace}.moreText`, {
                              number: propertyTypesLength - 1,
                          })
                        : ""}
                </Text>
            </VStack>
        );
    },
};

export const area: ColumnType = {
    title: (t) => t(`${translationNameSpace}.area`),
    render: (p) => {
        const { areaOverall, areaGrossInternal } = p;
        return (
            <VStack
                align="left"
                spacing={0}
            >
                <Text>{`${areaOverall} m²`}</Text>
                <Text
                    fontSize="14px"
                    variant="secondary"
                >
                    {`${areaGrossInternal} m²`}
                </Text>
            </VStack>
        );
    },
};

export const estimateValue: ColumnType = {
    title: (t) => t(`${translationNameSpace}.estimateValue`),
    render: (p) => {
        const { estimateValue } = p;
        return (
            <Text
                fontWeight="bold"
                color="cyan.500"
            >
                {`€${estimateValue}`}
            </Text>
        );
    },
};

export const propertyColumns = {
    propertyId,
    propertyAddress,
    propertyType,
    area,
    estimateValue,
};
