import { useParams } from 'react-router-dom';
import GeneralEnquiryOverview from '../../../../common/components/display/enquiry-form/overview/GeneralEnquiryOverview';
import { useCaseGetCase } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

const CaseEnquiryOverview = () => {
    const { caseId = '' } = useParams();

    const {
        data: caseData,
        isLoading: caseIsLoading,
    } = useCaseGetCase(caseId);

    if (caseIsLoading || !caseData) return <LoadingSpinner />;

    return (
        <GeneralEnquiryOverview variant="white" overviewData={caseData.enquiry} acceptedOffer={caseData.acceptedOffer} />
    );
};

export default CaseEnquiryOverview;
