import { useContext } from 'react';
import {
    Flex,
    ButtonGroup,
    Spacer,
    Box,
} from '@chakra-ui/react';
import { FlowContext } from '../../../../../common/components/display/ProcessFlowRouted';
import { WvwButton } from '../../../../../common/components/inputs';
import { useSmartTranslation } from '../../../../../common/hooks';
import { FormikSubmitButton } from '../../../../../common/forms';

type PropTypes = {
    back?: boolean;
    handleSaveAndClose: () => void;
};

const ProfileCompletionFormFooter = (props: PropTypes) => {
    const t = useSmartTranslation('common');

    const { back, handleSaveAndClose } = props;

    const { goBack, finalStep } = useContext(FlowContext);

    return (
        <Flex width="100%">
            {back && (
                <Box width="15rem">
                    <WvwButton
                        variant="outline"
                        block
                        onClick={goBack}
                        content={t('button.back')}
                    />
                </Box>
            )}

            <Spacer />

            <ButtonGroup width={finalStep ? '15rem' : '30rem'}>
                <WvwButton
                    formSubmitButton
                    onClick={() => handleSaveAndClose()}
                    block
                    variant="outline"
                    content={t('button.saveAndClose')}
                />

                {!finalStep && (
                    <FormikSubmitButton
                        block
                        content={t('button.next')}
                    />
                )}
            </ButtonGroup>
        </Flex>
    );
};

ProfileCompletionFormFooter.defaultProps = {
    back: true,
};

export default ProfileCompletionFormFooter;
