import { LoginNamespace } from "../en/login";

export const loginDE: LoginNamespace = {
    instruction: {
        email: 'Ihre $t(Email,  { "ns": "common" })',
        password: 'Ihr $t(Password,  { "ns": "common" })',
        pleaseCheck: "Bitte überprüfen Sie {{item}}",
        pleaseEnter: "Bitte geben Sie {{item}} an",
    },
    link: {
        register: "Registrieren",
        resetPassword: "Passwort vergessen?",
    },
    loginHeading: "Login",
    noAccountQ: "Sie haben noch kein Konto?",
    rememberMe: "Angemeldet bleiben",
    welcomeBlurb: "Willkommen zurück, Sie wurden vermisst",
    wvwBlurb:
        '$t(wvw,  { "ns": "common" }) bietet Wertgutachtern ein maßgeschneidertes, hochmodernes Beschaffungs- und Prozessmanagementsystem für Gutachterinnen und Gutachter sowie für alle, die ein Wertgutachten benötigen.',
    wvwWelcome: "Willkommen zurück!",
};
