import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputRightAddon,
    InputRightElement,
    Text,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { useContext } from 'react';
import i18n from '../../../../i18n';
import { LabelAndInput } from '../components/display';
import { useSmartTranslation } from '../hooks/useSmartTranslation';
import { FormikValidationContext } from './FormikForm';

type PropTypes = {
    name: string;
    disabled?: boolean;
    label?: string;
    min?: string;
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    type?: string;
    leftinternal?: string | JSX.Element;
    rightinternal?: string | JSX.Element;
    width?: string;
    maxW?: string;
    onChange?: (value: string, field?: FieldInputProps<any>) => void,
    noSubmitOnEnter?: boolean;
};

const FormikInput = (props: PropTypes) => {
    const t = useSmartTranslation();

    const {
        name,
        prefix,
        disabled,
        label = '',
        min,
        placeholder,
        suffix,
        leftinternal,
        maxW,
        rightinternal,
        width,
        onChange,
        noSubmitOnEnter,
        type,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const isFieldRequired = useContext(FormikValidationContext);

    const isRequired = isFieldRequired(name);

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? ' *' : ''}`}
            input={(
                <>
                    <InputGroup>
                        {prefix && (
                            <InputLeftAddon
                                h="3rem"
                                minW="4rem"
                            >
                                {prefix}
                            </InputLeftAddon>
                        )}

                        {leftinternal && (
                            <InputLeftElement>
                                {leftinternal}
                            </InputLeftElement>
                        )}

                        <Input
                            min={min}
                            placeholder={placeholder}
                            borderRadius="8"
                            bg="white"
                            disabled={disabled}
                            position="relative"
                            h="3rem"
                            isInvalid={!!meta.touched && !!meta.error}
                            maxW={maxW}
                            w={width}
                            lang={i18n.language}
                            type={type}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onClick={e => e.stopPropagation()}
                            onKeyDown={e => {
                                e.stopPropagation();

                                if (noSubmitOnEnter && e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            onChange={e => {
                                field.onChange(e);

                                onChange?.(e.target.value, field);
                            }}
                        />

                        {rightinternal && (
                            <InputRightElement>
                                {rightinternal}
                            </InputRightElement>
                        )}

                        {suffix && (
                            <InputRightAddon
                                h="3rem"
                                minW="4rem"
                            >
                                {suffix}
                            </InputRightAddon>
                        )}
                    </InputGroup>

                    {meta.touched
                        && meta.error
                        && (
                            <Text color="red">
                                {t(meta.error, { defaultValue: meta.error })}
                            </Text>
                        )}
                </>
            )}
        />
    );
};

FormikInput.defaultProps = {
    disabled: false,
    label: '',
    leftinternal: null,
    maxW: undefined,
    min: undefined,
    onChange: () => { },
    placeholder: '',
    prefix: null,
    rightinternal: null,
    suffix: null,
    type: 'text',
    width: '100%',
    noSubmitOnEnter: false,
};

export default FormikInput;
