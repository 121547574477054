import { Outlet, useNavigate } from 'react-router-dom';
import MainLayout from '../../common/components/display/MainLayout';
import { useRole } from '../../common/hooks';
import LoadingSpinner from '../../common/components/LoadingSpinner';

const Admin = () => {
    const navigate = useNavigate();

    const { userIsSystemAdmin, roleLoading } = useRole();

    if (roleLoading) return <LoadingSpinner />;

    if (!userIsSystemAdmin) {
        navigate('/dashboard');
        return null;
    }

    return (
        <MainLayout>
            <Outlet />
        </MainLayout>
    );
};

export default Admin;
