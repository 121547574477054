import { documentRequestSubmitAllRequests } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';
import useMutationHook from '../useMutationHook';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestSubmitAllRequests = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { refreshDocumentManagementArea } = useRefreshAllDMSDataStores(Number(caseId));

    const mutation = useMutationHook({
        mutationFunction: (cId: number | string) => documentRequestSubmitAllRequests(cId),
        refreshes: [refreshDocumentManagementArea],
        successMessage: t('toast.messages.requestUpdated'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestSubmitAllRequests;
