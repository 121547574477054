import { extendTheme } from "@chakra-ui/react";

export const wvwPalette = {
    wvwBackground: {
        main: "gray.100",
        container: "blackAlpha.50",
    },
    wvwOrange: {
        50: "#FFF8ED",
        100: "#FFE3B2",
        200: "#FFD080",
        300: "#FFB433",
        400: "#FFA100",
        500: "#ff9002",
    },
    wvwGradients: {
        blue: "linear-gradient(90deg, #10528F 25.98%, #3396BF 100%)",
        orange: "linear-gradient(90deg, #FF8F00 0%, #FFB300 100%)",
    },
};

const theme = extendTheme({
    styles: {
        global: {
            body: {
                minHeight: "100vh",
                bg: wvwPalette.wvwBackground.main,
                color: "blackAlpha.800",
            },
        },
    },
    colors: {
        orange: {
            50: wvwPalette.wvwOrange[50],
            100: wvwPalette.wvwOrange[100],
            200: wvwPalette.wvwOrange[200],
            300: wvwPalette.wvwOrange[300],
            400: wvwPalette.wvwOrange[400],
            500: wvwPalette.wvwOrange[500],
        },
    },
    components: {
        Button: {
            variants: {
                primary: {
                    bgColor: "orange",
                    color: "black",
                },
                secondary: {
                    bgColor: "blue.500",
                    color: "white",
                },
                outline: {
                    border: "1px solid",
                    borderColor: "blue.500",
                    bg: "transparent",
                    color: "blue.500",
                },
            },
        },
        Heading: {
            variants: {
                blue: {
                    color: "blue.700",
                },
                grey: {
                    color: "gray.600",
                },
            },
        },
        Table: {
            baseStyle: {
                table: {
                    background: "gray.50",
                    borderCollapse: "collapse !important",
                    maxWidth: "100%",
                },
                th: {
                    fontWeight: "normal",
                    height: "3.5rem",
                    textTransform: "none",
                },
                td: {
                    backgroundColor: "white",
                    borderBottomWidth: "0 !important",
                    borderTopWidth: "1px",
                },
            },
            variants: {
                simple: {
                    th: {
                        height: "1.8em",
                    },
                },
            },
        },
        Text: {
            baseStyle: {
                fontSize: "initial",
            },
            variants: {
                main: {
                    color: "black",
                },
                secondary: {
                    color: "gray.500",
                },
            },
        },
    },
});

export default theme;
