import {
    Flex,
    VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WvwButton } from '../../../../../common/components/inputs';
import { WVWTitle } from '../../../../../common/components/typography';
import { useToast } from '../../../../../common/hooks';
import { useMyEntity } from '../../../../../utils/api/hooks';

const InviteLinkWidget = () => {
    const { t } = useTranslation(['account', 'dashboard']);

    const { createSuccessToast } = useToast();

    const {
        data,
        isLoading,
        refresh,
    } = useMyEntity();

    useEffect(() => {
        if (isLoading) return;
        if (data.identityToken) return;

        refresh();
    }, [data]);

    if (isLoading) return null;

    return (
        <Flex
            paddingInline="2.5rem"
            paddingBlock="1rem"
            backgroundColor="white"
            borderRadius="10"
            border="1px solid #e2e8f0"
            width="20rem"
            height="15rem"
            alignItems="top"
        >
            <VStack
                spacing="2.3rem"
                width="100%"
                alignItems="left"
                paddingTop="1rem"
            >
                <WVWTitle
                    content={t('shareEnquiryLink', { ns: 'dashboard' })}
                    level="3"
                    color="black"
                />

                <WvwButton
                    variant="primary"
                    content={t('integration.customerTypes.private', { ns: 'account' })}
                    onClick={() => {
                        navigator.clipboard.writeText(`${window.location.origin}/direct-enquiry-form/individual/${data.identityToken}`);
                        createSuccessToast(t('integration.buttonTitle'));
                    }}
                />

                <WvwButton
                    variant="primary"
                    content={t('integration.customerTypes.institution', { ns: 'account' })}
                    onClick={() => {
                        navigator.clipboard.writeText(`${window.location.origin}/direct-enquiry-form/company/${data.identityToken}`);
                        createSuccessToast(t('integration.buttonTitle'));
                    }}
                />
            </VStack>
        </Flex>
    );
};

export default InviteLinkWidget;
