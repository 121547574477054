import { useSmartTranslation } from '../../hooks';
import StatusBadge, { StatusBadgeColor, StatusBadgeTextColor } from './StatusBadge';

type PropTypes = {
    status: 'PREPARATION' | 'NEGOTIATION' | 'INSPECTION' | 'FINISHED';
};

const colors: { [index:string]: StatusBadgeColor } = {
    PREPARATION: 'blue10',
    NEGOTIATION: 'wvwYellow10',
    INSPECTION: 'purple10',
    FINISHED: 'green10',
};

const textColors: { [index:string]: StatusBadgeTextColor } = {
    PREPARATION: 'blue',
    NEGOTIATION: 'wvwYellow',
    INSPECTION: 'purple',
    FINISHED: 'green',
};

const StatusBadgeInspection = (props: PropTypes) => {
    const { status } = props;

    const t = useSmartTranslation('inspection');

    return (
        <StatusBadge
            color={colors[status]}
            status={t(`status.${status}`, { defaultValue: status })}
            textColor={textColors[status]}
        />
    );
};

export default StatusBadgeInspection;
