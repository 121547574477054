import TemplateCreation from '../../case/documents/common/TemplateCreation';

const ProfileTemplates = () => (
    <TemplateCreation
        enableCreation
        enableEditing
        caseId={undefined}
        enableCreationFromFolder={false}
        enableDragAndDrop={false}
    />
);

export default ProfileTemplates;
