export const translations = {
    de: {
        button: {
            login: "Login",
        },
        loginHeading: "Login",
        welcomeBlurb: "Willkommen zurück, Sie wurden vermisst",

        wvwWelcome: "Willkommen zurück!",
        wvwBlurb:
            '$t(common.wvw) bietet Wertgutachtern ein maßgeschneidertes, hochmodernes Beschaffungs- und Prozessmanagementsystem für Gutachterinnen und Gutachter sowie für alle, die ein Wertgutachten benötigen.',

        noAccountQ: "Sie haben noch kein Konto?",
        link: {
            register: "Registrieren",
            resetPassword: "Passwort vergessen?",
        },
        instruction: {
            pleaseEnter: "Bitte geben Sie {{item}} an",
            pleaseCheck: "Bitte überprüfen Sie {{item}}",
            password: "Ihr $t(common.password)",
            email: "Ihre $t(common.email)",
        },
        rememberMe: "Angemeldet bleiben",
        otpSent: "🇬🇧OTP sent to your mobile℀1️⃣",
        verifyLogin: "🇬🇧Verify your login℀1️⃣",
        error: {
            emailAlreadyInUse: "Diese E-Mail-Adresse wird bereits verwendet.",
            minLowercase:
                "Das Passwort muss mindestens 1 Kleinbuchstaben enthalten",
            minNumbers: "Das Passwort muss mindestens 1 Zahl enthalten",
            minSymbols:
                "Das Passwort muss mindestens 1 Sonderzeichen enthalten",
            minUppercase:
                "Das Passwort muss mindestens 1 Großbuchstaben enthalten",
            mapRequired:
                "Bitte bestätigen Sie Ihre Adresse und den Standort auf der Karte",
            passwordMustMatch: "Passwörter müssen übereinstimmen",
            requiresRecentLogin:
                "Die Sitzung ist abgelaufen. Bitte melden Sie sich erneut an, bevor Sie fortfahren.",
            tryAgain: "Anmeldefehler, bitte versuchen Sie es erneut.",
            tooShort: "Zu kurz",
            userNotFound:
                "Dieser Nutzername ist nicht bekannt. Bitte registrieren Sie sich für ein Nutzerkonto.",
            wrongPassword:
                "Falsches Passwort, bitte überprüfen Sie die Eingabe.",
        },
    },
    en: {
        button: {
            login: "Login",
        },
        loginHeading: "Login",
        welcomeBlurb: "Welcome back, you have been missed!",

        wvwWelcome: "Hello again!",
        wvwBlurb:
            '$t(common.wvw) offers valuers a customised, state-of-the-art procurement and process management system for valuers, and those in need of a valuation.',

        noAccountQ: "Don't have an account?",
        link: {
            register: "Register",
            resetPassword: "Forgot password?",
        },
        instruction: {
            pleaseEnter: "Please enter {{item}}",
            pleaseCheck: "Please check {{item}}",
            password: 'your $t(common.password)',
            email: 'your $t(common.email)',
        },
        rememberMe: "Remember me",
        otpSent: "🇬🇧OTP sent to your mobile℀1️⃣",
        verifyLogin: "🇬🇧Verify your login℀1️⃣",
        error: {
            emailAlreadyInUse: "Email already in use, please try again.",
            requiresRecentLogin: "Please login again to continue.",
            userNotFound:
                "User not found, please consider creating an account.",
            wrongPassword: "Wrong password, please check.",
            tryAgain: "Authorization error, please try again.",
            minLowercase: "Password must contain at least 1 lower case letter",
            minNumbers: "Password must contain at least 1 number",
            minSymbols: "Password must contain at least 1 special character",
            minUppercase: "Password must contain at least 1 upper case letter",
            mapRequired: "Please confirm your address and map location",
            passwordMustMatch: "Passwords must match",
            tooShort: "Too short",
        },
    },
};
