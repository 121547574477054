import { documentRequestGetDocument } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const DOCUMENT_REQUEST_GET_DOCUMENT = 'DOCUMENT_REQUEST_GET_DOCUMENT';

const useDocumentRequestGetDocument = (documentId: string | number) => {
    const query = useQueryHook({
        uniqueName: DOCUMENT_REQUEST_GET_DOCUMENT,
        dependancies: [documentId],
        requiredParams: [documentId],
        queryFunction: () => documentRequestGetDocument(documentId),
    });

    return query;
};

export default useDocumentRequestGetDocument;
