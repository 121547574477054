import {
    EnquiryWithFromUserAndFromEntityType,
    OfferOnlyType,
    CaseType,
} from "../../../../../../types";
import { StatisticsFunction } from "../../../../common/features/statistics/Statistics";
import { TranslationFunction } from "../../../../common/hooks/useSmartTranslation";

export const activeEnquiriesStatistic: StatisticsFunction = (
    enquiries: EnquiryWithFromUserAndFromEntityType[]
) => {
    const activeEnquiriesCount = enquiries.filter(
        (enquiry) => enquiry.status === "PENDING"
    ).length;

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.enquiries.active"),
        data: activeEnquiriesCount,
        key: "clientDashboard.statistics.enquiries.active"
    };
};

export const clientAverageOffersStatistic: StatisticsFunction = (
    enquiries: EnquiryWithFromUserAndFromEntityType[],
    offers: OfferOnlyType[]
) => {
    const enquiriesCount = enquiries.length;
    const offersCount = offers.length;

    const averageOffers = Number((offersCount / enquiriesCount).toFixed(2));

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.enquiries.avgOffers"),
        data: averageOffers,
        key: "clientDashboard.statistics.enquiries.avgOffers"
    };
};

export const activeCasesStatistic: StatisticsFunction = (cases: CaseType[]) => {
    const activeCasesCount = cases.filter(
        (casedata) => casedata.status === "PENDING"
    ).length;

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.cases.active"),
        data: activeCasesCount,
        key: "clientDashboard.statistics.cases.active"
    };
};

export const completedCasesStatistic: StatisticsFunction = (cases: CaseType[]) => {
    const activeCasesCount = cases.filter(
        (casedata) => casedata.status === "COMPLETED"
    ).length;

    return {
        title: (t: TranslationFunction) =>
            t("clientDashboard.statistics.cases.completed"),
        data: activeCasesCount,
        key: "clientDashboard.statistics.cases.completed"
    };
};
