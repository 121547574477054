import { suspendAccount } from '../endpoints';
import useUsersByEntity from './useUsersByEntity';
import useMutationHook from './useMutationHook';
import { useSmartTranslation } from '../../../common/hooks';

type ParamsType = {
    entityId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useSuspendAccount = (params: ParamsType) => {
    const {
        entityId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('common');

    const { refresh: refreshUsers } = useUsersByEntity(Number(entityId));

    const mutation = useMutationHook({
        mutationFunction: (dto: { entityId: string | number, targetUserId: string | number }) => (
            suspendAccount(dto.entityId, dto.targetUserId)
        ),
        refreshes: [refreshUsers],
        successMessage: t('success.accountSuspended'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useSuspendAccount;
