import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SplitLayout from '../../../../common/components/SplitLayout';
import ModalDynamic from '../../../../common/components/ModalDynamic';
import InspectionDetailsActionArea from './InspectionDetailsActionArea';
import { CalendarEntryType } from '../../../../types/calendar/CalendarEntryType';
import PropertyOverview from '../../../../common/components/PropertyOverview';
import { useInspectionListContext } from './Inspection';

const ScheduledInspectionModal = () => {
    const { t } = useTranslation('inspection');
    const { inspectionId } = useParams();
    const navigate = useNavigate();

    const { inspectionList } = useInspectionListContext();

    const inspection = useMemo(() => {
        if (!inspectionList) return undefined;

        return inspectionList.find(i => i.id === Number(inspectionId));
    }, [inspectionId, inspectionList]);

    if (!inspection) return null;

    return (
        <ModalDynamic
            header={t('title.scheduledInspection')}
            isOpen
            onClose={() => navigate('../', { replace: true })}
        >
            <SplitLayout
                splitPercentage={35}
                titleLeft={t('title.inspectionDetails')}
                titleRight={t('title.propertyDetails')}
                contentLeft={(
                    <InspectionDetailsActionArea
                        calendarEntry={inspection.cause.calendarEntries[0] as CalendarEntryType}
                        inspection={inspection}
                    />
                )}
                contentRight={(
                    <PropertyOverview
                        property={inspection.property}
                    />
                )}
            />
        </ModalDynamic>
    );
};

export default ScheduledInspectionModal;
