import {
    OfferOnlyType
} from "../../../../../../types";

type Props = {
    offers: OfferOnlyType[];
};

export const CompanyOffers = (props: Props) => {
    return <div>CompanyOffers</div>;
};
