import { Center, Spinner, Text, VStack } from "@chakra-ui/react";
import { useSmartTranslation } from "../hooks/useSmartTranslation";

type PropTypes = {
    noText?: boolean;
};

const LoadingSpinner = (props: PropTypes) => {
    const { noText } = props;

    const t = useSmartTranslation();

    const color = "wvwYellow";
    const size = noText ? "xl" : "lg";
    const thickness = noText ? "4px" : undefined;

    return (
        <Center
            w="100%"
            h="100%"
        >
            <VStack
                spacing="4"
                w="100%"
            >
                <Spinner
                    color={color}
                    size={size}
                    thickness={thickness}
                />

                {!noText && <Text>{t("common.loading")}</Text>}
            </VStack>
        </Center>
    );
};

LoadingSpinner.defaultProps = {
    noText: false,
};

export default LoadingSpinner;
