import { HStack, VStack } from '@chakra-ui/react';
import { useSmartTranslation } from '../../../../../common/hooks';
import { FormikCheckbox, FormikCurrencyInput, FormikInput } from '../../../../../common/forms';
import EditCompanyPiDocs from '../../documents/common/EditCompanyPiDocs';
import { WVWTitle } from '../../../../../common/components/typography';

/**
 * Formik form section for editing the current user's company pi cover
 */
const CompanyDetailsPiCoverEdit = () => {
    const t = useSmartTranslation('account');

    return (
        <VStack
            width="100%"
            align="left"
        >
            <WVWTitle
                content={t('heading.piCover')}
                level="2"
                color="black"
            />

            <HStack
                align="end"
                spacing="6"
                w="100%"
                flexWrap={{
                    base: 'wrap',
                    md: 'nowrap',
                }}
            >
                <FormikCurrencyInput
                    name="piCoverValue"
                    label={t('valuationCapabilities.piCover.maxOf')}
                    placeholder="-"
                    prefix={t('unitSymbol.currency', { ns: 'common' })}
                />

                <FormikCurrencyInput
                    name="piCoverPercentage"
                    label={t('valuationCapabilities.piCover.orPercentage')}
                    placeholder="-"
                    prefix="%"
                />

                <FormikInput
                    noSubmitOnEnter
                    name="piCoverValidityDate"
                    label={t('valuationCapabilities.piCover.ValidUntil')}
                    type="date"
                />
            </HStack>

            <FormikCheckbox
                name="piCoverSpecialArrangementsAvailable"
                label={t('valuationCapabilities.piCover.specialArrangementsAvailable')}
            />

            <EditCompanyPiDocs />
        </VStack>
    );
};

export default CompanyDetailsPiCoverEdit;
