import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    VStack,
    Link as ChakraLink,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link as ReactLink } from 'react-router-dom';
import { Eye } from 'iconsax-react';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useAdminEnquiries } from '../../../utils/api/hooks';
import { WvwText } from '../../../common/components/typography';
import DataTable from '../../../common/components/datatable/DataTable';
import { EnquiryType } from '../../../types';
import delimitNumber from '../../../common/functions/delimitNumber';
import StatusBadgeEnquiry from '../../../common/components/display/StatusBadgeEnquiry';
import { displayDateWithCurrentLocale } from '../../../common/functions/displayDateInLocale';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const AdminEnquiries = () => {
    const { t } = useTranslation('dashboard');

    const { data: enquiries, isLoading } = useAdminEnquiries({});

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const headers = [
        {
            title: t('enquiriesTable.header.client'),
            maxWidth: '15rem',
            render: (enquiry: EnquiryType) => `${enquiry.invoiceFName} ${enquiry.invoiceLName}`,
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aName = `${a.invoiceFName} ${a.invoiceLName}`;
                const bName = `${b.invoiceFName} ${b.invoiceLName}`;

                return aName.localeCompare(bName);
            },
        },
        {
            title: t('enquiriesTable.header.property'),
            maxWidth: '15rem',
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aProperty = a?.properties?.[0]?.street || `${a?.properties?.length || 0} properties`;
                const bProperty = b?.properties?.[0]?.street || `${b?.properties?.length || 0} properties`;

                return aProperty.localeCompare(bProperty);
            },
            render: (i: EnquiryType) => {
                const { properties = [] } = i || {} as EnquiryType;

                if (properties.length === 1) {
                    return (
                        <WvwText>
                            {properties[0].street}
                        </WvwText>
                    );
                }

                return (
                    <WvwText>
                        {t('property_wc', { count: properties.length, ns: 'common' })}
                    </WvwText>
                );
            },
        },
        {
            title: t('enquiriesTable.header.estimateValue'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const { properties: aProperties = [] } = a;
                const { properties: bProperties = [] } = b;

                const aEstimateValue = aProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                const bEstimateValue = bProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                return aEstimateValue - bEstimateValue;
            },
            render: (i: EnquiryType) => {
                const { properties = [] } = i;

                const estimateValue = properties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                return (
                    <WvwText>
                        {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(estimateValue)}`}
                    </WvwText>
                );
            },
        },
        {
            title: t('enquiriesTable.header.status'),
            maxWidth: '10rem',
            render: (i: EnquiryType) => <StatusBadgeEnquiry status={i.status} />,
            sorter: (a: EnquiryType, b: EnquiryType) => a.status.localeCompare(b.status),
        },
        {
            title: t('enquiriesTable.header.dueDate'),
            dataIndex: 'completionDate',
            maxWidth: '10rem',
            sorter: (a: EnquiryType, b: EnquiryType) => {
                if (b.completionDate.replace(/-/g, '') < a.completionDate.replace(/-/g, '')) {
                    return -1;
                }
                if (b.completionDate.replace(/-/g, '') > a.completionDate.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: t('enquiriesTable.header.creationDate'),
            maxWidth: '10rem',
            render: (i: EnquiryType) => (
                <WvwText>
                    {displayDateWithCurrentLocale(i.createdAt)}
                </WvwText>
            ),
            sorter: (a: EnquiryType, b: EnquiryType) => (
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            ),
        },
        {
            title: t('tableHeading.matchedValuers', { ns: 'admin', defaultValue: 'Matched Valuers' }),
            render: (i: EnquiryType) => i.toUserIds.length,
            sorter: (a: EnquiryType, b: EnquiryType) => a.toUserIds.length - b.toUserIds.length,
        },
        {
            maxWidth: '10rem',
            render: (i: EnquiryType) => (
                <VStack
                    align="center"
                    w="100%"
                >
                    <Menu>
                        <MenuButton
                            as={Button}
                            variant="primary"
                            borderRadius="30"
                            size="sm"
                            rightIcon={<ChevronDownIcon />}
                        >
                            {t('enquiriesTable.button.actions')}
                        </MenuButton>

                        <MenuList>
                            <ChakraLink
                                as={ReactLink}
                                to={`/admin/enquiry/${i.id}`}
                            >
                                <MenuItem
                                    icon={<Eye size="1.2rem" />}
                                >
                                    {t('enquiriesTable.button.viewEnquiry')}
                                </MenuItem>
                            </ChakraLink>

                        </MenuList>
                    </Menu>
                </VStack>
            ),
        },
    ];

    const enquiriesFilters = [
        {
            filterName: 'Enquiry status',
            placeholder: 'Status',
            options: [
                { label: t('filters.enquiries.status.ACCEPTED', { ns: 'admin' }), value: 'ACCEPTED' },
                { label: t('filters.enquiries.status.CANCELLED', { ns: 'admin' }), value: 'CANCELLED' },
                { label: t('filters.enquiries.status.DORMANT', { ns: 'admin' }), value: 'DORMANT' },
                { label: t('filters.enquiries.status.DRAFT', { ns: 'admin' }), value: 'DRAFT' },
                { label: t('filters.enquiries.status.PENDING', { ns: 'admin' }), value: 'PENDING' },
            ],
            filterFunction:
                (i: EnquiryType, filterValue?: string) => i.status === filterValue,
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Enquiries">
            <DataTable
                columns={headers}
                data={enquiries}
                filters={enquiriesFilters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminEnquiries;
