import { Flex, Text, VStack } from "@chakra-ui/react";
import { PropertyType } from "../../../../types";
import { wvwPalette } from "../../theme/theme-v2";

type Property3LinerProps = {
    property: PropertyType;
};

const Property3Liner = (props: Property3LinerProps) => {
    const {
        street,
        postalCode,
        city,
        country,
        buildYear,
        propertyCondition,
        // commercial,
        // residential,
        // agricultural,
        areaOverall,
    } = props.property;

    // FIXME determine usage type/s
    const usage = "Residential";

    return (
        <VStack
            spacing={0}
            w="100%"
            padding=".5em 1em"
            bg={wvwPalette.wvwBackground.container}
            borderRadius={8}
            align="left"
        >
            <Text>{street}</Text>
            <Text>
                {postalCode} {city} {country}
            </Text>
            <Text variant="grey">
                {buildYear} {propertyCondition} {usage} {areaOverall}
            </Text>
        </VStack>
    );
};

export { Property3Liner as ThreeLiner };
