import { useTranslation } from 'react-i18next';
import StatusBadge, { StatusBadgeColor, StatusBadgeTextColor } from './StatusBadge';

type PropTypes = {
    status: 'ACCEPTED' | 'CANCELLED' | 'DORMANT' | 'DRAFT' | 'PENDING' | 'REJECTED' | 'WITHDRAWN' | 'AWAITING_OFFERS' | 'AWAITING_YOUR_OFFER' | 'AWAITING_ACCEPTANCE'; // The status of the parent Enquiry
};

const colors: { [index: string]: StatusBadgeColor } = {
    ACCEPTED: 'green10',
    AWAITING_OFFERS: 'wvwYellow10',
    AWAITING_YOUR_OFFER: 'wvwYellow10',
    AWAITING_ACCEPTANCE: 'wvwYellow10',
    CANCELLED: 'red10',
    DORMANT: 'wvwGrey05',
    DRAFT: 'blue10',
    PENDING: 'wvwYellow10',
    REJECTED: 'orange10',
    WITHDRAWN: 'purple10',
};

const textColors: { [index: string]: StatusBadgeTextColor } = {
    ACCEPTED: 'green',
    AWAITING_OFFERS: 'wvwYellow',
    AWAITING_YOUR_OFFER: 'wvwYellow',
    AWAITING_ACCEPTANCE: 'wvwYellow',
    CANCELLED: 'red',
    DORMANT: 'wvwGrey60',
    DRAFT: 'blue',
    PENDING: 'wvwYellow',
    REJECTED: 'orange',
    WITHDRAWN: 'purple',
};

const StatusBadgeEnquiry = (props: PropTypes) => {
    const { status } = props;

    const { t } = useTranslation('offer');

    return (
        <StatusBadge
            color={colors[status]}
            status={t(`status.${status}`, { defaultValue: status })}
            textColor={textColors[status]}
        />
    );
};

export default StatusBadgeEnquiry;
