import { Input } from '@chakra-ui/react';
import { ChangeEventHandler } from 'react';
import i18n from '../../../i18n';

type PropTypes = {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
    isDisabled?: boolean;
};

const WvwInput = (props: PropTypes) => {
    const {
        value,
        onChange,
        placeholder,
        isDisabled,
    } = props;

    return (
        <Input
            borderRadius="8"
            bg="white"
            disabled={isDisabled}
            position="relative"
            h="3rem"
            w="100%"
            lang={i18n.language}
            onClick={e => e.stopPropagation()}
            onKeyDown={e => e.stopPropagation()}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
        />
    );
};

WvwInput.defaultProps = {
    placeholder: '',
    isDisabled: false,
};

export default WvwInput;
