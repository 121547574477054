import { HStack, VStack } from "@chakra-ui/layout";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { EnquiryRequirements } from "../../../../domain/enquiry/enquiryRequirements/EnquiryRequirements";
import { testEnquiry } from "../../../../domain/enquiry/testdata";
import { testOffers } from "../../../../domain/offer/testdata";
import { Section } from "../../../../layout";
import { CompanyOffers } from "./CompanyOffers";
import { ValuerEnquiryTitle } from "./EnquiryTitle";
import { MyOffer } from "./MyOffer";
import { PropertiesMap } from "./PropertiesMap";
import { PropertyPortfolio } from "./PropertyPortfolio";
import { ValuerPropertyDetails } from "./ValuerPropertyDetails";

const translationNS = "screens.platform.valuer.valuerEnquiryOverview";

const EnquiryOverview = () => {
    const t = useSmartTranslation();

    // enquiry

    const enquiry = testEnquiry;

    const title = (
        <ValuerEnquiryTitle
            enquiry={enquiry}
            onArchive={() => console.log("archive enquiry")}
        />
    );

    const requestedValuation = (
        <Section
            title={t("domain.enquiry.requestedValuationTitle")}
            content={<EnquiryRequirements enquiry={enquiry} />}
        />
    );

    const propertyPortfolio = (
        <Section
            title={t(`${translationNS}.propertyPortfolio.title`)}
            content={<PropertyPortfolio properties={enquiry.properties} />}
        />
    );

    const map = <PropertiesMap properties={enquiry.properties} />;

    const selectedProperty = enquiry.properties[0];

    const propertyDetails = (
        <Section
            title={selectedProperty.street}
            content={<ValuerPropertyDetails property={selectedProperty} />}
        />
    );

    const offers = testOffers;

    const companyOffers = (
        <Section
            title={t(`${translationNS}.companyOffers.title`, {
                nCompanyOffers: 2,
            })}
            content={<CompanyOffers offers={offers} />}
        />
    );

    const offer = offers[0];

    const myOffer = (
        <Section
            title={t(`${translationNS}.myOffer.title`)}
            content={<MyOffer offer={offer} />}
        />
    );

    // render

    return (
        <VStack
            w={"100%"}
            padding=".5em"
            spacing={4}
        >
            {title}
            <HStack w={"100%"}>
                <VStack>
                    {requestedValuation}
                    {propertyPortfolio}
                </VStack>
                <VStack>
                    {map}
                    {propertyDetails}
                </VStack>
                <VStack>
                    {companyOffers}
                    {myOffer}
                </VStack>
            </HStack>
        </VStack>
    );
};

export default EnquiryOverview;
