import { VStack, HStack, Text, Circle, Spacer, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import { useMe, usePublicUser } from "../../utils/api/hooks";
import { UserType } from "../../types";
import { displayDateWithCurrentLocale } from "../functions/displayDateInLocale";
import { WvwText } from "./typography";
import ProfilePicture from "./display/ProfilePicture";

type PropTypes = {
    message: string;
    readBy: number[];
    createdAt: Date;
    id: number;
    fromUserId: number | string;
    notification?: boolean;
    obscureDetails?: boolean;
};

const Comment = (props: PropTypes) => {
    const { t } = useTranslation("common");

    const {
        message,
        readBy,
        createdAt,
        id,
        fromUserId,
        notification,
        obscureDetails,
    } = props;

    const createdTime = new Date(createdAt).toLocaleTimeString(
        t("dateAndTime"),
        { hour12: false, hour: "2-digit", minute: "2-digit" }
    );

    const createdDate = displayDateWithCurrentLocale(createdAt);

    const currentDateTime = `${createdDate} ${createdTime}`;

    const { data: currentUser, isLoading: currentUserIsLoading } = useMe();

    const {
        data: user = {} as UserType,
        isLoading: userLoading,
        profilePictureUrl = "",
    } = usePublicUser(
        fromUserId,
        obscureDetails && fromUserId !== currentUser?.id
    );

    if (!currentUser || userLoading || currentUserIsLoading)
        return <LoadingSpinner />;

    return (
        <VStack
            padding="1.2rem"
            bgColor="wvwGrey05"
            w="100%"
            borderRadius="10"
            alignSelf={
                fromUserId === currentUser.id ? "flex-end" : "flex-start"
            }
            key={id}
            spacing="3"
            align="left"
        >
            {!readBy.includes(currentUser.id) &&
                fromUserId !== currentUser.id &&
                notification && (
                    <HStack
                        alignSelf="flex-end"
                        marginTop="-.5rem"
                        marginBottom="-1.5rem"
                        marginRight="1rem"
                        spacing="1"
                    >
                        <Circle
                            size=".5rem"
                            bg="wvwYellow"
                        />

                        <Text color="wvwYellow">{t("new")}</Text>
                    </HStack>
                )}

            <Flex alignItems="flex-start">
                <ProfilePicture
                    url={profilePictureUrl}
                    size="2.5rem"
                />

                <VStack
                    align="right"
                    pl=".5rem"
                    spacing="0"
                >
                    <Text fontWeight="500">
                        {`${user.firstName} ${user.lastName}`}
                    </Text>

                    <WvwText color="wvwGrey60">{user.entity.name}</WvwText>
                </VStack>

                <Spacer />
            </Flex>

            <Text>{message}</Text>

            <Text
                color="wvwGrey40"
                fontSize=".7rem"
                alignSelf="flex-end"
            >
                {currentDateTime.toLocaleString()}
            </Text>
        </VStack>
    );
};

Comment.defaultProps = {
    notification: true,
    obscureDetails: false,
};

export default Comment;
