import { Box, VStack } from '@chakra-ui/react';
import { FormikListInput, FormikCheckboxGroup } from '../../../../common/forms';
import { parkingTypes } from '../../../../common/vars/valuationsAndMembershipTypes';
import { useSmartTranslation } from '../../../../common/hooks';
import { WvwText } from '../../../../common/components/typography';

const ExtraPropertyFeatures = () => {
    const t = useSmartTranslation('enquiryform');

    return (
        <VStack
            align="left"
            spacing="6"
        >
            <Box w="100%">
                <WvwText bold>
                    {t('newEnquiry.addingProperty.propertyDescription.parking.sectionTitle')}
                </WvwText>

                <FormikCheckboxGroup
                    name="propertyFeatures"
                    variant="outline"
                    options={parkingTypes.map(featureType => ({
                        label: t(featureType, { ns: 'formik', defaultValue: featureType }),
                        value: featureType,
                        key: featureType,
                    }))}
                />
            </Box>

            <Box paddingBottom="0.5rem" w="100%">
                <WvwText bold>
                    {t('newEnquiry.addingProperty.propertyDescription.features.sectionTitle')}
                </WvwText>
                <FormikListInput
                    name="otherFeatures"
                    placeholder={t('addPropertyFeature', { ns: 'wizard' })}
                />
            </Box>
        </VStack>

    );
};

export default ExtraPropertyFeatures;
