import {
    Container,
    Flex,
    VStack,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import LanguageToggleButton from '../../../common/components/LanguageToggleButton';
import Logo from '../../../common/components/Logo';
import { FormikForm, FormikPassword, FormikSubmitButton } from '../../../common/forms';
import { useFbConfirmPasswordReset } from '../../../utils/api/hooks';
import { confirmPasswordValidation, passwordValidation } from '../../../common/vars/passwordValidation';
import { useSmartTranslation } from '../../../common/hooks';
import { WvwButton, WvwButtonBack } from '../../../common/components/inputs';
import { WVWTitle, WvwText } from '../../../common/components/typography';
import WvwVStack from '../../../common/components/display/WvwVStack';

type PropTypes = {
    oobCode: string;
};

const validationSchema = Yup.object({
    password: passwordValidation,
    confirmPassword: confirmPasswordValidation,
});

const ResetPassword = (props: PropTypes) => {
    const { oobCode } = props;

    const t = useSmartTranslation('common');

    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        update: resetPassword,
        busy: isResetting,
    } = useFbConfirmPasswordReset({
        onSuccess: () => onOpen(),
    });

    return (
        <Box
            w="100%"
            h="100vh"
            bgColor="wvwGreen"
            paddingTop="2rem"
        >
            <Container
                maxW="container.lg"
                bg="white"
                borderRadius="lg"
                p="2em"
                w="100%"
                marginTop="1.5rem"
            >
                <VStack
                    spacing="8"
                    pb="4em"
                    w="100%"
                >
                    <Flex
                        justifyContent="space-between"
                        w="100%"
                    >
                        <WvwButtonBack to="/login" />

                        <LanguageToggleButton />
                    </Flex>

                    <Logo withColor />

                    <WVWTitle
                        level="1"
                        content={t('resetPassword')}
                    />

                    <WvwText>
                        {t('enterNewPassword')}
                    </WvwText>

                    <FormikForm
                        submitting={isResetting}
                        validationSchema={validationSchema}
                        initialValues={{
                            password: '',
                            confirmPassword: '',
                        }}
                        onSubmit={values => resetPassword({
                            oobCode,
                            newPassword: values.password,
                        })}
                    >
                        <Flex justifyContent="space-around">
                            <Box
                                maxW="24rem"
                                w="100%"
                            >
                                <WvwVStack>
                                    <FormikPassword
                                        name="password"
                                        placeholder="Password"
                                    />

                                    <FormikPassword
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                    />

                                    <Flex justifyContent="space-around">
                                        <FormikSubmitButton
                                            block
                                            content={t('button.reset')}
                                        />
                                    </Flex>
                                </WvwVStack>
                            </Box>
                        </Flex>
                    </FormikForm>
                </VStack>
            </Container>

            <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack
                            paddingBlock="6rem"
                            spacing="4"
                        >
                            <CheckCircleIcon boxSize="3rem" color="#38A169" />

                            <WVWTitle
                                level="1"
                                color="black"
                                content={t('success.success')}
                            />

                            <WvwText
                                color="wvwGrey60"
                                bold
                                textAlign="center"
                            >
                                {t('yourPasswordHasBeenReset')}
                            </WvwText>
                        </VStack>
                    </ModalBody>

                    <ModalFooter justifyContent="center">
                        <WvwButton
                            variant="primary"
                            block
                            onClick={() => {
                                onClose();
                                navigate('/login');
                            }}
                            content={t('button.takeMeThere')}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ResetPassword;
