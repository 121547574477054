import { VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useDocumentRequestGetUserFileList } from "../../../../../utils/api/hooks";
import DocumentListDisplay from "./DocumentListDisplay";
import UploadDocumentToActionArea from "./UploadDocumentToActionArea";

const DocumentUpload = () => {
    const { caseId: caseIdString } = useParams();

    const caseId = Number(caseIdString);

    const { data: documentsList = [] } =
        useDocumentRequestGetUserFileList(caseId);

    return (
        <VStack
            w="100%"
            h="100%"
            align="left"
            paddingBlock=".5rem"
        >
            <UploadDocumentToActionArea caseId={caseId} />

            <DocumentListDisplay
                documents={documentsList}
                isEditable
                isDeleteable
            />
        </VStack>
    );
};

export default DocumentUpload;
