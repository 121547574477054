import { useLocation, useNavigate } from 'react-router-dom';

/**
 * removes the last n parts of the current url and navigates to the new url
 * @returns {Function} navigateUpUrl
 */
const useNavigateUpUrl = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    /**
     * removes the last n parts of the current url and navigates to the new url
     * @param {number} numBack - number of parts to remove from the url
     */
    const navigateUpUrl = (numBack: number) => {
        const pathParts = pathname.split('/');

        pathParts.splice(-numBack, numBack);

        navigate(pathParts.join('/'));
    };

    return navigateUpUrl;
};

export default useNavigateUpUrl;
