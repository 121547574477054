import { Icon, IconProps, OmitCommonProps } from "@chakra-ui/react";
import { SVGProps } from "react";

type PropTypes = JSX.IntrinsicAttributes &
    OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps & { as?: "svg" | undefined };

export const EmailIcon = (props: PropTypes) => (
    <Icon
        viewBox="0 0 24 25"
        h="auto"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <path
            d="M4.30775 19.5021C3.80258 19.5021 3.375 19.3271 3.025 18.9771C2.675 18.6271 2.5 18.1995 2.5 17.6943V6.30983C2.5 5.80466 2.675 5.37708 3.025 5.02708C3.375 4.67708 3.80258 4.50208 4.30775 4.50208H19.6923C20.1974 4.50208 20.625 4.67708 20.975 5.02708C21.325 5.37708 21.5 5.80466 21.5 6.30983V17.6943C21.5 18.1995 21.325 18.6271 20.975 18.9771C20.625 19.3271 20.1974 19.5021 19.6923 19.5021H4.30775ZM12 12.5598L4 7.44433V17.6943C4 17.7842 4.02883 17.8579 4.0865 17.9156C4.14417 17.9732 4.21792 18.0021 4.30775 18.0021H19.6923C19.7821 18.0021 19.8558 17.9732 19.9135 17.9156C19.9712 17.8579 20 17.7842 20 17.6943V7.44433L12 12.5598ZM12 11.0021L19.8463 6.00208H4.15375L12 11.0021ZM4 7.44433V6.00208V17.6943C4 17.7842 4.02883 17.8579 4.0865 17.9156C4.14417 17.9732 4.21792 18.0021 4.30775 18.0021H4V7.44433Z"
            fill="#A0AEC0"
        />
    </Icon>
);

EmailIcon.defaultProps = {
    width: "1.7em",
};
