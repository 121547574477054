import {
    Box,
    Button,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string;
    options: {
        icon?: ReactNode;
        label: string;
        value: string;
    }[];
    onChange?: (field?: FieldInputProps<any>, value?: string[],) => void,
};

const FormikCloudSelect = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        options,
        onChange,
    } = props;

    const { values } = useFormikContext<{ [index: string]: any }>();

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const fieldValues = values[name] || [];

    return (
        <Box w="100%">
            <SimpleGrid
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                minChildWidth="13rem"
                spacing="0.2rem"
            >
                {options.map(option => {
                    if (option.icon) {
                        return (
                            <Button
                                key={`${field.name}[${option.value}]`}
                                variant="cloudSelect"
                                maxWidth="20rem"
                                name={`${field.name}[${option.value}]`}
                                w="100%"
                            >
                                <VStack
                                    align="center"
                                    spacing="1"
                                    w="7em"
                                >
                                    {option.icon}

                                    <Text
                                        align="center"
                                    >
                                        {option.label}
                                    </Text>
                                </VStack>
                            </Button>
                        );
                    }

                    return (
                        <Button
                            key={option.value}
                            margin="0.5rem"
                            minW="12rem"
                            fontSize={option.label.length > 20 ? '0.8rem' : '1rem'}
                            name={`${field.name}[${option.value}]`}
                            variant={fieldValues.includes(option.value) ? 'cloudSelectActive' : 'cloudSelect'}
                            onClick={() => {
                                let newFieldValues = [...fieldValues, option.value];
                                if (fieldValues.includes(option.value)) {
                                    newFieldValues = values[name].filter(
                                        (value: string) => value !== option.value,
                                    );
                                }
                                field.onChange({ target: { name, value: newFieldValues } });

                                onChange?.(field, newFieldValues);
                            }}
                        >
                            {option.label}
                        </Button>
                    );
                })}
            </SimpleGrid>

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </Box>
    );
};

FormikCloudSelect.defaultProps = {
    onChange: () => {},
};

export default FormikCloudSelect;
