import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Checkbox,
    Container,
    Flex,
    FormLabel,
    Heading,
    HStack,
    Link,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { MultiFactorError, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { auth } from "../../../../../utils/firebase";
import useMe from "../../../app/useMeV2";
import Logo from "../../../common/components/Logo";
import LanguageToggleButton from "../../../common/features/languageToggle/LanguageToggleButton";
import FormikForm from "../../../common/forms/FormikForm";
import FormikInput from "../../../common/forms/FormikInput";
import FormikPassword from "../../../common/forms/FormikPassword";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";

YupPassword(Yup);

type PropTypes = {
    onLogin: (verify?: boolean) => void;
    setAuthError: (error: MultiFactorError) => void;
};

const validationSchema = Yup.object({
    email: Yup.string()
        .email("forms.errors.invalidEmail")
        .required("forms.errors.required"),
    password: Yup.string()
        .min(8, "forms.errors.tooShort")
        .max(100, "forms.errors.tooLong")
        .minLowercase(1, "forms.errors.minLowercase")
        .minUppercase(1, "forms.errors.minUppercase")
        .minNumbers(1, "forms.errors.minNumbers")
        .minSymbols(1, "forms.errors.minSymbols")
        .required("forms.errors.required"),
});

type AlertBannerPropTypes = {
    alertText: string;
};

const AlertBanner = (props: AlertBannerPropTypes) => {
    const { alertText } = props;

    return (
        <Box bgColor="red20">
            <Alert status="error">
                <AlertIcon color="red" />
                {alertText}
            </Alert>
        </Box>
    );
};

const Login = (props: PropTypes) => {
    const { onLogin, setAuthError } = props;

    const t = useSmartTranslation();
    const navigate = useNavigate();

    const [error, setError] = useState("");

    const { refresh } = useMe();

    return (
        <Container
            maxW="container.lg"
            padding="1rem"
            backgroundColor="white"
            borderRadius="lg"
        >
            <HStack align="stretch">
                <Flex
                    flexDirection="column"
                    align="left"
                    padding="1.5rem"
                    maxW="45%"
                    color="white"
                    borderRadius="lg"
                    bgGradient={[
                        "linear-gradient(141.87deg, #10528F 8.08%, #3396BF 85.18%)",
                    ]}
                >
                    <Logo />

                    <Spacer />

                    <Box paddingBlock="8rem">
                        <Heading>{t("login.wvwWelcome")}</Heading>

                        <Spacer h="1.5rem" />

                        <Text>{t("login.wvwBlurb")}</Text>
                    </Box>
                </Flex>

                <VStack
                    paddingTop="1rem"
                    paddingBottom="3rem"
                    paddingInline="2.5rem"
                    w="55%"
                    align="left"
                    spacing="6"
                >
                    <Box
                        w="100%"
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <HStack
                            alignItems="center"
                            flexWrap="wrap"
                            spacing={0}
                        >
                            <Text paddingRight=".5rem">
                                {t("login.noAccountQ")}
                            </Text>

                            <Link
                                as={ReactLink}
                                to="/register"
                                color="wvwGreen"
                                fontWeight="bold"
                            >
                                {t("login.link.register")}
                            </Link>
                        </HStack>

                        <Box
                            pl="1rem"
                            alignSelf="right"
                        >
                            <LanguageToggleButton />
                        </Box>
                    </Box>

                    <Box paddingBlock="3rem">
                        <VStack
                            align="left"
                            spacing="6"
                        >
                            <Box>
                                <Heading
                                    as="h2"
                                    size="lg"
                                >
                                    {t("login.loginHeading")}
                                </Heading>

                                <Spacer h="1rem" />
                                <Text>{t("login.welcomeBlurb")}</Text>
                            </Box>

                            {error ===
                                "Firebase: Error (auth/user-not-found)." && (
                                <AlertBanner
                                    alertText={t("login.error.userNotFound")}
                                />
                            )}

                            {error ===
                                "Firebase: Error (auth/wrong-password)." && (
                                <AlertBanner
                                    alertText={t("login.error.wrongPassword")}
                                />
                            )}

                            {error !==
                                "Firebase: Error (auth/user-not-found)." &&
                                error !==
                                    "Firebase: Error (auth/wrong-password)." &&
                                error !== "" && (
                                    <AlertBanner
                                        alertText={t("login.error.tryAgain")}
                                    />
                                )}

                            <FormikForm
                                initialValues={{
                                    email: "",
                                    password: "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    signInWithEmailAndPassword(
                                        auth,
                                        values.email,
                                        values.password
                                    )
                                        .then(() => {
                                            refresh();
                                            onLogin();
                                        })
                                        .catch((err: MultiFactorError) => {
                                            if (
                                                err.code ===
                                                "auth/multi-factor-auth-required"
                                            ) {
                                                setAuthError(err);

                                                onLogin(true);

                                                return;
                                            }

                                            setError(err.message);
                                        });
                                }}
                            >
                                <FormLabel color="wvwGreen">
                                    {t("common.email")}
                                </FormLabel>
                                <FormikInput
                                    name="email"
                                    type="email"
                                    placeholder={t(
                                        "login.instruction.pleaseEnter",
                                        { item: t("login.instruction.email") }
                                    )}
                                />

                                <Spacer h="1rem" />

                                <FormLabel color="wvwGreen">
                                    {t("common.password")}
                                </FormLabel>
                                <FormikPassword
                                    name="password"
                                    placeholder={t(
                                        "login.instruction.pleaseEnter",
                                        {
                                            item: t(
                                                "login.instruction.password"
                                            ),
                                        }
                                    )}
                                />

                                <Spacer h="1rem" />

                                <HStack
                                    flexWrap="wrap"
                                    justifyContent="space-between"
                                    w="100%"
                                >
                                    <Checkbox>{t("login.rememberMe")}</Checkbox>

                                    <Button
                                        color="wvwGreen"
                                        padding={0}
                                        variant="none"
                                        flexWrap="wrap"
                                        onClick={() => {
                                            navigate("/forgot-password");
                                        }}
                                    >
                                        {t("login.link.resetPassword")}
                                    </Button>
                                </HStack>

                                <Button
                                    variant="primaryYellow"
                                    width={{
                                        sm: "10rem",
                                        md: "14rem",
                                    }}
                                    marginBlock="1.5rem"
                                    type="submit"
                                >
                                    {t("login.button.login")}
                                </Button>
                            </FormikForm>
                        </VStack>
                    </Box>
                </VStack>
            </HStack>
        </Container>
    );
};

export default Login;
