import {
    Center,
    VStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import update from 'immutability-helper';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useDrop } from 'react-dnd';
import DocumentListItem from './DocumentListItem';
import { DOCUMENT_IN_ACTION_AREA, DocumentRequestDocumentType } from '../../../../../types';

type PropTypes = {
    documents: DocumentRequestDocumentType[],
    isDeleteable?: boolean;
    isEditable?: boolean;
};

const DocumentListDisplay = (props: PropTypes) => {
    const {
        documents = [],
        isDeleteable,
        isEditable,
    } = props;

    const [items, setItems] = useState<DocumentRequestDocumentType[]>(documents.slice());

    const findItem = useCallback(
        (id: number) => {
            const item = items.filter(c => c.id === id)[0];

            return {
                item,
                index: items.indexOf(item),
            };
        },
        [items],
    );

    const moveItem = useCallback(
        (id: number, atIndex: number) => {
            const { item, index } = findItem(id);

            setItems(
                update(items, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, item],
                    ],
                }),
            );
        },
        [findItem, items, setItems],
    );

    const resetOrder = useCallback(
        () => setItems(documents.slice()),
        [documents],
    );

    const [, drop] = useDrop(() => ({ accept: DOCUMENT_IN_ACTION_AREA }));

    useEffect(() => {
        setItems(documents);
    }, [documents]);

    return (
        <VStack
            align="left"
            alignSelf="center"
            bg="wvwGrey05"
            borderRadius="10"
            paddingInline=".5rem"
            paddingTop=".5rem"
            maxH={window.innerHeight - 280}
            overflow="auto"
            ref={drop}
            w="100%"
        >
            {items.length === 0 && (
                <Center
                    pb="1rem"
                    color="wvwGrey40"
                    paddingBlock="2rem"
                >
                    {t('toast.errors.noDocuments', { ns: 'documentmanagement' })}
                </Center>
            )}

            {items.map(item => (
                <DocumentListItem
                    document={item}
                    key={item.id}
                    isDeleteable={isDeleteable}
                    isEditable={isEditable}
                    moveItem={moveItem}
                    findItem={findItem}
                    resetOrder={resetOrder}
                />
            ))}
        </VStack>
    );
};

DocumentListDisplay.defaultProps = {
    isDeleteable: false,
    isEditable: false,
};

export default DocumentListDisplay;
