import {
    RadioGroup,
    Text,
    Radio,
    VStack,
    Flex,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { WvwText } from '../components/typography';

type PropTypes = {
    name: string,
    width?: string,
    onChange?: (value: string, field: FieldInputProps<any>) => void,
    options: {
        label: string;
        description?: string;
        value: string;
    }[];
};

const FormikRadioGroup = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        width,
        options,
        onChange,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <RadioGroup
            variant="filled"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            onChange={value => {
                field.onChange({ target: { name, value } });

                onChange?.(value, field);
            }}
            value={field.value}
            width="100%"
        >
            <Flex
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                width="100%"
                flexWrap="wrap"
                gap={2}
            >
                {options.map(({ label, description, value }) => (
                    <Radio
                        key={value}
                        width={width}
                        minWidth="10rem"
                        maxWidth="24%"
                        value={value}
                        fontWeight={value}
                        variant="outline"
                    >
                        <VStack
                            align="left"
                            spacing="0"
                        >
                            <WvwText bold>
                                {label}
                            </WvwText>

                            {description && (
                                <WvwText>
                                    {description}
                                </WvwText>
                            )}
                        </VStack>
                    </Radio>
                ))}
            </Flex>

            {
                (meta.touched && meta.error)
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )
            }
        </RadioGroup>
    );
};

FormikRadioGroup.defaultProps = {
    width: '100%',
    onChange: () => { },
};

export default FormikRadioGroup;
