import {
    Button,
    ButtonGroup,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { EnquiryType } from "../../../../types";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../common/MISSING_TRANSLATION";

type Params = {
    enquiry: EnquiryType;
    onArchive: any;
};

const Title = (params: Params) => {
    const {
        enquiry: { id, status },
        onArchive,
    } = params;

    const t = useSmartTranslation();

    return (
        <Flex
            bg="white"
            w="100%"
            align="center"
            padding=".5em 1.5em"
            borderRadius="8"
        >
            <VStack
                align="start"
                spacing="0"
            >
                <Heading
                    color="blue.700"
                    size="lg"
                >
                    {t("domain.enquiry.enquiryId", { id })}
                </Heading>
                <HStack>
                    <Text color="darkgrey">
                        {t("domain.enquiry.status.title")}
                    </Text>
                    <Text
                        color="blue.400"
                        fontWeight="bold"
                    >
                        {t("domain.enquiry.status." + status, {
                            defaultValue: MISSING_TRANSLATION,
                        })}
                    </Text>
                </HStack>
            </VStack>
            <Spacer />
            <ButtonGroup variant="outline">
                <Button onClick={onArchive}>
                    {t("domain.enquiry.archive")}
                </Button>
            </ButtonGroup>
        </Flex>
    );
};

export default Title;
