export const screen = {
    de: {
        footer: {
            reportAProblem: "Ein Problem melden",
        },
    },
    en: {
        footer: {
            reportAProblem: "Report a problem",
        },
    },
};
