import { DocumentRequestCreateDocumentRequest } from '../../dto';
import { documentRequestCreateDocumentRequest } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';
import useMutationHook from '../useMutationHook';

type ParamsType = {
    caseId: number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestCreateDocumentRequest = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');

    const { refreshAll } = useRefreshAllDMSDataStores(caseId);

    const mutation = useMutationHook({
        mutationFunction: (dto: DocumentRequestCreateDocumentRequest) => (
            documentRequestCreateDocumentRequest(dto)
        ),
        refreshes: [refreshAll],
        successMessage: t('toast.messages.requestCreated'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestCreateDocumentRequest;
