import { VStack } from "@chakra-ui/react";
import { WVWTitle } from "../../../../common/components/typography";
import { FormikRadioGroup } from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";

type PropTypes = {
    updateDoesValuations?: (value: "true" | "false") => void;
};

const ProfileDetailsDoesValuatons = (props: PropTypes) => {
    const { updateDoesValuations } = props;

    const t = useSmartTranslation("common");

    return (
        <VStack
            width="100%"
            align="left"
        >
            <WVWTitle
                level="2"
                color="black"
                content={t("doesValuations", { ns: "profile" })}
            />

            <FormikRadioGroup
                name="doesValuations"
                width="10rem"
                onChange={(value) =>
                    updateDoesValuations?.(value === "true" ? "true" : "false")
                }
                options={[
                    {
                        label: t("yes"),
                        value: "true",
                    },
                    {
                        label: t("no"),
                        value: "false",
                    },
                ]}
            />
        </VStack>
    );
};

export default ProfileDetailsDoesValuatons;
