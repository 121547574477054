/* eslint-disable max-len */
import { VStack } from '@chakra-ui/react';
import CompanyDetailsBilling from './CompanyDetailsBilling';
import CompanyDetailsContact from './CompanyDetailsContact';
import CompanyDetailsOther from './CompanyDetailsOther';

/**
 * Formik form section for editing the current user's company details.
 */

const CompanyDetailsDetailsEdit = () => (
    <VStack width="100%">
        <CompanyDetailsBilling />

        <CompanyDetailsContact />

        <CompanyDetailsOther />
    </VStack>
);

export default CompanyDetailsDetailsEdit;
