import { MISSING_TRANSLATION } from "../../../MISSING_TRANSLATION";

export const caseColumns = {
    de: {
        case: "Fall",
        client: "Kunde",
        expectedDelivery: "Fällig",
        noOfProperties: "Anzahl Immobilien: {{count}}",
        piCover: "PI Cover",
        portfolioValue: "Geschätzter Portfoliowert",
        position: {
            at: "{{position}} bei {{company}}",
        },
        selectedValuer: "Gewählter Gutachter",
        type: "Bewertungsart",
    },
    en: {
        case: "Case",
        client: "Client",
        expectedDelivery: "Expected delivery",
        noOfProperties: "No. of properties: {{count}}",
        piCover: "PI Cover",
        portfolioValue: "Est. portfolio value",
        position: {
            at: "{{position}} at {{company}}",
        },
        selectedValuer: "Selected valuer",
        type: "Type",
    },
};

export const enquiryColumns = {
    de: {
        activeOffers: MISSING_TRANSLATION,
        bestDelivery: MISSING_TRANSLATION,
        bestOffer: MISSING_TRANSLATION,
        delivery: "Fällig:",
        enquiry: "Anfrage",
        expectedDelivery: "Fällig",
        moreText: "+{{count}} weitere",
        myOffer: MISSING_TRANSLATION,
        newText: MISSING_TRANSLATION,
        offers: "Angebote",
        piCover: "PI Cover",
        portfolioValue: "Geschätzter Portfoliowert",
        position: {
            at: "{{position}} bei {{company}}",
        },
        price: "Preis:",
        type: "Bewertungsart",
    },
    en: {
        activeOffers: "Active offers",
        bestDelivery: "Best delivery",
        bestOffer: "Best offer",
        delivery: "Delivery:",
        enquiry: "Enquiry",
        expectedDelivery: "Expected Delivery",
        moreText: "+{{count}} more",
        myOffer: "My offer",
        newText: "+{{count}} new",
        offers: "Offers",
        piCover: "PI Cover",
        portfolioValue: "Est. portfolio value",
        position: {
            at: "{{position}} at {{company}}",
        },
        price: "Price:",
        type: "Type",
    },
};

export const propertyColumns = {
    de: {
        address: MISSING_TRANSLATION,
        propertyType: MISSING_TRANSLATION,
        area: MISSING_TRANSLATION,
        estimateValue: MISSING_TRANSLATION,
        moreText: MISSING_TRANSLATION,
    },
    en: {
        address: "Address",
        propertyType: "Property type",
        area: "Area",
        estimateValue: "Estimated value",
        moreText: "+ {{number}} more",
    },
};
