import {
    createStandaloneToast,
    VStack,
    Heading,
    Text,
    Button,
    Box,
    HStack,
    SimpleGrid,
    Flex,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    FormikCurrencyInput,
    FormikForm,
    FormikInput,
    FormikTextarea,
} from "../../../../../common/forms";
import {
    useEnquiry,
    useMe,
    useMyEntity,
    useOfferCreate,
    useOfferEdit,
    useOfferGetMyOfferByEnquiryId,
} from "../../../../../utils/api/hooks";
import delimitNumber from "../../../../../common/functions/delimitNumber";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import parseDelimitedNumber from "../../../../../common/functions/parseDelimitedNumber";

const validationSchema = Yup.object({
    price: Yup.string().required("errors.required"),
    timeline: Yup.string().required("errors.required"),
    availability: Yup.string().required("errors.required"),
    piCoverValue: Yup.string().required("errors.required"),
    piCoverPercentage: Yup.string().required("errors.required"),
});

type ParamTypes = {
    closeEditor?: () => void;
    onSuccess: () => void;
};

const ValuerOfferForm = (params: ParamTypes) => {
    const { closeEditor, onSuccess } = params;

    const { toast } = createStandaloneToast();

    const { t } = useTranslation(["offer", "common", "formik"]);

    const { enquiryId, offerId } = useParams();

    const {
        data: myEntity,
        isLoading: myEntityLoading,
        error: myEntityError,
    } = useMyEntity();

    const {
        data: valuerData,
        isLoading: valuerLoading,
        error: valuerError,
    } = useMe();

    const {
        enquiry,
        isLoading: enquiryLoading,
        error: enquiryError,
    } = useEnquiry({ enquiryId: enquiryId || "" });

    const {
        data: valuerOffer,
        isLoading: valuerOfferLoading,
        error: valuerOfferError,
        refresh: valuerOfferRefresh,
    } = useOfferGetMyOfferByEnquiryId(Number(enquiryId));

    if (myEntityError || valuerError || enquiryError || valuerOfferError) {
        toast({
            title: t("error.error", { ns: "common" }),
            description: t("error.offer.loadingFailed", { ns: "common" }),
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    }

    const { update: updateOfferCreate } = useOfferCreate({
        onSuccess: () => {
            onSuccess();
            valuerOfferRefresh();
        },
        onError: (error) => {
            toast({
                title: t("error.error", { ns: "common" }),
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const { edit: offerEdit } = useOfferEdit({
        onSuccess: () => {
            onSuccess();
            valuerOfferRefresh();
            if (closeEditor) closeEditor();
        },
        onError: (error) => {
            toast({
                title: t("error.error", { ns: "common" }),
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    if (
        !valuerData ||
        valuerOfferLoading ||
        myEntityLoading ||
        valuerLoading ||
        enquiryLoading
    )
        return <LoadingSpinner />;

    return (
        <VStack
            w="100%"
            align="left"
            paddingBottom="4rem"
        >
            {!offerId && (
                <Flex
                    bg="wvwYellow10"
                    borderRadius="10"
                    align="left"
                    paddingInline="2rem"
                    paddingBlock="1rem"
                >
                    <VStack align="left">
                        <Heading
                            as="h4"
                            size="md"
                            color="wvwYellow"
                        >
                            {t("msg.noOfferSubmitted")}
                        </Heading>
                        <Text>
                            {t("msg.noOfferCreated")}
                            <br />
                            {t("msg.clickToStartOffer")}
                        </Text>
                    </VStack>
                </Flex>
            )}

            <VStack
                borderRadius="10"
                paddingInline="1rem"
                paddingBlock="1rem"
                bg="white"
            >
                <FormikForm
                    validationSchema={validationSchema}
                    initialValues={{
                        price: delimitNumber(valuerOffer.price) || 0,
                        piCoverCurrency: myEntity.piCoverCurrency || "",
                        piCoverValue: delimitNumber(
                            valuerOffer.piCoverValue !== undefined
                                ? valuerOffer.piCoverValue
                                : myEntity.piCoverValue
                        ),
                        piCoverPercentage: delimitNumber(
                            valuerOffer.piCoverPercentage !== undefined
                                ? valuerOffer.piCoverPercentage
                                : myEntity.piCoverPercentage
                        ),
                        timeline:
                            Number(delimitNumber(valuerOffer.timeline)) || "",
                        availability: valuerOffer.availability || "",
                        comments: valuerOffer.comments || "",
                    }}
                    onSubmit={(values) => {
                        if (!valuerOffer) {
                            updateOfferCreate({
                                enquiryId: Number(enquiryId),
                                toEntityId: Number(enquiry?.fromEntityId),
                                fromValuerId: valuerData.id,
                                fromEntityId: myEntity.id,
                                price: Number(
                                    parseDelimitedNumber(values.price)
                                ),
                                piCoverCurrency: values.piCoverCurrency,
                                piCoverValue: Number(
                                    parseDelimitedNumber(values.piCoverValue)
                                ),
                                piCoverPercentage: Number(
                                    parseDelimitedNumber(
                                        values.piCoverPercentage
                                    )
                                ),
                                timeline: values.timeline.toString(),
                                availability: values.availability,
                                comments: values.comments,
                            });
                        } else {
                            offerEdit({
                                offerId: valuerOffer.id,
                                price: Number(
                                    parseDelimitedNumber(values.price)
                                ),
                                piCoverCurrency: values.piCoverCurrency,
                                piCoverValue: Number(
                                    parseDelimitedNumber(values.piCoverValue)
                                ),
                                piCoverPercentage: Number(
                                    parseDelimitedNumber(
                                        values.piCoverPercentage
                                    )
                                ),
                                timeline: values.timeline.toString(),
                                availability: values.availability,
                                comments: values.comments,
                            });
                        }

                        if (closeEditor) closeEditor();
                    }}
                >
                    <Flex>
                        <VStack
                            align="left"
                            spacing={4}
                            w="100%"
                            paddingBlock="1.5rem"
                            paddingInline="1rem"
                        >
                            <Heading
                                size="md"
                                color="wvwGreen"
                            >
                                {valuerOffer
                                    ? t("offerForm.title_edit")
                                    : t("offerForm.title_create")}
                            </Heading>

                            <SimpleGrid
                                columns={{ md: 1, lg: 2 }}
                                spacing="6"
                            >
                                <VStack alignItems="left">
                                    <Text>{t("offerForm.price")}</Text>
                                    <FormikCurrencyInput name="price" />
                                </VStack>

                                <VStack alignItems="left">
                                    <HStack>
                                        <Text>
                                            {t("offerForm.piCover.title")}
                                        </Text>

                                        <Text color="wvwGrey60">
                                            {t("offerForm.piCover.description")}
                                        </Text>
                                    </HStack>
                                    <HStack
                                        align="flex-start"
                                        spacing="2"
                                        w="100%"
                                    >
                                        <FormikCurrencyInput
                                            name="piCoverValue"
                                            placeholder="-"
                                            prefix={t("unitSymbol.currency", {
                                                ns: "common",
                                            })}
                                        />

                                        <FormikCurrencyInput
                                            name="piCoverPercentage"
                                            placeholder="-"
                                            prefix="%"
                                        />
                                    </HStack>
                                </VStack>

                                <VStack alignItems="left">
                                    <HStack>
                                        <Text>
                                            {t("offerForm.timeline.title")}
                                        </Text>
                                        <Text color="wvwGrey60">
                                            {t(
                                                "offerForm.timeline.description"
                                            )}
                                        </Text>
                                    </HStack>

                                    <HStack>
                                        <FormikCurrencyInput
                                            name="timeline"
                                            suffix={t(
                                                "offerForm.timeline.days"
                                            )}
                                        />
                                    </HStack>
                                </VStack>

                                <VStack alignItems="left">
                                    <Text>
                                        {t("offerForm.earliestAvailability")}
                                    </Text>
                                    <FormikInput
                                        type="date"
                                        name="availability"
                                    />
                                </VStack>
                            </SimpleGrid>

                            <Box
                                bg="wvwGrey05"
                                borderRadius="10"
                                w="100%"
                            >
                                <VStack
                                    padding="1rem"
                                    align="left"
                                >
                                    <Text
                                        alignSelf="flex-start"
                                        textAlign="left"
                                        fontWeight="bold"
                                    >
                                        {t("offerDetails.sectionTitle")}
                                    </Text>

                                    <FormikTextarea name="comments" />
                                </VStack>
                            </Box>
                        </VStack>
                    </Flex>

                    <Flex
                        justifyContent="flex-end"
                        paddingEnd="1rem"
                    >
                        <Button
                            variant="primaryYellow"
                            type="submit"
                        >
                            {valuerOffer
                                ? t("button.update", { ns: "common" })
                                : t("button.submit", { ns: "common" })}
                        </Button>
                    </Flex>
                </FormikForm>
            </VStack>
        </VStack>
    );
};

export default ValuerOfferForm;
