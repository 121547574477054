import {
    Button,
    ButtonGroup,
    Heading,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Spacer,
    Text,
    VStack,
    createStandaloneToast,
    useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    useEnquiryGetDormant,
    useEnquirySetDormant,
    useOfferGetAllMyOffers,
} from '../../../utils/api/hooks';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import delimitNumber from '../../../common/functions/delimitNumber';
import DataTable from '../../../common/components/datatable/DataTable';
import { EnquiryType } from '../../../types';
import { useRole } from '../../../common/hooks';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const DormantEnquiries = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['dashboard', 'common']);
    const { isOpen, onClose } = useDisclosure();
    const { toast } = createStandaloneToast();

    const { userIsClient, roleLoading } = useRole();

    const {
        data: enquiryList = [],
        isLoading: enquiryLoading,
        refresh,
    } = useEnquiryGetDormant();

    const {
        data: offerList = [],
        isLoading: offerLoading,
    } = useOfferGetAllMyOffers();

    const { update: setDormant } = useEnquirySetDormant({
        onSuccess: () => {
            refresh();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    if (roleLoading || enquiryLoading || offerLoading) return <LoadingSpinner />;

    const headers = [
        {
            title: t('enquiriesTable.header.client'),
            render: (enquiry: EnquiryType) => `${enquiry.invoiceFName} ${enquiry.invoiceLName}`,
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aName = `${a.invoiceFName} ${a.invoiceLName}`;
                const bName = `${b.invoiceFName} ${b.invoiceLName}`;

                return aName.localeCompare(bName);
            },
        },
        {
            title: t('enquiriesTable.header.property'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aProperty = a?.properties?.[0]?.street || `${a?.properties?.length || 0} properties`;
                const bProperty = b?.properties?.[0]?.street || `${b?.properties?.length || 0} properties`;

                return aProperty.localeCompare(bProperty);
            },
            render: (i: EnquiryType) => {
                const { properties = [] } = i || {} as EnquiryType;

                if (properties.length === 1) {
                    return (
                        <Text>
                            {properties[0].street}
                        </Text>
                    );
                }

                return (
                    <Text>
                        {t('property_wc', { count: properties.length })}
                    </Text>
                );
            },
        },
        {
            title: t('enquiriesTable.header.estimateValue'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const { properties: aProperties = [] } = a;
                const { properties: bProperties = [] } = b;

                const aEstimateValue = aProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                const bEstimateValue = bProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                return aEstimateValue - bEstimateValue;
            },
            render: (i: EnquiryType) => {
                const { properties = [] } = i;

                const estimateValue = properties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                return (
                    <Text
                        pr={userIsClient ? '3.8rem' : '2rem'}
                    >
                        {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(estimateValue)}`}
                    </Text>
                );
            },
        },
        {
            title: t('enquiriesTable.header.currentPrice'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aOffers = offerList.filter(offer => offer.enquiryId === a.id);
                const bOffer = offerList.filter(offer => offer.enquiryId === b.id);

                const aLowestOffer = Math.min(...aOffers.map(offer => offer.price));
                const bLowestOffer = Math.min(...bOffer.map(offer => offer.price));

                return aLowestOffer - bLowestOffer;
            },
            render: (i: EnquiryType) => {
                const offers = offerList.filter(offer => offer.enquiryId === i.id);

                const lowestOffer = Math.min(...offers.map(offer => offer.price));

                return (
                    <Text
                        pr={userIsClient ? '2.4rem' : '1rem'}
                    >
                        {lowestOffer === Infinity ? t('enquiriesTable.cellValue.awaitingOffers') : `${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(lowestOffer)}`}
                    </Text>
                );
            },
        },
        {
            title: t('enquiriesTable.header.status'),
            maxWidth: '10rem',
            render: (i: EnquiryType) => {
                let bgColor = 'wvwYellow10';
                let color = 'wvwYellow';

                if (i.status === 'CANCELLED') {
                    bgColor = 'red10';
                    color = 'red';
                }

                return (
                    <Text
                        bg={bgColor}
                        borderRadius="10"
                        color={color}
                        fontWeight="bold"
                        paddingBlock=".2rem"
                        paddingInline=".5rem"
                        fontSize=".8rem"
                        textAlign="center"
                        w="7rem"
                    >
                        {t(`status.${i.status}`, { ns: 'offer', defaultValue: i.status })}
                    </Text>
                );
            },
            sorter: (a: EnquiryType, b: EnquiryType) => a.status.localeCompare(b.status),
        },
        {
            title: t('enquiriesTable.header.dueDate'),
            dataIndex: 'completionDate',
            sorter: (a: EnquiryType, b: EnquiryType) => {
                if (b.completionDate.replace(/-/g, '') < a.completionDate.replace(/-/g, '')) {
                    return -1;
                }
                if (b.completionDate.replace(/-/g, '') > a.completionDate.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: t('enquiriesTable.header.action'),
            render: (i: EnquiryType) => (
                <Flex pl=".3rem">
                    <Spacer />

                    <Button
                        borderRadius="30"
                        variant="primaryYellow"
                        size="sm"
                        onClick={() => setDormant({
                            enquiryId: i.id,
                            dormant: false,
                        })}
                    >
                        {t('enquiriesTable.button.reactivate')}
                    </Button>
                </Flex>
            ),
        },
    ];

    if (userIsClient) {
        headers.shift();
    }

    return (
        <>
            <PageWithTitleLayout title={t('enquiriesTable.sectionTitle')}>
                <DataTable
                    columns={headers}
                    data={enquiryList}
                    noDataText={t('noDormantEnquiries', { ns: 'enquiries' })}
                />
            </PageWithTitleLayout>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack
                            pt="3.5rem"
                            pb="2rem"
                        >
                            <Heading
                                as="h4"
                                size="md"
                            >
                                {t('noValuers')}
                            </Heading>

                            <Text textAlign="center">
                                {t('plsInviteValuers')}
                            </Text>
                        </VStack>
                    </ModalBody>

                    <ModalFooter justifyContent="center">
                        <ButtonGroup>
                            <Button
                                onClick={onClose}
                                variant="primary"
                            >
                                {t('button.close', { ns: 'common' })}
                            </Button>

                            <Button
                                onClick={() => navigate('/dashboard/account/valuer-panel')}
                                variant="primaryYellow"
                            >
                                {t('inviteValuers')}
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DormantEnquiries;
