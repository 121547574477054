import {
    VStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
    useEnquiry,
} from '../../../../../utils/api/hooks';
import ClientOfferBanner from './ClientOfferBanner';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import GeneralEnquiryOverview from '../../../../../common/components/display/enquiry-form/overview/GeneralEnquiryOverview';
import DraftEnquiryBanner from './DraftEnquiryBanner';
import { EnquiryType } from '../../../../../types';
import CancelledEnquiryBanner from './CancelledEnquiryBanner';

const ClientEnquiryOverview = () => {
    const { enquiryId = '' } = useParams();

    const {
        enquiry: enquiryData = {} as unknown as EnquiryType,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    if (enquiryLoading) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <VStack
            w="100%"
            align="left"
            paddingBottom="4rem"
        >
            {enquiryData?.status === 'DRAFT' && (
                <DraftEnquiryBanner />
            )}
            {enquiryData?.status === 'CANCELLED' && (
                <CancelledEnquiryBanner />
            )}
            {(enquiryData?.status !== 'DRAFT' && enquiryData?.status !== 'CANCELLED') && (
                <ClientOfferBanner />
            )}

            <GeneralEnquiryOverview variant="white" overviewData={enquiryData} />
        </VStack>
    );
};

export default ClientEnquiryOverview;
