export const translations = {
    de: {
        foo: "bar",
        awaitingOffers: {
            waitingForOffers: "Warte auf Angebote...",
            waitingForOffersBlurb1:
                "Wenn Angebote von den Gutachtern eingereicht werden, sehen Sie sie hier.",
            waitingForOffersBlurb2:
                "Wir benachrichtigen Sie auch per E-Mail und Benachrichtigungssystem.",
        },
    },
    en: {
        foo: "bar",
        awaitingOffers: {
            waitingForOffers: "Waiting for offers...",
            waitingForOffersBlurb1:
                "When any offers are submited by the valuers you will see them here.",
            waitingForOffersBlurb2:
                "We will also notify you by email and notification system.",
        },
    },
};
