import { OfferOnlyType } from "../../../../types";

type OfferTableProps = {
    offers: OfferOnlyType[];
};

const OfferTable = (props: OfferTableProps) => {
    const { offers } = props;

    const rows = offers.map((o, idx) => (
        <div key={`${o.id}_idx`}>
            offer #{o.id} ({o.status})
        </div>
    ));

    return (
        <>
            <div>OfferTable-Header</div>
            {rows}
        </>
    );
};

export { OfferTable };
