import {
    Box,
    Button,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ArrowRight } from 'iconsax-react';
import { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import InspectionDate from '../common/InspectionDate';
import { FormikForm } from '../../../../../common/forms';
import FormikDateSelect from '../../../../../common/forms/FormikDateSelect';
import FormikTimeSelect from '../../../../../common/forms/FormikTimeSelect';
import SplitLayout from '../../../../../common/components/SplitLayout';
import { useInspectionAddTentativeDate } from '../../../../../utils/api/hooks';
import { useSmartTranslation, useToast } from '../../../../../common/hooks';

const validationSchema = Yup.object({
    date: Yup.string().required('errors.required'),
    time: Yup.string().required('errors.required'),
});

const SelectDates = () => {
    const navigate = useNavigate();
    const t = useSmartTranslation('inspection');

    const {
        caseId: caseIdString,
        inspectionId: inspectionIdString,
    } = useParams();

    const caseId = Number(caseIdString);
    const inspectionId = Number(inspectionIdString);

    const { createErrorToast } = useToast();

    const { update: submitDates } = useInspectionAddTentativeDate({
        caseId,
        onSuccess: () => navigate('..', { replace: true }),
    });

    const [tentativeDates, setTentativeDates] = useState<Date[]>([]);

    return (
        <ModalDynamic
            header={t('title.selectDates')}
            isOpen
            onClose={() => navigate('..', { replace: true })}
            variant="medium"
        >
            <SplitLayout
                dividerIcon={ArrowRight}
                titleRight={t('title.selectedDates')}
                contentLeft={(
                    <FormikForm
                        validationSchema={validationSchema}
                        initialValues={{
                            date: '',
                            time: '',
                        }}
                        onSubmit={(values, formikHelpers) => {
                            const { date, time } = values;

                            const dateTime = new Date(date);

                            const [hours, minutes] = time.split(':').map(Number);

                            dateTime.setHours(hours);
                            dateTime.setMinutes(minutes);

                            setTentativeDates([
                                ...tentativeDates,
                                dateTime,
                            ]);

                            formikHelpers?.resetForm();
                        }}
                    >
                        <VStack
                            align="start"
                            w="100%"
                        >
                            <Text>
                                {t('selectTentativeDates')}
                            </Text>

                            <Box
                                bgColor="wvwGrey05"
                                borderRadius="10"
                                padding="1rem"
                                height="20rem"
                            >
                                <FormikDateSelect name="date" />
                            </Box>

                            <FormikTimeSelect name="time" />

                            <Button
                                variant="primaryYellow"
                                alignSelf="flex-end"
                                type="submit"
                            >
                                {t('button.add', { ns: 'common' })}
                            </Button>
                        </VStack>
                    </FormikForm>
                )}
                contentRight={(
                    <VStack
                        align="left"
                        w="100%"
                        pt="1rem"
                    >
                        <VStack
                            width="100%"
                            maxHeight="30rem"
                            h="100%"
                            overflowY="auto"
                        >
                            {tentativeDates.length === 0 && (
                                <Text color="wvwGrey40">
                                    {t('noDatesAdded')}
                                </Text>
                            )}

                            {tentativeDates.map(date => {
                                const dateTime = {
                                    date: date.toLocaleDateString(),
                                    time: date.toLocaleTimeString(),
                                };

                                return (
                                    <InspectionDate
                                        key={dateTime.date}
                                        dateAndTime={dateTime}
                                        onDelete={() => setTentativeDates(
                                            tentativeDates.filter(d => d !== date),
                                        )}
                                    />
                                );
                            })}
                        </VStack>

                        <Button
                            variant="primaryYellow"
                            alignSelf="flex-end"
                            onClick={() => {
                                if (tentativeDates.length === 0) {
                                    createErrorToast(t('error.noDatesSelected'));

                                    return;
                                }

                                const dates = tentativeDates.map(date => ({
                                    date,
                                    duration: 60,
                                }));

                                submitDates({
                                    dates,
                                    target: inspectionId,
                                });
                            }}
                        >
                            {t('button.submit', { ns: 'common' })}
                        </Button>
                    </VStack>
                )}
            />
        </ModalDynamic>
    );
};

export default SelectDates;
