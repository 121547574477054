import { Flex, VStack } from "@chakra-ui/react";
import * as Yup from "yup";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import {
    useEnquiry,
    useEnquiryAssignValuers,
    useMyEntity,
    useUsersByEntity,
} from "../../../utils/api/hooks";
import {
    FormikCheckboxList,
    FormikForm,
    FormikSubmitButton,
} from "../../../common/forms";
import { useSmartTranslation } from "../../../common/hooks";
import { ContentBox } from "../../../common/components/display";
import { WvwText } from "../../../common/components/typography";

type PropsType = {
    enquiryId: number | string;
    onComplete?: () => void;
};

const validationSchema = Yup.object().shape({
    valuers: Yup.array().of(Yup.string().required()).min(1, "errors.required"),
});

const AssignValuerToEnquiry = (props: PropsType) => {
    const { enquiryId, onComplete } = props;

    const t = useSmartTranslation("enquiries");

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    const { usersList: entityUsers = [], isLoading: entityUsersLoading } =
        useUsersByEntity(entity?.id);

    const {
        enquiry,
        isLoading: enquiryIsLoading,
        refresh,
    } = useEnquiry({
        enquiryId: enquiryId.toString(),
    });

    const allMatchedValuers = enquiry?.toUserIds || [];

    const matchedValuers = entityUsers
        .filter((user) => {
            return allMatchedValuers.some((i) => {
                return i === user.id;
            });
        })
        .map((user) => {
            return user.id.toString();
        });

    const { update: assignValuers, busy: assignValuersBusy } =
        useEnquiryAssignValuers({
            onSuccess: () => {
                onComplete?.();
                refresh();
            },
        });

    if (entityLoading || entityUsersLoading || enquiryIsLoading) {
        return <LoadingSpinner />;
    }

    return (
        <FormikForm
            initialValues={{
                valuers: matchedValuers,
            }}
            validationSchema={validationSchema}
            submitting={assignValuersBusy}
            onSubmit={(values) =>
                assignValuers({
                    enquiryId: Number(enquiryId),
                    valuers: values.valuers
                        .filter((v: string) =>
                            matchedValuers.every((i) => i !== v)
                        )
                        .map((v: string) => Number(v)),
                })
            }
        >
            <VStack
                align="left"
                spacing="6"
                w="100%"
            >
                <ContentBox>
                    <WvwText>{t("modal.assignEnquiry.content")}</WvwText>

                    <FormikCheckboxList
                        name="valuers"
                        options={entityUsers.map((u) => ({
                            label: `${u.firstName} ${u.lastName}`,
                            value: u.id.toString(),
                            isDisabled: allMatchedValuers.some((i) => {
                                return i === u.id;
                            }),
                        }))}
                    />
                </ContentBox>

                <Flex
                    alignItems="flex-end"
                    flexDirection="column"
                    w="100%"
                >
                    <FormikSubmitButton
                        content={t("button.save", { ns: "common" })}
                    />
                </Flex>
            </VStack>
        </FormikForm>
    );
};

AssignValuerToEnquiry.defaultProps = {
    onComplete: undefined,
};

export default AssignValuerToEnquiry;
