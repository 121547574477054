import { Route, Routes } from "react-router-dom";
import SendEmailVerification from "../../../../views/send-email-verification/SendEmailVerification";
import Logout from "../../screens/platform/logout/Logout";
import { ThemeV1Wrapper } from "../ThemeV1Wrapper";
import { global_routes } from "./global";

export const routes_unverified = (
    <Routes>
        <Route
            path="/logout"
            element={<Logout />}
        />

        <Route element={<ThemeV1Wrapper />}>
            <Route
                path="login"
                element={<div>user-login</div>}
            />
        </Route>

        {global_routes}

        <Route element={<ThemeV1Wrapper />}>
            <Route
                path="send-email-verification/:redirectUrl"
                element={<SendEmailVerification />}
            />

            <Route
                path="*"
                element={<SendEmailVerification />}
            />
        </Route>
    </Routes>
);
