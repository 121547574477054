import { CheckIcon } from '@chakra-ui/icons';
import {
    Box,
    HStack,
    Icon,
    IconButton,
} from '@chakra-ui/react';
import {
    Folder2,
} from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import WvwCancelIconButton from '../../../../../common/components/WvwCancelIconButton';
import { FormikForm, FormikInput } from '../../../../../common/forms';
import { useDocumentRequestCreateFolder } from '../../../../../utils/api/hooks';

type PropTypes = {
    closeEditor?: () => void;
};

const validationSchema = Yup.object({
    folder: Yup.string().required('errors.required'),
});

const NewFolderBox = (props: PropTypes) => {
    const {
        closeEditor,
    } = props;

    const { t } = useTranslation(['documentmanagement', 'common']);
    const { caseId = '' } = useParams();

    const { update } = useDocumentRequestCreateFolder({
        caseId,
        onSuccess: () => closeEditor?.(),
    });

    return (
        <Box
            bg="wvwGrey05"
            paddingBlock=".8rem"
            borderRadius="10"
            fontWeight="500"
            pl="2.75rem"
            marginTop=".5rem"
            w="100%"
        >
            <HStack>
                <Icon
                    as={Folder2}
                    color="wvwGreen"
                    boxSize={6}
                />

                <FormikForm
                    validationSchema={validationSchema}
                    initialValues={{
                        folder: '',
                    }}
                    onSubmit={values => {
                        update({
                            caseId: Number(caseId),
                            name: values.folder,
                        });
                    }}
                >
                    <HStack
                        paddingLeft="1.1rem"
                        width="100%"
                    >
                        <Box>
                            <FormikInput
                                name="folder"
                                placeholder={t('nameFolder', { ns: 'documentmanagement' })}
                                width="28rem"
                            />
                        </Box>

                        <IconButton
                            aria-label="Save"
                            borderRadius="30"
                            icon={<CheckIcon />}
                            onClick={e => e.stopPropagation()}
                            size="xs"
                            type="submit"
                            variant="primary"
                        />

                        <WvwCancelIconButton
                            onClick={closeEditor}
                            color="wvwGreen"
                        />
                    </HStack>
                </FormikForm>
            </HStack>
        </Box>
    );
};

NewFolderBox.defaultProps = {
    closeEditor: undefined,
};

export default NewFolderBox;
