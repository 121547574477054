import FileUploadFileList from "./FileUploadFileList";
import LoadingSpinner from "./LoadingSpinner";
import FileUploadInput from "../inputs/FileUploadInput";

type PropTypes = {
    defaultFileName?: string;
    files: {
        fileUrl: string;
        name: string;
    }[];
    loading?: boolean;
    onFileDelete: (fileUrl: string) => void;
    onRename: (fileUrl: string, newName: string) => void;
    onUpload: (fileList: File[]) => void;
};

const UploadCertificate = (props: PropTypes) => {
    const {
        defaultFileName,
        files = [],
        loading,
        onFileDelete,
        onRename,
        onUpload,
    } = props;

    return (
        <>
            <FileUploadInput
                defaultFileName={defaultFileName}
                fileTypes={[".pdf"]}
                onChange={(fileList) => onUpload(fileList)}
            />

            <FileUploadFileList
                files={files}
                onRename={(fileUrl, newName) => onRename(fileUrl, newName)}
                onRemove={(file) => onFileDelete(file.fileUrl)}
            />

            {loading && <LoadingSpinner />}
        </>
    );
};

UploadCertificate.defaultProps = {
    defaultFileName: undefined,
    loading: false,
};

export default UploadCertificate;
