import WVWLogoColor from "../../../../assets/icons/WVWLogoColor";
import WVWLogoWhite from "../../../../assets/icons/WVWLogoWhite";

const Logo = (props: { size?: string, withColor?: boolean }) => {
    const { size, withColor } = props;

    if (withColor) {
        return (
            <WVWLogoColor
                width={size}
            />
        );
    }

    return (
        <WVWLogoWhite
            width={size}
        />
    );
};

Logo.defaultProps = {
    size: '4rem',
    withColor: false,
};

export default Logo;
