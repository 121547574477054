import { getDeliveryDate } from "./enquiryFunctions";
import { EnquiryRequirements } from "./enquiryRequirements/EnquiryRequirements";
import { translations } from "./i18n";
import Title from "./Title";
import { ValuationPurposes } from "./ValuationPurposes";

export const Enquiry = {
    Title,
    Requirements: EnquiryRequirements,
    translations,
    getDeliveryDate,
};

export const valuationPurposes = {
    ValuationPurposes,
};
