import useRole from '../../../../common/hooks/useRole';
import { documentRequestGetUserRequestList } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const DOCUMENT_REQUEST_GET_USER_REQUEST_LIST = 'DOCUMENT_REQUEST_GET_USER_REQUEST_LIST';

const useDocumentRequestGetUserRequestList = (caseId: number | string) => {
    const { userIsValuer } = useRole();

    const query = useQueryHook({
        uniqueName: DOCUMENT_REQUEST_GET_USER_REQUEST_LIST,
        dependancies: [caseId],
        requiredParams: [caseId, userIsValuer],
        queryFunction: () => documentRequestGetUserRequestList(caseId),
    });

    return query;
};

export default useDocumentRequestGetUserRequestList;
