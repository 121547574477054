// const standardValuationTypes = [
//     'Red Book',
//     'Blue Book',
//     'White Book',
//     'Short Valuation',
// ];

const standardValuationTypes = [
    'Full Valuation',
    'Short Valuation',
    'Other Valuation',
];

export default standardValuationTypes;
