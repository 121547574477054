import { JSXElementConstructor, MouseEventHandler, ReactElement } from "react";
import WvwButton from "./WvwButton";

type PropTypes = {
    content: string;
    icon: ReactElement<any, string | JSXElementConstructor<any>>;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

const WvwLinkButton = (props: PropTypes) => {
    const { content, icon, onClick } = props;

    return (
        <WvwButton
            content={content}
            icon={icon}
            onClick={onClick}
            variant="link"
        />
    );
};

export default WvwLinkButton;
