import { useTranslation } from 'react-i18next';
import {
    VStack, Text,
} from '@chakra-ui/react';
import {
    FormikCloudSelectExpandable, FormikConditionalSection, FormikTextarea,
    FormikCheckboxGroup,
} from '../../../../common/forms';
import { standardValuationOccasions } from '../../../../common/vars/valuationsAndMembershipTypes';

type PropTypes = {
    reducedOptions: boolean;
};

const standardReducedPurposes = standardValuationOccasions.slice(0, 3);

const standardExpandedPurposes = standardValuationOccasions.slice(
    3,
    standardValuationOccasions.length,
);

const ValuationPurpose = (props: PropTypes) => {
    const { reducedOptions } = props;

    const { t } = useTranslation(['enquiryform', 'formik', 'common']);

    return (
        <>
            {reducedOptions ? (
                <FormikCloudSelectExpandable
                    name="valuationPurposes"
                    options={standardReducedPurposes.map(purpose => ({
                        label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                        value: purpose,
                    }))}
                    optionsExpanded={standardExpandedPurposes.map(purpose => ({
                        label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                        value: purpose,
                    }))}
                />
            ) : (
                <FormikCheckboxGroup
                    name="valuationPurposes"
                    variant="outline"
                    options={standardValuationOccasions.map(purpose => ({
                        label: t(purpose, { ns: 'formik', defaultValue: purpose }),
                        value: purpose,
                    }))}
                />
            )}

            <FormikConditionalSection
                formDataTarget="valuationPurposes"
                condition={x => x.includes('Other')}
            >
                <VStack
                    align="left"
                    backgroundColor="wvwGrey05"
                    borderRadius="8"
                    padding="1rem"
                >
                    <Text
                        color="wvwGreen"
                    >
                        {t('newEnquiry.valuationSection.valuationPurpose.other.instruction')}
                    </Text>

                    <FormikTextarea
                        name="valuationPurposesOther"
                    />
                </VStack>
            </FormikConditionalSection>
        </>
    );
};

export default ValuationPurpose;
