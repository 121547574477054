import {
    Button,
    Divider,
    Flex,
    Heading,
    HStack,
    IconButton,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { EnquiryType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";
import { Email, Fax, Mobile } from "../../../../common/icons/Icons";
import { JSXElementConstructor, ReactElement } from "react";
import ProfilePicture from "../../../../common/components/display/ProfilePicture";

type Props = {
    enquiry: EnquiryType;
    onArchive: Function;
};

export const ValuerEnquiryTitle = (props: Props) => {
    const {
        enquiry: { id, status },
        onArchive,
    } = props;

    const t = useSmartTranslation();

    const archive = (
        <Button
            onClick={() => onArchive()}
            variant="outline"
        >
            {t("domain.enquiry.archive")}
        </Button>
    );

    return (
        <Flex
            bg="white"
            w="100%"
            align="center"
            padding=".5em 1.5em"
            borderRadius="8"
        >
            <VStack
                align="start"
                spacing="0"
            >
                <Heading
                    color="blue.700"
                    size="lg"
                >
                    {t("domain.enquiry.enquiryId", { id })}
                </Heading>
                <HStack>
                    <Text color="darkgrey">
                        {t("domain.enquiry.status.title")}
                    </Text>
                    <Text
                        color="blue.400"
                        fontWeight="bold"
                    >
                        {t("domain.enquiry.status." + status, {
                            defaultValue: MISSING_TRANSLATION,
                        })}
                    </Text>
                </HStack>
            </VStack>
            <Spacer />
            <HStack spacing={4}>
                {archive}
                <Divider
                    orientation="vertical"
                    minH="3em"
                    h="100%"
                />
                <ObscuredClient />
            </HStack>
        </Flex>
    );
};

const ObscuredClient = () => {
    const t = useSmartTranslation();

    return (
        <HStack>
            <ProfilePicture />
            <VStack
                align={"flex-start"}
                spacing="0"
            >
                <Text fontWeight="bold">
                    {t("domain.client.obscuredClient")}
                </Text>
                <Text variant="secondary">
                    {t(
                        "screens.platform.valuer.valuerEnquiryOverview.enquiryTitle.detailsAvailableAfterOfferAcceptance"
                    )}
                </Text>
            </VStack>
            <ConnectButton
                ariaLabel="mobile"
                icon={<Mobile />}
            />
            <ConnectButton
                ariaLabel="email"
                icon={<Email />}
            />
            <ConnectButton
                ariaLabel="fax"
                icon={<Fax />}
            />
        </HStack>
    );
};

//helpers
type ConnectButtonType = {
    icon: ReactElement<any, string | JSXElementConstructor<any>>;
    ariaLabel: string;
};
const ConnectButton = (props: ConnectButtonType) => {
    const { ariaLabel, icon } = props;
    return (
        <IconButton
            aria-label={ariaLabel}
            icon={icon}
            color="gray.400"
            bg="gray.50"
            borderRadius={30}
        />
    );
};
