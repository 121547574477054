import { DocumentRequestCreateDocumentCommentDto } from '../../dto';
import { documentRequestCreateDocumentComment } from '../../endpoints';
import useMutationHook from '../useMutationHook';
import useDocumentRequestGetCaseDocuments from './useDocumentRequestGetCaseDocuments';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestCreateDocumentComment = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshDocuments } = useDocumentRequestGetCaseDocuments({ caseId });

    const mutation = useMutationHook({
        mutationFunction: (dto: DocumentRequestCreateDocumentCommentDto) => (
            documentRequestCreateDocumentComment(dto)
        ),
        refreshes: [refreshDocuments],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestCreateDocumentComment;
