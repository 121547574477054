export const translations = {
    de: {
        activeOffers: {
            title: "Aktive Angebote",
        },
        requestedValuation: {
            title: "Angeforderte Bewertung",
        },
    },
    en: {
        activeOffers: {
            title: "Active Offers",
        },
        requestedValuation: {
            title: "Requested Valuation",
        },
    },
};
