import { PropertyType } from "../../../../types";
import { propertyColumns } from "../../common/components/data-table/columns/PropertyColumns";
import DataTable from "../../common/components/data-table/DataTable";

type PortfolioTableProps = {
    properties: PropertyType[];
};

const PortfolioTable = (props: PortfolioTableProps) => {
    const { properties } = props;
    const columns = [
        propertyColumns.propertyId,
        propertyColumns.propertyAddress,
        propertyColumns.propertyType,
        propertyColumns.area,
        propertyColumns.estimateValue,
    ];
    return (
        // Add variant simple
        <DataTable
            data={properties}
            columns={columns}
            variant="simple"
        />
    );
};

export default PortfolioTable;
