import { Heading } from '@chakra-ui/react';

type PropTypes = {
    content: string;
    level: '1' | '2' | '3';
    color?: 'black' | 'red' | 'wvwGreen' | 'wvwYellow' | 'wvwGrey60' | 'white';
    normalFontWeight?: boolean;
};

const WVWTitle = (props: PropTypes) => {
    const {
        content,
        level,
        color,
        normalFontWeight,
    } = props;

    let size = 'lg';

    if (level === '2') {
        size = 'md';
    } else if (level === '3') {
        size = 'sm';
    }

    return (
        <Heading
            color={color === 'black' ? undefined : color}
            fontWeight={normalFontWeight ? 'normal' : 'bold'}
            overflow="hidden"
            size={size}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
        >
            {content}
        </Heading>
    );
};

WVWTitle.defaultProps = {
    normalFontWeight: false,
    color: 'wvwGreen',
};

export default WVWTitle;
