import {
    caseColumns,
    enquiryColumns,
    propertyColumns,
} from "./components/data-table/columns/i18n";

export const common = {
    de: {
        button: {
            back: "Zurück",
            cancel: "Abbrechen",
            close: "Schließen",
            confirm: "Bestätigen",
            complete: "Vervollständigen",
            done: "Fertig",
            edit: "Bearbeiten",
            sendLink: "Link senden",
        },
        components: {
            dataTable: {
                columns: {
                    caseColumns: caseColumns.de,
                    enquiryColumns: enquiryColumns.de,
                    propertyColumns: propertyColumns.de,
                },
            },
        },
        email: "E-Mail Adresse",
        error: {
            error: "Fehler",
            weAreSorry: "Es tut uns leid!",
        },
        goToDashboard: "Zum Dashboard gehen",
        loading: "Lädt...",
        "n/a": "N/A",
        noData: "Keine Daten vorhanden",
        password: "Passwort",
        toast: {},
        units: {
            km: "Km",
            years: "Jahre",
        },
        wvw: "WBW",
    },
    en: {
        button: {
            back: "Back",
            cancel: "Cancel",
            close: "Close",
            confirm: "Confirm",
            complete: "Complete",
            done: "Done",
            edit: "Edit",
            sendLink: "Send Link",
        },
        components: {
            dataTable: {
                columns: {
                    caseColumns: caseColumns.en,
                    enquiryColumns: enquiryColumns.en,
                    propertyColumns: propertyColumns.en,
                },
            },
        },
        email: "Email Address",
        error: {
            error: "Error",
            weAreSorry: "We're sorry!",
        },
        goToDashboard: "Go to Dashboard",
        loading: "Loading...",
        "n/a": "N/A",
        noData: "No data present",
        password: "Password",
        toast: {},
        units: {
            km: "Km",
            years: "Years",
        },
        wvw: "WVW",
    },
};
