import { getDaysUntilDate } from "../../../../common/functions";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { EnquiryType } from "../../../../types";
import { TranslationFunction } from "../../common/hooks/useSmartTranslation";

const T_NAMESPACE = "domain.enquiry";

const getDaysUntilDeliveryDate = (enquiry: EnquiryType) => {
    const deliveryDate = new Date(enquiry.completionDate);

    const daysCount = getDaysUntilDate(deliveryDate);

    return daysCount;
};

/**
 * @typedef {Object} getDeliveryDateReturn
 * @property {string} deliveryDate - The locale formatted delivery date
 * @property {string} daysToDeliveryDateText - Translated text as "{days} days left" or "Date passed"
 * @property {number} daysToDeliveryDate - Number of days until delivery
 */

/**
 *
 * @returns {getDeliveryDateReturn}
 */
export const getDeliveryDate = (
    enquiry: EnquiryType,
    t: TranslationFunction
) => {
    const deliveryDate = displayDateWithCurrentLocale(enquiry.completionDate);
    const daysToDeliveryDate = getDaysUntilDeliveryDate(enquiry);

    let daysToDeliveryDateText: string = t(`${T_NAMESPACE}.daysLeft`, {
        count: daysToDeliveryDate,
    });

    if (daysToDeliveryDate < 0) {
        daysToDeliveryDateText = t(`${T_NAMESPACE}.datePassed`);
    }

    return {
        deliveryDate,
        daysToDeliveryDate,
        daysToDeliveryDateText,
    };
};
