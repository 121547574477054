import { VStack } from '@chakra-ui/react';
import {
    standardPropertyTypes,
} from '../../../../common/vars/valuationsAndMembershipTypes';
import FormikConditionalFieldSwitch from '../../../../common/forms/FormikConditionalFieldSwitch';
import { useSmartTranslation } from '../../../../common/hooks';
import PropertyTypesChecklist from './components/PropertyTypesChecklist';

const PropertyType = () => {
    const t = useSmartTranslation('enquiryform');
    return (
        <VStack>
            <FormikConditionalFieldSwitch
                name="propertyTypes"
                options={standardPropertyTypes.map(propertyType => (
                    {
                        label: t(propertyType, { ns: 'formik', defaultValue: propertyType }),
                        value: propertyType,
                        conditionalSection: (
                            <PropertyTypesChecklist propertyType={propertyType} />
                        ),
                    }
                ))}
            />
        </VStack>
    );
};

export default PropertyType;
