import { CaseType } from '../../../../types';
import { AdminCasesDto } from '../../dto';
import { adminCases } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const ADMIN_CASES = 'ADMIN_CASES';

const useAdminCases = (dto: AdminCasesDto) => {
    const query = useQueryHook<CaseType[]>({
        uniqueName: ADMIN_CASES,
        dependancies: [JSON.stringify(dto)],
        requiredParams: [],
        queryFunction: () => adminCases(dto),
    });

    return query;
};

export default useAdminCases;
