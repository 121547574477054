import { CaseReviewSignedContract } from '../../dto/case-contract';
import { caseReviewSignedContract } from '../../endpoints';
import useMutationHook from '../useMutationHook';
import useCaseGetUploadedContracts from './useCaseGetUploadedContracts';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useCaseReviewSignedContract = (params: ParamsType) => {
    const { caseId, onSuccess, onError } = params;

    const { refresh: refreshReports } = useCaseGetUploadedContracts(caseId);

    const mutation = useMutationHook({
        mutationFunction: (dto: CaseReviewSignedContract) => caseReviewSignedContract(dto),
        refreshes: [refreshReports],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useCaseReviewSignedContract;
