import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";
import { EnquiryType } from "../../../../types";
import {
    useEnquiryGetAll,
    useOfferGetAllMyOffers,
} from "../../../../utils/api/hooks";
import StatusBadgeEnquiry from "../../../../common/components/display/StatusBadgeEnquiry";
import delimitNumber from "../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { useRole } from "../../../../common/hooks";
import DataTable from "../../../../common/components/datatable/DataTable";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { WvwButtonAsReactLink } from "../../../../common/components/inputs";
import { StatusBadge } from "../../../../common/components/display";

const NewEnquiries = () => {
    const { t } = useTranslation(["dashboard", "common", "offer"]);

    const { data: enquiryList = [], isLoading: enquiryLoading } =
        useEnquiryGetAll();

    const { data: offerList = [], isLoading: offerLoading } =
        useOfferGetAllMyOffers();

    const { userIsClient, userIsValuer, roleLoading } = useRole();

    if (roleLoading || enquiryLoading || offerLoading)
        return <LoadingSpinner />;

    const columnProperty = {
        title: t("enquiriesTable.header.property"),
        maxWidth: "15rem",
        sorter: (a: EnquiryType, b: EnquiryType) => {
            const aProperty =
                a?.properties?.[0]?.street ||
                `${a?.properties?.length || 0} properties`;
            const bProperty =
                b?.properties?.[0]?.street ||
                `${b?.properties?.length || 0} properties`;

            return aProperty.localeCompare(bProperty);
        },
        render: (i: EnquiryType) => {
            const { properties = [] } = i || ({} as EnquiryType);

            if (properties.length === 1) {
                return <Text>{properties[0].street}</Text>;
            }

            return (
                <Text>
                    {t("property_wc", {
                        count: properties.length,
                        ns: "common",
                    })}
                </Text>
            );
        },
    };

    const columnEstValue = {
        title: t("enquiriesTable.header.estimateValue"),
        sorter: (a: EnquiryType, b: EnquiryType) => {
            const { properties: aProperties = [] } = a;
            const { properties: bProperties = [] } = b;

            const aEstimateValue = aProperties.reduce(
                (acc, property) => acc + property.estimateValue,
                0
            );

            const bEstimateValue = bProperties.reduce(
                (acc, property) => acc + property.estimateValue,
                0
            );

            return aEstimateValue - bEstimateValue;
        },
        render: (i: EnquiryType) => {
            const { properties = [] } = i;

            const estimateValue = properties.reduce(
                (acc, property) => acc + property.estimateValue,
                0
            );

            return (
                <Text pr={userIsClient ? "3.8rem" : "2rem"}>
                    {`${t("unitSymbol.currency", {
                        ns: "common",
                    })} ${delimitNumber(estimateValue)}`}
                </Text>
            );
        },
    };

    const columnCurrentPrice = {
        title: t("enquiriesTable.header.currentPrice"),
        sorter: (a: EnquiryType, b: EnquiryType) => {
            const aOffers = offerList.filter(
                (offer) => offer.enquiryId === a.id
            );
            const bOffer = offerList.filter(
                (offer) => offer.enquiryId === b.id
            );

            const aLowestOffer = Math.min(
                ...aOffers.map((offer) => offer.price)
            );
            const bLowestOffer = Math.min(
                ...bOffer.map((offer) => offer.price)
            );

            return aLowestOffer - bLowestOffer;
        },
        render: (i: EnquiryType) => {
            const offers = offerList.filter(
                (offer) => offer.enquiryId === i.id
            );

            const lowestOffer = Math.min(...offers.map((offer) => offer.price));

            return (
                <Text pr={userIsClient ? "2.4rem" : "1rem"}>
                    {lowestOffer === Infinity
                        ? t("enquiriesTable.cellValue.awaitingOffers")
                        : `${t("unitSymbol.currency", {
                              ns: "common",
                          })} ${delimitNumber(lowestOffer)}`}
                </Text>
            );
        },
    };

    const columnEnquiryStatus = {
        title: t("enquiriesTable.header.status"),
        alignment: "center",
        maxWidth: "10rem",
        render: (i: EnquiryType) => {
            const offerExists = offerList.some(
                (offer) => offer.enquiryId === i.id
            );
            const numberOfOffers = offerList.filter(
                (offer) => offer.enquiryId === i.id
            ).length;
            const isPending = i.status === "PENDING";
            const rejectedOffer = offerList.find(
                (offer) =>
                    offer.enquiryId === i.id && offer.status === "REJECTED"
            );

            if (rejectedOffer && !userIsClient) {
                return <StatusBadgeEnquiry status="AWAITING_YOUR_OFFER" />;
            }

            if (!offerExists && i.status !== "CANCELLED") {
                const status = userIsClient
                    ? "AWAITING_OFFERS"
                    : "AWAITING_YOUR_OFFER";
                return <StatusBadgeEnquiry status={status} />;
            }

            if (isPending && numberOfOffers !== 0) {
                const numberOfOffersText = `(${numberOfOffers}) ${
                    numberOfOffers === 1 ? t("offerUpper") : t("offersUpper")
                }`;
                const awaitingAcceptanceText = t("status.AWAITING_ACCEPTANCE", {
                    ns: "offer",
                });
                const status = userIsClient
                    ? (numberOfOffersText as "AWAITING_OFFERS")
                    : (awaitingAcceptanceText as "AWAITING_ACCEPTANCE");
                return (
                    <StatusBadge
                        color="wvwYellow10"
                        status={status}
                        textColor="wvwYellow"
                    />
                );
            }

            return <StatusBadgeEnquiry status={i.status} />;
        },
        sorter: (a: EnquiryType, b: EnquiryType) => {
            const aOfferExists = offerList.some(
                (offer) => offer.enquiryId === a.id
            );
            const bOfferExists = offerList.some(
                (offer) => offer.enquiryId === b.id
            );

            const aNumberOfOffers = offerList.filter(
                (offer) => offer.enquiryId === a.id
            ).length;
            const bNumberOfOffers = offerList.filter(
                (offer) => offer.enquiryId === b.id
            ).length;

            const aIsPending = a.status === "PENDING";
            const bIsPending = b.status === "PENDING";

            if (!aOfferExists && !bOfferExists) {
                return 0;
            }

            if (!aOfferExists) {
                return -1;
            }

            if (!bOfferExists) {
                return 1;
            }

            if (aIsPending && bIsPending) {
                return bNumberOfOffers - aNumberOfOffers;
            }

            if (aIsPending) {
                return -1;
            }

            if (bIsPending) {
                return 1;
            }

            return a.status.localeCompare(b.status);
        },
    };

    const columnDueDate = {
        title: t("enquiriesTable.header.dueDate"),
        dataIndex: "completionDate",
        maxWidth: "10rem",
        render: (i: EnquiryType) => (
            <Text>{displayDateWithCurrentLocale(i.completionDate)}</Text>
        ),
        sorter: (a: EnquiryType, b: EnquiryType) => {
            if (
                b.completionDate.replace(/-/g, "") <
                a.completionDate.replace(/-/g, "")
            ) {
                return -1;
            }
            if (
                b.completionDate.replace(/-/g, "") >
                a.completionDate.replace(/-/g, "")
            ) {
                return 1;
            }
            return 0;
        },
    };

    const columnSubmittedDate = {
        title: t("enquiriesTable.header.submittedDate"),
        maxWidth: "10rem",
        render: (i: EnquiryType) =>
            displayDateWithCurrentLocale(i.submittedAt || ""),
        sorter: (a: EnquiryType, b: EnquiryType) => {
            const dateA = new Date(a.submittedAt ?? "");
            const dateB = new Date(b.submittedAt ?? "");
            return dateB.getTime() - dateA.getTime();
        },
    };

    const columnActions = {
        title: t("enquiriesTable.header.action"),
        maxWidth: "10rem",
        render: (i: EnquiryType) => (
            <WvwButtonAsReactLink
                to={`/dashboard/enquiry/${i.id}/overview`}
                content={t("enquiriesTable.button.view")}
                variant="link"
            />
        ),
    };

    const valuerColumns = [
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    ];

    const clientColumns = [
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    ];

    const columns = userIsValuer ? valuerColumns : clientColumns;

    const sortedEnquiryList = enquiryList
        .sort(columnSubmittedDate.sorter)
        .slice(0, 5);

    return (
        <DataTable
            columns={columns}
            data={sortedEnquiryList}
            noDataText={t("noEnquiries", { ns: "enquiries" })}
        />
    );
};

export default NewEnquiries;
