import { useState } from 'react';
import {
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { upperCase } from 'lodash';
import { EnquiryStepsContextType } from '../../../../../views/create-client-enquiry/common/types';

import { useRole } from '../../../../hooks';
import AwaitingAccessPage from '../../../AwaitingAccessPage';

import EnquiryContainer from './EnquiryContainer';

const STEPS = [
    'VALUATION',
    'VALUER',
    'PROPERTY_DETAILS',
    'OVERVIEW',
    'SUCCESS',
];

const ClientEnquiryBox = () => {
    const { pathname } = useLocation();
    let firstStep = pathname.includes('create-client-enquiry/') ? upperCase(pathname.split('-')[3]) : STEPS[0];
    const navigate = useNavigate();
    const { enquiryId = '' } = useParams();

    const {
        userIsApproved,
        userIsClient,
        userIsIndividual,
    } = useRole();

    if (firstStep === 'PROPERTY') {
        firstStep = 'PROPERTY_DETAILS';
    }

    const [step, setStep] = useState(firstStep);

    const onContinue = (eId?: number) => {
        const subPath = pathname.split('/')[2] || 'enquiry-valuation-info';

        switch (subPath) {
            case 'enquiry-valuation-info':
                if (userIsIndividual && userIsClient) {
                    navigate(`/create-client-enquiry/enquiry-property-info/${eId}`);
                    setStep(STEPS[2]);
                } else {
                    navigate(`/create-client-enquiry/enquiry-valuer-info/${eId}`);
                    setStep(STEPS[1]);
                }
                break;
            case 'enquiry-valuer-info':
                navigate(`enquiry-property-info/${enquiryId}`);
                setStep(STEPS[2]);
                break;
            case 'enquiry-property-info':
                navigate(`enquiry-overview-info/${enquiryId}`);
                setStep(STEPS[3]);
                break;
            default:
                break;
        }
    };

    const stepNames = userIsIndividual && userIsClient
        ? STEPS.filter(i => i !== STEPS[1] && i !== STEPS[4]) : STEPS.filter(i => i !== STEPS[4]);

    const onBack = () => {
        const subPath = pathname.split('/')[2] || 'enquiry-valuation-info';
        const index = STEPS.indexOf(step);
        if (index > 0) {
            setStep(STEPS[index - 1]);
        }
        switch (subPath) {
            case 'enquiry-overview-info':
                navigate(`enquiry-property-info/${enquiryId}`);
                setStep(STEPS[2]);
                break;
            case 'enquiry-property-info':
                if (userIsIndividual && userIsClient) {
                    navigate(`enquiry-valuation-info/${enquiryId}`);
                    setStep(STEPS[0]);
                } else {
                    navigate(`enquiry-valuer-info/${enquiryId}`);
                    setStep(STEPS[1]);
                }
                break;
            case 'enquiry-valuer-info':
                navigate(`enquiry-valuation-info/${enquiryId}`);
                setStep(STEPS[0]);
                break;
            default:
                break;
        }
    };

    if (!userIsApproved) {
        return (
            <AwaitingAccessPage />
        );
    }

    return (
        <EnquiryContainer
            steps={stepNames}
            currentStep={step}
        >
            <Outlet
                context={{ onBack, onContinue } satisfies EnquiryStepsContextType}
            />
        </EnquiryContainer>
    );
};

export default ClientEnquiryBox;
