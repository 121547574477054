import { MISSING_TRANSLATION } from "../../common/MISSING_TRANSLATION";

export const translations = {
    de: {
        properties: "Immobilien",
    },
    en: {
        properties: "Properties",
    },
};
