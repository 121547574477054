import { Box, Code, Heading, Text, VStack } from "@chakra-ui/react";

const TestingArea = () => {
    return (
        <Box padding="10rem">
            <VStack>
                <Heading>{"Hello developer! I'm the <TestingArea />!"}</Heading>

                <Text>Use me for all your component testing needs!</Text>

                <Text>
                    You can find me at <Code>/src/TestingArea.tsx</Code>
                </Text>

                <Text>
                    If you don't need me anymore and want to see the real app,
                    just set <Code>REACT_APP_TESTING_MODE=false</Code> in your{" "}
                    <Code>.env</Code>
                </Text>

                <Text>
                    I can't wait to see what we're building together! 😊
                </Text>
            </VStack>
        </Box>
    );
};

export default TestingArea;
