import { OfferOnlyType, PropertyType } from "../../../../../types";
import { TranslationFunction } from "../../hooks/useSmartTranslation";

const translationNameSpace = "common.components.dataTable.columns.caseColumns";

export const reducePropertyTitles = (
    properties: PropertyType[],
    t: TranslationFunction
) => {
    const noOfProperties = properties.length;

    if (noOfProperties < 2) {
        const firstProperty = properties[0];

        return `${firstProperty.city}, ${firstProperty.street}`;
    }
    return t(`${translationNameSpace}.noOfProperties`, {
        count: noOfProperties,
    });
};

export const estimatePortfolioValue = (properties: PropertyType[]) => {
    const estimatePortfolioValue = properties.reduce(
        (acc, i) => acc + i.estimateValue,
        0
    );

    return estimatePortfolioValue;
};

export const filterActiveOffers = (offers: OfferOnlyType[]) => {
    const activeOffers = offers.filter(
        (offer) => offer.status === "PENDING"
    ).length;

    return activeOffers;
};

export const calculateBestDelivery = (offers: OfferOnlyType[]) => {
    if (!offers.length) {
        return;
    }

    const bestDelivery = offers.reduce((acc, current) =>
        new Date(current.availability) < new Date(acc.availability)
            ? current
            : acc
    );

    return bestDelivery;
};

export const calculateBestOffer = (offers: OfferOnlyType[]) => {
    if (!offers.length) {
        return;
    }

    const bestOffer = offers.reduce((acc, current) =>
        current.price < acc.price ? current : acc
    );

    return bestOffer;
};
