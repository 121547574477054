import {
    HStack,
    PinInput,
    PinInputField,
    Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useSmartTranslation } from '../hooks/useSmartTranslation';

const FormikPinInput = (props: { name: string }) => {
    const t = useSmartTranslation();

    const { name } = props;

    const [field, meta] = useField(props);

    return (
        <div>
            <HStack>
                <PinInput
                    otp
                    isInvalid={!!meta.touched && !!meta.error}
                    variant="flushed"
                    placeholder="•"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    onChange={value => field.onChange({ target: { name, value } })}
                >
                    <PinInputField color="wvwGreen" fontSize="32px" paddingBottom={4} w={`${100 / 6}%`} />
                    <PinInputField color="wvwGreen" fontSize="32px" paddingBottom={4} w={`${100 / 6}%`} />
                    <PinInputField color="wvwGreen" fontSize="32px" paddingBottom={4} w={`${100 / 6}%`} />
                    <PinInputField color="wvwGreen" fontSize="32px" paddingBottom={4} w={`${100 / 6}%`} />
                    <PinInputField color="wvwGreen" fontSize="32px" paddingBottom={4} w={`${100 / 6}%`} />
                    <PinInputField color="wvwGreen" fontSize="32px" paddingBottom={4} w={`${100 / 6}%`} />
                </PinInput>
            </HStack>

            { meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                ) }
        </div>
    );
};

export default FormikPinInput;
