import { documentRequestUpdateFolder } from '../../endpoints';
import { DocumentRequestUpdateFolderDto } from '../../dto';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';
import useMutationHook from '../useMutationHook';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestUpdateFolder = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { refreshDocumentManagementArea } = useRefreshAllDMSDataStores(Number(caseId));

    const mutation = useMutationHook({
        mutationFunction: (dto: DocumentRequestUpdateFolderDto) => (
            documentRequestUpdateFolder(dto)
        ),
        refreshes: [refreshDocumentManagementArea],
        successMessage: t('toast.messages.folderUpdated'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestUpdateFolder;
