import {
    Button,
    createStandaloneToast,
    Flex,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCaseDormantCases } from '../../../utils/api/hooks';
import { CaseType, EnquiryType } from '../../../types';
import DataTable from '../../../common/components/datatable/DataTable';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import delimitNumber from '../../../common/functions/delimitNumber';
import useCaseSetDormant from '../../../utils/api/hooks/useCaseSetDormant';
import { useRole } from '../../../common/hooks';
import { displayDateWithCurrentLocale } from '../../../common/functions/displayDateInLocale';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const DormantCases = () => {
    const { t } = useTranslation(['dashboard', 'common']);

    const { toast } = createStandaloneToast();

    const { userIsClient, userIsValuer, roleLoading } = useRole();

    const {
        data: dormantCases = [],
        isLoading: dormantCasesLoading,
        refresh,
    } = useCaseDormantCases();

    const {
        update: setDormant,
    } = useCaseSetDormant({
        onSuccess: () => {
            toast({
                title: t('success.success', { ns: 'common' }),
                description: t('toast.cases.reactivateSuccess', { ns: 'enquiries' }),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refresh();
        },
        onError: err => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: err.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    if (roleLoading || dormantCasesLoading) {
        return <LoadingSpinner noText />;
    }

    const tableHeader = [
        {
            title: '#',
            sorter: (a: CaseType, b: CaseType) => a.id - b.id,
            render: (i: CaseType) => (
                <Text>
                    {i.id}
                </Text>
            ),
        },
        {
            title: userIsValuer ? t('enquiriesTable.header.client') : t('enquiriesTable.header.valuer'),
            sorter: (a: CaseType, b: CaseType) => {
                const aName = `${a.clientUser.firstName} ${a.clientUser.lastName}`;
                const bName = `${b.clientUser.firstName} ${b.clientUser.lastName}`;

                if (userIsValuer) {
                    return aName.localeCompare(bName || '');
                }

                const aValuerName = `${a.valuerUser.firstName} ${a.valuerUser.lastName}`;
                const bValuerName = `${b.valuerUser.firstName} ${b.valuerUser.lastName}`;

                return aValuerName.localeCompare(bValuerName);
            },

            render: (i: CaseType) => {
                const aName = `${i.clientUser?.firstName} ${i.clientUser?.lastName}`;
                const aValuarName = `${i.valuerUser?.firstName} ${i.valuerUser?.lastName}`;
                return userIsValuer ? aName : aValuarName;
            },
        },
        {
            title: t('enquiriesTable.header.property'),
            sorter: (a: CaseType, b: CaseType) => {
                const aProperty = a?.enquiry?.properties?.[0]?.street || `${a?.enquiry?.properties?.length || 0} properties`;
                const bProperty = b?.enquiry?.properties?.[0]?.street || `${b?.enquiry?.properties?.length || 0} properties`;

                return aProperty.localeCompare(bProperty);
            },
            render: (i: CaseType) => {
                const { properties = [] } = i.enquiry || {} as EnquiryType;

                if (properties.length === 1) {
                    return (
                        <Text>
                            {properties[0].street}
                        </Text>
                    );
                }

                return (
                    <Text>
                        {t('property_wc', { count: properties.length })}
                    </Text>
                );
            },
        },
        {
            title: t('activeCasesTable.header.dateAccepted'),
            sorter: (a: CaseType, b: CaseType) => a.createdAt.localeCompare(b.createdAt),
            render: (i: CaseType) => (
                <Text>
                    {displayDateWithCurrentLocale(i.createdAt)}
                </Text>
            ),
        },
        {
            title: t('enquiriesTable.header.estimateValue'),
            sorter: (a: CaseType, b: CaseType) => {
                const { properties: aProperties = [] } = a.enquiry;
                const { properties: bProperties = [] } = b.enquiry;

                const aEstimateValue = aProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                const bEstimateValue = bProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                return aEstimateValue - bEstimateValue;
            },
            render: (i: CaseType) => {
                const { properties = [] } = i.enquiry;

                const estimateValue = properties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                return (
                    <Text
                        pr={userIsClient ? '3.8rem' : '2rem'}
                    >
                        {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(estimateValue)}`}
                    </Text>
                );
            },
        },
        {
            title: t('activeCasesTable.header.price', { ns: 'dashboard', defaultValue: 'Price' }),
            sorter: (a: CaseType, b: CaseType) => a.acceptedOffer.price - b.acceptedOffer.price,
            render: (i: CaseType) => (
                <Text pr={userIsClient ? '2.4rem' : '1rem'}>
                    {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(i.acceptedOffer.price)}`}
                </Text>
            ),
        },
        {
            title: t('enquiriesTable.header.dueDate'),
            sorter: (a: CaseType, b: CaseType) => {
                const aDueDate = new Date(a.acceptedOffer.availability);
                const bDueDate = new Date(b.acceptedOffer.availability);

                aDueDate.setDate(aDueDate.getDate() + Number(a.acceptedOffer.timeline));
                bDueDate.setDate(bDueDate.getDate() + Number(b.acceptedOffer.timeline));

                return aDueDate.getTime() - bDueDate.getTime();
            },
            render: (i: CaseType) => {
                const dueDate = new Date(i.acceptedOffer.availability);

                dueDate.setDate(dueDate.getDate() + Number(i.acceptedOffer.timeline));

                return (
                    <Text>
                        {displayDateWithCurrentLocale(dueDate)}
                    </Text>
                );
            },
        },
        {
            render: (i: CaseType) => (
                <Flex justifyContent="flex-end">
                    <Button
                        borderRadius="30"
                        variant="primaryYellow"
                        size="sm"
                        onClick={() => setDormant({
                            caseId: i.id, setDormancy: false,
                        })}
                    >
                        {t('enquiriesTable.button.reactivate')}
                    </Button>
                </Flex>
            ),
        },
    ];

    return (
        <PageWithTitleLayout title={t('section.dormantCases')}>
            <DataTable
                columns={tableHeader}
                data={dormantCases}
                noDataText={t('noDormantCases', { ns: 'activecases' })}
            />
        </PageWithTitleLayout>
    );
};

export default DormantCases;
