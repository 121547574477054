const EMAIL_VERIFICATION = 'send-email-verification';

const goToSendEmailVerification = () => {
    const { pathname } = window.location;

    const pathFragments = pathname.split('/');

    if (pathFragments.includes(EMAIL_VERIFICATION)) return;
    if (pathFragments.includes('fb-action')) return;
    if (pathFragments.includes('register')) return;

    const pName = encodeURIComponent(pathname);

    if (pName !== '%2F') {
        window.location.href = `/${EMAIL_VERIFICATION}/${pName}`;
        return;
    }

    window.location.href = `/${EMAIL_VERIFICATION}`;
};

export default goToSendEmailVerification;
