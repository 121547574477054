import {
    Box,
    VStack,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import PropertyDetailsCondensedOverview from './common/PropertyDetailsCondensedOverview';
import SplitLayout from '../../../../common/components/SplitLayout';
import { CaseType, InspectionType, PropertyType } from '../../../../types';
import { useCaseGetCase, useInspectionGetCaseInspections } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import PropertyAccordion from './common/PropertyAccordion';

type ContextType = {
    inspectionList: InspectionType[];
};

const Inspection = () => {
    const { t } = useTranslation('inspection');

    const { caseId = '' } = useParams();

    const {
        data: caseData = {} as CaseType,
        isLoading: caseLoading,
    } = useCaseGetCase(Number(caseId));

    const {
        data: inspectionList = [],
        isLoading: inspectionsLoading,
    } = useInspectionGetCaseInspections(Number(caseId));

    const [selectedProperty, setSelectedProperty] = useState<PropertyType | undefined>(undefined);

    const selectedInspection = inspectionList.find(
        inspection => inspection.propertyId === selectedProperty?.id,
    );

    useEffect(() => {
        if (caseLoading) return;

        setSelectedProperty(caseData.enquiry.properties[0]);
    }, [caseData]);

    if (caseLoading || inspectionsLoading) return <LoadingSpinner />;

    return (
        <>
            <Box
                bg="white"
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="1.5rem"
                w="100%"
            >
                <SplitLayout
                    splitPercentage={70}
                    titleLeft={t('title.yourInspections')}
                    titleRight={t('title.propertyDetails')}
                    contentLeft={(
                        <VStack
                            align="left"
                        >
                            <Text
                                paddingRight="15rem"
                            >
                                {t('inspectionDetails.description')}
                            </Text>

                            <PropertyAccordion
                                caseData={caseData}
                                inspectionList={inspectionList}
                                propertyList={caseData.enquiry.properties}
                                onClick={setSelectedProperty}
                            />
                        </VStack>
                    )}
                    contentRight={(selectedProperty && selectedInspection) && (
                        <PropertyDetailsCondensedOverview
                            property={selectedProperty}
                            inspection={selectedInspection}
                        />
                    )}
                />
            </Box>

            <Outlet context={{ inspectionList } satisfies ContextType} />
        </>
    );
};

export default Inspection;

export const useInspectionListContext = () => useOutletContext<ContextType>();
