import {
    Flex,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSmartTranslation } from '../../../common/hooks';
import { WvwText, WVWTitle } from '../../../common/components/typography';
import { WvwButton } from '../../../common/components/inputs';
import ModalDynamic from '../../../common/components/ModalDynamic';
import AssignValuerToEnquiry from './AssignValuerToEnquiry';

type PropTypes = {
    enquiryId: number | string;
};

const ValuerAdminAssignEnquiryBannerWithAssignModal = (props: PropTypes) => {
    const { enquiryId } = props;

    const t = useSmartTranslation('enquiries');

    const [assignOpen, setAssignOpen] = useState(false);

    return (
        <>
            <Flex
                align="center"
                bg="wvwYellow10"
                borderRadius="10"
                justifyContent="space-between"
                paddingInline="2rem"
                paddingBlock="1rem"
            >
                <VStack align="flex-start">
                    <WVWTitle
                        level="2"
                        color="wvwYellow"
                        content={t('enquiryBanner.assignBanner.sectionTitle')}
                    />

                    <WvwText>
                        {t('enquiryBanner.assignBanner.sectionContent')}
                    </WvwText>
                </VStack>

                <WvwButton
                    content={t('assignEnquiry', { ns: 'common' })}
                    onClick={() => setAssignOpen(true)}
                />
            </Flex>

            <ModalDynamic
                isOpen={assignOpen}
                onClose={() => setAssignOpen(false)}
                header={t('modal.assignEnquiry.title')}
            >
                <AssignValuerToEnquiry
                    enquiryId={enquiryId}
                    onComplete={() => setAssignOpen(false)}
                />
            </ModalDynamic>
        </>
    );
};

export default ValuerAdminAssignEnquiryBannerWithAssignModal;
