import {
    Box,
    Button,
    createStandaloneToast,
    Flex,
    Heading,
    ListItem,
    OrderedList,
    Spacer,
    Text,
    VStack,
    Link as ChakraLink,
    ButtonGroup,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet, Link as ReactRouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMyEntity } from "../../../../utils/api/hooks";
import { useRole } from "../../../../common/hooks";
import AccountAccessDeniedAlert from "../../../../common/components/display/AccountAccessDeniedAlert";

const Integration = () => {
    const { t } = useTranslation(["account", "common"]);

    const { toast } = createStandaloneToast();

    const { userIsApproved, userIsClient } = useRole();

    const { data, isLoading, refresh } = useMyEntity();

    useEffect(() => {
        if (isLoading) return;
        if (data.identityToken) return;

        refresh();
    }, [data]);

    if (isLoading) return null;

    if (!userIsApproved || userIsClient) {
        return <AccountAccessDeniedAlert />;
    }

    return (
        <Flex
            paddingInline=".5rem"
            paddingBlock="2rem"
        >
            <VStack
                align="left"
                w="25%"
                alignSelf="flex-start"
                spacing="6"
            >
                <Box>
                    <Heading
                        as="h4"
                        size="md"
                        fontWeight="light"
                    >
                        {t("integration.sectionTitle")}
                    </Heading>

                    <Text
                        color="wvwGrey"
                        paddingTop="1rem"
                    >
                        {t("integration.blurb")}
                    </Text>

                    <Text
                        color="wvwGrey60"
                        fontStyle="italic"
                        paddingBlock="1.5rem"
                    >
                        {t("integration.procedure.disclaimer")}
                    </Text>
                </Box>
            </VStack>

            <Spacer />

            <VStack
                alignItems="left"
                spacing="6"
                w="70%"
            >
                <Heading
                    as="h4"
                    size="md"
                    fontWeight="light"
                >
                    {t("integration.customerTypes.private")}
                </Heading>
                <Flex
                    border="2px solid"
                    borderColor="wvwBlue"
                    bg="wvwGrey05"
                    borderRadius="8"
                    padding="0.5rem"
                    align="center"
                >
                    <Text
                        color="wvwGrey80"
                        fontSize="1rem"
                    >
                        {`${window.location.origin}/direct-enquiry-form/individual/${data.identityToken}`}
                    </Text>
                </Flex>

                <ButtonGroup>
                    <Button
                        variant="primary"
                        w="9rem"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${window.location.origin}/direct-enquiry-form/individual/${data.identityToken}`
                            );
                            toast({
                                title: t("integration.buttonTitle"),
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                            });
                        }}
                    >
                        {t("integration.button.copyLink")}
                    </Button>

                    <ChakraLink
                        alignSelf="center"
                        as={ReactRouterLink}
                        to="enquiry-email-link/individual"
                    >
                        <Button variant="primaryYellow">
                            {t("integration.button.emailLink")}
                        </Button>
                    </ChakraLink>
                </ButtonGroup>

                <Heading
                    as="h4"
                    size="md"
                    fontWeight="light"
                >
                    {t("integration.customerTypes.institution")}
                </Heading>
                <Flex
                    border="2px solid"
                    borderColor="wvwBlue"
                    bg="wvwGrey05"
                    borderRadius="8"
                    padding="0.5rem"
                    align="center"
                >
                    <Text
                        color="wvwGrey80"
                        fontSize="1rem"
                    >
                        {`${window.location.origin}/direct-enquiry-form/company/${data.identityToken}`}
                    </Text>
                </Flex>

                <ButtonGroup>
                    <Button
                        variant="primary"
                        w="9rem"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${window.location.origin}/direct-enquiry-form/company/${data.identityToken}`
                            );
                            toast({
                                title: t("integration.buttonTitle"),
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                            });
                        }}
                    >
                        {t("integration.button.copyLink")}
                    </Button>

                    <ChakraLink
                        alignSelf="center"
                        as={ReactRouterLink}
                        to="enquiry-email-link/company"
                    >
                        <Button variant="primaryYellow">
                            {t("integration.button.emailLink")}
                        </Button>
                    </ChakraLink>
                </ButtonGroup>

                <OrderedList>
                    <ListItem>{t("integration.procedure.copyCode")}</ListItem>
                    <ListItem>{t("integration.procedure.pasteCode")}</ListItem>
                </OrderedList>
            </VStack>

            <Outlet />
        </Flex>
    );
};

export default Integration;
