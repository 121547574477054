import { t } from 'i18next';
import { useState } from 'react';
import ContactForm from '../../../common/components/ContactForm';
import ModalDynamic from '../../../common/components/ModalDynamic';

const useSupportModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const modal = (
        <ModalDynamic
            variant="medium"
            isOpen={modalIsOpen}
            header={t('support', { ns: 'common' })}
            onClose={() => setModalIsOpen(false)}
        >
            <ContactForm />
        </ModalDynamic>
    );

    return {
        modal,
        openModal: () => setModalIsOpen(true),
    };
};

export default useSupportModal;
