import LoadingSpinner from '../../../common/components/LoadingSpinner';
import DataTable from '../../../common/components/datatable/DataTable';
import offerColumns from '../../../common/components/datatable/columns/offerColumns';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';
import { useSmartTranslation } from '../../../common/hooks';
import { AdminOffersDto } from '../../../utils/api/dto';
import { useAdminOffers } from '../../../utils/api/hooks';

const headers = [
    offerColumns.company,
    offerColumns.valuer,
    offerColumns.price,
    offerColumns.availability,
    offerColumns.status,
];

const AdminOffers = () => {
    const { data: offers, isLoading } = useAdminOffers({});

    const t = useSmartTranslation('admin');

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const offerFilters = [
        {
            filterName: 'Offer status',
            placeholder: 'Status',
            options: [
                { label: t('filters.offers.status.ACCEPTED', { ns: 'admin' }), value: 'ACCEPTED' },
                { label: t('filters.offers.status.PENDING', { ns: 'admin' }), value: 'PENDING' },
                { label: t('filters.offers.status.REJECTED', { ns: 'admin' }), value: 'REJECTED' },
                { label: t('filters.offers.status.WITHDRAWN', { ns: 'admin' }), value: 'WITHDRAWN' },
            ],
            filterFunction:
            (i: AdminOffersDto, filterValue?: string) => i.status === filterValue,
        },
    ];
    return (
        <PageWithTitleLayout title="Admin Offers">
            <DataTable
                columns={headers}
                data={offers}
                filters={offerFilters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminOffers;
