import { documentRequestRemoveDocumentFromRequest } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';
import useMutationHook from '../useMutationHook';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestRemoveDocumentFromRequest = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');
    const { refreshAll } = useRefreshAllDMSDataStores(Number(caseId));

    const mutation = useMutationHook({
        mutationFunction: (documentId: string | number) => (
            documentRequestRemoveDocumentFromRequest(documentId)
        ),
        refreshes: [refreshAll],
        successMessage: t('toast.messages.documentRemoved'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestRemoveDocumentFromRequest;
