import {
    Box,
} from '@chakra-ui/react';
import RequestDocumentsListItem from './RequestDocumentsListItem';
import { DocumentRequestDocumentType, DocumentRequestType } from '../../../../../../../types';

type PropTypes = {
    documentList: DocumentRequestDocumentType[];
    request: DocumentRequestType;
};

const RequestDocumentsList = (props: PropTypes) => {
    const { documentList, request } = props;

    return (
        <Box
            justifySelf="center"
            maxH="21rem"
            overflowY="auto"
        >
            {documentList.map(document => (
                <RequestDocumentsListItem
                    document={document}
                    key={document.id}
                    request={request}
                />
            ))}
        </Box>
    );
};

export default RequestDocumentsList;
