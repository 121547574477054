import { DocumentRequestUploadDocumentsDto } from '../../dto';
import { documentRequestUploadDocuments } from '../../endpoints';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';
import useMutationHook from '../useMutationHook';
import useDocumentRequestGetRequest from './useDocumentRequestGetRequest';

type ParamsType = {
    caseId: number | string;
    requestId?: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestUploadDocuments = (params: ParamsType) => {
    const {
        caseId,
        requestId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');

    const { refreshAll } = useRefreshAllDMSDataStores(Number(caseId));
    const { refresh: refreshRequest } = useDocumentRequestGetRequest(Number(requestId));

    const mutation = useMutationHook({
        mutationFunction:
            (dto: DocumentRequestUploadDocumentsDto) => documentRequestUploadDocuments(dto),
        refreshes: [refreshAll, refreshRequest],
        successMessage: t('toast.messages.documentUploaded'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestUploadDocuments;
