import { Flex, Text } from "@chakra-ui/react";
import { useRole, useSmartTranslation } from "../../../../common/hooks";
import { UserType } from "../../../../types";
import FormSectionProfileEdit from "./FormSectionProfileEdit";
import { WvwIcon } from "../../../../common/components/display";

type PropsType = {
    user: UserType;
};

const ProfileContactDetails = (props: PropsType) => {
    const { user } = props;

    const t = useSmartTranslation("profile");

    const { userIsValuer } = useRole();

    const renderFieldData = (
        field: string,
        data: (string | number)[],
        config?: {
            required?: true;
            joinWith?: string;
        }
    ) => {
        const { required, joinWith = " " } = config || {};

        if (data.every((item) => item)) {
            return <Text>{data.join(joinWith)}</Text>;
        }

        return (
            <Text color="wvwGrey40">
                {required && <WvwIcon icon="warning" />}{" "}
                {t(`fieldNotProvided.${field}`, {
                    ns: "common",
                    defaultValue: field,
                })}
            </Text>
        );
    };

    return (
        <FormSectionProfileEdit header={t("heading.contactDetails")}>
            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t("profileDetails.email")}
                </Text>

                {renderFieldData("email", [user?.email], { required: true })}
            </Flex>

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t("profileDetails.mobileNumber")}
                </Text>

                {renderFieldData(
                    "mobileNumber",
                    [user?.mobileDialCode, user?.mobile],
                    { required: true }
                )}
            </Flex>

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t("profileDetails.telNumber")}
                </Text>

                {renderFieldData("telNumber", [user?.telDialCode, user?.tel])}
            </Flex>

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t("profileDetails.faxNumber")}
                </Text>

                {renderFieldData("faxNumber", [user?.faxDialCode, user?.fax])}
            </Flex>

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t("profileDetails.roleInCompany")}
                </Text>

                {renderFieldData("roleInCompany", [user?.orgPosition])}
            </Flex>

            {userIsValuer && (
                <Flex
                    alignItems="center"
                    h="2rem"
                    w="100%"
                >
                    <Text
                        maxW="17rem"
                        w="100%"
                    >
                        {t("profileDetails.doesValuations")}
                    </Text>

                    {renderFieldData("doesValuations", [
                        user?.doesValuations === true
                            ? t("yes", { ns: "common" })
                            : t("no", { ns: "common" }),
                    ])}
                </Flex>
            )}
        </FormSectionProfileEdit>
    );
};

export default ProfileContactDetails;
