import { testOffers } from "../../../domain/offer/testdata";
import { Conversation } from "./types";
import { UserType } from "../../../../../types";

export const testConversations: Conversation[] = [
    {
        topic: {
            type: "Offer",
            offer: testOffers[0],
        },
        participants: [
            {
                id: 1,
                entity: {
                    accountType: "CLIENT",
                },
            } as UserType,
            {
                id: 2,
                entity: {
                    accountType: "VALUER",
                },
            } as UserType,
        ],
        messages: [
            {
                id: 1,
                type: "OfferAcceptance",
                sender: 1,
                receiver: 2,
                timestamp: new Date(),
                offer: testOffers[0],
            },
            {
                id: 2,
                type: "OfferComment",
                sender: 1,
                receiver: 2,
                timestamp: new Date(),
                offerComment: {
                    id: 1,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    offerId: 2,
                    fromId: 1,
                    readBy: [1, 2],
                    type: "COMMENT",
                    message:
                        "Lorem ipsum is lorem. Lorem ipsum is loremLorem ipsum is loremLorem ipsum is loremLorem ipsum is loremLorem ipsum is loremLorem ipsum is loremLorem ipsum is loremLorem ipsum is loremLorem ipsum is lorem",
                },
            },
            {
                id: 3,
                type: "OfferComment",
                sender: 2,
                receiver: 1,
                timestamp: new Date(),
                offerComment: {
                    id: 1,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    offerId: 2,
                    fromId: 1,
                    readBy: [1, 2],
                    type: "OFFER_UPDATE",
                    message: "",
                },
            },
        ],
    },
    {
        topic: {
            type: "Offer",
            offer: testOffers[1],
        },
        participants: [
            {
                id: 1,
                entity: {
                    accountType: "CLIENT",
                },
            } as UserType,
            {
                id: 2,
                entity: {
                    accountType: "VALUER",
                },
            } as UserType,
        ],
        messages: [
            {
                id: 4,
                type: "OfferAcceptance",
                sender: 1,
                receiver: 2,
                timestamp: new Date(),
                offer: testOffers[0],
            },
            {
                id: 5,
                type: "OfferComment",
                sender: 1,
                receiver: 2,
                timestamp: new Date(),
                offerComment: {
                    id: 1,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    offerId: 2,
                    fromId: 1,
                    readBy: [1, 2],
                    type: "COMMENT",
                    message: "Lorem ipsum is lorem. 2",
                },
            },
        ],
    },
];
