const enquiryOtherValuerMemberships: string[] = [
    'Tegova - Blue Book',
    'IVSC - White Book',
    'ValueV',
    'BDSF',
    'DESAG',
    'TAF',
    'AI',
    'ASA',
    'Other',
];

export default enquiryOtherValuerMemberships;
