import { JSXElementConstructor, MouseEventHandler, ReactElement } from "react";
import WvwButton from "./WvwButton";

type PropTypes = {
    content: string;
    icon?: ReactElement<any, string | JSXElementConstructor<any>>;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

const WvwPrimaryButton = (props: PropTypes) => {
    const { content, icon = undefined, onClick } = props;

    return (
        <WvwButton
            content={content}
            icon={icon}
            onClick={onClick}
            variant="primary"
        />
    );
};

WvwPrimaryButton.defaultProps = {
    icon: undefined,
};

export default WvwPrimaryButton;
