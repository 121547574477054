import { AxiosResponse } from "axios";
import { authConfirmEmailValidation } from "../endpoints";
import useMutationHook from "./useMutationHook";
import { UserType } from "../../../types";

type ParamsType = {
    onSuccess?: (data: AxiosResponse<UserType, any>) => void;
    onError?: (error: Error) => void;
};

const useAuthConfirmEmailValidation = (params: ParamsType) => {
    const { onSuccess, onError } = params;

    const mutation = useMutationHook({
        mutationFunction: () => authConfirmEmailValidation(),
        refreshes: [],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useAuthConfirmEmailValidation;
