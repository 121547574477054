import { HStack, VStack } from '@chakra-ui/react';
import { useRole, useSmartTranslation } from '../../../../common/hooks';
import { EntityType, UserType } from '../../../../types';
import PublicUserProfilePicture from '../../../../common/components/PublicUserProfilePicture';
import { WvwText, WVWTitle } from '../../../../common/components/typography';

type PropsType = {
    entity: EntityType;
    user: UserType;
};

const ProfileHeading = (props: PropsType) => {
    const { entity, user } = props;

    const t = useSmartTranslation('profile');

    const { userIsCompany } = useRole();

    const {
        id: entityId,
        name,
    } = entity;

    const {
        id: userId,
        firstName = '',
        lastName = '',
        orgPosition = '',
    } = user;

    return (
        <HStack
            w="100%"
            align="left"
            paddingTop="1rem"
            alignItems="center"
            spacing={4}
        >
            <PublicUserProfilePicture
                entityId={entityId}
                userId={userId}
                size="8rem"
            />

            <VStack
                align="left"
                spacing="0"
            >
                <WVWTitle
                    level="1"
                    color="black"
                    content={`${firstName} ${lastName}`}
                />

                {userIsCompany && (
                    <WvwText>
                        {orgPosition && t('position.at', {
                            position: orgPosition,
                            company: name,
                            interpolation: { escapeValue: false },
                        })}
                    </WvwText>
                )}
            </VStack>
        </HStack>
    );
};

export default ProfileHeading;
