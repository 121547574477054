import { Circle, Image } from "@chakra-ui/react";

type PropTypes = {
    url?: string; // The URL of the image to display
    size?: string; // The size of the profile picture
};

const ProfilePicture = (props: PropTypes) => {
    const { url, size } = props;

    return (
        <Circle
            size={size}
            margin="0"
        >
            <Image
                alt="Profile Picture"
                src={url || "/images/DefaultAvatar.png"}
                borderRadius="50%"
                objectFit="cover"
                h="100%"
                w="100%"
            />
        </Circle>
    );
};

ProfilePicture.defaultProps = {
    size: "3rem",
    url: undefined,
};

export default ProfilePicture;
