import { CaseType } from "../../../../../../types";
import { DataTableColumnType } from "../../../../common/components/data-table/DataTable";
import { caseColumns } from "../../../../common/components/data-table/columns/CaseColumns";

// columns
export const columns: DataTableColumnType<CaseType>[] = [
    caseColumns.caseIdWithProperties,
    caseColumns.expectedDelivery,
    caseColumns.caseValuer,
    caseColumns.viewCaseButton,
];
