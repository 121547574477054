/* eslint-disable max-len */
import { User } from 'firebase/auth';
import goToLogin from './goToLogin';
import goToSendEmailVerification from './goToSendEmailVerification';

/**
 * Check the authentication state of the user, redirect to login or email verification page if needed
 * @param firebaseUser The firebase user object
 */
const checkAuthState = (firebaseUser: User | null | undefined) => {
    if (!firebaseUser) {
        goToLogin();
    } else if (!firebaseUser.emailVerified) {
        goToSendEmailVerification();
    }
};

export default checkAuthState;
