import { VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PageWithTitleLayout from "../../../common/components/display/PageWithTitleLayout";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../common/hooks";
import { useAdminUploadedContract } from "../../../utils/api/hooks";
import { ContentBox, LabelAndData } from "../../../common/components/display";
import { WvwLink } from "../../../common/components/inputs";

const AdminUploadedContract = () => {
    const { contractId: contractIdString } = useParams<{
        contractId: string;
    }>();
    const contractId = Number(contractIdString);

    const t = useSmartTranslation("dashboard");

    const { data: contract, isLoading } = useAdminUploadedContract(contractId);

    if (isLoading || !contract) {
        return <LoadingSpinner />;
    }

    const {
        case: caseData,
        createdAt,
        signedContractReview,
        signedContractUrl,
        unsignedContractUrl,
    } = contract;

    const clientEntityName = caseData?.clientEntity?.name;
    const clientUserName = `${caseData?.clientUser?.firstName} ${caseData?.clientUser?.lastName}`;
    const valuerEntityName = caseData?.valuerEntity?.name;
    const valuerUserName = `${caseData?.valuerUser?.firstName} ${caseData?.valuerUser?.lastName}`;

    const signedContractLink = signedContractUrl ? (
        <WvwLink
            to={signedContractUrl}
            label={t("table.header.signedContract")}
            isExternal
        />
    ) : (
        t("noneProvided", { ns: "formik" })
    );

    const items = [
        {
            title: t("table.header.createdAt"),
            value: createdAt,
        },
        {
            title: t("table.header.status"),
            value: signedContractReview,
        },
        {
            title: t("table.header.clientEntity"),
            value: clientEntityName,
        },
        {
            title: t("table.header.clientUser"),
            value: clientUserName,
        },
        {
            title: t("table.header.valuerEntity"),
            value: valuerEntityName,
        },
        {
            title: t("table.header.valuerUser"),
            value: valuerUserName,
        },
        {
            title: t("table.header.unsignedContract"),
            value: (
                <WvwLink
                    to={unsignedContractUrl}
                    label={unsignedContractUrl}
                    isExternal
                />
            ),
        },
        {
            title: t("table.header.signedContract"),
            value: signedContractLink,
        },
    ];

    return (
        <PageWithTitleLayout title={t("uploadedContract")}>
            <ContentBox>
                <VStack>
                    {items.map(({ title, value }) => (
                        <LabelAndData
                            key={title}
                            label={title}
                            data={value}
                        />
                    ))}
                </VStack>
            </ContentBox>
        </PageWithTitleLayout>
    );
};

export default AdminUploadedContract;
