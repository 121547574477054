import ProfilePicture from "./ProfilePicture";

type PropTypes = {
    accountType: "VALUER" | "CLIENT"; // The account type of the user
    size?: string; // The size of the profile picture
    userType: "USER" | "ENTITY"; // The type of the user
};

const ProfilePictureDefault = (props: PropTypes) => {
    const { accountType, size, userType } = props;
    let url = "/images/";

    if (userType === "USER" && accountType === "VALUER") {
        url += "AvatarValuer.jpg";
    } else if (userType === "USER" && accountType === "CLIENT") {
        url += "AvatarendCust.jpg";
    } else if (userType === "ENTITY" && accountType === "VALUER") {
        url += "AvatarValuer-Company.jpg";
    } else {
        url += "AvatarEndcust-Company.jpg";
    }

    return (
        <ProfilePicture
            url={url}
            size={size}
        />
    );
};

ProfilePictureDefault.defaultProps = {
    size: "3rem",
};

export default ProfilePictureDefault;
