import {
    Box,
    Container,
    Flex,
    Heading,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';
import LoadingSpinner from './LoadingSpinner';

type PropTypes = {
    noLogo?: boolean;
    text?: string;
};

const SiteLoading = (props: PropTypes) => {
    const { noLogo = false, text } = props;

    const { t } = useTranslation();

    return (
        <Box
            bgColor="wvwGreen"
            paddingTop="1.5rem"
            h="100vh"
            w="100%"
        >
            <Container
                maxW="container.lg"
                bg="white"
                borderRadius="lg"
                p="2em"
                w="100%"
                justifyContent="center"
                alignContent="center"
            >
                <VStack
                    pb="9em"
                    pt="6em"
                    spacing="6"
                    w="100%"
                >
                    {!noLogo && (
                        <Logo withColor />
                    )}

                    <Flex alignItems="center">
                        <LoadingSpinner noText />
                    </Flex>

                    <VStack alignContent="center">
                        <Heading
                            as="h1"
                            color="wvwGrey"
                            size="xl"
                        >
                            {text || t('Welcome to WVW')}
                        </Heading>

                        <Heading
                            as="h5"
                            size="sm"
                            color="wvwGrey80"
                        >
                            {t('pleaseWait')}
                        </Heading>
                    </VStack>
                </VStack>
            </Container>
        </Box>
    );
};

SiteLoading.defaultProps = {
    noLogo: false,
    text: undefined,
};

export default SiteLoading;
