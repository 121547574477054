import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../utils/firebase';

const useSigninState = () => {
    const [firebaseUser] = useAuthState(auth);

    const signedIn = !!firebaseUser;

    return {
        signedIn,
        firebaseUser,
    };
};

export default useSigninState;
