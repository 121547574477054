/* eslint-disable react/no-this-in-sfc */
import { VStack } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ProfileContactDetailsEdit from "../../../profile/components/ProfileContactDetailsEdit";
import { FlowContext } from "../../../../../common/components/display/ProcessFlowRouted";
import {
    FormikConditionalSection,
    FormikForm,
} from "../../../../../common/forms";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { useMe } from "../../../../../utils/api/hooks";
import ProfileCompletionFormFooter from "./ProfileCompletionFormFooter";
import ProfileOfficeDetailsEdit from "../../../profile/components/ProfileOfficeDetailsEdit";
import { useRole, useSmartTranslation } from "../../../../../common/hooks";
import ProfileOperationalAreaEdit from "../../../profile/components/ProfileOperationalAreaEdit";
import FormSection from "../../../profile/components/FormSection";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("errors.invalidEmail"),
    mobileDialCode: Yup.string().required("errors.required"),
    mobile: Yup.number()
        .required("errors.required")
        .typeError("errors.enterNumber"),
    officeTelDialCode: Yup.string().test(
        "officeTel",
        "errors.required",
        function (value) {
            if (value === undefined) {
                if (this.parent.officeTel === undefined) return true;

                return false;
            }

            return true;
        }
    ),
    officeTel: Yup.number()
        .typeError("errors.enterNumber")
        .test("officeTel", "errors.required", function (value) {
            if (value === undefined) {
                if (this.parent.officeTelDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
    officeFaxDialCode: Yup.string().test(
        "officeFax",
        "errors.required",
        function (value) {
            if (value === undefined) {
                if (this.parent.officeFax === undefined) return true;

                return false;
            }

            return true;
        }
    ),
    officeFax: Yup.number()
        .typeError("errors.enterNumber")
        .test("officeFax", "errors.required", function (value) {
            if (value === undefined) {
                if (this.parent.officeFaxDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
});

const ProfileCompletionContactDetails = () => {
    const { goNext } = useContext(FlowContext);

    const t = useSmartTranslation("profile");

    const [saveAndClose, setSaveAndClose] = useState(false);

    const navigate = useNavigate();

    const { userIsValuer, userIsCompanyAdmin, roleLoading } = useRole();

    const {
        data: user,
        isLoading,
        updateMe,
    } = useMe({
        onUpdateSuccess: () => {
            if (saveAndClose) {
                navigate("/dashboard", { replace: true });
            } else {
                goNext();
            }
        },
    });

    if (!user || isLoading || roleLoading) {
        return <LoadingSpinner />;
    }

    const initalFormData = {
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        orgPosition: user.orgPosition || "",
        email: user.email || "",
        mobileDialCode: user.mobileDialCode || "",
        mobile: user.mobile || "",
        officeTelDialCode: user.officeTelDialCode || "",
        officeTel: user.officeTel || "",
        officeFaxDialCode: user.officeFaxDialCode || "",
        officeFax: user.officeFax || "",
        officeStreet: user.street || "",
        officePostalCode: user.postalCode || "",
        officeCity: user.city || "",
        officeCountry: user.country || "",
        officeLatitude: user.latitude || "",
        officeLongitude: user.longitude || "",
        maxOperatingDistance: user.maxOperatingDistance || "",
    };

    const update = (values: any) => {
        const maxOperatingDistance = parseInt(values.maxOperatingDistance, 10);

        updateMe({
            firstName: values.firstName,
            lastName: values.lastName,
            orgPosition: values.orgPosition,
            mobileDialCode: values.mobileDialCode,
            mobile: values.mobile,
            officeTelDialCode: values.officeTelDialCode,
            officeTel: values.officeTel,
            officeFaxDialCode: values.officeFaxDialCode,
            officeFax: values.officeFax,
            street: values.officeStreet,
            postalCode: values.officePostalCode,
            city: values.officeCity,
            country: values.officeCountry,
            latitude: values.officeLatitude,
            longitude: values.officeLongitude,
            maxOperatingDistance,
        });
    };

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={initalFormData}
            onSubmit={(values) => {
                update(values);
            }}
        >
            <VStack spacing={4}>
                <ProfileContactDetailsEdit
                    disableEmailEditing
                    minimumDetails
                />

                <ProfileOfficeDetailsEdit
                    userIsValuer={userIsValuer}
                    userIsCompanyAdmin={userIsCompanyAdmin}
                    streetName="officeStreet"
                    postalCodeName="officePostalCode"
                    cityName="officeCity"
                    countryName="officeCountry"
                    latName="officeLatitude"
                    lngName="officeLongitude"
                    officeTelDialCodeName="officeTelDialCode"
                    officeTelName="officeTel"
                    officeFaxDialCodeName="officeFaxDialCode"
                    officeFaxName="officeFax"
                />

                {userIsValuer && user.doesValuations && (
                    <FormSection
                        header={t("profileDetails.otherInfo")}
                        variant="white"
                        element={<ProfileOperationalAreaEdit />}
                    />
                )}

                <ProfileCompletionFormFooter
                    back={userIsCompanyAdmin}
                    handleSaveAndClose={() => setSaveAndClose(true)}
                />
            </VStack>
        </FormikForm>
    );
};

ProfileCompletionContactDetails.defaultProps = {
    updateDoesValuations: undefined,
};

export default ProfileCompletionContactDetails;
