import { VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import FormSection from './FormSection';

type PropsType = {
    header: string;
    subHeader?: string;
    children: ReactNode | ReactNode[];
};

const FormSectionProfileEdit = (props: PropsType) => {
    const {
        header,
        subHeader,
        children,
    } = props;

    return (
        <FormSection
            header={header}
            subHeader={subHeader}
            variant="white"
            element={(
                <VStack
                    spacing="6"
                    w="100%"
                >
                    {children}
                </VStack>
            )}
        />
    );
};

FormSectionProfileEdit.defaultProps = {
    subHeader: undefined,
};

export default FormSectionProfileEdit;
