import { documentRequestGetRequest } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const DOCUMENT_REQUEST_GET_REQUEST = 'DOCUMENT_REQUEST_GET_REQUEST';

const useDocumentRequestGetRequest = (requestId: string | number) => {
    const query = useQueryHook({
        uniqueName: DOCUMENT_REQUEST_GET_REQUEST,
        dependancies: [requestId],
        requiredParams: [requestId],
        queryFunction: () => documentRequestGetRequest(requestId),
    });

    return query;
};

export default useDocumentRequestGetRequest;
