import {
    Button, Divider, Input, Textarea, VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import RequestListDisplay from './RequestListDisplay';
import { DocumentRequestType } from '../../../../../types';

type RequestType = {
    name: string;
    description: string;
};

type PropTypes = {
    name: string;
    submitOnAdd?: boolean;
};

const RequestListCreation = (props: PropTypes) => {
    const { name, submitOnAdd = false } = props;

    const { t } = useTranslation(['documentmanagement']);

    const [field] = useField(props);
    const { submitForm } = useFormikContext();

    const [request, setRequest] = useState({} as RequestType);

    const addRequest = () => {
        const inputList = [...field.value, request];

        field.onChange({
            target: {
                name,
                value: inputList,
            },
        });

        // workaround to be able to submit TemplateListItem's form
        if (submitOnAdd) submitForm();

        setRequest({
            name: '',
            description: '',
        } as RequestType);
    };

    return (
        <VStack w="100%">
            <VStack w="100%">
                <Input
                    name="name"
                    value={request.name}
                    placeholder={t('requestTitle', { ns: 'documentmanagement' })}
                    onChange={
                        e => {
                            setRequest({
                                name: e.target.value as string,
                                description: request.description,
                            });
                        }
                    }
                />

                <Textarea
                    name="description"
                    value={request.description}
                    placeholder={t('instructionNotes', { ns: 'documentmanagement' })}
                    onChange={
                        e => {
                            setRequest({
                                name: request.name,
                                description: e.target.value as string,
                            });
                        }
                    }
                />

                <Button
                    variant="primary"
                    marginLeft=".5rem"
                    alignSelf="flex-end"
                    onClick={addRequest}
                >
                    {t('button.add', { ns: 'common' })}
                </Button>
            </VStack>

            <Divider />

            <RequestListDisplay
                onDelete={(_id, r) => {
                    const newRequestList = field.value.filter(
                        (i: RequestType) => JSON.stringify(i) !== r,
                    );

                    field.onChange({
                        target: {
                            name,
                            value: newRequestList,
                        },
                    });
                }}
                requests={field.value as DocumentRequestType[]}
                requestType="REQUEST"
            />
        </VStack>
    );
};

RequestListCreation.defaultProps = {
    submitOnAdd: false,
};

export default RequestListCreation;
