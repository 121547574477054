import { Accordion } from "@chakra-ui/react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import Folder from "./common/Folder";
import { useDocumentRequestGetFolders } from "../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import {
    DocumentFiltersType,
    RequestFiltersType,
} from "../common/documentManagementAreaTypes";

type PropTypes = {
    inEditMode: boolean;
    documentFilter: DocumentFiltersType;
    requestFilter: RequestFiltersType;
};

const Folders = (props: PropTypes) => {
    const { inEditMode, documentFilter, requestFilter } = props;

    const { caseId } = useParams<{ caseId: string }>();

    const { data: folders, isLoading } = useDocumentRequestGetFolders({
        caseId: Number(caseId),
        requestStatus: requestFilter === "ALL" ? undefined : requestFilter,
        documentStatus: documentFilter === "ALL" ? undefined : documentFilter,
    });

    const expandedAccordion = useMemo(
        () =>
            folders?.reduce((result, folder, index) => {
                if (folder.requests.length > 0) {
                    result.push(index);
                }
                return result;
            }, [] as number[]),
        [isLoading]
    );

    if (isLoading) return <LoadingSpinner />;

    return (
        <Accordion
            allowMultiple
            defaultIndex={expandedAccordion}
            overflow="auto"
            variant="primary"
            w="100%"
            paddingBottom=".3rem"
        >
            {folders?.map((folder) => (
                <Folder
                    inEditMode={inEditMode}
                    folder={folder}
                    key={folder.id}
                    requestFilter={requestFilter}
                    documentFilter={documentFilter}
                />
            ))}
        </Accordion>
    );
};

export default Folders;
