import { documentRequestMarkDocumentCommentAsRead } from '../../endpoints';
import useDocumentRequestGetDocument from './useDocumentRequestGetDocument';
import useMutationHook from '../useMutationHook';

type ParamsType = {
    documentId: string | number;
    onSuccess?: () => void;
    onError?: (error?: Error) => void;
};

const useDocumentRequestMarkDocumentCommentsAsRead = (params: ParamsType) => {
    const {
        documentId,
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshComments } = useDocumentRequestGetDocument(documentId);

    const mutation = useMutationHook({
        mutationFunction: (dId: number | string) => (
            documentRequestMarkDocumentCommentAsRead(dId)
        ),
        refreshes: [refreshComments],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestMarkDocumentCommentsAsRead;
