import { useTranslation } from 'react-i18next';

export type NamespaceTypes =
| 'account'
| 'activecases'
| 'admin'
| 'carousel'
| 'casereport'
| 'casecontract'
| 'common'
| 'companyCustomize'
| 'dashboard'
| 'documentmanagement'
| 'enquiries'
| 'enquiryform'
| 'errors'
| 'formik'
| 'inspection'
| 'login'
| 'menubar'
| 'offer'
| 'passwordReset'
| 'profile'
| 'updates'
| 'wizard'
| 'v2';

const useSmartTranslation = (defaultNamespace?: NamespaceTypes) => {
    const { t: translate } = useTranslation(defaultNamespace);

    return translate;
};

export default useSmartTranslation;
