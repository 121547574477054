import { useEffect } from "react";
import { PageContainerBasicWithUser } from "../../../common/components/display";
import { useApplyActionCode } from "../../../utils/api/hooks/firebase";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { WVWTitle } from "../../../common/components/typography";
import { useSmartTranslation } from "../../../common/hooks";
import { useAuthConfirmEmailValidation } from "../../../utils/api/hooks";
import { VStack } from "@chakra-ui/react";

type PropsType = {
    oobCode: string;
    redirectUrl: string;
};

let appliedOobCode = false;

const ProcessEmailVerification = (props: PropsType) => {
    const { oobCode, redirectUrl } = props;

    const t = useSmartTranslation("common");

    const { update: confirmEmailVerified } = useAuthConfirmEmailValidation({
        onSuccess: () => window.location.replace(redirectUrl),
    });

    const { update: applyActionCode } = useApplyActionCode({
        onSuccess: () => confirmEmailVerified(undefined),
    });

    useEffect(() => {
        if (appliedOobCode) return;

        appliedOobCode = true;

        applyActionCode(oobCode);
        console.log("ProcessEmailVerification", oobCode);
    }, []);

    return (
        <PageContainerBasicWithUser>
            <VStack>
                <WVWTitle
                    level="1"
                    content={t("verifyingEmail")}
                />

                <LoadingSpinner />
            </VStack>
        </PageContainerBasicWithUser>
    );
};

export default ProcessEmailVerification;
