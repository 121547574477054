import {
    VStack,
    Heading,
    Text,
    Button,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../../common/components/Logo';
import { useInviteValidateInvite } from '../../../utils/api/hooks';
import Footer from '../../../common/components/Footer';
import { FlowContextStateful } from '../../../common/components/display/ProcessFlowStateful';
import SiteLoading from '../../../common/components/SiteLoading';
import RegisterFormsContainer from '../common/RegisterFormsContainer';
import { WvwButton } from '../../../common/components/inputs';

type ParamsType = {
    token: string;
    inviteType: 'invitedToClientPanel' | 'invitedToValuerPanel' | 'invitedToTeam';
};

const InviteLandingPage = () => {
    const { t } = useTranslation(['account', 'common']);
    const navigate = useNavigate();

    const { token = '', inviteType = 'invitedToTeam' } = useParams<ParamsType>();

    const { goBack, goNext } = useContext(FlowContextStateful);

    const {
        isValid,
        isLoading,
    } = useInviteValidateInvite({ token, inviteType });

    if (isLoading) {
        return (
            <SiteLoading />
        );
    }

    return (
        <RegisterFormsContainer goBack={goBack}>
            <VStack
                align="center"
                spacing="6"
                pt="6rem"
                pb="9rem"
            >
                <Logo withColor />

                {isValid === false && (
                    <VStack>
                        <Text>
                            {t('invalidInvite', { ns: 'account' })}
                        </Text>

                        <Button
                            onClick={() => navigate('/login')}
                            variant="primary"
                        >
                            {t('button.backToLogin', { ns: 'common' })}
                        </Button>
                    </VStack>
                )}

                {isValid === true && (
                    <VStack
                        align="left"
                        spacing="6"
                        w="fit-content"
                    >
                        <Heading
                            as="h4"
                            textAlign="left"
                            size="lg"
                            width="28rem"
                        >
                            {t('Welcome to WVW', { ns: 'common' })}
                        </Heading>

                        {inviteType === 'invitedToTeam' && (
                            <Heading
                                as="h5"
                                size="sm"
                                color="wvwGrey80"
                            >
                                {t('teamInvite')}
                            </Heading>
                        )}

                        {inviteType === 'invitedToClientPanel' && (
                            <Heading
                                as="h5"
                                size="sm"
                                color="wvwGrey80"
                            >
                                {t('clientInvite')}
                            </Heading>
                        )}

                        {inviteType === 'invitedToValuerPanel' && (
                            <Heading
                                as="h5"
                                size="sm"
                                color="wvwGrey80"
                            >
                                {t('valuerInvite')}
                            </Heading>
                        )}

                        <Text
                            textAlign="justify"
                            overflowWrap="break-word"
                            width="24rem"
                        >
                            {t('allInOneSolution')}
                        </Text>

                        <WvwButton
                            onClick={() => goNext()}
                            variant="primary"
                            block
                            content={inviteType === 'invitedToTeam' ? t('button.joinTeam', { ns: 'common' }) : t('button.joinPanel', { ns: 'common' })}
                        />
                    </VStack>
                )}
            </VStack>

            <Footer invertColor />
        </RegisterFormsContainer>
    );
};

export default InviteLandingPage;
