import { Flex } from '@chakra-ui/react';
import * as Yup from 'yup';
import {
    FormikFileUploadLocal,
    FormikForm,
    FormikInput,
    FormikSubmitButton,
} from '../../../../../../../common/forms';
import useDocumentRequestReplaceDocument from '../../../../../../../utils/api/hooks/documents/useDocumentRequestReplaceDocument';
import WvwVStack from '../../../../../../../common/components/display/WvwVStack';
import { useSmartTranslation } from '../../../../../../../common/hooks';
import { FormikFileUploadDataType } from '../../../../../../../common/forms/FormikFileUploadLocal';

type PropTypes = {
    caseId: string | number;
    replacedDocumentId: string | number;
    onClose: () => void;
};

const validationSchema = Yup.object().shape({
    files: Yup.array().min(1, 'errors.required'),
});

const DocumentUploadWithNote = (props: PropTypes) => {
    const {
        caseId,
        replacedDocumentId,
        onClose,
    } = props;

    const t = useSmartTranslation('documentmanagement');

    const { update: replaceDocument, busy } = useDocumentRequestReplaceDocument({
        caseId: Number(caseId),
        onSuccess: onClose,
    });

    return (
        <FormikForm
            initialValues={{ submissionComment: '', files: [] }}
            validationSchema={validationSchema}
            submitting={busy}
            onSubmit={values => replaceDocument({
                documentId: Number(replacedDocumentId),
                submissionComment: values.submissionComment,
                files: values.files.map((f: FormikFileUploadDataType) => f.file),
            })}
        >
            <WvwVStack>
                <FormikFileUploadLocal name="files" />

                <FormikInput
                    name="submissionComment"
                    placeholder={t('submissionNote')}
                    noSubmitOnEnter
                />

                <Flex justifyContent="flex-end">
                    <FormikSubmitButton
                        block
                        content={t('replace')}
                        mustBeClicked
                    />
                </Flex>
            </WvwVStack>
        </FormikForm>
    );
};

export default DocumentUploadWithNote;
