import { Select, HStack } from "@chakra-ui/react";

export type OptionType = {
    label: string;
    value: string;
};

export type FilterType = {
    placeholder: string;
    value: string | undefined;
    options: OptionType[];
    onChange: (input: string) => void;
};

type PropsType = {
    selectList: FilterType[];
};

const DropdownFilter = (props: FilterType) => {
    const { placeholder, options, value, onChange } = props;

    return (
        <Select
            placeholder={placeholder}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            maxW="fit-content"
        >
            {options.map((option) => (
                <option
                    key={option.label}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </Select>
    );
};

const SelectArray = (props: PropsType) => {
    const { selectList } = props;

    return (
        <HStack>
            {selectList.map((filter) => (
                <DropdownFilter
                    placeholder={filter.placeholder}
                    value={filter.value}
                    onChange={filter.onChange}
                    options={filter.options}
                />
            ))}
        </HStack>
    );
};

export default SelectArray;
