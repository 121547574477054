import {
    Select,
    Text,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { LabelAndInput } from '../components/display';
import { FormikValidationContext } from './FormikForm';

type PropTypes = {
    name: string;
    label?: string;
    width?: string;
    options: {
        disabled?: boolean;
        label: string;
        value: string | number;
    }[];
    placeholder?: string;
};

const FormikSelect = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        label,
        placeholder,
        options,
        width,
    } = props;

    const [field, meta] = useField(props);

    const isFieldRequired = useContext(FormikValidationContext);

    const isRequired = isFieldRequired(name);

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? ' *' : ''}`}
            input={(
                <div
                    style={{
                        width,
                    }}
                >
                    <Select
                        bg="white"
                        h="3rem"
                        borderRadius="8"
                        fontSize="md"
                        isInvalid={!!meta.touched && !!meta.error}
                        placeholder={placeholder}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                    >
                        {options.map(option => (
                            <option
                                key={`${option.label} ${option.value}`}
                                disabled={option.disabled}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        ))}
                    </Select>

                    {meta.touched
                        && meta.error
                        && (
                            <Text
                                color="red"
                            >
                                {t(meta.error, { defaultValue: meta.error })}
                            </Text>
                        )}
                </div>
            )}
        />
    );
};

FormikSelect.defaultProps = {
    placeholder: '',
    label: '',
    width: '100%',
};

export default FormikSelect;
