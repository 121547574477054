/* eslint-disable no-lonely-if */
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    HStack,
    ModalCloseButton,
    ModalHeader,
    ModalFooter,
    Box,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { wvwPalette } from "../../../../theme";
import Logo from "../../../../common/components/Logo";
import { WVWTitle } from "../../../../common/components/typography";
import { useRole, useSmartTranslation } from "../../../../common/hooks";
import ProcessFlowRouted from "../../../../common/components/display/ProcessFlowRouted";
import ProfileCompletionLanding from "./components/ProfileCompletionLanding";
import ProgressTabs from "../../../../common/components/ProgressTabs";

import Footer from "../../../../common/components/Footer";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useMe } from "../../../../utils/api/hooks";

const ProfileCompletionWizard = () => {
    const t = useSmartTranslation("profile");

    const navigate = useNavigate();

    const { userIsClient, userIsCompanyAdmin, roleLoading } = useRole();

    const { data: user, isLoading: userLoading } = useMe();

    const currentPath = useLocation().pathname;

    if (!user || roleLoading || userLoading) return <LoadingSpinner />;

    const valuerDoesValuations = user.doesValuations;

    const profileStep = "personal";

    const companyStep = "company";

    const qualificationsStep = "qualifications";

    const valuationStep = "valuationOffer";

    const extrasStep = "extras";

    const valuerAdminProcess = [
        companyStep,
        profileStep,
        qualificationsStep,
        valuationStep,
        extrasStep,
    ];

    const valuerAdminNoValuationsProcess = [
        companyStep,
        profileStep,
        extrasStep,
    ];

    const valuerUserProcess = [profileStep, qualificationsStep, valuationStep];

    const clientAdminProcess = [companyStep, profileStep];

    const clientUserProcess = [profileStep];

    let profileCompletionSteps;

    if (userIsClient) {
        if (userIsCompanyAdmin) {
            profileCompletionSteps = clientAdminProcess;
        } else {
            profileCompletionSteps = clientUserProcess;
        }
    } else {
        if (userIsCompanyAdmin) {
            if (!valuerDoesValuations) {
                profileCompletionSteps = valuerAdminNoValuationsProcess;
            } else {
                profileCompletionSteps = valuerAdminProcess;
            }
        } else {
            if (!valuerDoesValuations) {
                profileCompletionSteps = clientUserProcess;
            } else {
                profileCompletionSteps = valuerUserProcess;
            }
        }
    }

    const paths = profileCompletionSteps;

    const currentStep = currentPath.split("/")[2];

    return (
        <Modal
            isOpen
            onClose={() => navigate("../")}
            size="full"
            autoFocus={false}
        >
            <ModalOverlay />

            <ModalContent bgColor={wvwPalette.wvwBackground.main}>
                <ModalHeader bgColor="wvwGreen">
                    <HStack spacing="0">
                        <Logo size="5rem" />

                        <WVWTitle
                            color="white"
                            level="1"
                            content={t("completeProfile")}
                        />
                    </HStack>
                </ModalHeader>
                {currentPath !== "/complete-profile" && (
                    <ProgressTabs
                        currentStep={currentStep}
                        steps={paths}
                    />
                )}

                <ModalCloseButton color="white" />

                <ModalBody>
                    <Box
                        width="100%"
                        minWidth="400px"
                        maxWidth="1600px"
                        marginLeft="auto"
                        marginRight="auto"
                    >
                        {currentStep === "" ? (
                            <ProfileCompletionLanding />
                        ) : (
                            <ProcessFlowRouted steps={profileCompletionSteps} />
                        )}
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Footer />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ProfileCompletionWizard;
