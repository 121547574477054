import useCaseGetCaseReports from './useCaseReportGetReports';
import useMutationHook from '../useMutationHook';
import { caseMarkReportCommentsRead } from '../../endpoints';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useCaseCommentOnReport = (params: ParamsType) => {
    const { caseId, onSuccess, onError } = params;

    const { refresh: refreshReports } = useCaseGetCaseReports(caseId);

    const mutation = useMutationHook({
        mutationFunction: (reportId: number | string) => caseMarkReportCommentsRead(reportId),
        refreshes: [refreshReports],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useCaseCommentOnReport;
