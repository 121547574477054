import {
    Flex,
    List,
    ListItem,
    Text,
} from '@chakra-ui/react';
import { useSmartTranslation } from '../../../../common/hooks';
import { UserType } from '../../../../types';
import FormSectionProfileEdit from './FormSectionProfileEdit';
import toTitleCase from '../../../../common/functions/toTitleCase';

type PropsType = {
    user: UserType;
};

const ProfilePropertyTypes = (props: PropsType) => {
    const { user } = props;

    const {
        valuationPropertyTypes = [],
    } = user;

    const t = useSmartTranslation('profile');

    return (
        <FormSectionProfileEdit header={t('heading.propertyTypes')}>
            <Flex
                alignItems="flex-start"
                minH="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('valuationCompetence.propertyTypes')}
                </Text>

                <List>
                    {valuationPropertyTypes.length === 0 && (
                        <ListItem
                            color="wvwGrey40"
                        >
                            {t('none', { context: 'female', ns: 'common' })}
                        </ListItem>
                    )}

                    {valuationPropertyTypes.sort().map(valuation => (
                        <ListItem key={valuation}>
                            {t(toTitleCase(valuation), { ns: 'formik', defaultValue: toTitleCase(valuation) })}
                        </ListItem>
                    ))}
                </List>
            </Flex>
        </FormSectionProfileEdit>
    );
};

export default ProfilePropertyTypes;
