import {
    Button,
    VStack,
    Heading,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    useDisclosure,
    useToast,
    Center,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFbSendPasswordReset } from "../../../../../utils/api/hooks";
import WVWTitle from "../../../common/components/typography/WVWTitle";
import { WvwText } from "../../../common/components/typography";
import WvwButton from "../../../common/components/inputs/WvwButton";
import FormikForm from "../../../common/forms/FormikForm";
import FormikInput from "../../../common/forms/FormikInput";
import Logo from "../../../common/components/Logo";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import RegisterFormsContainer from "../register/RegisterFormsContainer";

const validationSchema = Yup.object({
    email: Yup.string()
        .email("errors.invalidEmail")
        .required("errors.required"),
});

const ForgotPassword = () => {
    const t = useSmartTranslation();

    const navigate = useNavigate();
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { update } = useFbSendPasswordReset({
        onSuccess: () => {
            onOpen();
        },
        onError: (error) => {
            toast({
                title: t("common.error.error"),
                description:
                    error?.message === "Firebase: Error (auth/user-not-found)."
                        ? t("login.error.userNotFound")
                        : error?.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return (
        <RegisterFormsContainer goBack={() => navigate("/")}>
            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    email: "",
                }}
                onSubmit={(values) => {
                    update(values.email);
                }}
            >
                <Center width="100%">
                    <VStack
                        spacing="6"
                        align="left"
                        pb="9em"
                        pt="6em"
                        width="fit-content"
                    >
                        <Logo withColor />

                        <Heading
                            as="h1"
                            size="xl"
                        >
                            {t("passwordReset.forgotPassword")}
                        </Heading>

                        <Text
                            align="left"
                            w="25rem"
                        >
                            {t("passwordReset.enterEmail")}
                        </Text>

                        <VStack
                            spacing="4"
                            align="center"
                            w="100%"
                        >
                            <FormikInput
                                name="email"
                                label={t("common.email")}
                                type="email"
                                placeholder={t("common.email")}
                            />

                            <Button
                                type="submit"
                                variant="primaryYellow"
                                w="100%"
                            >
                                {t("common.button.sendLink")}
                            </Button>
                        </VStack>
                    </VStack>
                </Center>
            </FormikForm>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack
                            paddingBlock="6rem"
                            spacing="4"
                        >
                            <CheckCircleIcon
                                boxSize="3rem"
                                color="#38A169"
                            />

                            <WVWTitle
                                level="1"
                                color="black"
                                content={t("passwordReset.thankYou")}
                            />

                            <WvwText
                                color="wvwGrey60"
                                bold
                                textAlign="center"
                            >
                                {t("passwordReset.linkMessage")}
                            </WvwText>
                        </VStack>
                    </ModalBody>

                    <ModalFooter justifyContent="center">
                        <WvwButton
                            variant="primary"
                            onClick={() => {
                                onClose();
                            }}
                            block
                            content={t("common.button.done")}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </RegisterFormsContainer>
    );
};

export default ForgotPassword;
