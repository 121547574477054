import { FormikConditionalSection } from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";
import ProfileOperationalAreaEdit from "./ProfileOperationalAreaEdit";
import FormSection from "./FormSection";
import ProfileDetailsDoesValuatons from "./ProfileDetailsDoesValuations";

type PropsType = {
    updateDoesValuations?: (value: "true" | "false") => void;
};

const ProfileOtherDetailsEdit = (props: PropsType) => {
    const { updateDoesValuations } = props;

    const t = useSmartTranslation("profile");

    return (
        <FormSection
            header={t("profileDetails.otherInfo")}
            variant="white"
            element={
                <>
                    <ProfileDetailsDoesValuatons
                        updateDoesValuations={updateDoesValuations}
                    />

                    <FormikConditionalSection
                        formDataTarget="doesValuations"
                        condition={(value) => value === "true"}
                    >
                        <ProfileOperationalAreaEdit />
                    </FormikConditionalSection>
                </>
            }
        />
    );
};

export default ProfileOtherDetailsEdit;
