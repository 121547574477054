import { Flex, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { Edit } from "iconsax-react";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useRole, useSmartTranslation } from "../../../../common/hooks";
import { useMe, useMyEntity } from "../../../../utils/api/hooks";
import ProfileContactDetails from "./ProfileContactDetails";
import ProfileHeading from "./ProfileHeading";
import ProfileOfficeDetails from "./ProfileOfficeDetails";
import ProfileDetailsEdit from "./ProfileDetailsEdit";
import { WvwButton } from "../../../../common/components/inputs";
import ProfileOperationalArea from "./ProfileOperationalArea";
import ProfileValuationStandards from "./ProfileValuationStandards";
import ProfileValuationTypes from "./ProfileValuationTypes";
import ProfileValuationOccasions from "./ProfileValuationOccasions";
import ProfilePropertyTypes from "./ProfilePropertyTypes";

const ProfileDetails = () => {
    const t = useSmartTranslation("common");
    const { userIsValuer, roleLoading } = useRole();

    const [editing, setEditing] = useState(false);

    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
    } = useMe();

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    if (
        !user ||
        userLoading ||
        roleLoading ||
        entityLoading ||
        myCertificatesIsLoading
    ) {
        return <LoadingSpinner />;
    }

    const doesValuations = userIsValuer && user.doesValuations;

    if (editing) {
        return <ProfileDetailsEdit openEditor={() => setEditing(false)} />;
    }

    return (
        <VStack
            w="100%"
            spacing="1.5rem"
            paddingBottom="2rem"
        >
            <Flex
                justifyContent="flex-end"
                w="100%"
            >
                <WvwButton
                    variant="link"
                    icon={<Edit />}
                    onClick={() => setEditing(true)}
                    content={t("button.edit")}
                />
            </Flex>

            <ProfileHeading
                user={user}
                entity={entity}
            />

            <ProfileContactDetails user={user} />

            <ProfileOfficeDetails
                user={user}
                userIsValuer={userIsValuer}
            />

            {doesValuations && (
                <>
                    <ProfileOperationalArea user={user} />

                    <ProfileValuationStandards
                        user={user}
                        certificates={myCertificates}
                    />

                    <ProfileValuationTypes user={user} />

                    <ProfileValuationOccasions user={user} />

                    <ProfilePropertyTypes user={user} />
                </>
            )}

            {userIsValuer && (
                <Flex
                    justifyContent="flex-end"
                    w="100%"
                >
                    <WvwButton
                        variant="link"
                        icon={<Edit />}
                        onClick={() => setEditing(true)}
                        content={t("button.edit")}
                    />
                </Flex>
            )}
        </VStack>
    );
};

export default ProfileDetails;
