import { Box, VStack } from '@chakra-ui/react';
import {
    FormikConditionalSection,
    FormikFileUpload,
    FormikCheckboxGroup,
    FormikListInput,
    FormikRadioGroup,
} from '../../../../common/forms';
import { standardValuationTypes, standardSpecialValuationTypes } from '../../../../common/vars/valuationsAndMembershipTypes';
import { WVWLabel, WvwText } from '../../../../common/components/typography';
import { useSmartTranslation } from '../../../../common/hooks';
import { wvwPalette } from '../../../../theme';

const ValuationType = () => {
    const t = useSmartTranslation('profile');

    return (
        <>
            <FormikRadioGroup
                name="valuationType"
                options={standardValuationTypes.map(type => ({
                    label: t(type, { ns: 'formik', defaultValue: type }),
                    value: type,
                }))}
            />

            <VStack
                align="start"
                backgroundColor={wvwPalette.wvwBackground.main}
                borderRadius="8"
                padding="1rem"
                spacing="4"
                w="100%"
            >
                <Box>
                    <WVWLabel
                        noColor
                        content={t('heading.specificValuationScopes')}
                    />

                    <WvwText>
                        {t('subHeading.specificValuationScopes')}
                    </WvwText>
                </Box>

                <FormikCheckboxGroup
                    name="valuationSpecial"
                    options={
                        standardSpecialValuationTypes.map(type => ({
                            label: t(type, { ns: 'formik', defaultValue: type }),
                            value: type,
                        }))
                    }
                />
            </VStack>

            <FormikConditionalSection
                formDataTarget="valuationType"
                condition={x => x.includes('Other Valuation')}
            >
                <VStack
                    align="start"
                    backgroundColor={wvwPalette.wvwBackground.main}
                    borderRadius="8"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <Box>
                        <WVWLabel
                            noColor
                            content={t('heading.otherValuationScopes')}
                        />

                        <WvwText>
                            {t('subHeading.otherValuationScopes')}
                        </WvwText>
                    </Box>

                    <FormikListInput
                        name="otherValuationTypes"
                        placeholder={t('membershipsAndQualifications.edit.addValuationScope')}
                    />
                </VStack>
            </FormikConditionalSection>
        </>

    );
};

export default ValuationType;
