import {
    EnquiryType,
    EnquiryWithFromUserAndFromEntityType,
    OfferOnlyType,
    OfferType,
} from "../../../../../../../types";
import { enquiryColumns } from "../../../../../common/components/data-table/columns/EnquiryColumns";
import { DataTableColumnType } from "../../../../../common/components/data-table/DataTable";

type TableDataType = EnquiryWithFromUserAndFromEntityType & {
    offers: OfferOnlyType[];
};

// create table data
type RecentMatchingsProps = {
    enquiries: EnquiryWithFromUserAndFromEntityType[];
    offers: OfferOnlyType[];
};

export const createRecentMatchingsData = (props: RecentMatchingsProps) => {
    const { enquiries, offers } = props;
    const recentMatchingsData = enquiries.map((enquiry): TableDataType => {
        const enquiryOffers = offers.filter(
            (offer) => offer.enquiryId === enquiry.id
        );

        return {
            ...enquiry,
            offers: enquiryOffers,
        };
    });

    return recentMatchingsData;
};

// columns
export const columns: DataTableColumnType<TableDataType>[] = [
    enquiryColumns.enquiryIdWithProperties,
    enquiryColumns.valuationRequirements,
    enquiryColumns.piCoverRequirements,
    enquiryColumns.offers,
    enquiryColumns.expectedDeliveryDate,
    enquiryColumns.estPortfolioValueEnquiry,
    enquiryColumns.myOffer,
];
