import { CaseReportWithCaseAndClientAndValuerType } from '../../../../types';
import { adminCaseReports } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const ADMIN_CASE_REPORTS = 'ADMIN_CASE_REPORTS';

const useAdminCaseReports = () => {
    const query = useQueryHook<CaseReportWithCaseAndClientAndValuerType[]>({
        uniqueName: ADMIN_CASE_REPORTS,
        dependancies: [],
        requiredParams: [],
        queryFunction: () => adminCaseReports(),
    });

    return query;
};

export default useAdminCaseReports;
