import { documentRequestGetComments } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const DOCUMENT_REQUEST_GET_COMMENTS = 'DOCUMENT_REQUEST_GET_COMMENTS';

const useDocumentRequestGetComments = (requestId: string | number) => {
    const query = useQueryHook({
        uniqueName: DOCUMENT_REQUEST_GET_COMMENTS,
        dependancies: [requestId],
        requiredParams: [requestId],
        queryFunction: () => documentRequestGetComments(requestId),
    });

    return query;
};

export default useDocumentRequestGetComments;
