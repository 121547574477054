/* eslint-disable max-len */
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Flex, Box, VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { WvwButton } from '../../../../../common/components/inputs';
import { WvwText } from '../../../../../common/components/typography';
import { FormikForm, FormikInput, FormikSubmitButton } from '../../../../../common/forms';
import { useNavigateUpUrl, useToast } from '../../../../../common/hooks';
import { useCaseReviewSignedContract } from '../../../../../utils/api/hooks';

type PropTypes = {
    caseId: number;
    contractId: number;
    cancelButton?: string;
    continueButton?: string;
    isOpen: boolean;
    respondingToContract: 'ACCEPTED' | 'REJECTED';
    onCancel?: () => void;
    onContinue?: () => void;
};

const CaseContractRespondModal = (props: PropTypes) => {
    const {
        caseId,
        contractId,
        cancelButton,
        continueButton,
        respondingToContract,
        isOpen,
        onCancel = () => { },
        onContinue = () => { },
    } = props;

    const { createSuccessToast } = useToast();

    const { t } = useTranslation(['casecontract']);

    const successMessage = respondingToContract === 'ACCEPTED' ? t('toast.contractAccepted', { ns: 'casecontract' }) : t('toast.contractRejected', { ns: 'casecontract' });

    const navigateUpUrl = useNavigateUpUrl();

    const { update: respondToContract } = useCaseReviewSignedContract({
        caseId,
        onSuccess: () => {
            createSuccessToast(successMessage);
            onContinue();
            navigateUpUrl(2);
        },
    });

    let title = t('title.acceptContract');
    let confirmationContent = t('details.confirmAccept');

    if (respondingToContract === 'REJECTED') {
        title = t('title.rejectContract');
        confirmationContent = t('details.confirmReject');
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onCancel}
            size="lg"
        >
            <FormikForm
                initialValues={{}}
                onSubmit={values => {
                    const response = respondingToContract === 'ACCEPTED' ? 'ACCEPTED' : 'REJECTED';

                    respondToContract({ contractId, signedContractReview: response, reviewComment: values.reviewComment });
                }}
            >
                <ModalOverlay />

                <ModalContent alignItems="center">
                    <Box
                        bgColor="white"
                        borderRadius="md"
                        boxShadow="md"
                        p="1rem"
                        w="100%"
                    >
                        <ModalHeader color="wvwGreen" textAlign="center">
                            {title}
                        </ModalHeader>

                        <ModalCloseButton />

                        <ModalBody
                            paddingBottom="1rem"
                            textAlign="center"
                        >
                            <VStack spacing="1rem">
                                <WvwText textAlign="center">
                                    {confirmationContent}
                                </WvwText>

                                {respondingToContract === 'REJECTED' && (
                                    <FormikInput
                                        name="reviewComment"
                                        placeholder={t('pleaseProvideReason')}
                                        type="text"
                                    />
                                )}
                            </VStack>
                        </ModalBody>

                        <ModalFooter w="100%">
                            <Flex
                                gap="2"
                                justifyContent="space-between"
                                w="100%"
                            >
                                <WvwButton
                                    block
                                    content={cancelButton || t('button.close', { ns: 'common' })}
                                    onClick={() => {
                                        onCancel();
                                        navigateUpUrl(3);
                                    }}
                                    variant="outline"
                                />

                                <FormikSubmitButton
                                    block
                                    content={continueButton || t('button.continue', { ns: 'common' })}
                                />

                            </Flex>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </FormikForm>
        </Modal>
    );
};

CaseContractRespondModal.defaultProps = {
    cancelButton: undefined,
    continueButton: undefined,
    onCancel: () => { },
    onContinue: () => { },
};

export default CaseContractRespondModal;
