import { Flex, Text, VStack } from "@chakra-ui/react";
import { EnquiryType, UserType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";

type ValuerQualifications = {
    enquiry: EnquiryType;
};

export const ValuerQualifications = (props: ValuerQualifications) => {
    const {
        enquiry: {
            valuerExperience,
            valuerMaxOfficeDistance,
            valuerMinimumPICover,
            valuerMinimumPICoverPercentage,
            valuerMinimumPartners,
            valuationStandards,
        },
    } = props;

    return (
        <VStack>
            <ValuerQualification valuationStandards={valuationStandards} />
            <ValuerExperience required={valuerExperience} />
            <ValuerMinPartners minPartners={valuerMinimumPartners} />
            <ValuerMaxDistance maxDistance={valuerMaxOfficeDistance} />
            <ValuerPICover
                minPICover={valuerMinimumPICover}
                minPICoverPercentage={valuerMinimumPICoverPercentage}
            />
        </VStack>
    );
};

// qualification

type ValuerQualificationProps = {
    valuationStandards: string;
};

const ValuerQualification = (props: ValuerQualificationProps) => {
    const { valuationStandards } = props;

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.qualification"}
            value={valuationStandards}
        />
    );
};

// min partners

type ValuerMinPartnersProps = {
    minPartners: string;
};

const ValuerMinPartners = (props: ValuerMinPartnersProps) => {
    const { minPartners } = props;

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.minPartners"}
            value={minPartners}
        />
    );
};

// pi cover

type ValuerPICoverProps = {
    minPICover?: number;
    minPICoverPercentage?: number;
};

const ValuerPICover = (props: ValuerPICoverProps) => {
    const { minPICover, minPICoverPercentage } = props;

    // render both
    const piCover = `€${minPICover}/ ${minPICoverPercentage}\%`;

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.minPiCover"}
            value={piCover}
        />
    );
};

// experience

type ValuerExperienceProps = {
    required: string;
};

const ValuerExperience = (props: ValuerExperienceProps) => {
    const { required } = props;

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.minExperience"}
            value={required}
            unit={"common.units.years"}
        />
    );
};

// max distance

type ValuerMaxDistanceProps = {
    maxDistance?: string;
    valuer?: UserType;
};

const ValuerMaxDistance = (props: ValuerMaxDistanceProps) => {
    const { maxDistance } = props;

    return (
        <ValuerRequirement
            label={"domain.enquiry.valuerRequirements.maxDistance"}
            value={maxDistance}
            unit={"common.units.km"}
        />
    );
};

// base requirements

const matchingResults = {
    YES: <div>YES</div>,
    PARTIAL: <div>PARTIAL</div>,
    NO: <div>NO</div>,
};

type MatchingResultType = keyof typeof matchingResults | undefined;

type ValuerRequirementProps = {
    label: string;
    value?: string | string[] | number;
    unit?: string;
    match?: MatchingResultType;
};

const ValuerRequirement = (props: ValuerRequirementProps) => {
    const { label, value, unit, match } = props;

    const t = useSmartTranslation();

    const translatedLabel = t(label, { defaultValue: MISSING_TRANSLATION });

    let valueWithUnit;

    if (value) {
        valueWithUnit = value;

        if (unit) {
            const translatedUnit = t(unit, {
                defaultValue: MISSING_TRANSLATION,
            });

            valueWithUnit += " " + translatedUnit;
        }
    }

    const labelText = <Text>{translatedLabel}</Text>;

    const valueText = valueWithUnit ? (
        <Text>{valueWithUnit}</Text>
    ) : (
        <Text>{t("domain.enquiry.valuerRequirements.noRequirement")}</Text>
    );

    const matchIcon = match && matchingResults[match];

    return (
        <Flex
            gap={6}
            w="100%"
            minW="max"
        >
            {labelText}
            {matchIcon}
            {valueText}
        </Flex>
    );
};
