import { User } from "../../domain/user/User";
import { routes_unauthorized } from "./unauthorized";
import { routes_unverified } from "./unverified";
import { routes_user } from "./user";

export const getRoutes = (user?: User | null) => {
    if (!user) return routes.unauthorized;
    if (!user.emailVerified) return routes.unverified;
    return routes.user(user);
};

const routes = {
    unauthorized: routes_unauthorized,
    unverified: routes_unverified,
    user: routes_user,
};
