import { EditIcon } from '@chakra-ui/icons';
import {
    Button,
    ButtonGroup,
    Flex,
    HStack,
    IconButton,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    FormikForm,
    FormikInput,
    FormikSelect,
    FormikTextarea,
} from '../../../../../../../common/forms';
import { DocumentRequestFolderType, DocumentRequestType } from '../../../../../../../types';
import { useDocumentRequestUpdateRequest } from '../../../../../../../utils/api/hooks';
import RequestDetailsHeading from './RequestDetailsHeading';
import StatusBadge from '../../../../../../../common/components/StatusBadge';
import { useRole } from '../../../../../../../common/hooks';

type PropTypes = {
    folders: DocumentRequestFolderType[];
    request: DocumentRequestType;
    requestList: DocumentRequestType[];
};

const getPreviousRequestId = (
    requestList: DocumentRequestType[],
    currentRequestId: number,
): number => {
    const currentIndex = requestList.findIndex(request => request.id === currentRequestId);

    if (currentIndex === 0) return currentRequestId;

    return requestList[currentIndex - 1].id;
};

const getNextRequestId = (
    requestList: DocumentRequestType[],
    currentRequestId: number,
): number => {
    const currentIndex = requestList.findIndex(request => request.id === currentRequestId);

    if (currentIndex === requestList.length - 1) return currentRequestId;

    return requestList[currentIndex + 1].id;
};

const RequestInformation = (props: PropTypes) => {
    const {
        folders,
        request,
        requestList,
    } = props;

    const {
        caseId,
        description,
        name,
        status,
        type,
    } = request;

    const { t } = useTranslation('documentmanagement');
    const navigate = useNavigate();
    const { userIsValuer } = useRole();

    const [editing, setEditing] = useState(false);

    const { update: updateRequest } = useDocumentRequestUpdateRequest({
        caseId,
        onSuccess: () => setEditing(false),
    });

    if (editing) {
        return (
            <Flex
                align="center"
                bg="wvwGrey05"
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="1rem"
                paddingTop="1rem"
                w="100%"
            >
                <FormikForm
                    initialValues={{
                        name,
                        type: type?.name,
                        description,
                        folderId: request.folder?.id,
                    }}
                    onSubmit={values => {
                        updateRequest({
                            requestId: request.id,
                            folderId: Number(values.folderId),
                            name: values.name,
                            type: values.type,
                            description: values.description,
                        });
                    }}
                >
                    <VStack
                        align="left"
                        marginTop="-0.5rem"
                        paddingBottom="0.2rem"
                        w="100%"
                    >
                        <HStack w="100%">
                            <FormikInput
                                name="name"
                                width="100%"
                                placeholder={t('requestTitle', { ns: 'documentmanagement' })}
                            />

                            <FormikSelect
                                name="folderId"
                                width="100%"
                                options={folders.map(i => ({
                                    label: i.name,
                                    value: i.id,
                                }))}
                            />
                        </HStack>

                        <VStack>
                            <FormikTextarea
                                name="description"
                                placeholder={t('instructionNotes', { ns: 'documentmanagement' })}
                            />
                        </VStack>

                        <ButtonGroup
                            alignSelf="flex-end"
                        >
                            <Button
                                variant="primary"
                                onClick={() => setEditing(false)}
                            >
                                {t('button.cancel', { ns: 'common' })}
                            </Button>

                            <Button
                                alignSelf="flex-end"
                                type="submit"
                                variant="primaryYellow"
                            >
                                {t('button.save', { ns: 'common' })}
                            </Button>
                        </ButtonGroup>
                    </VStack>
                </FormikForm>
            </Flex>
        );
    }

    return (
        <>
            <RequestDetailsHeading
                onNext={() => navigate(`/dashboard/case/${caseId}/documents/request/${getNextRequestId(requestList, request.id)}`)}
                onPrevious={() => navigate(`/dashboard/case/${caseId}/documents/request/${getPreviousRequestId(requestList, request.id)}`)}
                title={request.name}
            />

            <Flex
                align="center"
                bg="wvwGrey05"
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="1rem"
                paddingTop="1rem"
                w="100%"
            >
                <Text
                    justifyContent="left"
                    overflowY="auto"
                >
                    {description}
                </Text>

                <Spacer />

                <StatusBadge
                    margin="0rem"
                    status={status}
                />

                {userIsValuer && (
                    <IconButton
                        aria-label="edit"
                        color="wvwGreen"
                        icon={<EditIcon />}
                        onClick={() => setEditing(true)}
                        variant="none"
                    />
                )}
            </Flex>
        </>
    );
};

export default RequestInformation;
