import { Accordion } from '@chakra-ui/react';
import { DocumentRequestTemplateType } from '../../../../../types';
import TemplateListItem from './TemplateListItem';

type WithoutCaseId = {
    caseId: undefined;
    enableDragAndDrop: false;
};

type WithCaseId = {
    caseId: number;
    enableDragAndDrop?: boolean;
};

type PropTypes = {
    templateList: DocumentRequestTemplateType[];
    enableEditing: boolean;
} & (WithCaseId | WithoutCaseId);

const TemplateList = (props: PropTypes) => {
    const {
        caseId,
        templateList,
        enableEditing,
        enableDragAndDrop,
    } = props;

    return (
        <Accordion
            allowMultiple
            variant="primary"
            w="100%"
        >
            {templateList.map(template => (
                <TemplateListItem
                    caseId={Number(caseId)}
                    key={template.id}
                    template={template}
                    enableEditing={enableEditing}
                    enableDragAndDrop={enableDragAndDrop}
                />
            ))}
        </Accordion>
    );
};

export default TemplateList;
