/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
    CheckboxGroup,
    Text,
    Checkbox,
    SimpleGrid,
    VStack,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { WvwText } from '../components/typography';

type PropTypes = {
    name: string,
    variant?: string;
    onChange?: (value: string[], field?: FieldInputProps<any>) => void,
    options: {
        label: ReactNode | string;
        value: string,
    }[],
    selectAll?: boolean;
};

const FormikCheckboxGroup = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        options,
        name,
        variant,
        selectAll,
        onChange,
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const { value: fieldValue = [] } = field;

    const allSelected = fieldValue.length === options.length;

    return (
        <VStack w="100%" align="right">
            {selectAll && (
                <Checkbox
                    name="useMyDetails"
                    alignSelf="flex-end"
                    paddingRight="1em"
                    isChecked={allSelected}
                    onChange={() => {
                        const newValue = fieldValue.length >= 0;

                        if (newValue || !allSelected) {
                            field?.onChange({ target: { name, value: options.map(option => option.value) } });
                        }

                        if (!newValue || allSelected) {
                            field?.onChange({ target: { name, value: [] } });
                        }
                    }}
                >
                    <WvwText>
                        {t('button.selectAll', { ns: 'common' })}
                    </WvwText>
                </Checkbox>
            )}

            <CheckboxGroup
                {...field}
                {...props}
                onChange={(value: string[]) => {
                    field.onChange({ target: { name, value } });

                    onChange?.(value, field);
                }}
            >
                <SimpleGrid
                    width="100%"
                    minChildWidth="16.5rem"
                    spacing="0.2rem"
                >
                    {options.map(({ value, label }) => (
                        <Checkbox
                            key={value}
                            variant={variant}
                            value={value}
                            paddingBlock=".3rem"
                            alignItems="top"
                            width="100%"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                        >
                            <Text>
                                {label}
                            </Text>
                        </Checkbox>
                    ))}
                </SimpleGrid>

                {
                    meta.touched
                    && meta.error
                    && (
                        <Text
                            color="red"
                        >
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )
                }
            </CheckboxGroup>
        </VStack>
    );
};

FormikCheckboxGroup.defaultProps = {
    variant: 'none',
    onChange: () => { },
    selectAll: false,
};

export default FormikCheckboxGroup;
