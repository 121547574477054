import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import SuccessScreen from "../../success/SuccessScreen";

const RegisterSuccess = () => {
    const t = useSmartTranslation();

    return (
        <SuccessScreen
            bodyText={t("registration.registerSuccess")}
            buttonText={t("common.goToDashboard")}
            navLink="../dashboard"
        />
    );
};

export default RegisterSuccess;
