import { useSmartTranslation } from '../../../common/hooks';
import { EnquiryAssignValuersDto } from '../dto';
import { enquiryAssignValuers } from '../endpoints';
import useEnquiryGetEnquiriesWithAssignments from './useEnquiryGetEnquiriesWithAssignments';
import useMutationHook from './useMutationHook';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useEnquiryAssignValuers = (params: ParamsType) => {
    const { onSuccess, onError } = params;

    const t = useSmartTranslation('enquiries');

    const { refresh } = useEnquiryGetEnquiriesWithAssignments();

    const mutation = useMutationHook({
        mutationFunction: (dto: EnquiryAssignValuersDto) => enquiryAssignValuers(dto),
        refreshes: [refresh],
        successMessage: t('toast.enquiryAssignment.success'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useEnquiryAssignValuers;
