/* eslint-disable max-len */
import { HStack, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useSmartTranslation } from "../../../../../common/hooks";
import FormSection from "../../../profile/components/FormSection";
import { FormikCountrySelect, FormikInput } from "../../../../../common/forms";
import { useMe } from "../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import FormikFieldAutoFillCheckBox from "../../../../../common/forms/FormikFieldAutoFIllCheckbox";
import FormikPhoneNumberInput from "../../../../../common/forms/FormikPhoneNumberInput";

/**
 * Formik form section for editing the current user's company contact details.
 */

const CompanyDetailsContact = () => {
    const t = useSmartTranslation("account");
    const { data: user, isLoading: userLoading } = useMe();

    const renderInputRow = (
        inputOne: ReactNode,
        inputTwo: ReactNode | null = null
    ) => (
        <HStack
            align="start"
            spacing="6"
            w="100%"
        >
            {inputOne}
            {inputTwo}
        </HStack>
    );

    if (!user || userLoading) return <LoadingSpinner />;

    return (
        <FormSection
            header={t("heading.contactInfo")}
            variant="white"
            element={
                <VStack w="100%">
                    <VStack width="100%">
                        <FormikFieldAutoFillCheckBox
                            name="useMyDetails"
                            label={t("modal.accessDetails.form.myDetails", {
                                ns: "inspection",
                            })}
                            formFields={[
                                {
                                    name: "contactFirstName",
                                    value: user.firstName,
                                },
                                {
                                    name: "contactLastName",
                                    value: user.lastName,
                                },
                                {
                                    name: "companyEmail",
                                    value: user.email,
                                },
                                {
                                    name: "contactNumberDialCode",
                                    value: user.mobileDialCode,
                                },
                                {
                                    name: "contactNumber",
                                    value: user.mobile,
                                },
                            ]}
                        />
                        <HStack
                            alignItems="top"
                            spacing="6"
                            w="100%"
                        >
                            <FormikInput
                                name="contactFirstName"
                                label={t("firstName")}
                                placeholder={t("firstName")}
                            />

                            <FormikInput
                                name="contactLastName"
                                label={t("lastName")}
                                placeholder={t("lastName")}
                            />
                        </HStack>

                        <FormikInput
                            name="companyEmail"
                            label={t("companyDetails.email")}
                            placeholder={t("fieldPlaceholder.email", {
                                ns: "common",
                            })}
                            type="email"
                        />
                    </VStack>

                    {renderInputRow(
                        <FormikPhoneNumberInput
                            dialCodeName="contactNumberDialCode"
                            dialCodeLabel={t("companyDetails.dialCode")}
                            phoneNumberName="contactNumber"
                            phoneNumberLabel={t("companyDetails.phoneNumber")}
                            phoneNumberPlaceholder={t(
                                "fieldPlaceholder.phoneNumber",
                                {
                                    ns: "common",
                                }
                            )}
                        />
                    )}

                    <FormikInput
                        name="websiteAddress"
                        label={t("companyDetails.website")}
                        placeholder={t("fieldPlaceholder.url", {
                            ns: "common",
                        })}
                    />
                </VStack>
            }
        />
    );
};

export default CompanyDetailsContact;
