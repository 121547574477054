import {
    Box,
    Container,
    Link as ChakraLink,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import Logo from './common/components/Logo';
import { WVWTitle, WvwText } from './common/components/typography';
import { WvwButton } from './common/components/inputs';

const FourZeroFour = () => {
    const { t } = useTranslation('common');

    return (
        <Box
            bgColor="wvwGreen"
            paddingTop="1.5rem"
            h="100vh"
            w="100%"
        >
            <Container
                maxW="container.lg"
                bg="white"
                borderRadius="lg"
                p="2em"
                w="100%"
                justifyContent="center"
                alignContent="center"
            >
                <VStack
                    spacing="6"
                    pb="9em"
                    pt="6em"
                    w="100%"
                >
                    <Logo withColor />

                    <WVWTitle
                        level="1"
                        content={t('fourZeroFour.header')}
                    />

                    <WvwText>
                        {t('fourZeroFour.message')}
                    </WvwText>

                    <ChakraLink
                        as={ReactLink}
                        to="/"
                    >
                        <WvwButton
                            content={t('fourZeroFour.button')}
                            onClick={() => { }}
                            size="md"
                            variant="primary"
                        />
                    </ChakraLink>
                </VStack>
            </Container>
        </Box>
    );
};

export default FourZeroFour;
