import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";

export const translations = {
    de: {
        customizeExperience: {
            customizeYourExperience: "Passe deine Erfahrung an",
            completeProfile: {
                title: "Vervollständige dein Profil",
                subtext: MISSING_TRANSLATION,
            },
            addTeamMembers: {
                title: "Teammitglieder hinzufügen",
                subtext: MISSING_TRANSLATION,
            },
            uploadMarketing: {
                title: "Marketingmaterial hochladen",
                subtext: MISSING_TRANSLATION,
            },
            uploadPiCover: {
                title: "PI-Cover hochladen",
                subtext: MISSING_TRANSLATION,
            },
            uploadValuationSample: {
                title: MISSING_TRANSLATION,
                subtext: MISSING_TRANSLATION,
            },
        },
        statistics: {
            cases: {
                active: "Aktiv",
                conversionRate: "Konversionsrate",
            },
            enquiries: {
                allOffersSent: "Gesendete Angebote",
                matchings: "Matchings",
                rejectedOffers: "Abgelehnte Angebote",
                waitingFor: "Warten auf Entscheidung",
            },
        },
    },
    en: {
        customizeExperience: {
            customizeYourExperience: "Customize your experience",
            completeProfile: {
                title: "Complete your profile",
                subtext:
                    "You have missing mandatory information about your company, please complete all relevant fields.",
            },
            addTeamMembers: {
                title: "Add team members",
                subtext:
                    "Consider adding your team members to your company profile.",
            },
            uploadMarketing: {
                title: "Upload marketing material",
                subtext:
                    "Uploading marketing material inceases transpacency among clients and can have positive impact on your offers acceptance.",
            },
            uploadPiCover: {
                title: "Upload PI cover",
                subtext:
                    "Uploading those documents inceases transpacency among clients and can have positive impact on your offers acceptance.",
            },
            uploadValuationSample: {
                title: "Upload valuation sample",
                subtext:
                    "Uploading valuation samples inceases transpacency among clients and can have positive impact on your offers acceptance.",
            },
        },
        statistics: {
            cases: {
                active: "Active",
                conversionRate: "Conversion Rate",
            },
            enquiries: {
                allOffersSent: "All Offers Sent",
                matchings: "Matchings",
                rejectedOffers: "Rejected Offers",
                waitingFor: "Waiting for decision",
            },
        },
    },
};
