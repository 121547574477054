import {
    IconButton,
    TableContainer,
    Table,
    Tbody,
    Td,
    Text,
    Thead,
    Th,
    Tr,
    Box,
} from "@chakra-ui/react";
import { ArrangeVertical } from "iconsax-react";
import { ReactNode, useEffect, useState } from "react";
import SelectArray, { OptionType } from "../SelectArray";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../hooks/useSmartTranslation";

export type DataTableColumnType<T> = {
    alignment?: string;
    title?: (t: TranslationFunction) => string;
    dataIndex?: string;
    maxWidth?: string;
    sorter?: (a: T, b: T) => number;
    render?: (data: T, t: TranslationFunction) => ReactNode;
};

export type DataTableFilterType<T> = {
    placeholder: string;
    options: OptionType[];
    filterName: string;
    filterFunction: (i: T, v: string | undefined) => boolean;
};

// FIXME how to use sorting from props in state
// FIXME we could probably make a type from string-identifiers on columns and use that in the SortOrder
type SortOrder = {
    column: number | undefined;
    direction?: "default" | "asc" | "desc";
};

type PropTypes<T> = {
    columns: DataTableColumnType<T>[];
    data: T[];
    noDataText?: string;
    tableWidth?: string;
    initialSorting?: SortOrder;
    filters?: DataTableFilterType<T>[];
    variant?: string;
};

type TableRowProps<T> = {
    columns: DataTableColumnType<T>[];
    item: T;
};

const TableRow = <T extends Record<string, any>>(props: TableRowProps<T>) => {
    const { columns, item } = props;

    const t = useSmartTranslation();

    return (
        <Tr>
            {columns.map((col, index) => (
                <Td
                    key={index}
                    maxWidth={col.maxWidth}
                >
                    <Box
                        justifyContent={col.alignment}
                        display="flex"
                    >
                        {col.render && col.render(item, t)}

                        {!col.render && item?.[col.dataIndex || ""]}
                    </Box>
                </Td>
            ))}
        </Tr>
    );
};

const DataTable = <T extends Record<string, any>>(props: PropTypes<T>) => {
    const {
        columns,
        data,
        noDataText,
        tableWidth,
        initialSorting = {
            // FIXME why do I have to put defaults when I have defaultProps
            column: undefined,
            direction: "default",
        },
        filters,
        variant,
    } = props;

    const t = useSmartTranslation();

    const [sortedData, setSortedData] = useState<T[]>(data);

    const [sort, setSort] = useState<SortOrder>(initialSorting);

    const [filterState, setFilterState] = useState<{
        [index: string]: string | undefined;
    }>(
        filters?.reduce(
            (res, i) => ({ ...res, [i.filterName]: undefined }),
            {}
        ) || {}
    );

    useEffect(() => {
        const { column, direction } = sort;

        let newData = [...data];

        if (column !== undefined && columns[column] && columns[column].sorter) {
            const { sorter } = columns[column];
            newData = [...data].sort(sorter);

            if (direction === "desc") {
                newData = newData.reverse();
            }
        }

        filters?.forEach((i) => {
            const filterValue = filterState?.[i.filterName];
            if (filterValue) {
                newData = newData.filter((row) =>
                    i.filterFunction(row, filterValue)
                );
            }
        });

        setSortedData([...newData]);
    }, [data, sort, filterState]);

    const sortColumn = (index: number) => {
        let sortDirection = sort.direction;

        if (sort.column !== index) {
            setSort({
                column: index,
                direction: "asc",
            });

            return;
        }

        switch (sortDirection) {
            case "asc":
                sortDirection = "desc";
                break;
            case "desc":
                sortDirection = "default";
                break;
            default:
                sortDirection = "asc";
                break;
        }

        setSort({
            column: index,
            direction: sortDirection,
        });
    };

    if (!sortedData.length) {
        return (
            <Box
                backgroundColor="gray.50"
                borderRadius="8px"
                w="100%"
            >
                <Text
                    paddingBlock="2rem"
                    textAlign="center"
                    variant="secondary"
                    w="100%"
                >
                    {noDataText || t("common.noData")}
                </Text>
            </Box>
        );
    }

    return (
        <>
            {filters && (
                <SelectArray
                    selectList={filters.map((i) => ({
                        placeholder: i.placeholder,
                        options: i.options,
                        value: filterState[i.filterName],
                        onChange: (selectedValue) =>
                            setFilterState({
                                ...filterState,
                                [i.filterName]: selectedValue,
                            }),
                    }))}
                />
            )}

            <TableContainer
                borderRadius="8px"
                borderWidth="1px"
                borderColor="gray.100"
                maxWidth={tableWidth}
                w="100%"
                className="hide-scrollbar hide-scrollbar::-webkit-scrollbar"
            >
                <Table variant={variant}>
                    <Thead>
                        <Tr>
                            {columns.map((col, index) => (
                                <Th
                                    key={index}
                                    maxW={col.maxWidth}
                                >
                                    <Box
                                        alignItems="center"
                                        justifyContent={col.alignment}
                                        display="flex"
                                    >
                                        <Text
                                            _hover={{
                                                cursor: col.sorter
                                                    ? "pointer"
                                                    : "normal",
                                            }}
                                            onClick={() => sortColumn(index)}
                                            variant="secondary"
                                        >
                                            {col.title && col.title(t)}
                                            {!col.title && ""}
                                        </Text>

                                        {col.sorter && (
                                            <IconButton
                                                aria-label="Sort"
                                                icon={
                                                    <ArrangeVertical size="18" />
                                                }
                                                color="blue.500"
                                                size="xs"
                                                variant="ghost"
                                                _hover={{ color: "wvwGrey80" }}
                                                onClick={() =>
                                                    sortColumn(index)
                                                }
                                            />
                                        )}
                                    </Box>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>

                    <Tbody>
                        {sortedData?.map((i, index) => (
                            <TableRow
                                columns={columns}
                                item={i}
                                key={index}
                            />
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};

DataTable.defaultProps = {
    noDataText: undefined,
    tableWidth: "100%",
    initialSorting: {
        column: undefined,
        direction: "default",
    },
    filters: undefined,
    variant: "default",
};

export default DataTable;
