import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/layout";
import { ReactNode } from "react";

type SectionProps = {
    title: string;
    subtitle?: string;
    content: ReactNode;
};

const Section = (props: SectionProps) => {
    const { title, subtitle, content } = props;

    return (
        <VStack
            width="100%"
            minWidth="max"
            align="left"
            padding="1em"
            bg="white"
            borderRadius="8"
        >
            <HStack>
                <Heading
                    size="md"
                    variant="blue"
                >
                    {title}
                </Heading>
                {subtitle && <Text variant="grey">{subtitle}</Text>}
            </HStack>
            {content}
        </VStack>
    );
};

export { Section };
