import { createStandaloneToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { FormikForm } from "../../../common/forms";
import { useEnquiry } from "../../../utils/api/hooks";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import useEnquirySteps from "../common/hooks/useEnquirySteps";
import { useRole } from "../../../common/hooks";
import EnquiryFormBox from "../common/EnquiryFormBox";
import EnquiryContactForm from "./contact-forms/EnquiryContactForm";

const validationSchema = Yup.object({
    invoiceFName: Yup.string().required("errors.required"),
    invoiceLName: Yup.string().required("errors.required"),
    invoiceEmail: Yup.string()
        .email("errors.invalidEmail")
        .required("errors.required"),
    invoiceEmailCc: Yup.string().email("errors.invalidEmail"),
    invoiceMobileDialCode: Yup.string().required("errors.required").nullable(),
    invoiceMobile: Yup.number()
        .required("errors.required")
        .typeError("errors.enterNumber"),
});

const EnquiryInvoiceInfo = () => {
    const { t } = useTranslation(["enquiryform", "common", "formik"]);
    const { toast } = createStandaloneToast();
    const { onContinue, onBack } = useEnquirySteps();
    const { enquiryId = "" } = useParams();

    const { userIsValuer } = useRole();

    const {
        enquiry,
        isLoading: isEnquiryLoading,
        update: updateEnquiry,
    } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => onContinue(),
        onUpdateError: (error) =>
            toast({
                title: t("error.error", { ns: "common" }),
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            }),
    });

    if (isEnquiryLoading) {
        return <LoadingSpinner noText />;
    }

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                invoiceFName: enquiry?.invoiceFName || "",
                invoiceLName: enquiry?.invoiceLName || "",
                invoiceEmail: enquiry?.invoiceEmail || "",
                invoiceEmailCc: enquiry?.invoiceEmailCc || "",
                invoiceMobileDialCode: enquiry?.invoiceMobileDialCode || "",
                invoiceMobile: enquiry?.invoiceMobile || "",
            }}
            onSubmit={(values) => {
                const data = {
                    ...values,
                    id: Number(enquiryId),
                    createdOnBehalfOf: userIsValuer
                        ? values.invoiceEmail
                        : undefined,
                };

                updateEnquiry(data);
            }}
        >
            <EnquiryFormBox
                onBack={onBack}
                content={[
                    {
                        key: "contactInfo",
                        header: t(
                            "newEnquiry.invoiceSection.contactDetails.sectionTitle"
                        ),
                        subHeader: t(
                            "newEnquiry.invoiceSection.contactDetails.instruction"
                        ),
                        element: <EnquiryContactForm />,
                    },
                ]}
            />
        </FormikForm>
    );
};

export default EnquiryInvoiceInfo;
