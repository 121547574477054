import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../common/hooks/useToast';
import useInspectionGetCaseInspections from './useInspectionGetCaseInspections';
import { updateContactPerson } from '../../endpoints';
import { UpdateContactPersonDto } from '../../dto/inspection/updateContactPerson.dto';

type ParamsType = {
    caseId: number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useInspectionUpdateContactPerson = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshInspections } = useInspectionGetCaseInspections(caseId);

    const { t } = useTranslation();

    const { createErrorToast } = useToast();

    const { mutate: update } = useMutation(
        (dto: UpdateContactPersonDto) => updateContactPerson(dto),
        {
            onSuccess: () => {
                refreshInspections();
                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));
                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useInspectionUpdateContactPerson;
