import { AdminInspectionDto } from '../../dto';
import adminInspection from '../../endpoints/admin/adminInspections';
import useQueryHook from '../useQueryHook';

const ADMIN_INSPECTIONS = 'ADMIN_INSPECTIONS';

const useAdminInspections = (dto: AdminInspectionDto) => {
    const query = useQueryHook({
        uniqueName: ADMIN_INSPECTIONS,
        dependancies: [JSON.stringify(dto)],
        requiredParams: [],
        queryFunction: () => adminInspection(dto),
    });

    return query;
};

export default useAdminInspections;
