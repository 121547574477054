import { enquirySubmit } from '../endpoints';
import useMutationHook from './useMutationHook';
import useEnquiryGetAll from './useEnquiryGetAll';
import { EnquiryType } from '../../../types';

type ParamsType = {
    onSuccess?: (data?: EnquiryType) => void;
    onError?: (error: Error) => void;
};

const useEnquirySubmit = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const {
        refresh: refreshEnquiryList,
    } = useEnquiryGetAll();

    const mutation = useMutationHook({
        mutationFunction: (enquiryId: number | string) => enquirySubmit(enquiryId),
        refreshes: [refreshEnquiryList],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useEnquirySubmit;
