import { BrowserRouter, Route, Routes } from "react-router-dom";
import TestingArea from "./TestingArea";

const TestingRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="*"
                    element={<TestingArea />}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default TestingRouter;
