import WVWTitle from './WVWTitle';

type PropTypes = {
    content: string;
    noColor?: boolean;
};

const WVWLabel = (props: PropTypes) => {
    const { content, noColor } = props;

    return (
        <WVWTitle
            content={content}
            level="3"
            color={noColor ? 'black' : undefined}
        />
    );
};

WVWLabel.defaultProps = {
    noColor: false,
};

export default WVWLabel;
