import { Flex } from '@chakra-ui/react';
import ActiveCasesWidget from './widgets/ActiveCasesWidget';
import ActiveEnquiriesWidget from './widgets/ActiveEnquiriesWidget';
import OfferConversionRateWidget from './widgets/OfferConversionRateWidget';
import InviteLinkWidget from './widgets/InviteLinkWidget';

type PropTypes = {
    userIsValuer: boolean;
    scrollToRef?: () => void;
};

const DashboardWidgets = (props: PropTypes) => {
    const { userIsValuer, scrollToRef } = props;

    return (
        <Flex
            w="100%"
            justifyContent="space-evenly"
            flexWrap="wrap"
            paddingBlock="2rem"
            gap="1rem"
        >

            <ActiveEnquiriesWidget />

            <ActiveCasesWidget />

            {userIsValuer && (
                <>
                    <OfferConversionRateWidget scrollToRef={scrollToRef} />

                    <InviteLinkWidget />
                </>
            )}
        </Flex>
    );
};

DashboardWidgets.defaultProps = {
    scrollToRef: null,
};

export default DashboardWidgets;
