import { useTranslation } from 'react-i18next';
import { StatusBadge } from '../../../../../common/components/display';
import { StatusBadgeColor, StatusBadgeTextColor } from '../../../../../common/components/display/StatusBadge';

type InspectionStatus = 'finished' | 'awaitingConfirmation' | 'noDateSuggested' | 'completed' | 'scheduled' | 'rescheduled';

type InspectionStatusColors = {
    color: StatusBadgeColor;
    textColor: StatusBadgeTextColor;
};

const statusColors: Record<InspectionStatus, InspectionStatusColors> = {
    finished: { color: 'green10', textColor: 'green' },
    awaitingConfirmation: { color: 'purple10', textColor: 'purple' },
    noDateSuggested: { color: 'wvwYellow10', textColor: 'wvwYellow' },
    completed: { color: 'white', textColor: 'green' },
    scheduled: { color: 'orange10', textColor: 'orange' },
    rescheduled: { color: 'white', textColor: 'wvwGrey60' },
};

type PropTypes = {
    status: InspectionStatus;
};

const InspectionStatusBadge = (props: PropTypes) => {
    const { status } = props;
    const { color, textColor } = statusColors[status];

    const { t } = useTranslation('inspection');

    return (
        <StatusBadge
            color={color}
            status={t(`status.${status}`)}
            textColor={textColor}
        />
    );
};

export default InspectionStatusBadge;
