import useSigninState from '../../../common/hooks/useSigninState';
import { enquiryGetAll } from '../endpoints';
import useQueryHook from './useQueryHook';

const MY_ENQUIRIES = 'MY_ENQUIRIES';

const useEnquiryGetAll = () => {
    const { signedIn } = useSigninState();

    const query = useQueryHook({
        uniqueName: MY_ENQUIRIES,
        dependancies: [],
        requiredParams: [signedIn],
        queryFunction: () => enquiryGetAll(),
    });

    return query;
};

export default useEnquiryGetAll;
