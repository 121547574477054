import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../common/hooks/useToast';
import useInspectionGetCaseInspections from './useInspectionGetCaseInspections';
import { ReportProblemDto } from '../../dto/inspection/reportProblem.dto';
import reportProblem from '../../endpoints/inspection/report-problem';

type ParamsType = {
    caseId: number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useInspectionReportProblem = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshInspections } = useInspectionGetCaseInspections(caseId);

    const { t } = useTranslation();

    const { createErrorToast, createSuccessToast } = useToast();

    const { mutate: update } = useMutation(
        (dto: ReportProblemDto) => reportProblem(dto),
        {
            onSuccess: () => {
                createSuccessToast(t('reportProblem.description', { ns: 'inspection', defaultValue: 'Default success message' }));
                refreshInspections();
                onSuccess?.();
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));
                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useInspectionReportProblem;
