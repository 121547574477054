import {
    Flex,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InspectionAccessDetails from './common/InspectionAccessDetails';
import { InspectionType } from '../../../../types';
import { CalendarEntryType } from '../../../../types/calendar/CalendarEntryType';
import { useInspectionReportOutcome, useInspectionReportProblem } from '../../../../utils/api/hooks';
import { useRole } from '../../../../common/hooks';
import { WvwButton } from '../../../../common/components/inputs';
import { ContentBox, EventDateTime } from '../../../../common/components/display';

type PropTypes = {
    calendarEntry: CalendarEntryType;
    inspection: InspectionType;
};

const InspectionDetailsActionArea = (props: PropTypes) => {
    const { calendarEntry, inspection } = props;
    const {
        start,
        duration,
    } = calendarEntry;

    const { t } = useTranslation(['inspection']);
    const navigate = useNavigate();
    const { userIsValuer } = useRole();

    const { update: reportOutcome } = useInspectionReportOutcome({
        caseId: inspection.caseId,
        onSuccess: () => navigate('../', { replace: true }),
    });

    const { update: reportProblem } = useInspectionReportProblem({
        caseId: inspection.caseId,
        onSuccess: () => navigate('../', { replace: true }),
    });

    const startDate = new Date(start);

    return (
        <VStack spacing={4} pt=".5rem">
            <Flex
                gap="2"
                justifyContent="space-between"
                w="100%"
            >
                {userIsValuer && (
                    <WvwButton
                        block
                        content={t('button.markComplete')}
                        onClick={() => reportOutcome({
                            outcome: {
                                success: true,
                                comment: 'Die Besichtigung war erfolgreich',
                            },
                            target: inspection.id,
                        })}
                        size="sm"
                    />
                )}

                <WvwButton
                    block
                    content={t('button.reportProblem')}
                    onClick={() => reportProblem({
                        comment: 'Die Besichtigung hatte ein Problem',
                        target: inspection.id,
                    })}
                    size="sm"
                    variant="danger"
                />

                <WvwButton
                    block
                    content={t('button.reschedule')}
                    onClick={() => reportOutcome({
                        outcome: {
                            success: false,
                            comment: 'Die Besichtigung muss neu terminiert werden',
                        },
                        target: inspection.id,
                    })}
                    size="sm"
                    variant="primary"
                />
            </Flex>

            <ContentBox color="orange">
                <EventDateTime
                    date={startDate}
                    duration={duration}
                    orientation="vertical"
                />
            </ContentBox>

            {inspection.contactPerson && (
                <InspectionAccessDetails
                    contactPerson={inspection.contactPerson}
                    inspection={inspection}
                />
            )}
        </VStack>
    );
};

export default InspectionDetailsActionArea;
