import { Flex, SimpleGrid } from '@chakra-ui/react';
import * as Yup from 'yup';
import WvwVStack from '../../../../../common/components/display/WvwVStack';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import { useSmartTranslation } from '../../../../../common/hooks';
import {
    FormikFileUploadLocal,
    FormikForm,
    FormikSubmitButton,
    FormikTextarea,
} from '../../../../../common/forms';
import { WVWLabel } from '../../../../../common/components/typography';
import { useCaseSubmitReport } from '../../../../../utils/api/hooks';
import { CaseSubmitReportDto } from '../../../../../utils/api/dto/case-report';

type PropTypes = {
    caseId: number;
    isOpen: boolean;
    onClose: () => void;
};

const validationSchema = Yup.object().shape({
    caseId: Yup.number().required('errors.required'),
    documents: Yup.array().min(1, 'errors.required'),
});

const AddReportModal = (props: PropTypes) => {
    const { caseId, isOpen, onClose } = props;

    const t = useSmartTranslation('casereport');

    const { update: submitReport, busy } = useCaseSubmitReport({ caseId });

    return (
        <ModalDynamic
            header={t('title.addReport')}
            isOpen={isOpen}
            onClose={onClose}
        >
            <FormikForm
                validationSchema={validationSchema}
                submitting={busy}
                initialValues={{
                    caseId,
                    description: '',
                    documents: [],
                }}
                onSubmit={values => {
                    const dto: CaseSubmitReportDto = {
                        caseId: values.caseId,
                        description: values.description,
                        files: values.documents
                            .map((f: { file: File, comment: string }) => f.file),
                    };

                    submitReport(dto);
                }}
            >
                <SimpleGrid gap="6">
                    <WvwVStack>
                        <WVWLabel content={t('description', { ns: 'common' })} />

                        <FormikTextarea
                            name="description"
                            placeholder={t('placeholder.reportDescription')}
                        />
                    </WvwVStack>

                    <WvwVStack>
                        <WVWLabel content={t('documents', { ns: 'common' })} />
                        <FormikFileUploadLocal name="documents" />
                    </WvwVStack>

                    <Flex justifyContent="flex-end">
                        <FormikSubmitButton content={t('button.submit', { ns: 'common' })} />
                    </Flex>
                </SimpleGrid>
            </FormikForm>
        </ModalDynamic>
    );
};

export default AddReportModal;
