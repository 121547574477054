import { Flex, Link, Text } from '@chakra-ui/react';
import { WvwIcon } from '../../../../../common/components/display';
import { EntityType } from '../../../../../types';
import { useRole, useSmartTranslation, useStandards } from '../../../../../common/hooks';
import FormSectionProfileEdit from '../../../profile/components/FormSectionProfileEdit';
import toUrl from '../../../../../common/functions/toUrl';

type PropsType = {
    entity: EntityType;
};

const CompanyDetailsDetails = (props: PropsType) => {
    const { entity } = props;

    const {
        valuerNumberOfPartners,
        valuerYearsOfExperience,
        websiteAddress = '',
    } = entity;

    const t = useSmartTranslation('account');
    const { userIsValuer } = useRole();
    const { yearsOfExperience } = useStandards();

    const renderFieldData = (
        field: string,
        data: (string | number | undefined)[],
        config?: {
            required?: boolean;
            joinWith?: string;
        },
    ) => {
        const { required, joinWith = ' ' } = config || {};

        if (data.every(item => item)) {
            return (
                <Text>
                    {data.join(joinWith)}
                </Text>
            );
        }

        return (
            <Text color="wvwGrey40">
                {required && (
                    <WvwIcon icon="warning" />
                )}
                {' '}
                {t(`fieldNotProvided.${field}`, { ns: 'common', defaultValue: field })}
            </Text>
        );
    };

    return (
        <FormSectionProfileEdit header={t('heading.companyDetails')}>
            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    w="100%"
                    maxW="17rem"
                >
                    {t('companyDetails.website')}
                </Text>

                {websiteAddress ? (
                    <Link
                        color="wvwGreen"
                        href={toUrl(websiteAddress)}
                        isExternal
                        textOverflow="elipsis"
                        noOfLines={1}
                    >
                        {websiteAddress}
                    </Link>
                ) : (
                    <Text color="wvwGrey40">
                        {t('fieldNotProvided.website', { ns: 'common' })}
                    </Text>
                )}
            </Flex>

            {userIsValuer && (
                <>
                    <Flex
                        alignItems="flex-start"
                        w="100%"
                    >
                        <Text w="17rem">
                            {t('companyDetails.yearsOfExperience')}
                        </Text>

                        {renderFieldData(
                            'yearsOfExperience',
                            [
                                yearsOfExperience
                                    .find(i => i.value === valuerYearsOfExperience)
                                    ?.label,
                            ],
                            { required: true },
                        )}
                    </Flex>

                    <Flex
                        alignItems="flex-start"
                        w="100%"
                    >
                        <Text w="17rem">
                            {t('companyDetails.numberOfValuers')}
                        </Text>

                        {renderFieldData('numberOfPartners', [valuerNumberOfPartners], { required: true })}
                    </Flex>
                </>
            )}

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('companyDetails.contactPerson')}
                </Text>

                {renderFieldData('contactPerson', [entity?.contactFirstName, entity?.contactLastName])}
            </Flex>

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('companyDetails.email')}
                </Text>

                {renderFieldData('email', [entity?.companyEmail], { required: userIsValuer })}
            </Flex>

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('companyDetails.phoneNumber')}
                </Text>

                {renderFieldData('phoneNumber', [entity?.contactNumberDialCode, entity?.contactNumber])}
            </Flex>

            <Flex
                w="100%"
            >
                <Text
                    w="17rem"
                    alignItems="flex-start"
                >
                    {t('companyDetails.location')}
                </Text>

                {renderFieldData('location', [entity?.street, entity?.postalCode, entity?.city, entity?.country], { joinWith: ', ', required: true })}
            </Flex>
        </FormSectionProfileEdit>
    );
};

export default CompanyDetailsDetails;
