import { publicCreateEnquiry } from '../endpoints';
import { PublicCreateEnquiryDto } from '../dto';
import useMutationHook from './useMutationHook';
import useEnquiryGetAll from './useEnquiryGetAll';
import { AxiosResponse } from 'axios';
import { EnquiryType } from '../../../types';

type ParamsType = {
    onSuccess?: (data: AxiosResponse<EnquiryType, any>) => void;
    onError?: (error: Error) => void;
};

const usePublicCreateEnquiry = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshEnquiryList } = useEnquiryGetAll();

    const mutation = useMutationHook({
        mutationFunction: (dto: PublicCreateEnquiryDto) => publicCreateEnquiry(dto),
        refreshes: [refreshEnquiryList],
        onSuccess,
        onError,
    });

    return mutation;
};

export default usePublicCreateEnquiry;
