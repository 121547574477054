import { documentRequestGetCaseDocuments } from "../../endpoints";
import { DocumentRequestGetCaseDocumentsDto } from "../../dto";
import useQueryHook from "../useQueryHook";

const DOCUMENT_REQUEST_GET_CASE_DOCUMENTS =
    "DOCUMENT_REQUEST_GET_CASE_DOCUMENTS";

type ParamsType = DocumentRequestGetCaseDocumentsDto & {
    includeDeleted?: boolean;
};

const useDocumentRequestGetCaseDocuments = (params: ParamsType) => {
    const { caseId, requestId, documentStatus, includeDeleted } = params;

    const query = useQueryHook({
        uniqueName: DOCUMENT_REQUEST_GET_CASE_DOCUMENTS,
        dependancies: [caseId],
        requiredParams: [caseId],
        queryFunction: () => documentRequestGetCaseDocuments({ caseId }),
    });

    let data = query.data || [];

    if (requestId) {
        data = data.filter(
            (document) => document.documentRequestId === requestId
        );
    }

    if (documentStatus) {
        data = data.filter((document) => document.status === documentStatus);
    }

    if (!includeDeleted) {
        data = data.filter((document) => !document.deleted);
    }

    return {
        data,
        error: query.error,
        isLoading: query.isLoading,
        refresh: query.refresh,
    };
};

export default useDocumentRequestGetCaseDocuments;
