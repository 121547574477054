import { applyActionCode } from 'firebase/auth';
import useMutationHook from '../useMutationHook';
import { auth } from '../../../firebase';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useApplyActionCode = (params: ParamsType) => {
    const { onSuccess, onError } = params;

    const mutation = useMutationHook({
        mutationFunction: (oobCode: string) => applyActionCode(auth, oobCode),
        refreshes: [],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useApplyActionCode;
