import {
    Divider,
    Flex,
    HStack,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { Calendar, Clock } from 'iconsax-react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { InspectionType } from '../../../../../types';
import StatusBadge from '../../../../../common/components/StatusBadge';
import DropdownMenu from '../../../../../common/components/DropdownMenu';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';

type PropTypes = {
    inspection?: InspectionType;
    dateAndTime?: {
        date: string;
        time: string;
    };
    onDelete?: () => void;
};

const InspectionDate = (props: PropTypes) => {
    const {
        inspection,
        dateAndTime,
        onDelete,
    } = props;

    return (
        <Flex
            w="100%"
            paddingBlock="1rem"
            paddingInline="2rem"
            bg="wvwGreen"
            color="white"
            borderRadius="10"
        >
            <Flex
                minW="15rem"
                className="hide-scrollbar hide-scrollbar::-webkit-scrollbar"
            >
                <HStack
                    className="hide-scrollbar hide-scrollbar::-webkit-scrollbar"
                >
                    <Calendar />

                    <Text>
                        {dateAndTime ? dateAndTime.date : inspection?.id}
                    </Text>
                </HStack>

                <Spacer />

                <Divider
                    orientation="vertical"
                    marginInline=".5rem"
                />

                <Spacer />

                <HStack>
                    <Clock />

                    <Text>
                        {dateAndTime ? dateAndTime.time : undefined}
                    </Text>
                </HStack>
            </Flex>

            <Spacer />

            {inspection && (
                <StatusBadge
                    status="Scheduled"
                />
            )}

            <Spacer />

            {inspection && (
                <DropdownMenu
                    menu={{
                        rightIcon: <ChevronDownIcon />,
                        size: 'sm',
                        buttonVariant: 'none',
                        menuList: [],
                    }}
                />
            )}

            {dateAndTime && (
                <WvwDeleteIconButton
                    onClick={() => onDelete?.()}
                    color="white"
                />
            )}
        </Flex>
    );
};

InspectionDate.defaultProps = {
    inspection: undefined,
    dateAndTime: {},
    onDelete: undefined,
};

export default InspectionDate;
