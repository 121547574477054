import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import { useSmartTranslation } from "../../../../common/hooks";
import { UserType } from "../../../../types";
import FormSectionProfileEdit from "./FormSectionProfileEdit";
import { FirebaseCertificateType } from "../../../../utils/storage/storage";

type PropsType = {
    user: UserType;
    certificates: FirebaseCertificateType[];
};

const ProfileValuationStandards = (props: PropsType) => {
    const { user, certificates } = props;

    const { systemStandardsOffered = [], otherStandardsOffered = [] } = user;

    const t = useSmartTranslation("profile");

    return (
        <FormSectionProfileEdit header={t("heading.valuationStandards")}>
            <Flex
                alignItems="flex-start"
                minH="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t("valuationCompetence.valuationStandards")}
                </Text>

                <List spacing={2}>
                    {systemStandardsOffered.length === 0 && (
                        <ListItem color="wvwGrey40">
                            {t("none", { context: "female", ns: "common" })}
                        </ListItem>
                    )}

                    {systemStandardsOffered?.sort()?.map((i) => {
                        const files = certificates.filter(
                            (certificate) =>
                                certificate.type.toLowerCase() ===
                                i.toLowerCase()
                        );

                        return (
                            <ListItem key={i}>
                                <Flex w="100%">
                                    <Text w="10rem">
                                        {t(i, {
                                            ns: "formik",
                                            defaultValue: i,
                                        })}
                                    </Text>

                                    <List>
                                        {files.map((file) => (
                                            <ListItem key={file.file.fullPath}>
                                                <Link
                                                    href={file.url}
                                                    isExternal
                                                    target="_blank"
                                                    color="wvwGreen"
                                                >
                                                    {file.label}
                                                </Link>
                                            </ListItem>
                                        ))}

                                        {files.length === 0 && (
                                            <ListItem color="wvwGrey40">
                                                {t(
                                                    "membershipsAndQualifications.noCertificate"
                                                )}
                                            </ListItem>
                                        )}
                                    </List>
                                </Flex>
                            </ListItem>
                        );
                    })}
                </List>
            </Flex>

            {otherStandardsOffered && (
                <Flex
                    alignItems="flex-start"
                    minH="2rem"
                    w="100%"
                >
                    <Text w="17rem">
                        {t("valuationCompetence.otherValuationStandards")}
                    </Text>

                    <List spacing={2}>
                        {otherStandardsOffered?.length === 0 && (
                            <ListItem color="wvwGrey40">
                                {t("none", { context: "female", ns: "common" })}
                            </ListItem>
                        )}

                        {otherStandardsOffered?.sort()?.map((i) => {
                            const files = certificates.filter(
                                (certificate) =>
                                    certificate.type === i.toLowerCase()
                            );

                            return (
                                <ListItem key={i}>
                                    <Flex w="100%">
                                        <Text w="10rem">{i}</Text>

                                        <List>
                                            {files.map((file) => (
                                                <ListItem
                                                    key={file.file.fullPath}
                                                >
                                                    <Link
                                                        href={file.url}
                                                        isExternal
                                                        target="_blank"
                                                        color="wvwGreen"
                                                    >
                                                        {file.label}
                                                    </Link>
                                                </ListItem>
                                            ))}

                                            {files.length === 0 && (
                                                <ListItem color="wvwGrey40">
                                                    {t(
                                                        "membershipsAndQualifications.noCertificate"
                                                    )}
                                                </ListItem>
                                            )}
                                        </List>
                                    </Flex>
                                </ListItem>
                            );
                        })}
                    </List>
                </Flex>
            )}
        </FormSectionProfileEdit>
    );
};

export default ProfileValuationStandards;
