import { VStack } from "@chakra-ui/layout";
import Conversations from "../../../../common/features/conversations/components/Conversations";
import { testConversations } from "../../../../common/features/conversations/testdata";
import {
    Message,
    Topic
} from "../../../../common/features/conversations/types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Enquiry, Property } from "../../../../domain";
import { testEnquiry } from "../../../../domain/enquiry/testdata";
import { testOffers } from "../../../../domain/offer/testdata";
import { testProperties } from "../../../../domain/property/testdata";
import { Section } from "../../../../layout";
import TwoColumn from "../../../../layout/TwoColumn";
import OffersView from "./OffersView";

const EnquiryOverview = () => {
    const t = useSmartTranslation();
    // enquiry

    const enquiry = testEnquiry;

    const title = (
        <Enquiry.Title
            enquiry={enquiry}
            onArchive={() => console.log("archive enquiry")}
        />
    );

    const requestedValuation = (
        <Section
            title={t("domain.enquiry.requestedValuationTitle")}
            content={<Enquiry.Requirements enquiry={enquiry} />}
        />
    );

    // properties

    const properties = testProperties;

    const enquiryProps = (
        <Section
            title={t("domain.property.properties")}
            content={<Property.PortfolioTable properties={properties} />}
        />
    );

    // messages

    const conversations = testConversations;
    const markMessageRead = (message: Message) =>
        console.log(`marking message #${message.id} as read`);
    const sendMessage = ({
        topic,
        message,
    }: {
        topic: Topic;
        message: string;
    }) => {
        console.log(
            `adding message '${message}' to topic ${JSON.stringify(topic)}`
        );
    };

    const messagesBox = (
        <Conversations
            conversations={conversations}
            markMessageRead={markMessageRead}
            sendMessage={sendMessage}
        />
    );

    // render

    return (
        <VStack
            w={"100%"}
            padding=".5em"
            spacing={4}
        >
            {title}
            <TwoColumn
                right={
                    <>
                        {requestedValuation}
                        {enquiryProps}
                    </>
                }
                left={<OffersView offers={testOffers} />}
            />
        </VStack>
    );
};

export default EnquiryOverview;
