import { CaseRespondToReportDto } from '../../dto/case-report';
import { caseRespondToReport } from '../../endpoints';
import useMutationHook from '../useMutationHook';
import useCaseGetCaseReports from './useCaseReportGetReports';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: () => void;
};

const useCaseReportRespondToReport = (params: ParamsType) => {
    const { caseId, onSuccess, onError } = params;

    const { refresh: refreshReports } = useCaseGetCaseReports(caseId);

    const mutation = useMutationHook({
        mutationFunction: (dto: CaseRespondToReportDto) => caseRespondToReport(dto),
        refreshes: [refreshReports],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useCaseReportRespondToReport;
