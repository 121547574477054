import { EnquiryType, OfferOnlyType, CaseType } from "../../../../../../types";
import { StatisticsFunction } from "../../../../common/features/statistics/Statistics";
import { TranslationFunction } from "../../../../common/hooks/useSmartTranslation";

// Enquiry statistics

export const allPendingEnquiriesStatistic: StatisticsFunction = (
    enquiries: EnquiryType[]
) => {
    let pendingEnquiriesCount;

    if (enquiries.length > 0) {
        pendingEnquiriesCount = enquiries.filter(
            (enquiry) => enquiry.status === "PENDING"
        ).length;
    }

    return {
        title: (t: TranslationFunction) =>
            t("valuerDashboard.statistics.enquiries.waitingFor"),
        data: pendingEnquiriesCount,
        key: "valuerDashboard.statistics.enquiries.waitingFor",
    };
};

export const allMatchingEnquiriesStatistic: StatisticsFunction = (
    enquiries: EnquiryType[]
) => {
    let matchingEnquiriesCount;

    if (enquiries.length > 0) {
        matchingEnquiriesCount = enquiries.length;
    }

    return {
        title: (t: TranslationFunction) =>
            t("valuerDashboard.statistics.enquiries.matchings"),
        data: matchingEnquiriesCount,
        key: "valuerDashboard.statistics.enquiries.matchings",
    };
};

export const allOffersSentStatistic: StatisticsFunction = (
    offers: OfferOnlyType[],
    enquiries: EnquiryType[]
) => {
    let offersCount;

    if (enquiries.length > 0) {
        offersCount = offers.length;
    }

    return {
        title: (t: TranslationFunction) =>
            t("valuerDashboard.statistics.enquiries.allOffersSent"),
        data: offersCount,
        key: "valuerDashboard.statistics.enquiries.allOffersSent",
    };
};

export const allRejectedOffersStatistic: StatisticsFunction = (
    offers: OfferOnlyType[],
    enquiries: EnquiryType[]
) => {
    let rejectedOffersCount;

    if (enquiries.length > 0) {
        rejectedOffersCount = offers.filter(
            (offer) => offer.status === "REJECTED"
        ).length;
    }

    return {
        title: (t: TranslationFunction) =>
            t("valuerDashboard.statistics.enquiries.rejectedOffers"),
        data: rejectedOffersCount,
        key: "valuerDashboard.statistics.enquiries.rejectedOffers",
    };
};

// Case statistics

export const allActiveCases: StatisticsFunction = (
    cases: CaseType[],
    enquiries: EnquiryType[]
) => {
    let activeCaseCount;

    if (enquiries.length > 0) {
        activeCaseCount = cases.length;
    }

    return {
        title: (t: TranslationFunction) =>
            t("valuerDashboard.statistics.cases.active"),
        data: activeCaseCount,
        key: "valuerDashboard.statistics.cases.active",
    };
};

export const caseConversionRate: StatisticsFunction = (
    cases: CaseType[],
    enquiries: EnquiryType[],
) => {
    let completedCasesCount;

    if (enquiries.length > 0) {
        const caseCountEquation = cases.length / enquiries.length * 100;
        const caseCountEquationDecimal = caseCountEquation.toFixed(2);
        completedCasesCount = `${caseCountEquationDecimal}%`;
    }

    return {
        title: (t: TranslationFunction) =>
            t("valuerDashboard.statistics.cases.conversionRate"),
        data: completedCasesCount,
        key: "valuerDashboard.statistics.cases.conversionRate",
    };
};
