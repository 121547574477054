import { VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CommentsThread from "../../../../../../../common/components/CommentsThread";
import {
    useDocumentRequestCreateComment,
    useDocumentRequestGetComments,
    useDocumentRequestMarkDocumentRequestCommentAsRead,
} from "../../../../../../../utils/api/hooks";

type PropTypes = {
    requestId: number;
};

const RequestDetailsCommentSection = (props: PropTypes) => {
    const { t } = useTranslation("documentmanagement");

    const { requestId } = props;

    const { data: requestCommentsList = [] } =
        useDocumentRequestGetComments(requestId);

    const { update: markAsRead } =
        useDocumentRequestMarkDocumentRequestCommentAsRead({ requestId });

    const { update: createComment } = useDocumentRequestCreateComment({
        requestId,
    });

    return (
        <VStack
            align="left"
            w="100%"
        >
            <CommentsThread
                title={t("modal.title.requestComments", {
                    ns: "documentmanagement",
                })}
                commentsList={
                    requestCommentsList.map((comment) => ({
                        id: comment.id,
                        createdAt: comment.createdAt,
                        updatedAt: comment.updatedAt,
                        fromUserId: comment.fromUserId,
                        readBy: comment.readBy,
                        message: comment.message,
                    })) || []
                }
                onSend={(values) => {
                    createComment({
                        requestId: Number(requestId),
                        comment: values.comment,
                    });
                }}
                markAsRead={() => markAsRead(requestId)}
            />
        </VStack>
    );
};

export default RequestDetailsCommentSection;
