import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../firebase';
import useMutationHook from './useMutationHook';

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

type DtoType = {
    oobCode: string;
    newPassword: string;
};

const useFbConfirmPasswordReset = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const mutation = useMutationHook({
        mutationFunction:
            ({ oobCode, newPassword }: DtoType) => confirmPasswordReset(auth, oobCode, newPassword),
        refreshes: [],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useFbConfirmPasswordReset;
