import { Box, Container, Flex, Spacer } from "@chakra-ui/react";
import { ReactNode } from "react";
import { WvwButtonBack } from "../../../../../common/components/inputs";
import LanguageToggleButton from "../../../common/features/languageToggle/LanguageToggleButton";
import Footer from "../../Footer";

type PropTypes = {
    children: ReactNode | ReactNode[];
    goBack: () => void;
};

const RegisterFormsContainer = (props: PropTypes) => {
    const { children, goBack } = props;

    return (
        <Box
            bgColor="wvwGreen"
            paddingTop="1.5rem"
            paddingInline="1rem"
            h="100vh"
            w="100%"
        >
            <Container
                maxW="container.lg"
                bg="white"
                borderRadius="lg"
                p="2em"
                w="100%"
                justifyContent="center"
                alignContent="center"
            >
                <Flex w="100%">
                    <WvwButtonBack onClick={goBack} />

                    <Spacer />

                    <LanguageToggleButton />
                </Flex>

                {children}
            </Container>

            <Footer invertColor />
        </Box>
    );
};

export default RegisterFormsContainer;
