import { documentRequestUpdateDocument } from '../../endpoints';
import { DocumentRequestUpdateDocumentDto } from '../../dto';
import useDocumentRequestGetDocument from './useDocumentRequestGetDocument';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';
import useMutationHook from '../useMutationHook';
import useDocumentRequestGetRequest from './useDocumentRequestGetRequest';

type ParamsType = {
    caseId: number;
    requestId?: number;
    documentId?: number;
    noSuccessToast?: boolean;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestUpdateDocument = (params: ParamsType) => {
    const {
        caseId,
        requestId,
        documentId = '',
        noSuccessToast = false,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');

    const { refresh: refreshRequest } = useDocumentRequestGetRequest(Number(requestId));
    const { refresh: refreshDocument } = useDocumentRequestGetDocument(documentId);
    const { refreshAll } = useRefreshAllDMSDataStores(caseId);

    const mutation = useMutationHook({
        mutationFunction:
            (dto: DocumentRequestUpdateDocumentDto) => documentRequestUpdateDocument(dto),
        refreshes: [refreshDocument, refreshAll, refreshRequest],
        successMessage: noSuccessToast ? undefined : t('toast.messages.documentUpdated'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestUpdateDocument;
