import { useFormikContext } from 'formik';
import { WvwButton } from '../components/inputs';

type PropTypes = {
    block?: boolean;
    content: string;
    variant?: 'primary' | 'outline';
    mustBeClicked?: boolean;
    width?: string;
};

const FormikSubmitButton = (props: PropTypes) => {
    const {
        block,
        width,
        content,
        variant,
        mustBeClicked,
    } = props;

    const {
        handleSubmit,
        // isValid,
    } = useFormikContext();

    let formSubmitButton = true;
    let onClick;

    if (mustBeClicked) {
        formSubmitButton = false;
        onClick = () => handleSubmit();
    }

    return (
        <WvwButton
            onClick={onClick}
            block={block}
            width={width}
            variant={variant}
            // isDisabled={!isValid}
            formSubmitButton={formSubmitButton}
            content={content}
        />
    );
};

FormikSubmitButton.defaultProps = {
    block: false,
    width: '15rem',
    variant: 'primary',
    mustBeClicked: false,
};

export default FormikSubmitButton;
