import { Flex, Text } from '@chakra-ui/react';
import { useSmartTranslation } from '../../../../common/hooks';
import { UserType } from '../../../../types';
import FormSectionProfileEdit from './FormSectionProfileEdit';
import { standardValuerMaxOperatingDistance } from '../../../../common/vars/valuationsAndMembershipTypes';

type PropsType = {
    user: UserType;
};

const ProfileOperationalArea = (props: PropsType) => {
    const { user } = props;

    const { maxOperatingDistance } = user;

    const t = useSmartTranslation('profile');

    return (
        <FormSectionProfileEdit
            header={t('heading.operationalArea')}
        >
            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t('profileDetails.valuerMaxOperationDistance')}
                </Text>

                {maxOperatingDistance !== null ? (
                    <Text>
                        {t(`${standardValuerMaxOperatingDistance.find(distance => distance.value === maxOperatingDistance)?.label}`, { ns: 'formik', defaultValue: 'Not limit' })}
                    </Text>
                ) : (
                    <Text
                        color="wvwGrey40"
                    >
                        {t('fieldNotProvided.valuerMaxOperationDistance', { ns: 'common' })}
                    </Text>
                )}
            </Flex>
        </FormSectionProfileEdit>
    );
};

export default ProfileOperationalArea;
