import { Flex, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { Edit } from 'iconsax-react';
import { useMyEntity } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { useRole, useSmartTranslation } from '../../../../common/hooks';
import CompanyDetailsPiCover from './common/CompanyDetailsPiCover';
import CompanyDetailsHeading from './common/CompanyDetailsHeader';
import CompanyDetailsDetails from './common/CompanyDetailsDetails';
import { WvwButton } from '../../../../common/components/inputs';
import CompanyDetailsEdit from './common/CompanyDetailsEdit';

const CompanyDetails = () => {
    const t = useSmartTranslation('common');
    const { userIsCompanyAdmin, userIsValuer } = useRole();

    const [editing, setEditing] = useState(false);

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    if (entityLoading) {
        return <LoadingSpinner />;
    }

    if (editing) {
        return (
            <CompanyDetailsEdit entity={entity} onComplete={() => setEditing(false)} />
        );
    }

    return (
        <VStack
            align="flex-start"
            w="100%"
            spacing="1.5rem"
            paddingBottom="2rem"
        >
            {userIsCompanyAdmin && (
                <Flex
                    justifyContent="flex-end"
                    w="100%"
                >
                    <WvwButton
                        variant="link"
                        icon={<Edit />}
                        onClick={() => setEditing(true)}
                        content={t('button.edit')}
                    />
                </Flex>
            )}

            <CompanyDetailsHeading entity={entity} />

            <CompanyDetailsDetails entity={entity} />

            {userIsValuer && (
                <CompanyDetailsPiCover entity={entity} />
            )}

        </VStack>
    );
};

export default CompanyDetails;
