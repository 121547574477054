import { documentRequestGetRequests } from "../../endpoints";
import { GetRequestsDto } from "../../dto";
import useQueryHook from "../useQueryHook";
import { DocumentRequestType } from "../../../../types";

const DOCUMENT_REQUEST_GET_REQUESTS = "DOCUMENT_REQUEST_GET_REQUESTS";

const useDocumentRequestGetRequests = (params: GetRequestsDto) => {
    const { caseId, folderId, status } = params;

    const query = useQueryHook<DocumentRequestType[]>({
        uniqueName: DOCUMENT_REQUEST_GET_REQUESTS,
        dependancies: [caseId],
        requiredParams: [caseId],
        queryFunction: () => {
            const dto = {
                caseId,
            };

            return documentRequestGetRequests(dto);
        },
    });

    let data = query.data || [];

    if (folderId) {
        data = data.filter((request) => request.folderId === folderId);
    }

    if (status) {
        data = data.filter((request) => request.status === status);
    }

    return {
        data,
        error: query.error,
        isLoading: query.isLoading,
        refresh: query.refresh,
    };
};

export default useDocumentRequestGetRequests;
