import {
    Heading,
    HStack,
    List,
    ListItem,
    Text,
    VStack
} from "@chakra-ui/react";
import { EnquiryType } from "../../../../../types";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { ValuerQualifications } from "./ValuerRequirements";

type Props = {
    enquiry: EnquiryType;
};

export const EnquiryRequirements = (props: Props) => {
    const {
        enquiry: {
            valuationType,
            valuationStandards,
            completionDate,
            valuationSpecial,
            valuationPurposes,
            additionalRequirements = "-",
        },
        enquiry,
    } = props;

    const t = useSmartTranslation();

    const standardAndType = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.standardAndType")}
        >
            <Text>{valuationType}</Text>
            <Text>{valuationStandards}</Text>
        </Subsection>
    );

    const expectedDelivery = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.expectedDelivery")}
        >
            <Text>{completionDate}</Text>
            <Text>30 days left</Text>
        </Subsection>
    );

    const specificScopes = (
        <Subsection
            title={t("domain.enquiry.valuationRequirements.specificScopes")}
        >
            <List>
                {valuationSpecial &&
                    valuationSpecial.map((spec) => <ListItem>{spec}</ListItem>)}
            </List>
        </Subsection>
    );

    const purposes = (
        <Subsection title={t("domain.enquiry.valuationRequirements.purpose")}>
            <List>
                {valuationPurposes.map((purp) => (
                    <Text key={purp}>{purp}</Text>
                ))}
            </List>
        </Subsection>
    );

    const qualifications = <ValuerQualifications enquiry={enquiry} />;

    const additional = (
        <Subsection
            title={t(
                "domain.enquiry.valuationRequirements.additionalRequirements"
            )}
        >
            <Text>{additionalRequirements}</Text>
        </Subsection>
    );

    // FIXME container - grid?

    return (
        <HStack>
            {standardAndType}
            {expectedDelivery}
            {specificScopes}
            {purposes}
            {qualifications}
            {additional}
        </HStack>
    );
};

type SubsectionProps = {
    children: React.ReactNode | React.ReactNode[];
    title: string;
};

const Subsection = (props: SubsectionProps) => {
    const { children, title } = props;

    // FIXME vstack config - cf. Section?
    return (
        <VStack bg="lightblue">
            <Heading
                size="sm"
                color="grey"
            >
                {title}
            </Heading>
            {children}
        </VStack>
    );
};
