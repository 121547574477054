import { AxiosResponse } from 'axios';
import { OfferType } from '../../../types';
import { OfferRespondDto } from '../dto';
import { offerRespond } from '../endpoints';
import useMutationHook from './useMutationHook';
import useOfferGetById from './useOfferGetById';

type ParamsType = {
    onSuccess?: (response: AxiosResponse<OfferType>) => void;
    onError?: (error: Error) => void;
    offerId: number;
};

const useOfferRespond = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
        offerId,
    } = params;

    const {
        refresh: refreshOffer,
    } = useOfferGetById(offerId);

    const mutate = useMutationHook({
        mutationFunction:
            (dto: OfferRespondDto) => offerRespond(dto),
        refreshes: [refreshOffer],
        onSuccess,
        onError,
    });

    return mutate;
};

export default useOfferRespond;
