import {
    Badge,
    Divider,
    Flex,
    HStack,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import toTitleCase from '../functions/toTitleCase';

type PropTypes = {
    currentStep: string;
    steps: string[];
};

const ProgressTabs = (props: PropTypes) => {
    const { currentStep, steps } = props;

    const currentIndex = steps.findIndex(i => i === currentStep) || 0;

    const { t } = useTranslation('common');

    return (
        <Flex
            w="100%"
            justifyContent="space-between"
            paddingInline="1.5rem"
            bg="white"
        >
            {steps.map((step, index) => (
                <Flex
                    w="100%"
                    key={step}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <HStack
                        padding="1rem"
                        minWidth="fit-content"
                    >
                        <Badge
                            bgColor={index === currentIndex ? 'wvwGreen' : 'wvwGrey20'}
                            color={index === currentIndex ? 'white' : 'wvwGrey'}
                            paddingBlock=".2rem"
                            paddingInline=".5rem"
                            borderRadius="6"
                        >
                            {index + 1}
                        </Badge>

                        <Text
                            color={index === currentIndex ? 'wvwGreen' : 'wvwGrey60'}
                            display={['none', 'none', 'none', 'block']}
                        >
                            {t(`processStep.${step}`, { defaultValue: toTitleCase(step) })}
                        </Text>
                    </HStack>

                    <Divider orientation="horizontal" width="100%" borderWidth="1px" />
                </Flex>
            ))}
        </Flex>
    );
};

export default ProgressTabs;
