import { AxiosResponse } from 'axios';
import { notificationMarkAsRead } from '../endpoints';
import useMutationHook from './useMutationHook';
import useNotificationGetUnread from './useNotificationGetUnread';
import { NotificationType } from '../../../types';

type ParamsType = {
    refreshLimit?: number;
    onSuccess?: (response: AxiosResponse<NotificationType>) => void;
    onError?: (error?: Error) => void;
};

const useNotificationMarkAsRead = (params: ParamsType) => {
    const {
        refreshLimit = 1,
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshNotifications } = useNotificationGetUnread(refreshLimit);

    const mutate = useMutationHook({
        mutationFunction:
            (notificationId: number | string) => notificationMarkAsRead(notificationId),
        refreshes: [refreshNotifications],
        onSuccess,
        onError,
    });

    return mutate;
};

export default useNotificationMarkAsRead;
