import { Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { ContentBox } from '../../../../../common/components/display';
import { WvwText } from '../../../../../common/components/typography';
import { CaseContractType } from '../../../../../types';
import { useSmartTranslation } from '../../../../../common/hooks';
import { displayDateWithCurrentLocale } from '../../../../../common/functions/displayDateInLocale';
import WvwButtonAsReactLink from '../../../../../common/components/inputs/WvwButtonAsReactLink';
import WvwVStack from '../../../../../common/components/display/WvwVStack';
import StatusBadgeCaseContract from '../../../../../common/components/StatusBadgeCaseContract';
import AddContractModal from './AddContractModal';

type PropTypes = {
    caseId: number;
    contractList: CaseContractType[];
};

const ContractList = (props: PropTypes) => {
    const { contractList, caseId } = props;

    const t = useSmartTranslation('casecontract');

    const [addContractModalOpen, setAddContractModalOpen] = useState(false);

    const renderItem = (contract: CaseContractType) => {
        const {
            id: contractId,
            createdAt,
            signedContractReview,
        } = contract;

        const date = displayDateWithCurrentLocale(createdAt);

        return (
            <ContentBox key={contractId}>
                <Flex
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                >
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box paddingRight="5rem">
                            <WvwText>
                                {`${date}`}
                            </WvwText>
                        </Box>

                        <Box paddingRight="5rem">
                            <StatusBadgeCaseContract status={signedContractReview} />
                        </Box>
                    </Flex>

                    <WvwButtonAsReactLink
                        content={t('button.view')}
                        to={`view-contract/${contractId}`}
                    />
                </Flex>
            </ContentBox>
        );
    };

    return (
        <>
            <WvwVStack>
                {contractList.map(contract => renderItem(contract))}
            </WvwVStack>

            <AddContractModal
                isOpen={addContractModalOpen}
                caseId={caseId}
                onClose={() => setAddContractModalOpen(false)}
            />
        </>
    );
};

export default ContractList;
