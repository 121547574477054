import { HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    children: ReactNode | ReactNode[];
    w?: string;
    h?: string;
    justifyContent?: string;
    alignItems?: string;
};

const WvwHStack = (props: PropTypes) => {
    const {
        children,
        w,
        h,
        justifyContent,
        alignItems,
    } = props;

    return (
        <HStack w={w} h={h} justifyContent={justifyContent} alignItems={alignItems}>
            {children}
        </HStack>
    );
};

WvwHStack.defaultProps = {
    w: undefined,
    h: undefined,
    justifyContent: undefined,
    alignItems: undefined,
};

export default WvwHStack;
