import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormikRadioGroup, FormikConditionalSection, FormikTextarea } from '../../../../common/forms';
import { enquiryCommonValuerMemberships, enquiryOtherValuerMemberships } from '../../../../common/vars/valuationsAndMembershipTypes';
import { WvwText } from '../../../../common/components/typography';

const ValuationStandards = () => {
    const { t } = useTranslation(['enquiryform', 'formik', 'common']);

    return (
        <>
            <FormikRadioGroup
                name="valuationStandards"
                options={enquiryCommonValuerMemberships.map(membership => ({
                    label: t(membership, { ns: 'formik', defaultValue: membership }),
                    value: membership,
                }))}
            />

            <FormikConditionalSection
                formDataTarget="valuationStandards"
                condition={x => x.includes('More')}
            >
                <VStack
                    backgroundColor="wvwGrey05"
                    borderRadius="10"
                    align="left"
                    padding="1rem"
                    w="100%"
                >
                    <FormikRadioGroup
                        name="valuationStandardsExtra"
                        options={enquiryOtherValuerMemberships.map(membership => ({
                            label: t(membership, { ns: 'formik', defaultValue: membership }),
                            value: membership,
                        }))}
                    />

                    <FormikConditionalSection
                        formDataTarget="valuationStandardsExtra"
                        condition={x => x.includes('Other')}
                    >
                        <VStack
                            alignItems="start"
                            spacing="4"
                            w="100%"
                        >
                            <WvwText color="wvwGreen">
                                {t('newEnquiry.valuationSection.valuationStandards.other.instruction')}
                            </WvwText>

                            <FormikTextarea
                                name="valuationStandardsOther"
                            />
                        </VStack>
                    </FormikConditionalSection>
                </VStack>
            </FormikConditionalSection>
        </>
    );
};

export default ValuationStandards;
