import { useLocation } from 'react-router-dom';

type PropTypes = {
    steps: string[];
};

const getCurrentStep = (props: PropTypes) => {
    const { steps } = props;

    const path = useLocation().pathname.split('/');

    const currentStep = steps.filter(p => path.includes(p))[0];

    return currentStep;
};

export default getCurrentStep;
