import { Button, HStack, Icon, Input } from "@chakra-ui/react";
import { Form, Formik, useField } from "formik";
import { Send } from "iconsax-react";
import { useTranslation } from "react-i18next";

type PropTypes = {
    onSendMessage: (msg: string) => void;
};

const MessageInput = () => {
    const [field] = useField({
        name: "message",
        type: "text",
    });
    const { t } = useTranslation("messages");

    return (
        <Input
            {...field}
            placeholder={t("form.placeholder")}
            bg="white"
            h={42}
            borderRadius={50}
            pr={12}
            fontSize="xs"
        />
    );
};

const MessageForm = ({ onSendMessage }: PropTypes) => {
    return (
        <Formik
            initialValues={{
                message: "",
            }}
            onSubmit={(values, formikHelpers) => {
                onSendMessage(values.message);
                formikHelpers?.resetForm();
            }}
        >
            {({ isValid, dirty }) => (
                <Form style={{ width: "100%" }}>
                    <HStack w="100%" p={1} spacing={0} position="relative">
                        <MessageInput />
                        <HStack>
                            <Button
                                type="submit"
                                position="absolute"
                                right={3}
                                bg="transparent"
                                p={0}
                                borderRadius="50%"
                                zIndex={1}
                                isDisabled={!isValid || !dirty}
                            >
                                <Icon as={Send} color="wvwGreen" boxSize={6} />
                            </Button>
                        </HStack>
                    </HStack>
                </Form>
            )}
        </Formik>
    );
};

export default MessageForm;
