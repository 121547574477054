const STEPS = {
    VALUATION: 'VALUATION',
    PROPERTY_DETAILS: 'PROPERTY_DETAILS',
    CONTACT_DETAILS: 'CONTACT_DETAILS',
    OVERVIEW: 'OVERVIEW',
} as const;

export type DirectEnquiryStep = typeof STEPS[keyof typeof STEPS];

export const {
    VALUATION: DIRECT_ENQUIRY_STEPS_VALUATION,
    PROPERTY_DETAILS: DIRECT_ENQUIRY_STEPS_PROPERTY_DETAILS,
    CONTACT_DETAILS: DIRECT_ENQUIRY_STEPS_CONTACT,
    OVERVIEW: DIRECT_ENQUIRY_STEPS_OVERVIEW,
} = STEPS;

export const DIRECT_ENQUIRY_STEPS = [
    DIRECT_ENQUIRY_STEPS_VALUATION,
    DIRECT_ENQUIRY_STEPS_PROPERTY_DETAILS,
    DIRECT_ENQUIRY_STEPS_CONTACT,
    DIRECT_ENQUIRY_STEPS_OVERVIEW,
];
