import { OfferOnlyType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Offer } from "../../../../domain";
import { Section } from "../../../../layout";

type OffersViewProps = {
    offers: OfferOnlyType[];
};

const OffersView = (props: OffersViewProps) => {
    const { offers } = props;

    const t = useSmartTranslation();

    const activeOffers = offers.filter((o) => o.status === "PENDING");
    const historicOffers = offers.filter((o) =>
        ["REJECTED", "WITHDRAWN"].includes(o.status),
    );

    if (offers.length === 0) return (
        <Section
            title={`${t('clientEnquiryOverview.activeOffers.title')}: 0`}
            content={<Offer.NoOffersView />}
        />
    ); else return (
        <>
            <Section
                title={`${t('clientEnquiryOverview.activeOffers.title')}: 6`}
                subtitle={"from 5 companies"}
                content={<Offer.Table offers={activeOffers} />}
            />
            <Section
                title={"Offer history"}
                content={<Offer.Table offers={historicOffers} />}
            />
        </>
    );
};

export default OffersView;
