import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import RegisterPersonalForm from './account-details/RegisterPersonalForm';
// eslint-disable-next-line max-len
// import SelectOrganisationOrIndividual from './select-company-or-individual/SelectOrganisationOrIndividual';
import { RegisterDataType } from '../../types';
import ProcessFlowStateful from '../../common/components/display/ProcessFlowStateful';
import SelectValuerOrClient from './select-valuer-or-client/SelectValuerOrClient';
import InviteLandingPage from './invite-landing/InviteLandingPage';

type ParamsType = {
    inviteType?: 'invitedToClientPanel' | 'invitedToValuerPanel' | 'invitedToTeam';
    registerAsType?: 'client' | 'valuer';
    token?: string;
};

const initialRegisterData: RegisterDataType = {
    accountType: '',
    companyOrIndividual: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobile: '',
    orgName: '',
    orgPosition: '',
    street: '',
    city: '',
    country: '',
    postalCode: '',
    billingTier: 'FREE',
    billingCity: '',
    billingCountry: '',
    billingPostalCode: '',
    billingStreet: '',
};

const Register = () => {
    const { inviteType, registerAsType } = useParams<ParamsType>();

    const navigate = useNavigate();

    const onRegisterAsRoute = registerAsType !== undefined;

    const onInviteRoute = inviteType !== undefined;

    let accountType: '' | 'CLIENT' | 'VALUER' = '';

    if (onRegisterAsRoute) {
        accountType = registerAsType === 'client' ? 'CLIENT' : 'VALUER';
    }

    if (onInviteRoute) {
        accountType = inviteType === 'invitedToClientPanel' ? 'VALUER' : 'CLIENT';
    }

    const [registerData, setRegisterData] = useState<RegisterDataType>({
        ...initialRegisterData,
        accountType,
        companyOrIndividual: 'COMPANY',
    });

    const onContinue = (values?: { [index: string]: string }) => {
        setRegisterData({
            ...registerData,
            ...values,
        });
    };

    const inviteLanding = <InviteLandingPage />;
    const selectValuerOrClient = <SelectValuerOrClient onContinue={onContinue} />;

    // const selectOrganisationOrIndividual = (
    //     <SelectOrganisationOrIndividual
    //         onContinue={onContinue}
    //     />
    // );

    const registerPersonalForm = (
        <RegisterPersonalForm
            // onContinue={onContinue}
            registerData={registerData}
        />
    );

    const defaultRegisterFlow = [
        selectValuerOrClient,
        // selectOrganisationOrIndividual,
        registerPersonalForm,
    ];

    const invitedFlow = [
        inviteLanding,
        registerPersonalForm,
    ];

    const registerAsFlow = [
        registerPersonalForm,
    ];

    let steps = defaultRegisterFlow;

    if (onRegisterAsRoute) {
        steps = registerAsFlow;
    }

    if (onInviteRoute) {
        steps = invitedFlow;
    }

    return (
        <ProcessFlowStateful
            steps={steps}
            goBackFromStart={() => navigate('/')}
            onProcessEnd={() => navigate('/send-email-verification/%2Fregister-success')}
        />
    );
};

export default Register;
