import {
    Heading,
    VStack,
    Text,
    Flex,
    Divider,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Link,
    List,
    ListItem,
    Spacer,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useMe,
    usePublicUser,
    useSuspendAccount,
} from "../../../utils/api/hooks";
import LoadingSlider from "../LoadingSlider";
import { useRole } from "../../hooks";
import PublicProfileDetails from "./PublicProfileDetails";
import ConfirmationModal from "../ConfirmationModal";
import toTitleCase from "../../functions/toTitleCase";
import {
    publicUserSliderViewModes,
    PublicUserSliderViewModeType,
} from "./PublicUserSliderViewModeType";

type PropTypes = {
    isOpen: boolean;
    onClose: () => void;
    userId: number;
    viewMode: PublicUserSliderViewModeType;
};

const PublicUserSlider = (props: PropTypes) => {
    const { isOpen, onClose, userId, viewMode } = props;

    const inTeam = viewMode === publicUserSliderViewModes.TEAM;

    const { t } = useTranslation(["profile", "account", "common"]);

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const {
        data: user,
        isLoading: userLoading,
        userCertificates = [],
        userCertificatesIsLoading,
    } = usePublicUser(userId);

    const { data: me } = useMe();

    const { userIsCompanyAdmin } = useRole();

    const { update: suspendAccount } = useSuspendAccount({
        entityId: Number(me?.entityId),
        onSuccess: () => {
            setConfirmDeleteOpen(false);
            onClose();
        },
    });

    if (userLoading || userCertificatesIsLoading || !user || !me) {
        return (
            <LoadingSlider
                isOpen={isOpen}
                onClose={onClose}
                title={t("profileSectionLink.profile", { ns: "profile" })}
            />
        );
    }

    const { otherStandardsOffered = [] } = user;

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="lg"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader color="wvwGreen">
                    {t("details", { ns: "common" })}
                </DrawerHeader>

                <DrawerBody>
                    <VStack
                        w="100%"
                        maxW="container.xl"
                        align="left"
                        spacing="5"
                    >
                        <PublicProfileDetails
                            userId={userId}
                            viewMode={viewMode}
                        />

                        {user.entity.accountType === "VALUER" && (
                            <Box
                                bg="wvwGrey05"
                                borderRadius="10"
                                padding="1.5rem 2rem"
                                w="100%"
                            >
                                <Heading
                                    as="h4"
                                    color="wvwGrey"
                                    size="md"
                                >
                                    {t("heading.valuationStandards")}
                                </Heading>

                                <VStack paddingTop="1rem">
                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="32rem"
                                        >
                                            {t(
                                                "valuationCompetence.valuationStandards"
                                            )}
                                        </Text>

                                        <List w="100%">
                                            {user?.systemStandardsOffered
                                                ?.length === 0 && (
                                                <ListItem color="wvwGrey40">
                                                    {t("none", {
                                                        contect: "female",
                                                        ns: "common",
                                                    })}
                                                </ListItem>
                                            )}

                                            {user?.systemStandardsOffered
                                                ?.sort()
                                                ?.map((i) => {
                                                    const files =
                                                        userCertificates.filter(
                                                            (certificate) =>
                                                                certificate.type.toLowerCase() ===
                                                                i.toLowerCase()
                                                        );

                                                    return (
                                                        <ListItem key={i}>
                                                            <Flex
                                                                justifyContent="space-between"
                                                                w="100%"
                                                            >
                                                                <Text pr="1rem">
                                                                    {t(i, {
                                                                        ns: "formik",
                                                                        defaultValue:
                                                                            i,
                                                                    })}
                                                                </Text>

                                                                <List>
                                                                    {files.map(
                                                                        (
                                                                            file
                                                                        ) => (
                                                                            <ListItem
                                                                                key={
                                                                                    file
                                                                                        .file
                                                                                        .fullPath
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    href={
                                                                                        file.url
                                                                                    }
                                                                                    isExternal
                                                                                    target="_blank"
                                                                                    color="wvwGreen"
                                                                                >
                                                                                    <Text
                                                                                        maxW="7rem"
                                                                                        overflow="hidden"
                                                                                        textOverflow="ellipsis"
                                                                                        whiteSpace="nowrap"
                                                                                    >
                                                                                        {
                                                                                            file.label
                                                                                        }
                                                                                    </Text>
                                                                                </Link>
                                                                            </ListItem>
                                                                        )
                                                                    )}

                                                                    {files.length ===
                                                                        0 && (
                                                                        <ListItem color="wvwGrey40">
                                                                            {t(
                                                                                "membershipsAndQualifications.noCertificate"
                                                                            )}
                                                                        </ListItem>
                                                                    )}
                                                                </List>
                                                            </Flex>
                                                        </ListItem>
                                                    );
                                                })}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    {user.otherStandardsOffered && (
                                        <Flex
                                            alignItems="flex-start"
                                            minH="2rem"
                                            w="100%"
                                        >
                                            <Text
                                                color="wvwGreen"
                                                w="32rem"
                                            >
                                                {t(
                                                    "valuationCompetence.otherValuationStandards"
                                                )}
                                            </Text>

                                            <List w="100%">
                                                {otherStandardsOffered?.length ===
                                                    0 && (
                                                    <ListItem color="wvwGrey40">
                                                        {t("none", {
                                                            context: "female",
                                                            ns: "common",
                                                        })}
                                                    </ListItem>
                                                )}

                                                {otherStandardsOffered
                                                    ?.sort()
                                                    ?.map((i) => {
                                                        const files =
                                                            userCertificates.filter(
                                                                (c) =>
                                                                    c.type ===
                                                                    i.toLowerCase()
                                                            );

                                                        return (
                                                            <ListItem key={i}>
                                                                <Flex
                                                                    justifyContent="space-between"
                                                                    w="100%"
                                                                >
                                                                    <Text pr="1rem">
                                                                        {i}
                                                                    </Text>

                                                                    <List>
                                                                        {files.map(
                                                                            (
                                                                                file
                                                                            ) => (
                                                                                <ListItem
                                                                                    key={
                                                                                        file
                                                                                            .file
                                                                                            .fullPath
                                                                                    }
                                                                                >
                                                                                    <Link
                                                                                        href={
                                                                                            file.url
                                                                                        }
                                                                                        isExternal
                                                                                        target="_blank"
                                                                                        color="wvwGreen"
                                                                                    >
                                                                                        <Text
                                                                                            maxW="7rem"
                                                                                            overflow="hidden"
                                                                                            textOverflow="ellipsis"
                                                                                            whiteSpace="nowrap"
                                                                                        >
                                                                                            {
                                                                                                file.label
                                                                                            }
                                                                                        </Text>
                                                                                    </Link>
                                                                                </ListItem>
                                                                            )
                                                                        )}

                                                                        {files.length ===
                                                                            0 && (
                                                                            <ListItem color="wvwGrey40">
                                                                                {t(
                                                                                    "membershipsAndQualifications.noCertificate"
                                                                                )}
                                                                            </ListItem>
                                                                        )}
                                                                    </List>
                                                                </Flex>
                                                            </ListItem>
                                                        );
                                                    })}
                                            </List>
                                        </Flex>
                                    )}
                                </VStack>
                            </Box>
                        )}

                        {user?.entity.accountType === "VALUER" && (
                            <Box
                                bg="wvwGrey05"
                                borderRadius="10"
                                marginTop="1.5rem"
                                padding="1.5rem 2rem"
                                w="100%"
                            >
                                <Heading
                                    as="h4"
                                    color="wvwGrey"
                                    size="md"
                                >
                                    {t("valuationCompetence.sectionTitle")}
                                </Heading>

                                <VStack paddingTop="1rem">
                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t(
                                                "valuationCompetence.valuationScope"
                                            )}
                                        </Text>

                                        <List>
                                            {user?.valuationTypes?.length ===
                                                0 && (
                                                <ListItem color="wvwGrey40">
                                                    {t("none", {
                                                        context: "female",
                                                        ns: "common",
                                                    })}
                                                </ListItem>
                                            )}

                                            {user?.valuationTypes?.map(
                                                (valuation) => (
                                                    <ListItem key={valuation}>
                                                        {t(valuation, {
                                                            ns: "formik",
                                                            defaultValue:
                                                                valuation,
                                                        })}
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t(
                                                "valuationCompetence.specificValuationScopes"
                                            )}
                                        </Text>

                                        <List>
                                            {user?.specialValuationTypes
                                                ?.length === 0 && (
                                                <ListItem color="wvwGrey40">
                                                    {t("none", {
                                                        context: "female",
                                                        ns: "common",
                                                    })}
                                                </ListItem>
                                            )}

                                            {user?.specialValuationTypes?.map(
                                                (valuation) => (
                                                    <ListItem key={valuation}>
                                                        {t(valuation, {
                                                            ns: "formik",
                                                            defaultValue:
                                                                valuation,
                                                        })}
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t(
                                                "valuationCompetence.otherValuationScopes"
                                            )}
                                        </Text>

                                        <List>
                                            {user?.otherValuationTypes
                                                ?.length === 0 && (
                                                <ListItem color="wvwGrey40">
                                                    {t("none", {
                                                        context: "female",
                                                        ns: "common",
                                                    })}
                                                </ListItem>
                                            )}

                                            {user?.otherValuationTypes?.map(
                                                (valuation) => (
                                                    <ListItem key={valuation}>
                                                        {valuation}
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t(
                                                "valuationCompetence.valuationOccasions"
                                            )}
                                        </Text>

                                        <List>
                                            {user?.valuationOccasions
                                                ?.length === 0 &&
                                                user?.otherValuationOccasions
                                                    ?.length === 0 && (
                                                    <ListItem color="wvwGrey40">
                                                        {t("none", {
                                                            context: "female",
                                                            ns: "common",
                                                        })}
                                                    </ListItem>
                                                )}

                                            {user?.valuationOccasions?.map(
                                                (occasion) => (
                                                    <ListItem key={occasion}>
                                                        {t(occasion, {
                                                            ns: "formik",
                                                            defaultValue:
                                                                occasion,
                                                        })}
                                                    </ListItem>
                                                )
                                            )}

                                            {user?.otherValuationOccasions?.map(
                                                (occasion) => (
                                                    <ListItem key={occasion}>
                                                        {occasion}
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    </Flex>

                                    <Divider />

                                    <Flex
                                        alignItems="flex-start"
                                        minH="2rem"
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="17rem"
                                        >
                                            {t(
                                                "valuationCompetence.propertyTypes"
                                            )}
                                        </Text>

                                        <List>
                                            {user?.valuationPropertyTypes
                                                ?.length === 0 && (
                                                <ListItem color="wvwGrey40">
                                                    {t("none", {
                                                        context: "female",
                                                        ns: "common",
                                                    })}
                                                </ListItem>
                                            )}

                                            {user?.valuationPropertyTypes?.map(
                                                (propertyType) => (
                                                    <ListItem
                                                        key={propertyType}
                                                    >
                                                        {t(
                                                            toTitleCase(
                                                                propertyType
                                                            ),
                                                            {
                                                                ns: "formik",
                                                                defaultValue:
                                                                    toTitleCase(
                                                                        propertyType
                                                                    ),
                                                            }
                                                        )}
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    </Flex>
                                </VStack>
                            </Box>
                        )}
                    </VStack>
                </DrawerBody>

                <DrawerFooter>
                    <Flex w="100%">
                        {userIsCompanyAdmin && inTeam && (
                            <Button
                                variant="danger"
                                onClick={() => setConfirmDeleteOpen(true)}
                            >
                                {t("teamDetails.button.revokeAccess", {
                                    ns: "account",
                                })}
                            </Button>
                        )}

                        <Spacer />

                        <Button
                            variant="primary"
                            mr={3}
                            onClick={onClose}
                        >
                            {t("button.close", { ns: "common" })}
                        </Button>
                    </Flex>
                </DrawerFooter>
            </DrawerContent>

            <ConfirmationModal
                cancelButton={t("button.cancel", { ns: "common" })}
                continueButton={t("button.continue", { ns: "common" })}
                isOpen={confirmDeleteOpen}
                content={t("teamDetails.revokeAccess.text", { ns: "account" })}
                title={t("teamDetails.revokeAccess.title", { ns: "account" })}
                onCancel={() => setConfirmDeleteOpen(false)}
                onContinue={() => {
                    suspendAccount({
                        entityId: me.entityId,
                        targetUserId: user.id,
                    });
                }}
            />
        </Drawer>
    );
};

export default PublicUserSlider;
