import {
    Flex, Spacer, Switch, VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { WvwText } from '../components/typography';
import FormikConditionalSection from './FormikConditionalSection';

type PropTypes = {
    name: string;
    options: {
        value: string;
        label: string;
        description?: string;
        conditionalSection: React.ReactElement;
    }[];
};

const FormikConditionalFieldSwitch = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        options,
        // onChange,
    } = props;

    const [fieldValues, setFieldValues] = useState<string[]>([]);

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <>
            {options.map(option => (
                <VStack
                    key={option.value}
                    width="100%"
                    alignItems="flex-start"
                    bgColor="wvwGrey05"
                    borderRadius="8"
                >
                    <Flex
                        _hover={{ cursor: 'pointer' }}
                        id="switch-box"
                        width="100%"
                        padding="1rem"
                        alignItems="center"
                        onClick={() => {
                            const newFieldValues = fieldValues.includes(option.value)
                                ? fieldValues.filter(value => value !== option.value)
                                : [...fieldValues, option.value];

                            setFieldValues(newFieldValues);

                            field.onChange({
                                target: {
                                    name,
                                    value: newFieldValues,
                                },
                            });
                        }}
                    >
                        <VStack align="left" spacing="0">
                            <WvwText bold>
                                {option.label}
                            </WvwText>

                            <WvwText>
                                {option.description}
                            </WvwText>
                        </VStack>

                        <Spacer />

                        <Switch
                            id="switcher"
                            isChecked={fieldValues.includes(option.value)}
                            value={option.value}
                            onChange={e => {
                                const newFieldValues = e.target.checked
                                    ? fieldValues.filter(value => value !== e.target.value)
                                    : [...fieldValues, e.target.value];

                                setFieldValues(newFieldValues);

                                field.onChange({
                                    target: {
                                        name,
                                        value: newFieldValues,
                                    },
                                });
                            }}
                        />
                        {
                            meta.touched
                            && meta.error
                            && (
                                <WvwText
                                    color="red"
                                >
                                    {t(meta.error, { defaultValue: meta.error })}
                                </WvwText>
                            )
                        }
                    </Flex>

                    <FormikConditionalSection
                        formDataTarget={name}
                        condition={v => v?.includes(option.value)}
                    >
                        {option.conditionalSection}
                    </FormikConditionalSection>
                </VStack>
            ))}
        </>
    );
};

export default FormikConditionalFieldSwitch;
