import { Button, Flex, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import RequestListDisplay from "./RequestListDisplay";
import {
    FormikForm,
    FormikInput,
    FormikTextarea,
} from "../../../../../common/forms";
import {
    useDocumentRequestCreateDocumentRequest,
    useDocumentRequestDeleteRequest,
    useDocumentRequestGetUserRequestList,
    useDocumentRequestUpdateRequest,
} from "../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";

const validationSchema = Yup.object({
    name: Yup.string().required("errors.required"),
});

const RequestCreation = () => {
    const { caseId = "" } = useParams();
    const { t } = useTranslation(["documentmanagement"]);

    const { data: requestList, isLoading: requestListLoading } =
        useDocumentRequestGetUserRequestList(caseId);

    const { update: createRequest } = useDocumentRequestCreateDocumentRequest({
        caseId: Number(caseId),
    });

    const { update: deleteRequest } = useDocumentRequestDeleteRequest({
        caseId,
    });

    const { update: updateRequest } = useDocumentRequestUpdateRequest({
        caseId,
    });

    return (
        <VStack>
            <VStack
                bg="wvwGrey05"
                borderRadius="10"
                paddingBlock="1rem"
                paddingInline="1rem"
            >
                <Flex w="100%">
                    <Text paddingRight="2rem">
                        {t("actionArea.requestBlurb")}
                    </Text>
                </Flex>
            </VStack>

            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    name: "",
                    description: "",
                }}
                onSubmit={(values, formikValues) => {
                    createRequest({
                        caseId: Number(caseId),
                        name: values.name,
                        description: values.description,
                    });
                    formikValues?.resetForm();
                }}
            >
                <VStack
                    align="left"
                    paddingBlock=".5rem"
                    w="100%"
                >
                    <Text
                        color="wvwGreen"
                        fontWeight="bold"
                    >
                        {t("requestTitle", { ns: "documentmanagement" })}
                    </Text>

                    <FormikInput
                        name="name"
                        placeholder={t("requestTitle", {
                            ns: "documentmanagement",
                        })}
                    />

                    <Text
                        color="wvwGreen"
                        fontWeight="bold"
                    >
                        {t("instructionNotes", { ns: "documentmanagement" })}
                    </Text>

                    <FormikTextarea
                        name="description"
                        placeholder={t("instructionNotes", {
                            ns: "documentmanagement",
                        })}
                    />

                    <Button
                        alignSelf="flex-end"
                        type="submit"
                        variant="primaryYellow"
                    >
                        {t("button.save", { ns: "common" })}
                    </Button>
                </VStack>
            </FormikForm>

            {requestListLoading && <LoadingSpinner />}

            {!requestListLoading && (
                <RequestListDisplay
                    onDelete={(requestId) => deleteRequest(requestId)}
                    onEdit={(requestId, name, description) =>
                        updateRequest({
                            requestId,
                            name,
                            description,
                        })
                    }
                    requests={requestList || []}
                    requestType="REQUEST"
                />
            )}
        </VStack>
    );
};

export default RequestCreation;
