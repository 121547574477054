import { Flex, HStack } from '@chakra-ui/react';
import { ContentBox, StatusBadgeCaseReport } from '../../../../../common/components/display';
import { WvwText } from '../../../../../common/components/typography';
import { CaseReportType } from '../../../../../types';
import { useSmartTranslation } from '../../../../../common/hooks';
import { displayDateWithCurrentLocale } from '../../../../../common/functions/displayDateInLocale';
import WvwButtonAsReactLink from '../../../../../common/components/inputs/WvwButtonAsReactLink';
import WvwVStack from '../../../../../common/components/display/WvwVStack';

type PropTypes = {
    reportList: CaseReportType[];
};

const ReportList = (props: PropTypes) => {
    const { reportList } = props;

    const t = useSmartTranslation('common');

    const renderItem = (report: CaseReportType) => {
        const {
            id: reportId,
            createdAt,
            number,
            status,
        } = report;

        const date = displayDateWithCurrentLocale(createdAt);

        return (
            <ContentBox key={reportId}>
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <WvwText>
                        {`#${number} - ${date}`}
                    </WvwText>

                    <HStack spacing={6}>
                        <StatusBadgeCaseReport status={status} />

                        <WvwButtonAsReactLink
                            content={t('button.view')}
                            to={`view-report/${reportId}`}
                        />
                    </HStack>
                </Flex>
            </ContentBox>
        );
    };

    return (
        <WvwVStack>
            {reportList.map(report => renderItem(report))}
        </WvwVStack>
    );
};

export default ReportList;
