import {
    Button,
    CircularProgress,
    CircularProgressLabel,
    Flex,
    Heading,
    HStack,
    Spacer,
    VStack,
    Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@chakra-ui/icons";
import { EntityType, UserType } from "../../../../../types";
import { useRole } from "../../../../../common/hooks";

const calculateProgress = (profileData: any): number => {
    const numberOfInputs = profileData.length;

    if (numberOfInputs === 0) return 0;

    const percentagePerField = 100 / numberOfInputs;

    const progress = Math.round(
        profileData.reduce((acc: any, currentValue: any): number => {
            if (
                currentValue === null ||
                currentValue === undefined ||
                currentValue === ""
            ) {
                return acc - percentagePerField;
            }

            return acc;
        }, 100)
    );

    return Math.min(progress, 100);
};

type PropTypes = {
    progress: number;
};

const ProgressCircle = (props: PropTypes) => {
    const { progress } = props;
    let color = "";

    switch (true) {
        case progress < 20:
            color = "red.500";
            break;
        case progress < 40:
        case progress < 60:
            color = "orange.400";
            break;
        case progress < 80:
        case progress < 100:
            color = "green.400";
            break;
        default:
            color = "green.400";
            break;
    }
    return (
        <CircularProgress
            value={progress}
            color={color}
            size="5rem"
        >
            <CircularProgressLabel>{`${progress}%`}</CircularProgressLabel>
        </CircularProgress>
    );
};

type ProfileCompletionBannerProps = {
    entity: EntityType;
    user: UserType;
    userRoles: {
        userIsCompanyAdmin: boolean;
        userIsClient: boolean;
        userIsValuer: boolean;
    };
};

const ProfileCompletionBanner = (props: ProfileCompletionBannerProps) => {
    const { t } = useTranslation(["dashboard"]);
    const navigate = useNavigate();

    const { entity, user, userRoles } = props;

    const { userIsCompanyAdmin, userIsClient, userIsValuer } = userRoles;

    const userIsValuerAdmin = userIsValuer && userIsCompanyAdmin;

    const userIsValuerUser = userIsValuer && userIsCompanyAdmin;

    const userIsClientAdmin = userIsClient && userIsCompanyAdmin;

    const userIsClientUser = userIsClient && !userIsCompanyAdmin;

    const valuerDoesValuations = user.doesValuations;

    const companyData = [
        entity.name,
        entity.contactFirstName,
        entity.contactLastName,
        entity.contactNumberDialCode,
        entity.contactNumber,
        entity.street,
        entity.city,
        entity.postalCode,
        entity.country,
        entity.vatNumber,
        entity.websiteAddress,
        entity.companyEmail,
    ];

    const valuerCompanyData = [
        ...companyData,
        entity.valuerNumberOfPartners,
        entity.valuerYearsOfExperience,
        entity.piCoverValue,
        entity.piCoverValidityDate,
    ];

    const profileData = [
        user.firstName,
        user.lastName,
        user.email,
        user.orgPosition,
        user.mobileDialCode,
        user.mobile,
        user.street,
        user.city,
        user.country,
        user.postalCode,
        user.officeTelDialCode,
        user.officeTel,
        user.officeFaxDialCode,
        user.officeFax,
    ];

    const valuerData = [
        user.doesValuations,
        user.maxOperatingDistance,
        user.systemStandardsOffered,
        user.otherStandardsOffered,
        user.valuationTypes,
        user.specialValuationTypes,
        user.otherValuationTypes,
        user.valuationOccasions,
        user.otherValuationOccasions,
        user.valuationPropertyTypes,
    ];
    let progressData: any = [];

    switch (true) {
        case userIsClientAdmin:
            progressData = [...companyData, ...profileData];
            break;
        case userIsClientUser:
            progressData = [...profileData];
            break;
        case userIsValuerUser && valuerDoesValuations:
            progressData = [...profileData, ...valuerData];
            break;
        case userIsValuerUser && !valuerDoesValuations:
            progressData = [...profileData];
            break;
        case userIsValuerAdmin && !valuerDoesValuations:
            progressData = [...valuerCompanyData, ...profileData];
            break;
        default:
            progressData = [
                ...valuerCompanyData,
                ...profileData,
                ...valuerData,
            ];
            break;
    }

    const progress = calculateProgress(progressData);

    const benefits = [
        t("profileCompletion.benefitOne"),
        t("profileCompletion.benefitTwo"),
        t("profileCompletion.benefitThree"),
    ];

    if (progress !== 100) {
        return (
            <Flex
                w="100%"
                paddingInline="2.5rem"
                paddingBlock="1rem"
                backgroundColor="white"
                borderRadius="10"
                border="1px solid #e2e8f0"
                alignItems="center"
            >
                <HStack
                    spacing="6"
                    justifyItems="top"
                >
                    <ProgressCircle progress={progress} />

                    <VStack
                        align="left"
                        spacing="1"
                    >
                        <Heading size="md">
                            {t("profileCompletion.title")}
                        </Heading>

                        <Text variant="secondary">
                            {t("profileCompletion.subtitle")}
                        </Text>

                        {userIsValuer && (
                            <VStack
                                spacing="0"
                                align="left"
                            >
                                {benefits.map((benefit) => (
                                    <HStack key={benefit}>
                                        <CheckIcon color="green.400" />

                                        <Text>{benefit}</Text>
                                    </HStack>
                                ))}
                            </VStack>
                        )}
                    </VStack>
                </HStack>

                <Spacer />

                <Button
                    variant="primary"
                    onClick={() => navigate("/complete-profile")}
                >
                    {t("profileCompletion.button")}
                </Button>
            </Flex>
        );
    }
    return null;
};

export default ProfileCompletionBanner;
