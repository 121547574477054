import { Tooltip } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    label: string;
    children: ReactNode;
};

const WvwTooltip = (props: PropTypes) => {
    const { label, children } = props;

    return (
        <Tooltip
            label={label}
            hasArrow
        >
            {children}
        </Tooltip>
    );
};

export default WvwTooltip;
