export const translations = {
    de: {
        enterEmail:
            "Geben Sie unten Ihre E-Mail-Adresse ein und Sie erhalten einen Link, um Ihr Passwort zurückzusetzen.",
        forgotPassword: "Sie haben Ihr Passwort vergessen?",
        linkMessage:
            "Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts geschickt.",
        thankYou: "Vielen Dank!",
    },
    en: {
        enterEmail:
            "Enter your email address below and you will receive a link to reset your password.",
        forgotPassword: "Forgot your password?",
        linkMessage:
            "We have sent you an email with a link to reset your password.",
        thankYou: "Thank You!",
    },
};
