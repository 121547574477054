import {
    VStack, Box, Flex, Spacer,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WVWLabel, WvwText } from '../../../../../common/components/typography';
import { ContentBox } from '../../../../../common/components/display';
import { ContactPersonType, InspectionType } from '../../../../../types';
import { WvwIconButton } from '../../../../../common/components/inputs';
import InspectionAccessDetailsModal from './InspectionAccessDetailsModal';
import { useInspectionUpdateContactPerson } from '../../../../../utils/api/hooks';

type PropTypes = {
    contactPerson: ContactPersonType;
    inspection: InspectionType;
};

const InspectionAccessDetails = (props: PropTypes) => {
    const { t } = useTranslation(['inspection']);

    const { caseId: caseIdString } = useParams();

    const caseId = Number(caseIdString);

    const {
        contactPerson: {
            firstName,
            lastName,
            emailAddress,
            phoneDialcode,
            phoneNumber,
            note,
        },
        inspection: {
            id: inspectionId,
        },
    } = props;

    const [editAccessDetails, setEditAccessDetails] = useState(false);

    const { update: updateAccessDetails } = useInspectionUpdateContactPerson({
        caseId: Number(caseId),
        onSuccess: () => {
            setEditAccessDetails(false);
        },
    });

    return (
        <ContentBox>
            <VStack
                align="left"
                spacing="4"
                width="100%"
            >
                <Flex
                    width="100%"
                    alignItems="flex-start"
                >
                    <Box>
                        <WVWLabel content={t('label.fullName')} />

                        <WvwText>
                            {`${firstName} ${lastName}`}
                        </WvwText>
                    </Box>

                    <Spacer />

                    <WvwIconButton
                        ariaLabel="edit"
                        icon="edit"
                        onClick={() => setEditAccessDetails(true)}
                    />
                </Flex>

                <Box>
                    <WVWLabel content={t('label.email')} />

                    <WvwText>
                        {emailAddress}
                    </WvwText>
                </Box>

                <Box>
                    <WVWLabel content={t('label.mobile')} />

                    <WvwText>
                        {`${phoneDialcode} ${phoneNumber}`}
                    </WvwText>
                </Box>

                <Box>
                    <WVWLabel content={t('label.accessInstructions')} />

                    <WvwText>
                        {note}
                    </WvwText>
                </Box>
            </VStack>

            <InspectionAccessDetailsModal
                onClose={() => setEditAccessDetails(!editAccessDetails)}
                onConfirm={values => updateAccessDetails({
                    target: inspectionId,
                    contactPerson: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        emailAddress: values.emailAddress,
                        phoneDialcode: values.phoneDialcode,
                        phoneNumber: values.phoneNumber,
                        note: values.note,
                    },
                })}
                isOpen={editAccessDetails}
                caseId={caseId}
            />
        </ContentBox>
    );
};

export default InspectionAccessDetails;
