import { Box } from '@chakra-ui/react';
import { useRef } from 'react';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';
import { WVWTitle } from '../../../common/components/typography';
import { useRole, useSmartTranslation } from '../../../common/hooks';
import NewEnquiries from './components/NewEnquiries';
import ProfileCompletionBanner from './components/ProfileCompletionBanner';
import LatestOffers from './components/LatestOffers';
import CaseDashboardWidgets from './components/DashboardWidgets';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const Dashboard = () => {
    const t = useSmartTranslation('dashboard');

    const {
        userIsValuer,
        userIsCompanyAdmin,
        roleLoading,
    } = useRole();

    const latestOffersRef = useRef<HTMLDivElement>(null);

    const scrollToLatestOffers = () => {
        if (latestOffersRef.current) {
            latestOffersRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (roleLoading) return <LoadingSpinner />;

    const showBackToProfile = userIsValuer && !userIsCompanyAdmin;

    return (
        <>
            <ProfileCompletionBanner />

            <PageWithTitleLayout
                title={t('dashboard')}
                noBackToProfile={!showBackToProfile}
            >

                <CaseDashboardWidgets
                    scrollToRef={scrollToLatestOffers}
                    userIsValuer={userIsValuer}
                />

                <WVWTitle
                    content={t('section.latestEnquiries')}
                    level="2"
                    color="black"
                />

                <NewEnquiries />

                <WVWTitle
                    content={t('section.latestOffers')}
                    level="2"
                    color="black"
                />

                <Box ref={latestOffersRef}>
                    <LatestOffers />
                </Box>

            </PageWithTitleLayout>
        </>
    );
};

export default Dashboard;
