import {
    Input,
    InputGroup,
    InputRightElement,
    Button,
    Text,
    VStack,
    Flex,
} from "@chakra-ui/react";
import { FullMetadata, StorageReference } from "firebase/storage";
import { useState } from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import UploadCertificate from "../components/UploadCertificate";
import storage from "../../utils/storage/storage";
import { useMe } from "../../utils/api/hooks";
import WvwDeleteIconButton from "../components/WvwDeleteIconButton";
import LoadingSpinner from "../components/LoadingSpinner";

type PropTypes = {
    name: string;
    placeholder?: string;
    uploadedCertificates:
        | {
              type: string;
              file: StorageReference;
              label: string;
              meta: FullMetadata;
              url: string;
          }[]
        | undefined;
    onUploadComplete: () => void;
};

const FormikListInputCertificateUpload = (props: PropTypes) => {
    const { t } = useTranslation(["common", "formik"]);

    const {
        name,
        placeholder,
        uploadedCertificates = [],
        onUploadComplete,
    } = props;

    const { data: user, isLoading } = useMe();

    const [field, meta] = useField(props);

    const [inputValue, setInputValue] = useState("");

    const submitValues = () => {
        const inputList = [...field.value, inputValue];

        field.onChange({
            target: {
                name,
                value: inputList,
            },
        });

        setInputValue("");
    };

    if (!user || isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <VStack w="100%">
            {field.value.map((listItem: string) => {
                const files = uploadedCertificates
                    .filter((i) => {
                        const fileCategory = listItem.toLowerCase();

                        return i.type.toLowerCase() === fileCategory;
                    })
                    .map((i) => ({
                        name: i.label,
                        fileUrl: i.file.fullPath,
                    }));

                return (
                    <VStack
                        key={listItem}
                        w="100%"
                    >
                        <Flex
                            height="3rem"
                            width="100%"
                            border="1px solid"
                            borderColor="wvwGrey40"
                            borderRadius="8"
                            paddingLeft="1rem"
                            paddingRight="1rem"
                            justifyContent="space-between"
                            alignItems="center"
                            key={listItem}
                        >
                            <Text
                                color="wvwGreen"
                                fontWeight="bold"
                            >
                                {listItem}
                            </Text>

                            <WvwDeleteIconButton
                                onClick={() => {
                                    const newList = field.value.filter(
                                        (i: string) => i !== listItem
                                    );

                                    field.onChange({
                                        target: {
                                            name,
                                            value: newList,
                                        },
                                    });
                                }}
                                color="wvwGrey60"
                            />
                        </Flex>

                        <VStack
                            align="start"
                            backgroundColor="wvwGrey05"
                            borderRadius="8"
                            padding="1rem"
                            spacing="4"
                            w="100%"
                        >
                            <UploadCertificate
                                defaultFileName={
                                    files.length === 0
                                        ? `${listItem} Certificate`
                                        : undefined
                                }
                                files={files}
                                onFileDelete={(fileUrl) => {
                                    storage
                                        .deleteUserFile(fileUrl)
                                        .then(onUploadComplete);
                                }}
                                onRename={(fileUrl, label) => {
                                    storage
                                        .updateFileMetadata(fileUrl, label)
                                        .then(onUploadComplete);
                                }}
                                onUpload={(fileList) => {
                                    fileList.forEach((file) => {
                                        storage
                                            .uploadUserCertificate(
                                                user?.id,
                                                file,
                                                listItem
                                            )
                                            .then(onUploadComplete);
                                    });
                                }}
                            />
                        </VStack>
                    </VStack>
                );
            })}

            <InputGroup>
                <Input
                    bg="white"
                    name={name}
                    value={inputValue}
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();

                            submitValues();
                        }
                    }}
                />

                <InputRightElement width="fit-content">
                    <Button
                        borderLeftRadius="0"
                        type="button"
                        onClick={() => {
                            if (inputValue === "") return;
                            submitValues();
                        }}
                    >
                        {t("button.add")}
                    </Button>
                </InputRightElement>
            </InputGroup>

            {meta.touched && meta.error && (
                <Text color="red">
                    {t(meta.error, { defaultValue: meta.error })}
                </Text>
            )}
        </VStack>
    );
};

FormikListInputCertificateUpload.defaultProps = {
    placeholder: "",
};

export default FormikListInputCertificateUpload;
