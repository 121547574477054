import { useTranslation } from 'react-i18next';
import { HiPlus } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { CaseType, InspectionType } from '../../../../../types';
import { WvwButton } from '../../../../../common/components/inputs';
import InspectionLayout from './InspectionLayout';
import { useRole } from '../../../../../common/hooks';
import { WvwText } from '../../../../../common/components/typography';

type PropTypes = {
    caseData: CaseType;
    inspection: InspectionType;
};

const InspectionPreparation = (props: PropTypes) => {
    const { inspection, caseData } = props;

    const { t, i18n } = useTranslation('inspection');
    const navigate = useNavigate();
    const { userIsValuer } = useRole();

    const currentLanguage = i18n.language;

    const noValidDates = inspection.cause.calendarEntries.every(
        e => e.eventParticipations.some(
            p => p.userId === caseData.clientUserId && p.status === 'DECLINED',
        ),
    );

    return (
        <InspectionLayout
            color="wvwYellow10"
            key={currentLanguage}
            customHeading={(
                <>
                    {noValidDates && (
                        <WvwText>
                            {t('inspectionDetails.addDateBlurbOne')}
                        </WvwText>
                    )}

                    {userIsValuer && (
                        <WvwText>
                            {t('inspectionDetails.addDateBlurbTwo')}
                        </WvwText>
                    )}
                </>
            )}
            inspection={inspection}
            actionButton={userIsValuer && (
                <WvwButton
                    content={t('button.addDates')}
                    icon={<HiPlus />}
                    onClick={event => {
                        event.stopPropagation();
                        navigate(`select-dates/${inspection.id}`);
                    }}
                    size="sm"
                    variant="default"
                />
            )}
        />
    );
};

export default InspectionPreparation;
