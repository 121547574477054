const useMonthStartAndEnd = (monthOffset: number): { monthStartDate: Date; monthEndDate: Date } => {
    const monthStartDate = new Date(new Date().getFullYear(), new Date().getMonth()
        + monthOffset, 1);
    monthStartDate.setHours(0, 0, 0, 0);

    const monthEndDate = new Date(new Date().getFullYear(), new Date().getMonth()
        + monthOffset + 1, 0);
    monthEndDate.setHours(23, 59, 59, 999);

    return {
        monthStartDate,
        monthEndDate,
    };
};

export default useMonthStartAndEnd;
