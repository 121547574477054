import { documentRequestGetTemplates } from '../../endpoints';
import useQueryHook from '../useQueryHook';
import useRole from '../../../../common/hooks/useRole';

const DOCUMENT_REQUEST_GET_TEMPLATES = 'DOCUMENT_REQUEST_GET_TEMPLATES';

const useDocumentRequestGetTemplates = () => {
    const { userIsValuer } = useRole();

    const query = useQueryHook({
        uniqueName: DOCUMENT_REQUEST_GET_TEMPLATES,
        dependancies: [],
        requiredParams: [userIsValuer],
        queryFunction: () => documentRequestGetTemplates(),
    });

    return query;
};

export default useDocumentRequestGetTemplates;
