import { ChakraProvider } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import theme from "../../../theme";

export const ThemeV1Wrapper = () => {
    return (
        <ChakraProvider theme={theme}>
            <Outlet />
        </ChakraProvider>
    );
};
