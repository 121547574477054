import { EnquiryType, OfferOnlyType } from "../../../../../../types";
import { enquiryColumns } from "../../../../common/components/data-table/columns/EnquiryColumns";
import { DataTableColumnType } from "../../../../common/components/data-table/DataTable";

// types
type EnquiryTableDataType = EnquiryType & {
    offers: OfferOnlyType[];
};

type createEnquiriesTableDataProps = {
    enquiries: EnquiryType[];
    offers: OfferOnlyType[];
};

export const createEnquiriesTableData = (
    props: createEnquiriesTableDataProps
) => {
    const { enquiries, offers } = props;
    const enquiryTableData = enquiries.map((enquiry): EnquiryTableDataType => {
        const enquiryOffers = offers.filter(
            (offer) => offer.enquiryId === enquiry.id
        );

        const data = {
            ...enquiry,
            offers: enquiryOffers,
        };

        return data;
    });

    return enquiryTableData;
};

// columns
export const columns: DataTableColumnType<EnquiryTableDataType>[] = [
    enquiryColumns.enquiryIdWithProperties,
    enquiryColumns.activeOffers,
    enquiryColumns.bestDelivery,
    enquiryColumns.bestOffer,
    enquiryColumns.viewClientEnquiry,
];
