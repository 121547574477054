import {
    Box,
    createStandaloneToast,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';
import { useClientInvitesCreate } from '../../../../../utils/api/hooks';
import { FormikForm, FormikInput, FormikSubmitButton } from '../../../../../common/forms';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import { WvwText } from '../../../../../common/components/typography';

type PropTypes = {
    isOpen: boolean;
    onClose: () => void;
};

const validationSchema = Yup.object({
    clientCompanyName: Yup.string()
        .required('errors.required'),
    clientName: Yup.string()
        .required('errors.required'),
    clientEmail: Yup.string()
        .required('errors.required'),
});

const CustomerInviteForm = (props: PropTypes) => {
    const { isOpen, onClose } = props;

    const { t } = useTranslation(['account', 'common', 'formik', 'errors']);
    const { toast } = createStandaloneToast();

    const [invitationError, setInvitationError] = useState<string>();

    const cannotInviteIndividualClientInfo = invitationError && (
        <ConfirmationModal
            isOpen={invitationError !== undefined}
            content={t(invitationError, {
                ns: 'errors',
                defaultValue: t('error.generic', { ns: 'common' }),
            })}
            title={t('inviteAClient.title', { ns: 'errors' })}
            onContinue={() => {
                setInvitationError(undefined);
                onClose();
            }}
            hideCloseButton
            continueButton={t('button.ok', { ns: 'common' })}
        />
    );

    const { update } = useClientInvitesCreate({
        onSuccess: result => {
            if (result.data.error) {
                setInvitationError(result.data.error);
            } else {
                onClose();
            }
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message || t('error.somethingWrong', { ns: 'common' }),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    if (isOpen === false) return null;

    return (
        <Modal
            isOpen
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent minWidth="2xl">
                <VStack
                    align="left"
                    paddingBottom="1rem"
                    spacing={0}
                >
                    <ModalHeader
                        color="black"
                    >
                        {t('customerPanelDetails.inviteCustomer')}
                    </ModalHeader>

                    <Box
                        width="100%"
                        paddingLeft="1.5rem"
                    >
                        <WvwText color='wvwGrey60'>
                            {t('customerPanelDetails.customerExplainerText')}
                        </WvwText>
                    </Box>
                </VStack>


                <ModalCloseButton />
                <FormikForm
                    initialValues={{
                        clientCompanyName: '',
                        clientEmail: '',
                        clientName: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        update({
                            clientCompanyName: values.clientCompanyName,
                            clientEmail: values.clientEmail,
                            clientName: values.clientName,
                        });
                    }}
                >
                    <ModalBody>

                        <VStack
                            align="left"
                            spacing="2rem"
                        >
                            <Flex gap={2}>
                                <FormikInput
                                    placeholder={t('customerPanelDetails.name')}
                                    name="clientName"
                                    type="text"
                                    label={t('customerPanelDetails.name')}
                                />

                                <FormikInput
                                    placeholder={t('customerPanelDetails.email')}
                                    name="clientEmail"
                                    type="email"
                                    label={t('customerPanelDetails.email')}
                                />
                            </Flex>

                            <FormikInput
                                placeholder={t('customerPanelDetails.customerCompanyName')}
                                name="clientCompanyName"
                                type="text"
                                label={t('customerPanelDetails.customerCompanyName')}
                            />
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Box
                            width="15rem"
                            alignSelf="flex-end"
                        >
                            <FormikSubmitButton
                                block
                                content={t('button.add', { ns: 'common' })}
                            />
                        </Box>
                    </ModalFooter>
                </FormikForm>

            </ModalContent>

            {cannotInviteIndividualClientInfo}
        </Modal>
    );
};

export default CustomerInviteForm;
