import { entityMyValuerPanels } from '../endpoints';
import useQueryHook from './useQueryHook';
import { useRole } from '../../../common/hooks';

const ENTITY_MY_VALUER_PANELS = 'ENTITY_MY_VALUER_PANELS';

const useMyValuerPanels = () => {
    const { userIsValuer } = useRole();

    const query = useQueryHook({
        uniqueName: ENTITY_MY_VALUER_PANELS,
        queryFunction: () => entityMyValuerPanels(),
        dependancies: [],
        requiredParams: [userIsValuer],
    });

    return query;
};

export default useMyValuerPanels;
