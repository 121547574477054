import { Box, Center, Circle } from "@chakra-ui/react";
import {
    useEntityPublicEntityGetById,
    usePublicEntityProfilePicture,
    usePublicUserProfilePicture,
} from "../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import ProfilePicture from "../../../../common/components/display/ProfilePicture";
import ProfilePictureDefault from "../../../../common/components/display/ProfilePictureDefault";

type PropTypes = {
    entityId: number | string;
    userId: number | string;
};

const PublicUserProfilePictureWithEntityLogo = (props: PropTypes) => {
    const { entityId, userId } = props;

    const { data: entity, isLoading: entityIsLoading } =
        useEntityPublicEntityGetById(entityId);

    const { data: profilePictureUrl, isLoading: profilePictureUrlIsLoading } =
        usePublicUserProfilePicture(userId);

    const { data: entityLogoUrl, isLoading: entityLogoUrlIsLoading } =
        usePublicEntityProfilePicture(entityId);

    if (
        entityIsLoading ||
        profilePictureUrlIsLoading ||
        entityLogoUrlIsLoading
    ) {
        return (
            <Circle
                size="3rem"
                margin="0"
            >
                <Center
                    w="100%"
                    h="100%"
                >
                    <LoadingSpinner noText />
                </Center>
            </Circle>
        );
    }

    const renderProfilePic = () => {
        if (profilePictureUrl) {
            return (
                <ProfilePicture
                    url={profilePictureUrl}
                    size="3rem"
                />
            );
        }

        return (
            <ProfilePictureDefault
                accountType={
                    entity?.accountType === "VALUER" ? "VALUER" : "CLIENT"
                }
                userType="USER"
                size="3rem"
            />
        );
    };

    const renderEntityLogo = () => {
        if (entityLogoUrl) {
            return (
                <ProfilePicture
                    url={entityLogoUrl}
                    size="2rem"
                />
            );
        }
        return (
            <ProfilePictureDefault
                accountType={
                    entity?.accountType === "VALUER" ? "VALUER" : "CLIENT"
                }
                userType="ENTITY"
                size="2rem"
            />
        );
    };

    return (
        <Box>
            {renderProfilePic()}

            <Box
                mt="-1.5rem"
                ml="1.5rem"
            >
                {renderEntityLogo()}
            </Box>
        </Box>
    );
};

export default PublicUserProfilePictureWithEntityLogo;
