import { Outlet, Route, Routes } from "react-router-dom";
import Admin from "../../../../views/admin/Admin";
import AdminCaseReport from "../../../../views/admin/case-report/AdminCaseReport";
import AdminCaseReports from "../../../../views/admin/case-reports/AdminCaseReports";
import AdminCases from "../../../../views/admin/cases/AdminCases";
import AdminDocuments from "../../../../views/admin/documents/AdminDocuments";
import AdminEnquiries from "../../../../views/admin/enquiries/AdminEnquiries";
import AdminEnquiry from "../../../../views/admin/enquiry/AdminEnquiry";
import AdminEntities from "../../../../views/admin/entities/AdminEntities";
import AdminInspections from "../../../../views/admin/inspections/AdminInspections";
import AdminOffers from "../../../../views/admin/offers/AdminOffers";
import AdminUploadedContract from "../../../../views/admin/uploaded-contract/AdminUploadedContract";
import AdminUploadedContracts from "../../../../views/admin/uploaded-contracts/AdminUploadedContracts";
import AdminUsers from "../../../../views/admin/users/AdminUsers";
import AdminUser from "../../../../views/admin/users/user/AdminUser";
import Account from "../../../../views/dashboard/account/Account";
import CompanyCustomize from "../../../../views/dashboard/account/customize/CompanyCustomize";
import CompanyDocuments from "../../../../views/dashboard/account/documents/CompanyDocuments";
import EmailSharedDocs from "../../../../views/dashboard/account/documents/email/EmailSharedDocs";
import CompanyDetails from "../../../../views/dashboard/account/index/CompanyDetails";
import EnquiryEmailSlider from "../../../../views/dashboard/account/integration/enquiry-email-link/EnquiryEmailSlider";
import Integration from "../../../../views/dashboard/account/integration/Integration";
import CompanyTeam from "../../../../views/dashboard/account/team/CompanyTeam";
import ValuerPanel from "../../../../views/dashboard/account/valuer-panel/ValuerPanel";
import ProfileCompletionBilling from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionBilling";
import ProfileCompletionContactDetails from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionContactDetails";
import ProfileCompletionExtras from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionExtras";
import ProfileCompletionLanding from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionLanding";
import ProfileCompletionQualifications from "../../../../views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionQualifications";
import ProfileCompletionCompetencies from "../../../../views/dashboard/dashboard/profile-completion-wizard/ProfileCompletionCompetencies";
import ProfileCompletionWizard from "../../../../views/dashboard/dashboard/profile-completion-wizard/ProfileCompletionWizard";
import ProfileDetails from "../../../../views/dashboard/profile/components/ProfileDetails";
import ProfileTeam from "../../../../views/dashboard/profile/components/ProfileTeam";
import ProfileTemplates from "../../../../views/dashboard/profile/components/ProfileTemplates";
import Profile from "../../../../views/dashboard/profile/Profile";
import { ClientScreens } from "../../screens";
import { EnquiryOverviewScreen as ClientEnquiryOverviewScreen } from "../../screens/platform/client/enquiry-overview/Index";
import { EnquiryOverviewScreen as ValuerEnquiryOverviewScreen } from "../../screens/platform/valuer/enquiry-overview";
import Logout from "../../screens/platform/logout/Logout";
import { PlatformScreen } from "../../screens/platform/screen/PlatformScreen";
import RegisterSuccess from "../../screens/public/register/RegisterSuccess";
import { LayoutV1Wrapper } from "../LayoutV1Wrapper";
import { ThemeV1Wrapper } from "../ThemeV1Wrapper";
import { global_routes } from "./global";
import { User } from "../../domain/user/User";
import { ValuerScreens } from "../../screens/platform/valuer/ValuerScreens";

const { REACT_APP_FEATURE_ENABLE_DMS = "false" } = process.env;

// route fragments

const sysadmin = (
    <Route element={<ThemeV1Wrapper />}>
        <Route
            path="admin"
            element={<Admin />}
        >
            <Route
                index
                element={<AdminUsers />}
            />
            <Route
                path="cases"
                element={<AdminCases />}
            />
            <Route
                path="case-reports"
                element={<AdminCaseReports />}
            />
            <Route
                path="case-report/:reportId"
                element={<AdminCaseReport />}
            />
            <Route
                path="documents"
                element={<AdminDocuments />}
            />
            <Route
                path="enquiries"
                element={<AdminEnquiries />}
            />
            <Route
                path="enquiry/:enquiryId"
                element={<AdminEnquiry />}
            />
            <Route
                path="entities"
                element={<AdminEntities />}
            />
            <Route
                path="inspections"
                element={<AdminInspections />}
            />
            <Route
                path="offers"
                element={<AdminOffers />}
            />
            <Route
                path="uploaded-contract/:contractId"
                element={<AdminUploadedContract />}
            />
            <Route
                path="uploaded-contracts"
                element={<AdminUploadedContracts />}
            />
            <Route
                path="users"
                element={<AdminUsers />}
            />
            <Route
                path="users/:userId"
                element={<AdminUser />}
            />
        </Route>
    </Route>
);

const account = (
    <Route element={<LayoutV1Wrapper />}>
        <Route element={<ThemeV1Wrapper />}>
            <Route
                path="account"
                element={<Account />}
            >
                <Route
                    index
                    element={<CompanyDetails />}
                />

                <Route
                    path="*"
                    element={<CompanyDetails />}
                />
                <Route
                    path="team"
                    element={<CompanyTeam />}
                />
                <Route
                    path="valuer-panel"
                    element={<ValuerPanel />}
                />

                <Route
                    path="documents"
                    element={<CompanyDocuments />}
                >
                    <Route
                        path="email"
                        element={<EmailSharedDocs />}
                    />
                </Route>

                <Route
                    path="customize"
                    element={<CompanyCustomize />}
                />

                <Route
                    path="integration"
                    element={<Integration />}
                >
                    <Route
                        path="enquiry-email-link/:isCompanyOrIndividual"
                        element={<EnquiryEmailSlider />}
                    />
                </Route>
            </Route>
        </Route>
    </Route>
);

const profile = (
    <Route element={<LayoutV1Wrapper />}>
        <Route element={<ThemeV1Wrapper />}>
            <Route
                path="profile"
                element={<Profile />}
            >
                <Route
                    index
                    element={<ProfileDetails />}
                />

                <Route
                    path="*"
                    element={<ProfileDetails />}
                />
                <Route
                    path="team"
                    element={<ProfileTeam />}
                />

                {REACT_APP_FEATURE_ENABLE_DMS === "true" && (
                    <Route
                        path="templates"
                        element={<ProfileTemplates />}
                    />
                )}

                <Route
                    path="documents"
                    element={<CompanyDocuments />}
                >
                    <Route
                        path="email"
                        element={<EmailSharedDocs />}
                    />
                </Route>
            </Route>
        </Route>
    </Route>
);

const profileCompletion = (
    <Route element={<ThemeV1Wrapper />}>
        <Route
            path="complete-profile"
            element={<ProfileCompletionWizard />}
        >
            <Route
                index
                element={<ProfileCompletionLanding />}
            />

            <Route
                path="company"
                element={<ProfileCompletionBilling />}
            />

            <Route
                path="personal"
                element={<ProfileCompletionContactDetails />}
            />

            <Route
                path="qualifications"
                element={<ProfileCompletionQualifications />}
            />

            <Route
                path="valuationOffer"
                element={<ProfileCompletionCompetencies />}
            />

            <Route
                path="extras"
                element={<ProfileCompletionExtras />}
            />
        </Route>
    </Route>
);

const common_routes = {
    dashboard: (
        <>
            {profile}

            {account}
        </>
    ),
    root: (
        <>
            <Route
                path="/logout"
                element={<Logout />}
            />

            <Route element={<ThemeV1Wrapper />}>
                <Route
                    path="register-success"
                    element={<RegisterSuccess />}
                />
            </Route>

            <Route element={<ThemeV1Wrapper />}>
                <Route
                    path="login"
                    element={<div>user-login</div>}
                />
            </Route>

            {profileCompletion}

            {global_routes}

            {sysadmin}
        </>
    ),
};

// actual, user-type-speficic routes

const routes_client = (
    <Routes>
        <Route element={<PlatformScreen />}>
            <Route
                path="/"
                element={<ClientScreens.Dashboard />}
            />
            <Route
                path="dashboard"
                element={<Outlet />}
            >
                <Route
                    index
                    element={<ClientScreens.Dashboard />}
                />
                <Route
                    path="/dashboard/enquiries"
                    element={<ClientEnquiryOverviewScreen.EnquiryOverview />}
                />

                {common_routes.dashboard}
            </Route>
        </Route>

        {common_routes.root}
    </Routes>
);

const routes_valuer = (
    <Routes>
        <Route element={<PlatformScreen />}>
            <Route
                path="/"
                element={<ValuerScreens.Dashboard />}
            />
            <Route
                path="dashboard"
                element={<Outlet />}
            >
                <Route
                    index
                    element={<ValuerScreens.Dashboard />}
                />
                <Route
                    path="/dashboard/enquiry/:enquiryId"
                    element={<ValuerEnquiryOverviewScreen.EnquiryOverview />}
                />

                {common_routes.dashboard}
            </Route>
        </Route>

        {common_routes.root}
    </Routes>
);

export const routes_user = (user: User) => {
    const isClient = user.entity.accountType === "CLIENT";

    return isClient ? routes_client : routes_valuer;
};
