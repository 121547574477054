import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { ContentBox, WvwIcon } from '../../../../../common/components/display';
import { WvwText } from '../../../../../common/components/typography';
import { WvwButton } from '../../../../../common/components/inputs';
import { useRole, useSmartTranslation } from '../../../../../common/hooks';
import AddContractModal from './AddContractModal';

type PropTypes = {
    caseId: number;
};

const AddContractBanner = (props: PropTypes) => {
    const { caseId } = props;

    const { userIsValuer } = useRole();

    const t = useSmartTranslation('casecontract');

    const [addContractModalOpen, setAddContractModalOpen] = useState(false);

    return (
        <>
            <ContentBox color="wvwYellow10">
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <WvwText>
                        {userIsValuer ? t('details.addContractSigningDescription') : t('details.addContractReviewingDescription')}
                    </WvwText>

                    <WvwButton
                        icon={<WvwIcon icon="add" color="white" />}
                        content={t('button.addContract')}
                        size="sm"
                        onClick={() => setAddContractModalOpen(true)}
                    />
                </Flex>
            </ContentBox>

            <AddContractModal
                isOpen={addContractModalOpen}
                caseId={caseId}
                onClose={() => setAddContractModalOpen(false)}
            />
        </>
    );
};

export default AddContractBanner;
