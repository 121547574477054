/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import InfoWidget from './InfoWidget';
import useWidgetInformation from './useWidgetInformation';

const ActiveCasesWidget = () => {
    const { t } = useTranslation('dashboard');

    const {
        numberOfDataValues: numberOfCases,
        dataDifferencePercentage: caseDifferencePercentage,
        currentMonthCount,
        previousMonthCount,
    } = useWidgetInformation('case');

    const currentMonth = new Date().toLocaleString('default', { month: 'long' });

    const previousMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        .toLocaleString('default', { month: 'long' });

    return (
        <InfoWidget
            title={t('section.activeCases')}
            info={numberOfCases}
            infoPercentage={caseDifferencePercentage}
            buttonText={t('enquiriesTable.button.viewAll')}
            buttonLink="active-cases"
            tooltipPercentageLabel={`${currentMonth}: (${currentMonthCount}) / ${previousMonth}: (${previousMonthCount})`}
        />
    );
};

export default ActiveCasesWidget;
