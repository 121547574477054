import {
    CircularProgress,
    CircularProgressLabel,
    Flex,
    HStack,
    Spacer,
    VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@chakra-ui/icons";
import { WvwText, WVWTitle } from "../../../../common/components/typography";
import { WvwButton } from "../../../../common/components/inputs";
import { useMyEntity, useMe } from "../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useRole } from "../../../../common/hooks";

const calculateProgress = (profileData: any): number => {
    const numberOfInputs = profileData.length;

    if (numberOfInputs === 0) return 0;

    const percentagePerField = 100 / numberOfInputs;

    const progress = Math.round(
        profileData.reduce((acc: any, currentValue: any): number => {
            if (
                currentValue === null ||
                currentValue === undefined ||
                currentValue === ""
            ) {
                return acc - percentagePerField;
            }

            return acc;
        }, 100)
    );

    return Math.min(progress, 100);
};

type PropTypes = {
    progress: number;
};

const ProgressCircle = (props: PropTypes) => {
    const { progress } = props;
    let color = "";

    switch (true) {
        case progress < 20:
            color = "red";
            break;
        case progress < 40:
        case progress < 60:
            color = "wvwYellow";
            break;
        case progress < 80:
        case progress < 100:
            color = "green";
            break;
        default:
            color = "green";
            break;
    }
    return (
        <CircularProgress
            value={progress}
            color={color}
            size="5rem"
        >
            <CircularProgressLabel>{`${progress}%`}</CircularProgressLabel>
        </CircularProgress>
    );
};

const ProfileCompletionBanner = () => {
    const { t } = useTranslation(["dashboard"]);
    const navigate = useNavigate();

    const {
        data: entity,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
        entityCertificates = [],
        entityCertificatesIsLoading,
        isLoading: entityLoading,
    } = useMyEntity();

    const { data: user, isLoading: userLoading } = useMe();

    const { userIsValuer, userIsClient, userIsCompanyAdmin, roleLoading } =
        useRole();

    if (
        !user ||
        userLoading ||
        entityLoading ||
        entitySampleValuationsIsLoading ||
        entityCertificatesIsLoading ||
        roleLoading
    )
        return <LoadingSpinner />;

    const userIsValuerAdmin = userIsValuer && userIsCompanyAdmin;

    const userIsValuerUser = userIsValuer && !userIsCompanyAdmin;

    const userIsClientAdmin = userIsClient && userIsCompanyAdmin;

    const userIsClientUser = userIsClient && !userIsCompanyAdmin;

    const valuerDoesValuations = user.doesValuations;

    const awardsAndMarketing = entityCertificates.filter(
        (i) =>
            i.type.toLowerCase() === "award" &&
            i.type.toLowerCase() === "marketing"
    );

    const companyData = [
        entity.name,
        entity.contactFirstName,
        entity.contactLastName,
        entity.contactNumberDialCode,
        entity.contactNumber,
        entity.street,
        entity.city,
        entity.postalCode,
        entity.country,
        entity.vatNumber,
        entity.websiteAddress,
        entity.companyEmail,
    ];

    const valuerCompanyData = [
        ...companyData,
        entity.valuerNumberOfPartners,
        entity.valuerYearsOfExperience,
        entity.piCoverValue,
        entity.piCoverValidityDate,
    ];

    const profileData = [
        user.firstName,
        user.lastName,
        user.email,
        user.orgPosition,
        user.mobileDialCode,
        user.mobile,
        user.street,
        user.city,
        user.country,
        user.postalCode,
        user.officeTelDialCode,
        user.officeTel,
        user.officeFaxDialCode,
        user.officeFax,
    ];

    const valuerData = [
        user.doesValuations,
        user.maxOperatingDistance,
        user.systemStandardsOffered,
        user.otherStandardsOffered,
        user.valuationTypes,
        user.specialValuationTypes,
        user.otherValuationTypes,
        user.valuationOccasions,
        user.otherValuationOccasions,
        user.valuationPropertyTypes,
    ];

    const extraData = [entitySampleValuations, awardsAndMarketing];

    let progressData: any = [];

    switch (true) {
        case userIsClientAdmin:
            progressData = [...companyData, ...profileData];
            break;
        case userIsClientUser:
            progressData = [...profileData];
            break;
        case userIsValuerUser && valuerDoesValuations:
            progressData = [...profileData, ...valuerData];
            break;
        case userIsValuerUser && !valuerDoesValuations:
            progressData = [...profileData];
            break;
        case userIsValuerAdmin && !valuerDoesValuations:
            progressData = [...valuerCompanyData, ...profileData, ...extraData];
            break;
        default:
            progressData = [
                ...valuerCompanyData,
                ...profileData,
                ...valuerData,
                ...extraData,
            ];
            break;
    }

    const progress = calculateProgress(progressData);

    const benefits = [
        t("profileCompletion.benefitOne"),
        t("profileCompletion.benefitTwo"),
        t("profileCompletion.benefitThree"),
    ];

    if (progress !== 100) {
        return (
            <Flex
                paddingInline="2.5rem"
                paddingBlock="1rem"
                backgroundColor="white"
                borderRadius="10"
                border="1px solid #e2e8f0"
                alignItems="center"
                marginTop="1rem"
                marginBottom="1rem"
            >
                <HStack
                    spacing="6"
                    justifyItems="top"
                >
                    <ProgressCircle progress={progress} />

                    <VStack
                        align="left"
                        spacing="1"
                    >
                        <WVWTitle
                            content={t("profileCompletion.title")}
                            level="2"
                            color="black"
                        />

                        <WvwText color="wvwGrey60">
                            {t("profileCompletion.subtitle")}
                        </WvwText>

                        {userIsValuer && (
                            <VStack
                                spacing="0"
                                align="left"
                            >
                                {benefits.map((benefit) => (
                                    <HStack key={benefit}>
                                        <CheckIcon color="green" />

                                        <WvwText>{benefit}</WvwText>
                                    </HStack>
                                ))}
                            </VStack>
                        )}
                    </VStack>
                </HStack>

                <Spacer />

                <WvwButton
                    content={t("profileCompletion.button")}
                    variant="primary"
                    onClick={() => navigate("/complete-profile")}
                />
            </Flex>
        );
    }
    return null;
};

export default ProfileCompletionBanner;
