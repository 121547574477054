import { VStack } from "@chakra-ui/react";
import { WvwText, WVWTitle } from "../../../../common/components/typography";
import { FormikSelect } from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";
import { standardValuerMaxOperatingDistance } from "../../../../common/vars/valuationsAndMembershipTypes";

const ProfileOperationalAreaEdit = () => {
    const t = useSmartTranslation("profile");

    return (
        <VStack
            width="100%"
            align="left"
        >
            <WVWTitle
                level="2"
                color="black"
                content={t("heading.operationalArea")}
            />

            <WvwText>{t("subHeading.operationalArea")}</WvwText>

            <FormikSelect
                name="maxOperatingDistance"
                placeholder={t("profileDetails.valuerMaxOperationDistance")}
                options={standardValuerMaxOperatingDistance.map((distance) => ({
                    label: t(distance.label, {
                        ns: "formik",
                        defaultValue: distance.label,
                    }),
                    value: distance.value,
                }))}
            />
        </VStack>
    );
};

export default ProfileOperationalAreaEdit;
