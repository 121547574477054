/* eslint-disable react/no-this-in-sfc */
import {
    ButtonGroup,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import * as Yup from "yup";
import { useMe, useUpdateEmail } from "../../../../utils/api/hooks";
import {
    FormikConditionalSection,
    FormikForm,
    FormikSubmitButton,
} from "../../../../common/forms";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import PasswordConfirmModal from "../../../../common/components/PasswordConfirmModal";
import ProfileContactDetailsEdit from "./ProfileContactDetailsEdit";
import ProfileOfficeDetailsEdit from "./ProfileOfficeDetailsEdit";
import { useRole, useSmartTranslation } from "../../../../common/hooks";
import { WvwButton } from "../../../../common/components/inputs";
import {
    standardValuationTypes,
    standardSystemStandardsOffered,
} from "../../../../common/vars/valuationsAndMembershipTypes";
import ProfileValuationStandardsEdit from "./ProfileValuationStandardsEdit";
import ProfileValuationTypesEdit from "./ProfileValuationTypesEdit";
import ProfileValuationOccasionsEdit from "./ProfileValuationOccasionsEdit";
import ProfilePropertyTypesEdit from "./ProfilePropertyTypesEdit";
import Logo from "../../../../common/components/Logo";
import { WVWTitle } from "../../../../common/components/typography";
import { wvwPalette } from "../../../../theme";
import ProfileOtherDetailsEdit from "./ProfileOtherDetailsEdit";

type PropTypes = {
    openEditor: () => void;
};

const ProfileDetailsEdit = (props: PropTypes) => {
    const { openEditor } = props;

    const t = useSmartTranslation("common");

    const { userIsClient, userIsValuer, userIsCompanyAdmin, roleLoading } =
        useRole();

    const {
        data: user,
        isLoading,
        updateMe,
    } = useMe({
        onUpdateSuccess: () => {
            openEditor();
        },
    });

    const [userDoesValuations, setUserDoesValuations] = useState(
        user?.doesValuations
    );

    const clientValidationFields = {
        firstName: Yup.string().required("errors.required"),
        lastName: Yup.string().required("errors.required"),
        email: Yup.string()
            .email("errors.invalidEmail")
            .required("errors.required"),
        mobileDialCode: Yup.string().required("errors.required"),
        mobile: Yup.number()
            .required("errors.required")
            .typeError("errors.enterNumber"),
        telDialCode: Yup.string().test(
            "tel",
            "errors.required",
            function (value) {
                if (value === undefined) {
                    if (this.parent.tel === undefined) return true;

                    return false;
                }

                return true;
            }
        ),
        tel: Yup.number()
            .typeError("errors.enterNumber")
            .test("tel", "errors.required", function (value) {
                if (value === undefined) {
                    if (this.parent.telDialCode === undefined) return true;

                    return false;
                }

                return true;
            }),
        faxDialCode: Yup.string().test(
            "fax",
            "errors.required",
            function (value) {
                if (value === undefined) {
                    if (this.parent.fax === undefined) return true;

                    return false;
                }

                return true;
            }
        ),
        fax: Yup.number()
            .typeError("errors.enterNumber")
            .test("fax", "errors.required", function (value) {
                if (value === undefined) {
                    if (this.parent.faxDialCode === undefined) return true;

                    return false;
                }

                return true;
            }),
        officeTelDialCode: Yup.string().test(
            "officeTel",
            "errors.required",
            function (value) {
                if (value === undefined) {
                    if (this.parent.officeTel === undefined) return true;

                    return false;
                }

                return true;
            }
        ),
        officeTel: Yup.number()
            .typeError("errors.enterNumber")
            .test("officeTel", "errors.required", function (value) {
                if (value === undefined) {
                    if (this.parent.officeTelDialCode === undefined)
                        return true;

                    return false;
                }

                return true;
            }),
        officeFaxDialCode: Yup.string().test(
            "officeFax",
            "errors.required",
            function (value) {
                if (value === undefined) {
                    if (this.parent.officeFax === undefined) return true;

                    return false;
                }

                return true;
            }
        ),
        officeFax: Yup.number()
            .typeError("errors.enterNumber")
            .test("officeFax", "errors.required", function (value) {
                if (value === undefined) {
                    if (this.parent.officeFaxDialCode === undefined)
                        return true;

                    return false;
                }

                return true;
            }),
        street: Yup.string().required("errors.required"),
        city: Yup.string().required("errors.required"),
        country: Yup.string().required("errors.required"),
        postalCode: Yup.string().required("errors.required"),
    };

    const valuerValidationFields = {
        firstName: clientValidationFields.firstName,
        lastName: clientValidationFields.lastName,
        email: clientValidationFields.email,
        mobileDialCode: clientValidationFields.mobileDialCode,
        mobile: clientValidationFields.mobile,
        telDialCode: clientValidationFields.telDialCode,
        tel: clientValidationFields.tel,
        faxDialCode: clientValidationFields.faxDialCode,
        fax: clientValidationFields.fax,
        officeTelDialCode: clientValidationFields.officeTelDialCode,
        officeTel: clientValidationFields.officeTel,
        officeFaxDialCode: clientValidationFields.officeFaxDialCode,
        officeFax: clientValidationFields.officeFax,
        valuationTypes: Yup.array().min(1, "errors.required"),
        latitude: Yup.number().required("errors.required"),
        longitude: Yup.number().required("errors.required"),
    };

    if (!userDoesValuations) {
        valuerValidationFields.valuationTypes = Yup.array();
    }

    const validationSchema = Yup.object(
        userIsClient ? clientValidationFields : valuerValidationFields
    );

    const [formDataForEmailUpdate, setFormDataForEmailUpdate] =
        useState<any>(undefined);

    const updateUser = (values: any) => {
        const {
            maxOperatingDistance: formMaxOperatingDistance,
            systemStandardsOffered: formSystemStandardsOffered,
            otherStandardsOffered: formOtherStandardsOffered,
            valuationTypes: formValuationTypes,
            valuationOccasions,
            otherValuationOccasions: formOtherValuationOccasions,
            ...rest
        } = values;

        const maxOperatingDistance = parseInt(formMaxOperatingDistance, 10);

        const systemStandardsOffered = formSystemStandardsOffered.filter(
            (i: string) => standardSystemStandardsOffered.includes(i)
        );

        const otherStandardsOffered = formSystemStandardsOffered.includes(
            "Other"
        )
            ? formOtherStandardsOffered
            : [];

        const valuationTypes = formValuationTypes.filter((i: string) =>
            standardValuationTypes.includes(i)
        );

        const otherValuationOccasions = valuationOccasions.includes("Other")
            ? formOtherValuationOccasions
            : [];

        updateMe({
            ...rest,
            maxOperatingDistance,
            systemStandardsOffered,
            otherStandardsOffered,
            valuationTypes,
            valuationOccasions,
            doesValuations: values.doesValuations === "true",
            otherValuationOccasions,
        });
    };

    const { update: updateEmail } = useUpdateEmail({
        onSuccess: () => {
            updateUser(formDataForEmailUpdate);
            setFormDataForEmailUpdate(undefined);
        },
    });

    if (!user || isLoading || roleLoading) {
        return <LoadingSpinner />;
    }

    const initalFormData = {
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        orgPosition: user.orgPosition || "",
        email: user.email || "",
        mobileDialCode: user.mobileDialCode || "",
        mobile: user.mobile || "",
        telDialCode: user.telDialCode || "",
        tel: user.tel || "",
        faxDialCode: user.faxDialCode || "",
        fax: user.fax || "",
        officeTelDialCode: user.officeTelDialCode || "",
        officeTel: user.officeTel || "",
        officeFaxDialCode: user.officeFaxDialCode || "",
        officeFax: user.officeFax || "",
        street: user.street || "",
        postalCode: user.postalCode || "",
        city: user.city || "",
        country: user.country || "",
        latitude: user.latitude || "",
        longitude: user.longitude || "",
        doesValuations: user.doesValuations === true ? "true" : "false",
        maxOperatingDistance: user.maxOperatingDistance || "",
        systemStandardsOffered: (user.systemStandardsOffered || []).filter(
            (i) => standardSystemStandardsOffered.includes(i)
        ),
        otherStandardsOffered: user.otherStandardsOffered || [],
        valuationTypes: user.valuationTypes || [],
        specialValuationTypes: user.specialValuationTypes || [],
        otherValuationTypes: user.otherValuationTypes || [],
        valuationOccasions: user.valuationOccasions || [],
        otherValuationOccasions: user.otherValuationOccasions || [],
        valuationPropertyTypes: user.valuationPropertyTypes || [],
    };

    if (initalFormData.otherStandardsOffered.length > 0) {
        initalFormData.systemStandardsOffered.push("Other");
    }

    const renderValuerOnlySections = () => {
        if (userIsClient) return null;

        return (
            <>
                <ProfileOtherDetailsEdit
                    updateDoesValuations={(value) =>
                        setUserDoesValuations(value === "true")
                    }
                />

                <FormikConditionalSection
                    formDataTarget="doesValuations"
                    condition={(value) => value === "true"}
                >
                    <>
                        <ProfileValuationStandardsEdit />

                        <ProfileValuationTypesEdit />

                        <ProfileValuationOccasionsEdit />

                        <ProfilePropertyTypesEdit />
                    </>
                </FormikConditionalSection>
            </>
        );
    };

    return (
        <Modal
            isOpen
            size="full"
            closeOnOverlayClick={false}
            onClose={openEditor}
        >
            <ModalOverlay />

            <ModalContent>
                <FormikForm
                    validationSchema={validationSchema}
                    initialValues={initalFormData}
                    onSubmit={(values) => {
                        if (values.email !== user?.email) {
                            setFormDataForEmailUpdate(values);
                        } else {
                            updateUser(values);
                        }
                    }}
                >
                    <ModalHeader bgColor="white">
                        <HStack spacing="0">
                            <Logo
                                size="5rem"
                                withColor
                            />

                            <WVWTitle
                                color="black"
                                level="1"
                                content={t("edit", { ns: "profile" })}
                            />
                        </HStack>
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody bgColor={wvwPalette.wvwBackground.main}>
                        <VStack
                            spacing="6"
                            maxW="1700px"
                            minW="400px"
                            marginX="auto"
                        >
                            <ProfileContactDetailsEdit />

                            <ProfileOfficeDetailsEdit
                                userIsValuer={userIsValuer}
                                userIsCompanyAdmin={userIsCompanyAdmin}
                                streetName="street"
                                postalCodeName="postalCode"
                                cityName="city"
                                countryName="country"
                                latName="latitude"
                                lngName="longitude"
                                officeTelDialCodeName="officeTelDialCode"
                                officeTelName="officeTel"
                                officeFaxDialCodeName="officeFaxDialCode"
                                officeFaxName="officeFax"
                            />

                            {renderValuerOnlySections()}

                            <ButtonGroup
                                alignSelf="flex-end"
                                width="19rem"
                            >
                                <WvwButton
                                    onClick={openEditor}
                                    content={t("button.cancel")}
                                    variant="outline"
                                />

                                <FormikSubmitButton
                                    content={t("button.save")}
                                    block
                                />
                            </ButtonGroup>
                        </VStack>
                    </ModalBody>
                </FormikForm>

                <PasswordConfirmModal
                    isOpen={!!formDataForEmailUpdate}
                    onCancel={() => setFormDataForEmailUpdate(undefined)}
                    onContinue={() => updateEmail(formDataForEmailUpdate.email)}
                />
            </ModalContent>
        </Modal>
    );
};

export default ProfileDetailsEdit;
