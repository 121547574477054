import { CaseSubmitReportDto } from '../../dto/case-report';
import { caseSubmitReport } from '../../endpoints';
import useMutationHook from '../useMutationHook';
import useCaseGetCaseReports from './useCaseReportGetReports';

type ParamTypes = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: () => void;
};

const useCaseSubmitReport = (params: ParamTypes) => {
    const { caseId, onSuccess, onError } = params;

    const { refresh: refreshReportList } = useCaseGetCaseReports(caseId);

    const mutation = useMutationHook({
        mutationFunction: (dto: CaseSubmitReportDto) => caseSubmitReport(dto),
        refreshes: [refreshReportList],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useCaseSubmitReport;
