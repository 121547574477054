import {
    useCaseActiveCases,
    useEnquiryGetAll,
} from "../../../../../utils/api/hooks";
import widgetInformationDateFilter from "./widgetInformationDateFilter";
import useMonthStartAndEnd from "../../../../../common/hooks/useMonthStartAndEnd";

const useWidgetInformation = (dataToUse: "enquiry" | "case") => {
    const { data: allEnquiries = [] } = useEnquiryGetAll();
    const { data: allActiveCases = [] } = useCaseActiveCases();

    const enquiryList = allEnquiries.map((enquiry) => ({
        createdAt: enquiry.createdAt,
    }));

    const activeCasesList = allActiveCases.map((activeCase) => ({
        createdAt: activeCase.createdAt,
    }));

    const previousMonthTimeFrame = useMonthStartAndEnd(-1);
    const currentMonthTimeFrame = useMonthStartAndEnd(0);

    const numberOfDataValues =
        dataToUse === "enquiry" ? enquiryList.length : activeCasesList.length;

    const { dataDifferencePercentage, currentMonthCount, previousMonthCount } =
        widgetInformationDateFilter(
            currentMonthTimeFrame,
            previousMonthTimeFrame,
            dataToUse === "enquiry" ? enquiryList : activeCasesList
        );

    return {
        numberOfDataValues,
        dataDifferencePercentage,
        currentMonthCount,
        previousMonthCount,
    };
};

export default useWidgetInformation;
