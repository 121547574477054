import { Flex, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../../common/components/datatable/DataTable';
import { EnquiryType, EntityType } from '../../../../types';
import { WvwButton } from '../../../../common/components/inputs';
import AddManualMatchModal from './AddManualMatchModal';
import { useAdminUsers } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

type PropTypes = {
    enquiry: EnquiryType;
    matchingValuers: EntityType[];
};

type UserTableProps = {
    entity: EntityType;
    matchingUsers: number[];
};

const UserTable = (props: UserTableProps) => {
    const { matchingUsers, entity } = props;

    const { data: users = [], isLoading } = useAdminUsers({ entityIds: [entity.id] });

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <DataTable
            columns={[
                {
                    title: '#',
                    dataIndex: 'id',
                },
                {
                    title: 'Name',
                    render: i => `${i.firstName} ${i.lastName}`,
                },
                {
                    title: 'Role',
                    dataIndex: 'role',
                },
                {
                    title: 'Matched',
                    render: i => {
                        const matched = matchingUsers.includes(i.id);

                        return matched ? 'Yes' : 'No';
                    },
                },
            ]}
            data={users}
        />
    );
};

const AdminMatchingValuers = (props: PropTypes) => {
    const { enquiry, matchingValuers } = props;

    const { t } = useTranslation('admin');

    const [addManualMatchModalOpen, setAddManualMatchModalOpen] = useState(false);

    const headers = [
        {
            title: '#',
            sorter: (a: EntityType, b: EntityType) => a.id - b.id,
            render: (i: EntityType) => i.id,
        },
        {
            title: t('tableHeading.companyName'),
            sorter: (a: EntityType, b: EntityType) => a.name.localeCompare(b.name),
            render: (i: EntityType) => i.name,
        },
        {
            title: t('tableHeading.createdAt'),
            sorter: (a: EntityType, b: EntityType) => a.createdAt.localeCompare(b.createdAt),
            render: (i: EntityType) => new Date(i.createdAt).toLocaleDateString(),
        },
    ];

    return (
        <>
            <VStack
                align="start"
                spacing="3"
                w="100%"
            >
                <Flex
                    flexDirection="row-reverse"
                    w="100%"
                >
                    <WvwButton
                        content={t('button.addManualMatch')}
                        onClick={() => setAddManualMatchModalOpen(true)}
                        variant="primary"
                    />
                </Flex>

                <DataTable
                    columns={headers}
                    data={matchingValuers}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    expandedContent={rowEntity => (
                        <UserTable
                            entity={rowEntity}
                            matchingUsers={enquiry.toUserIds}
                        />
                    )}
                />
            </VStack>

            <AddManualMatchModal
                enquiry={enquiry}
                isOpen={addManualMatchModalOpen}
                onClose={() => setAddManualMatchModalOpen(false)}
            />
        </>
    );
};

export default AdminMatchingValuers;
