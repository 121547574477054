import { CaseType } from "../../../../../../../types";
import { caseColumns } from "../../../../../common/components/data-table/columns/CaseColumns";
import { DataTableColumnType } from "../../../../../common/components/data-table/DataTable";

// columns
export const columns: DataTableColumnType<CaseType>[] = [
    caseColumns.caseIdWithProperties,
    caseColumns.caseClient,
    caseColumns.valuationType,
    caseColumns.offerPiCover,
    caseColumns.expectedDelivery,
    caseColumns.estPortfolioValue,
    caseColumns.viewCaseButton,
];
