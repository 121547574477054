import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Button,
    Heading,
    VStack,
    Text,
    Circle,
    Flex,
    Spacer,
    Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
    useNotificationGetUnread,
    useNotificationMarkAsRead,
} from "../../../../../utils/api/hooks";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";
import LoadingSpinner from "../../components/LoadingSpinner";
import { NotificationType } from "../../../../../types";

type PropType = {
    isOpen: boolean;
    onClose: () => void;
};

const NotificationPanel = (props: PropType) => {
    const { isOpen, onClose } = props;

    const t = useSmartTranslation();

    const navigate = useNavigate();

    const { notificationUnreadList, error, isLoading } =
        useNotificationGetUnread(5);

    const { update: markAsRead } = useNotificationMarkAsRead({
        onSuccess: (response) => {
            onClose();
            navigate(response.data.actionLink || "/dashboard");
        },
    });

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="sm"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader>
                    <VStack
                        align="left"
                        spacing={4}
                    >
                        <Heading
                            size="md"
                            color="blue.700"
                        >
                            {t("notifications.panelTitle")}
                        </Heading>
                        <Divider />
                    </VStack>
                </DrawerHeader>

                <DrawerBody>
                    <VStack
                        align="left"
                        spacing="4"
                    >
                        <>
                            {error && (
                                <VStack>
                                    <Heading size="sm">
                                        {t("common.error.weAreSorry")}
                                    </Heading>

                                    <Text>
                                        {t("notifications.loadingFailed")}
                                    </Text>
                                </VStack>
                            )}

                            {isLoading && <LoadingSpinner />}

                            {notificationUnreadList?.length === 0 && (
                                <Text>{t("notifications.noUnreads")}</Text>
                            )}

                            {notificationUnreadList?.map((notification) => (
                                <UnreadNotification
                                    notification={notification}
                                    markAsRead={markAsRead}
                                    key={notification.id}
                                />
                            ))}
                        </>
                    </VStack>
                </DrawerBody>

                <DrawerFooter>
                    <Button
                        variant="primary"
                        onClick={() => {
                            navigate("/dashboard/notifications");
                            onClose();
                        }}
                    >
                        {t("notifications.seeAll")}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

//helpers
type UnreadNotificationProps = {
    notification: NotificationType;
    markAsRead: (id: number) => void;
};
const UnreadNotification = (props: UnreadNotificationProps) => {
    const { notification, markAsRead } = props;

    const { id, title, message } = notification;

    return (
        <VStack
            as="button"
            onClick={() => markAsRead(id)}
            align="left"
            bgColor="green.50"
            padding="1rem"
            border="1px solid"
            borderColor="green.100"
            borderRadius="10"
            w="100%"
        >
            <Heading
                size="xs"
                textAlign="left"
            >
                {title}
            </Heading>

            <Text textAlign="left">{message}</Text>
        </VStack>
    );
};

export default NotificationPanel;
