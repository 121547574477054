import { WvwIconButton } from './inputs';
import { IconButtonPropType } from './inputs/WvwIconButton';

const WvwDeleteIconButton = (props: IconButtonPropType) => {
    const {
        color,
        onClick,
    } = props;

    return (
        <WvwIconButton
            ariaLabel="Delete"
            color={color}
            icon="delete"
            onClick={onClick}
        />
    );
};

WvwDeleteIconButton.defaultProps = {
    color: 'wvwGreen',
    onClick: () => {},
};

export default WvwDeleteIconButton;
