import { Text } from '@chakra-ui/react';

type PropTypes = {
    children: React.ReactNode;
    bold?: boolean;
    color?: 'black' | 'red' | 'wvwGreen' | 'wvwGrey60' | 'wvwGrey40' | 'white';
    textAlign?: 'center' | 'left';
    noWrap?: boolean;
};

const WvwText = (props: PropTypes) => {
    const {
        children,
        bold,
        color: inputColor = 'black',
        textAlign,
        noWrap,
    } = props;

    const color = inputColor === 'black' ? undefined : inputColor;

    let overflow;
    let textOverflow;
    let whiteSpace: 'nowrap' | undefined;

    if (noWrap) {
        overflow = 'hidden';
        textOverflow = 'ellipsis';
        whiteSpace = 'nowrap';
    }

    return (
        <Text
            color={color}
            fontWeight={bold ? 'bold' : 'normal'}
            textAlign={textAlign}
            overflow={overflow}
            textOverflow={textOverflow}
            whiteSpace={whiteSpace}
        >
            {children}
        </Text>
    );
};

WvwText.defaultProps = {
    bold: false,
    color: 'black',
    textAlign: 'left',
    noWrap: false,
};

export default WvwText;
