import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { NamespaceTypes } from "../../../../common/hooks/useSmartTranslation";

export type TranslationFunction = TFunction<NamespaceTypes>;

export const useSmartTranslation = () => {
    const { t: translate } = useTranslation("v2");

    return translate;
};
