import {
    Box,
    Button,
    createStandaloneToast,
    Divider,
    Flex,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikForm, FormikInput, FormikRadioGroup } from '../../../../common/forms';
import {
    useMyEntity,
    useUserInvitesCreate,
    useUserInvitesPending,
    useUsersByEntity,
} from '../../../../utils/api/hooks';
import ExcelUploadUsers from '../../../../common/components/ExcelUploadUsers';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import PublicUserProfilePicture from '../../../../common/components/PublicUserProfilePicture';
import TeamPendingInvites from './common/TeamPendingInvites';
import PublicUserSlider from '../../../../common/components/user-details/PublicUserSlider';
import { useRole } from '../../../../common/hooks';
import AccountAccessDeniedAlert from '../../../../common/components/display/AccountAccessDeniedAlert';
import { WVWTitle } from '../../../../common/components/typography';

const validationSchema = Yup.object({
    firstName: Yup.string()
        .required('errors.required'),
    lastName: Yup.string()
        .required('errors.required'),
    email: Yup.string()
        .required('errors.required'),
    role: Yup.string()
        .required('errors.required'),
});

const CompanyTeam = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { toast } = createStandaloneToast();
    const { userIsCompanyAdmin, userIsIndividual } = useRole();

    const { t } = useTranslation(['account', 'common', 'formik']);

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    const {
        usersList = [],
        isLoading: usersLoading,
        error: usersError,
        refresh: refreshUsers,
    } = useUsersByEntity(entity?.id);

    const {
        refresh: refreshInvites,
    } = useUserInvitesPending();

    const [openUser, setOpenUser] = useState<number>();

    const { update: sendInvite } = useUserInvitesCreate({
        onSuccess: () => {
            onClose();
            refreshInvites();
            refreshUsers();
        },
        onError: () => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: t('error.inviteSend', { ns: 'common' }),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    if (entityLoading) {
        return <LoadingSpinner />;
    }

    if (userIsIndividual) {
        return <AccountAccessDeniedAlert />;
    }

    return (
        <>
            <VStack
                w="100%"
                align="left"
                paddingInline=".5rem"
                paddingBlock="2rem"
                spacing="10"
            >
                <Flex>
                    <VStack
                        align="left"
                        w="25%"
                        alignSelf="flex-start"
                        spacing="6"
                    >
                        <Box>
                            <Heading
                                as="h4"
                                size="md"
                                fontWeight="light"
                            >
                                {t('teamDetails.sectionTitle')}
                            </Heading>

                            <Text
                                color="wvwGrey"
                                paddingTop="1rem"
                            >
                                {t('teamDetails.blurb')}
                            </Text>
                        </Box>

                        <VStack spacing="2">
                            <Button
                                variant="primaryYellow"
                                w="fit-content"
                                paddingInline={4}
                                fontWeight="light"
                                onClick={onOpen}
                            >
                                {t('teamDetails.button.inviteMembers')}
                            </Button>

                            <ExcelUploadUsers
                                addUsers={users => {
                                    users.forEach(user => {
                                        sendInvite({
                                            entityId: entity?.id,
                                            firstName: user.firstName,
                                            lastName: user.lastName,
                                            email: user.email,
                                            role: user.userType.toUpperCase() === 'ADMIN' ? 'ADMIN' : 'USER',
                                        });
                                    });
                                }}
                                asButton
                            />
                        </VStack>
                    </VStack>

                    <Spacer />

                    <VStack
                        w="70%"
                        paddingTop="1.5rem"
                    >
                        {usersLoading && (
                            <LoadingSpinner />
                        )}

                        {usersError !== null && (
                            <VStack>
                                <Heading
                                    size="sm"
                                >
                                    {t('error.weAreSorry')}
                                </Heading>

                                <Text>
                                    {t('vListError')}
                                </Text>
                            </VStack>
                        )}

                        {usersList.map(user => (
                            <Flex
                                alignItems="center"
                                w="100%"
                                height="3.5rem"
                                border="1px solid"
                                borderColor="wvwGrey40"
                                bg="wvwGrey05"
                                borderRadius="8"
                                paddingInline="1rem"
                                key={user.email}
                            >
                                <HStack spacing="8">
                                    <PublicUserProfilePicture
                                        size="2.5rem"
                                        userId={user.id}
                                        entityId={user.entityId}
                                    />
                                    <Box paddingBlock="1rem">
                                        <Text
                                            color="wvwGrey80"
                                            fontWeight="medium"
                                        >
                                            {`${user.firstName} ${user.lastName}`}
                                        </Text>
                                    </Box>

                                    {user.role === 'ADMIN' && (
                                        <Box
                                            bg="wvwYellow"
                                            paddingBlock=".2rem"
                                            paddingInline=".6rem"
                                            borderRadius="15"
                                        >
                                            <Text
                                                color="white"
                                                fontWeight="medium"
                                            >
                                                {t('admin', { ns: 'account' })}
                                            </Text>
                                        </Box>
                                    )}

                                </HStack>

                                <Spacer />

                                <Button
                                    borderRadius="30"
                                    onClick={() => setOpenUser(user.id)}
                                    size="sm"
                                    variant="primary"
                                >
                                    {userIsCompanyAdmin && t('button.manage', { ns: 'common' })}
                                    {!userIsCompanyAdmin && t('button.view', { ns: 'common' })}
                                </Button>
                            </Flex>
                        ))}
                    </VStack>
                </Flex>

                <Divider />

                <TeamPendingInvites />
            </VStack>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />

                <ModalContent minWidth="2xl">
                    <ModalHeader
                        color="black"
                    >
                        {t('addTeamMember')}
                    </ModalHeader>

                    <ModalCloseButton />

                    <FormikForm
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            role: 'USER',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={values => {
                            sendInvite({
                                entityId: entity.id,
                                email: values.email,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                role: values.role,
                            });
                        }}
                    >
                        <ModalBody>
                            <VStack
                                alignItems="left"
                                spacing="4"
                            >
                                <Flex>
                                    <FormikInput
                                        width="98%"
                                        placeholder={t('fieldPlaceholder.firstName', { ns: 'common' })}
                                        name="firstName"
                                    />

                                    <Spacer />

                                    <FormikInput
                                        placeholder={t('fieldPlaceholder.lastName', { ns: 'common' })}
                                        name="lastName"
                                    />
                                </Flex>

                                <FormikInput
                                    placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                                    name="email"
                                    type="email"
                                />
                            </VStack>

                            <VStack
                                marginTop={4}
                                alignItems="left-flex"
                                spacing="2"
                            >
                                <WVWTitle
                                    level="2"
                                    color="black"
                                    content={t('role')}
                                />

                                <FormikRadioGroup
                                    width="100%"
                                    name="role"
                                    options={[
                                        {
                                            label: t('user'),
                                            description: t('userDescription'),
                                            value: 'USER',
                                        },
                                        {
                                            label: t('admin'),
                                            description: t('adminDescription'),
                                            value: 'ADMIN',
                                        },
                                    ]}
                                />
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant="primaryYellow"
                                alignSelf="flex-end"
                                type="submit"
                                width="15rem"
                            >
                                {t('button.add', { ns: 'common' })}
                            </Button>
                        </ModalFooter>
                    </FormikForm>
                </ModalContent>
            </Modal>

            {openUser && (
                <PublicUserSlider
                    isOpen
                    onClose={() => setOpenUser(undefined)}
                    userId={openUser}
                    viewMode="TEAM"
                />
            )}
        </>
    );
};

export default CompanyTeam;
