/* eslint-disable max-len */
import { VStack, Grid, GridItem, Box, HStack } from "@chakra-ui/react";
import {
    FormikInput,
    FormikCountrySelect,
    FormikTextarea,
} from "../../../../common/forms";
import { useRole, useSmartTranslation } from "../../../../common/hooks";
import { UserType } from "../../../../types";
import { WvwText } from "../../../../common/components/typography";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import FormikFieldAutoFillCheckBox from "../../../../common/forms/FormikFieldAutoFIllCheckbox";
import FormikPhoneNumberInput from "../../../../common/forms/FormikPhoneNumberInput";

type PropTypes = {
    inspection?: boolean;
    client: UserType | undefined;
    renderUseMyDetailsCheckbox: boolean;
};

const AccessDetails = (props: PropTypes) => {
    const t = useSmartTranslation("inspection");

    const { userIsValuer, roleLoading } = useRole();

    const { client, inspection, renderUseMyDetailsCheckbox } = props;

    if (roleLoading) return <LoadingSpinner />;

    let accessData = {
        checkboxTxt: t("modal.accessDetails.form.myDetails"),
        fName: "accessFName",
        lName: "accessLName",
        email: "accessEmail",
        dialCode: "accessMobileDialCode",
        mobileNumber: "accessMobile",
        note: "accessNote",
    };

    if (inspection) {
        accessData = {
            checkboxTxt: userIsValuer
                ? t("modal.accessDetails.form.clientDetails")
                : t("modal.accessDetails.form.myDetails"),
            fName: "firstName",
            lName: "lastName",
            email: "emailAddress",
            dialCode: "phoneDialcode",
            mobileNumber: "phoneNumber",
            note: "note",
        };
    }

    return (
        <VStack
            w="100%"
            align="left"
        >
            {client && renderUseMyDetailsCheckbox && (
                <Box
                    justifyContent="flex-end"
                    display="grid"
                >
                    <FormikFieldAutoFillCheckBox
                        name="useMyDetails"
                        label={accessData.checkboxTxt}
                        formFields={[
                            {
                                name: accessData.fName,
                                value: client.firstName,
                            },
                            {
                                name: accessData.lName,
                                value: client.lastName,
                            },
                            {
                                name: accessData.email,
                                value: client.email,
                            },
                            {
                                name: accessData.dialCode,
                                value: client.mobileDialCode,
                            },
                            {
                                name: accessData.mobileNumber,
                                value: client.mobile,
                            },
                        ]}
                    />
                </Box>
            )}

            <Grid
                gap="3"
                templateColumns="repeat(6, 1fr)"
            >
                <GridItem colSpan={3}>
                    <FormikInput
                        name={accessData.fName}
                        label={t("fieldPlaceholder.firstName", {
                            ns: "common",
                        })}
                        placeholder={t("fieldPlaceholder.firstName", {
                            ns: "common",
                        })}
                        type="text"
                    />
                </GridItem>

                <GridItem colSpan={3}>
                    <FormikInput
                        name={accessData.lName}
                        label={t("fieldPlaceholder.lastName", {
                            ns: "common",
                        })}
                        placeholder={t("fieldPlaceholder.lastName", {
                            ns: "common",
                        })}
                        type="text"
                    />
                </GridItem>

                <GridItem colSpan={6}>
                    <FormikInput
                        name={accessData.email}
                        label={t("fieldPlaceholder.email", { ns: "common" })}
                        placeholder={t("fieldPlaceholder.email", {
                            ns: "common",
                        })}
                        type="email"
                    />
                </GridItem>

                <FormikPhoneNumberInput
                    dialCodeName={accessData.dialCode}
                    dialCodeLabel={t("fieldPlaceholder.dialCode", {
                        ns: "common",
                    })}
                    phoneNumberName={accessData.mobileNumber}
                    phoneNumberLabel={t("fieldPlaceholder.phoneNumber", {
                        ns: "common",
                    })}
                    phoneNumberPlaceholder={t("fieldPlaceholder.phoneNumber", {
                        ns: "common",
                    })}
                    fieldWrapper={(field) => (
                        <GridItem colSpan={3}>{field}</GridItem>
                    )}
                />

                <GridItem colSpan={6}>
                    <VStack align="left">
                        <WvwText>
                            {t("modal.accessDetails.form.accessInstructions")}
                        </WvwText>

                        <FormikTextarea
                            name={accessData.note}
                            placeholder={t(
                                "fieldPlaceholder.accessInstructions",
                                { ns: "common" }
                            )}
                        />
                    </VStack>
                </GridItem>
            </Grid>
        </VStack>
    );
};

AccessDetails.defaultProps = {
    inspection: false,
};

export default AccessDetails;
