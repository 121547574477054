/* eslint-disable max-len */
import { HStack, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useSmartTranslation } from "../../../../common/hooks";
import { FormikCountrySelect, FormikInput } from "../../../../common/forms";
import FormSectionProfileEdit from "./FormSectionProfileEdit";
import { useMyEntity } from "../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import FormikMapsAutocomplete from "../../../../common/forms/FormikMapsAutocomplete";
import FormikFieldAutoFillCheckBox from "../../../../common/forms/FormikFieldAutoFIllCheckbox";
import FormikPhoneNumberInput from "../../../../common/forms/FormikPhoneNumberInput";

type PropsType = {
    userIsValuer: boolean;
    userIsCompanyAdmin: boolean;
    streetName: string;
    postalCodeName: string;
    cityName: string;
    countryName: string;
    latName: string;
    lngName: string;
    officeTelDialCodeName: string;
    officeTelName: string;
    officeFaxDialCodeName: string;
    officeFaxName: string;
};

/**
 * Formik form section for editing the current user's office details.
 */
const ProfileOfficeDetailsEdit = (props: PropsType) => {
    const {
        userIsValuer,
        userIsCompanyAdmin,
        streetName,
        postalCodeName,
        cityName,
        countryName,
        latName,
        lngName,
        officeTelDialCodeName,
        officeTelName,
        officeFaxDialCodeName,
        officeFaxName,
    } = props;

    const t = useSmartTranslation("profile");

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    const renderInputRow = (
        inputOne: ReactNode,
        inputTwo: ReactNode | null = null
    ) => (
        <HStack
            align="start"
            spacing="6"
            w="100%"
        >
            {inputOne}
            {inputTwo}
        </HStack>
    );

    if (entityLoading) return <LoadingSpinner />;

    return (
        <FormSectionProfileEdit
            header={t("heading.officeLocation")}
            subHeader={t("subHeading.workLocation")}
        >
            <VStack
                width="100%"
                align="left"
            >
                {userIsCompanyAdmin && !userIsValuer && (
                    <FormikFieldAutoFillCheckBox
                        name="useCompanyAddress"
                        label={t("profileCompletionWizard.useCompanyDetails", {
                            ns: "profile",
                        })}
                        formFields={[
                            {
                                name: streetName,
                                value: entity.street,
                            },
                            {
                                name: postalCodeName,
                                value: entity.postalCode,
                            },
                            {
                                name: cityName,
                                value: entity.city,
                            },
                            {
                                name: countryName,
                                value: entity.country,
                            },
                        ]}
                    />
                )}

                {!userIsValuer &&
                    renderInputRow(
                        <HStack
                            alignItems="top"
                            spacing="6"
                            w="100%"
                            flexWrap={{
                                base: "wrap",
                                md: "nowrap",
                            }}
                        >
                            <FormikInput
                                noSubmitOnEnter
                                name={streetName}
                                label={t("profileDetails.street")}
                                placeholder={t("profileDetails.street")}
                            />
                            <FormikInput
                                noSubmitOnEnter
                                name={postalCodeName}
                                label={t("profileDetails.postalCode")}
                                placeholder={t("profileDetails.postalCode")}
                            />
                        </HStack>,
                        <HStack
                            alignItems="top"
                            spacing="6"
                            w="100%"
                            flexWrap={{
                                base: "wrap",
                                md: "nowrap",
                            }}
                        >
                            <FormikInput
                                noSubmitOnEnter
                                name={cityName}
                                label={t("profileDetails.city")}
                                placeholder={t("profileDetails.city")}
                            />

                            <FormikCountrySelect
                                name={countryName}
                                label={t("profileDetails.country")}
                                w="100%"
                            />
                        </HStack>
                    )}

                {userIsValuer && (
                    <FormikMapsAutocomplete
                        cityName={cityName}
                        countryName={countryName}
                        streetName={streetName}
                        postalCodeName={postalCodeName}
                        latName={latName}
                        lngName={lngName}
                        placeholder={t("profileDetails.address")}
                    />
                )}
            </VStack>

            {renderInputRow(
                <FormikPhoneNumberInput
                    dialCodeLabel={t("profileDetails.dialCode")}
                    dialCodeName={officeTelDialCodeName}
                    phoneNumberLabel={t("profileDetails.officeTelNumber")}
                    phoneNumberPlaceholder={t("profileDetails.officeTelNumber")}
                    phoneNumberName={officeTelName}
                />
            )}

            {renderInputRow(
                <FormikPhoneNumberInput
                    dialCodeLabel={t("profileDetails.dialCode")}
                    dialCodeName={officeFaxDialCodeName}
                    phoneNumberLabel={t("profileDetails.officeFaxNumber")}
                    phoneNumberPlaceholder={t("profileDetails.officeFaxNumber")}
                    phoneNumberName={officeFaxName}
                />
            )}
        </FormSectionProfileEdit>
    );
};

export default ProfileOfficeDetailsEdit;
