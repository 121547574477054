export const translations = {
    de: {
        congratulations: "Glückwunsch!",
        registerSuccess: "Sie haben sich erfolgreich registriert!",
    },
    en: {
        congratulations: "Congratulations!",
        registerSuccess: "You have successfully registered!",
    },
};
