import storage from "../../storage/storage";
import { userGetPublicUser } from "../endpoints";
import useQueryHook from "./useQueryHook";
import useRole from "../../../common/hooks/useRole";
import defaultProfilePicture from "../../../common/vars/defaultProfilePicture";
import { obscureUser } from "../../../common/functions/obscure";

const PUBLIC_USER = "PUBLIC_USER";
const USER_CERTIFICATES = "USER_CERTIFICATES";
const USER_PROFILE_PICTURE = "USER_PROFILE_PICTURE";

const usePublicUser = (userId: number | string, obscureDetails?: boolean) => {
    const { userIsClient, userIsValuer } = useRole();

    const userQuery = useQueryHook({
        uniqueName: PUBLIC_USER,
        queryFunction: () => userGetPublicUser(userId),
        dependancies: [userId, !!obscureDetails],
        requiredParams: [userId],
    });

    const profilePictureQuery = useQueryHook({
        uniqueName: USER_PROFILE_PICTURE,
        queryFunction: async () => {
            const profilePicture = await storage.getUserProfilePictureUrl(
                userId
            );

            if (!profilePicture || obscureDetails) {
                if (userIsValuer) {
                    return defaultProfilePicture.user.valuer;
                }

                if (userIsClient) {
                    return defaultProfilePicture.user.client;
                }
            }

            return profilePicture;
        },
        dependancies: [userId, !!obscureDetails],
        requiredParams: [userId],
    });

    const certificatesQuery = useQueryHook({
        uniqueName: USER_CERTIFICATES,
        queryFunction: () => storage.getUserCertificates(userId),
        dependancies: [userId],
        requiredParams: [userId],
    });

    let userData = userQuery.data;

    if (obscureDetails && userData) {
        userData = obscureUser(userData);
    }

    return {
        data: userData,
        refresh: userQuery.refresh,
        error: userQuery.error,
        isLoading: userQuery.isLoading,

        profilePictureRefresh: profilePictureQuery.refresh,
        profilePictureUrl: profilePictureQuery.data,
        profilePictureUrlError: profilePictureQuery.error,
        profilePictureUrlIsLoading: profilePictureQuery.isLoading,

        userCertificates: certificatesQuery.data,
        userCertificatesError: certificatesQuery.error,
        userCertificatesIsLoading: certificatesQuery.isLoading,
        userCertificatesRefresh: certificatesQuery.refresh,
    };
};

export default usePublicUser;
