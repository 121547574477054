import { useAuthState } from "react-firebase-hooks/auth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fromAddress } from "react-geocode";
import { UserType } from "../../../types";
import storage from "../../../utils/storage/storage";
import { meGet, meUpdate } from "../../../utils/api/endpoints";
import { MeUpdateDto } from "../../../utils/api/dto";
import { auth } from "../../../utils/firebase";
import { User } from "../domain";
import { createContext } from "react";

export const UserContext = createContext<User | null | undefined>(undefined);

type ParamsType = {
    onUpdateSuccess?: () => void;
};

const ME = "me";
const MY_CERTIFICATES = "MY_CERTIFICATES";
const MY_PROFILE_PICTURE = "MY_PROFILE_PICTURE";

const useMe = (params: ParamsType = {}) => {
    const { onUpdateSuccess } = params;

    const [user, loadingFBuser] = useAuthState(auth);

    const queryClient = useQueryClient();

    const { data, error, isError, isLoading } = useQuery([ME, user], meGet, {
        enabled: !!user,
    });

    const refresh = () => queryClient.invalidateQueries(ME);

    const userId = data?.id;

    const {
        data: profilePictureUrl,
        error: profilePictureUrlError,
        isError: profilePictureUrlIsError,
        isLoading: profilePictureUrlIsLoading,
    } = useQuery(
        [MY_PROFILE_PICTURE, userId],
        async () => {
            if (!userId) return "";

            const profilePicture = await storage.getUserProfilePictureUrl(
                userId
            );

            if (!profilePicture) {
                return "";
            }

            return profilePicture;
        },
        {
            enabled: !!userId,
        }
    );

    const profilePictureRefresh = () => {
        queryClient.invalidateQueries(MY_PROFILE_PICTURE);
    };

    const {
        data: myCertificates,
        error: myCertificatesError,
        isError: myCertificatesIsError,
        isLoading: myCertificatesIsLoading,
    } = useQuery(
        [MY_CERTIFICATES, userId],
        async () => {
            if (!userId) return [];

            const certificateList = await storage.getUserCertificates(userId);

            return certificateList || [];
        },
        { enabled: !!userId }
    );

    const myCertificatesRefresh = () =>
        queryClient.invalidateQueries(MY_CERTIFICATES);

    const { mutate: updateMe } = useMutation(
        async (updates: MeUpdateDto) => {
            const { city, country, postalCode, street } = updates;

            const dto = {
                ...data,
                ...updates,
                id: Number(data?.id),
            };

            if (street || city || country || postalCode) {
                const address = `${updates.street}, ${updates.city}, ${updates.country}, ${updates.postalCode}`;

                const location = await fromAddress(address).then(
                    (response) => response.results[0].geometry.location
                );

                dto.latitude = location.lat.toString();
                dto.longitude = location.lng.toString();
            }

            return meUpdate(dto);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(ME);

                onUpdateSuccess?.();
            },
        }
    );

    const stillLoading = isLoading || loadingFBuser;

    return {
        data:
            data &&
            user &&
            ({
                ...data,
                emailVerified: user.emailVerified,
            } as User),
        error,
        isError,
        isLoading: stillLoading,
        refresh,

        profilePictureRefresh,
        profilePictureUrl,
        profilePictureUrlError,
        profilePictureUrlIsError,
        profilePictureUrlIsLoading,

        myCertificates,
        myCertificatesError,
        myCertificatesIsError,
        myCertificatesIsLoading,
        myCertificatesRefresh,

        updateMe,
    };
};

export default useMe;
