import { VStack } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlowContext } from "../../../../../common/components/display/ProcessFlowRouted";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { FormikForm } from "../../../../../common/forms";
import { standardSystemStandardsOffered } from "../../../../../common/vars/valuationsAndMembershipTypes";
import { useMe } from "../../../../../utils/api/hooks";
import ProfileCompletionFormFooter from "./ProfileCompletionFormFooter";
import ProfileValuationStandardsEdit from "../../../profile/components/ProfileValuationStandardsEdit";

const ProfileCompletionQualifications = () => {
    const { goNext } = useContext(FlowContext);

    const [saveAndClose, setSaveAndClose] = useState(false);

    const navigate = useNavigate();

    const {
        data: user,
        isLoading,
        updateMe,
    } = useMe({
        onUpdateSuccess: () => {
            if (saveAndClose) {
                navigate("/dashboard", { replace: true });
            } else {
                goNext();
            }
        },
    });

    if (!user || isLoading) return <LoadingSpinner />;

    const updateUser = (values: any) => {
        const {
            systemStandardsOffered: formSystemStandardsOffered,
            otherStandardsOffered: formOtherStandardsOffered,
        } = values;

        // Clean up form data before updating user
        const systemStandardsOffered = formSystemStandardsOffered.filter(
            (i: string) => standardSystemStandardsOffered.includes(i)
        );

        const otherStandardsOffered = formSystemStandardsOffered.includes(
            "Other"
        )
            ? formOtherStandardsOffered
            : [];

        updateMe({
            systemStandardsOffered,
            otherStandardsOffered,
        });
    };

    const { systemStandardsOffered = [], otherStandardsOffered = [] } = user;

    // Ensure that only standard qualifications are displayed, incase the standard list ever changes
    const formDataSystemStandardsOffered = systemStandardsOffered.filter((i) =>
        standardSystemStandardsOffered.includes(i)
    );

    if (otherStandardsOffered.length > 0) {
        formDataSystemStandardsOffered.push("Other");
    }

    return (
        <FormikForm
            initialValues={{
                systemStandardsOffered: formDataSystemStandardsOffered,
                otherStandardsOffered,
            }}
            onSubmit={(values) => {
                updateUser({
                    systemStandardsOffered: values.systemStandardsOffered,
                    otherStandardsOffered: values.otherStandardsOffered,
                });
            }}
        >
            <VStack spacing={4}>
                <ProfileValuationStandardsEdit />

                <ProfileCompletionFormFooter
                    handleSaveAndClose={() => setSaveAndClose(true)}
                />
            </VStack>
        </FormikForm>
    );
};

export default ProfileCompletionQualifications;
