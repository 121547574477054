import { adminUploadedContract } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const ADMIN_UPLOADED_CONTRACT = 'ADMIN_UPLOADED_CONTRACT';

const useAdminCaseReports = (contractId: number) => {
    const query = useQueryHook({
        uniqueName: ADMIN_UPLOADED_CONTRACT,
        dependancies: [],
        requiredParams: [],
        queryFunction: () => adminUploadedContract(contractId),
    });

    return query;
};

export default useAdminCaseReports;
