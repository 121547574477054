import { useTranslation } from 'react-i18next';
import { FormikInput } from '../../../../common/forms';

const CompletionDate = () => {
    const { t } = useTranslation(['enquiryform', 'formik', 'common']);

    return (
        <FormikInput
            width="53%"
            name="completionDate"
            type="date"
            placeholder={t('fieldPlaceholder.date', { ns: 'common' })}
        />
    );
};

export default CompletionDate;
