import { Flex, HStack, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useSmartTranslation } from "../../../../common/hooks";
import ProfilePictureEditor from "../../../../common/components/ProfilePictureEditor";
import { FormikCountrySelect, FormikInput } from "../../../../common/forms";
import { WvwText } from "../../../../common/components/typography";
import FormSection from "./FormSection";
import FormikPhoneNumberInput from "../../../../common/forms/FormikPhoneNumberInput";

/**
 * Formik form section for editing the current user's personal contact details.
 */

type PropTypes = {
    disableEmailEditing?: boolean;
    minimumDetails?: boolean;
};

const ProfileContactDetailsEdit = (props: PropTypes) => {
    const t = useSmartTranslation("profile");

    const { disableEmailEditing, minimumDetails } = props;

    const allDetails = minimumDetails === false;

    const renderInputRow = (
        inputOne: ReactNode,
        inputTwo: ReactNode | null = null
    ) => (
        <HStack
            align="start"
            spacing="6"
            w="100%"
        >
            {inputOne}
            {inputTwo}
        </HStack>
    );

    return (
        <FormSection
            header={t("heading.contactDetails")}
            variant="white"
            element={
                <Flex
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap-reverse", md: "revert" }}
                >
                    <VStack
                        spacing="6"
                        w="100%"
                    >
                        {(allDetails || minimumDetails) &&
                            renderInputRow(
                                <FormikInput
                                    noSubmitOnEnter
                                    name="firstName"
                                    label={t("profileDetails.firstName")}
                                    placeholder={t("profileDetails.firstName")}
                                />,
                                <FormikInput
                                    noSubmitOnEnter
                                    name="lastName"
                                    label={t("profileDetails.lastName")}
                                    placeholder={t("profileDetails.lastName")}
                                />
                            )}

                        {(allDetails || minimumDetails) &&
                            renderInputRow(
                                <FormikInput
                                    noSubmitOnEnter
                                    name="orgPosition"
                                    label={t("profileDetails.roleInCompany")}
                                    placeholder={t(
                                        "profileDetails.roleInCompany"
                                    )}
                                />,
                                <FormikInput
                                    disabled={disableEmailEditing}
                                    noSubmitOnEnter
                                    name="email"
                                    label={t("profileDetails.email")}
                                    placeholder={t("profileDetails.email")}
                                />
                            )}

                        {(allDetails || minimumDetails) &&
                            renderInputRow(
                                <FormikPhoneNumberInput
                                    dialCodeLabel={t("profileDetails.dialCode")}
                                    dialCodeName="mobileDialCode"
                                    phoneNumberLabel={t(
                                        "profileDetails.mobileNumber"
                                    )}
                                    phoneNumberPlaceholder={t(
                                        "profileDetails.mobileNumber"
                                    )}
                                    phoneNumberName="mobile"
                                />
                            )}

                        {allDetails &&
                            renderInputRow(
                                <FormikPhoneNumberInput
                                    dialCodeLabel={t("profileDetails.dialCode")}
                                    dialCodeName="telDialCode"
                                    phoneNumberLabel={t(
                                        "profileDetails.telNumber"
                                    )}
                                    phoneNumberPlaceholder={t(
                                        "profileDetails.telNumber"
                                    )}
                                    phoneNumberName="tel"
                                />
                            )}

                        {allDetails &&
                            renderInputRow(
                                <FormikPhoneNumberInput
                                    dialCodeLabel={t("profileDetails.dialCode")}
                                    dialCodeName="faxDialCode"
                                    phoneNumberLabel={t(
                                        "profileDetails.faxNumber"
                                    )}
                                    phoneNumberPlaceholder={t(
                                        "profileDetails.faxNumber"
                                    )}
                                    phoneNumberName="fax"
                                />
                            )}
                    </VStack>

                    <VStack
                        marginBottom="1rem"
                        paddingInline={{ base: "0rem", md: "2rem" }}
                    >
                        <WvwText>{t("profileDetails.profilePicture")}</WvwText>

                        <ProfilePictureEditor />
                    </VStack>
                </Flex>
            }
        />
    );
};

ProfileContactDetailsEdit.defaultProps = {
    disableEmailEditing: false,
    minimumDetails: false,
};

export default ProfileContactDetailsEdit;
