/* eslint-disable max-len */
import {
    VStack,
    Text,
    Box,
    HStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import { CaseContractType } from '../../../../../types';
import {
    useNavigateUpUrl,
    useRole,
    useSmartTranslation,
} from '../../../../../common/hooks';
import { LabelAndData } from '../../../../../common/components/display';
import StatusBadgeCaseContract from '../../../../../common/components/StatusBadgeCaseContract';
import { WvwButton } from '../../../../../common/components/inputs';
import CaseContractRespondModal from './CaseContractRespondModal';
import CaseContractDocumentList from '../common/CaseContractDocumentList';
import { WVWTitle } from '../../../../../common/components/typography';
import AddContractModal from '../common/AddContractModal';
import WvwHStack from '../../../../../common/components/display/WvwHStack';

type PropsType = {
    contract?: CaseContractType;
    caseId: number;
};

const CaseContractDetailsModal = (props: PropsType) => {
    const { contract, caseId } = props;

    const { userIsValuer, userIsClient } = useRole();

    const navigateUpUrl = useNavigateUpUrl();

    const [addContractModalOpen, setAddContractModalOpen] = useState(false);

    const t = useSmartTranslation('casecontract');

    const [respondingToContract, setRespondingToContract] = useState<'ACCEPTED' | 'REJECTED' | undefined>(undefined);

    const status = contract?.signedContractReview;

    const valuerResponseSectionShows = userIsValuer && status === 'AWAITING_REVIEW';

    const valuerResponseSection = (
        <WvwHStack
            h="100%"
            w="100%"
            justifyContent="flex-end"
            alignItems="flex-end"
        >
            <WvwButton
                variant="danger"
                content={t('button.reject', { ns: 'common' })}
                onClick={() => setRespondingToContract('REJECTED')}
            />

            <WvwButton
                variant="success"
                content={t('button.accept', { ns: 'common' })}
                onClick={() => setRespondingToContract('ACCEPTED')}
            />
        </WvwHStack>
    );

    if (!contract) return null;

    return (
        <>
            <ModalDynamic
                isOpen
                header={t('contract')}
                onClose={() => navigateUpUrl(2)}
            >
                <VStack
                    spacing={5}
                    paddingBottom="2rem"
                    height={window.innerHeight * 0.7}
                >
                    <HStack
                        width="100%"
                        justifyContent="space-between"
                    >
                        <LabelAndData
                            label={t('status', { ns: 'common' })}
                            data={<StatusBadgeCaseContract status={status ?? 'AWAITING_SIGNING'} />}
                        />

                        {userIsClient && contract.signedContractReview === 'AWAITING_SIGNING' && (
                            <WvwButton
                                content={t('title.uploadSignedContract')}
                                onClick={() => {
                                    setAddContractModalOpen(true);
                                }}
                                variant="primary"
                            />
                        )}
                    </HStack>

                    {contract.reviewComment && contract.signedContractReview === 'REJECTED' && (
                        <VStack
                            width="100%"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                        >
                            <WVWTitle
                                level="3"
                                content={`${t('details.rejectionComment')}:`}
                            />

                            <Box
                                width="100%"
                            >
                                <Text>
                                    {contract.reviewComment}
                                </Text>
                            </Box>
                        </VStack>
                    )}

                    <CaseContractDocumentList contract={contract} />

                    {valuerResponseSectionShows && valuerResponseSection}
                </VStack>
            </ModalDynamic>

            <AddContractModal
                isOpen={addContractModalOpen}
                caseId={caseId}
                onClose={() => setAddContractModalOpen(false)}
            />

            <CaseContractRespondModal
                caseId={caseId}
                contractId={contract.id}
                respondingToContract={respondingToContract === 'ACCEPTED' ? 'ACCEPTED' : 'REJECTED'}
                isOpen={respondingToContract !== undefined}
                onCancel={() => setRespondingToContract(undefined)}
                onContinue={() => {
                    setRespondingToContract(undefined);
                }}
            />
        </>
    );
};

CaseContractDetailsModal.defaultProps = {
    contract: undefined,
};

export default CaseContractDetailsModal;
