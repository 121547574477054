import { useAuthState } from "react-firebase-hooks/auth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fromAddress } from "react-geocode";
import { UserType } from "../../../types";
import storage from "../../storage/storage";
import { meGet, meUpdate } from "../endpoints";
import { MeUpdateDto } from "../dto";
import { auth } from "../../firebase";
import useQueryHook from "./useQueryHook";

type ParamsType = {
    onUpdateSuccess?: () => void;
};

const ME = "me";
const MY_CERTIFICATES = "MY_CERTIFICATES";
const MY_PROFILE_PICTURE = "MY_PROFILE_PICTURE";

const useMe = (params: ParamsType = {}) => {
    const { onUpdateSuccess } = params;

    const [user] = useAuthState(auth);

    const queryClient = useQueryClient();

    const {
        data: me,
        error: meError,
        isLoading: meIsLoading,
        refresh: meRefresh,
    } = useQueryHook({
        uniqueName: ME,
        dependancies: [!!user],
        requiredParams: [!!user],
        queryFunction: meGet,
    });

    const userId = me?.id;

    const {
        data: profilePictureUrl,
        error: profilePictureUrlError,
        isError: profilePictureUrlIsError,
        isLoading: profilePictureUrlIsLoading,
    } = useQuery(
        [MY_PROFILE_PICTURE, userId],
        async () => {
            if (!userId) return "";

            const profilePicture = await storage.getUserProfilePictureUrl(
                userId
            );

            if (!profilePicture) {
                return "";
            }

            return profilePicture;
        },
        {
            enabled: !!userId,
        }
    );

    const profilePictureRefresh = () => {
        queryClient.invalidateQueries(MY_PROFILE_PICTURE);
    };

    const {
        data: myCertificates,
        error: myCertificatesError,
        isError: myCertificatesIsError,
        isLoading: myCertificatesIsLoading,
    } = useQuery(
        [MY_CERTIFICATES, userId],
        async () => {
            if (!userId) return [];

            const certificateList = await storage.getUserCertificates(userId);

            return certificateList || [];
        },
        { enabled: !!userId }
    );

    const myCertificatesRefresh = () =>
        queryClient.invalidateQueries(MY_CERTIFICATES);

    const { mutate: updateMe } = useMutation(
        async (updates: MeUpdateDto) => {
            const { city, country, postalCode, street } = updates;

            const dto = {
                ...me,
                ...updates,
                id: Number(me?.id),
            };

            if (street || city || country || postalCode) {
                const address = `${updates.street}, ${updates.city}, ${updates.country}, ${updates.postalCode}`;

                const location = await fromAddress(address).then(
                    (response) => response.results[0].geometry.location
                );

                dto.latitude = location.lat.toString();
                dto.longitude = location.lng.toString();
            }

            return meUpdate(dto);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(ME);

                onUpdateSuccess?.();
            },
        }
    );

    return {
        data: me,
        error: meError,
        isLoading: meIsLoading,
        refresh: meRefresh,

        profilePictureRefresh,
        profilePictureUrl,
        profilePictureUrlError,
        profilePictureUrlIsError,
        profilePictureUrlIsLoading,

        myCertificates,
        myCertificatesError,
        myCertificatesIsError,
        myCertificatesIsLoading,
        myCertificatesRefresh,

        updateMe,
    };
};

export default useMe;
