import { useSearchParams } from 'react-router-dom';
import ResetPassword from './common/ResetPassword';
import FourZeroFour from '../../FourZeroFour';
import ProcessEmailVerification from './common/ProcessEmailVerification';

const FbAction = () => {
    const [searchParams] = useSearchParams();

    const mode = searchParams.get('mode');
    const oobCode = searchParams.get('oobCode');
    const redirectUrl = searchParams.get('continueUrl') || '/';

    if (oobCode) {
        if (mode === 'resetPassword') {
            return <ResetPassword oobCode={oobCode} />;
        }

        if (mode === 'verifyEmail') {
            return (
                <ProcessEmailVerification
                    oobCode={oobCode}
                    redirectUrl={redirectUrl}
                />
            );
        }
    }

    return <FourZeroFour />;
};

export default FbAction;
