import { AdminRequestDocumentsDto } from '../../dto';
import { adminRequestDocuments } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const ADMIN_DOCUMENTS = 'ADMIN_DOCUMENTS';

const useAdminRequestDocuments = (dto: AdminRequestDocumentsDto) => {
    const query = useQueryHook({
        uniqueName: ADMIN_DOCUMENTS,
        dependancies: [JSON.stringify(dto)],
        requiredParams: [],
        queryFunction: () => adminRequestDocuments(dto),
    });

    return query;
};

export default useAdminRequestDocuments;
