import { goToSuspendedAccess } from "../../../common/functions/auth";
import { UserType } from "../../../types";
import { get } from "../request";

const meGet = async (): Promise<UserType> => {
    const user: UserType = await get("/user/me");

    const { entityId, systemAccess, accessPending } = user;

    if (entityId === null) {
        goToSuspendedAccess();
    }

    if (!accessPending && !systemAccess) {
        goToSuspendedAccess();
    }

    return user;
};

export default meGet;
