import useMutationHook from '../useMutationHook';
import { documentRequestReplaceDocument } from '../../endpoints';
import useRefreshAllDMSDataStores from './useRefreshAllDMSDataStores';
import { DocumentRequestReplaceDocumentDto } from '../../dto';
import useSmartTranslation from '../../../../common/hooks/useSmartTranslation';

type ParamsType = {
    caseId: number;
    onSuccess?: () => void;
    onError?: (error?: Error) => void;
};

const useDocumentRequestReplaceDocument = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('documentmanagement');

    const { refreshDocumentManagementArea } = useRefreshAllDMSDataStores(caseId);

    const mutation = useMutationHook({
        mutationFunction:
            (dto: DocumentRequestReplaceDocumentDto) => documentRequestReplaceDocument(dto),
        refreshes: [refreshDocumentManagementArea],
        successMessage: t('toast.messages.documentReplaced'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestReplaceDocument;
