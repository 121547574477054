import {
    Box,
    Grid,
    GridItem,
    HStack,
    List,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AccordionContainer from '../../../../../common/components/AccordionContainer';
import PropertyOverview from '../../../../../common/components/PropertyOverview';
import { WvwText, WVWTitle } from '../../../../../common/components/typography';
import delimitNumber from '../../../../../common/functions/delimitNumber';
import { EnquiryType } from '../../../../../types';

type PropTypes = {
    enquiry: EnquiryType;
};

const CaseEnquirySummary = (props: PropTypes) => {
    const { enquiry } = props;

    const { t } = useTranslation(['enquiryform', 'formik']);

    const valuationRequirementPairs = [
        {
            key: t('newEnquiry.valuationSection.valuationScope.sectionTitle'), value:
                t(enquiry.valuationType, {
                    ns: "formik",
                    defaultValue: `${t(
                        "noRequirement"
                    )}`,
                })
        },
        {
            key: t('newEnquiry.valuationSection.valuationStandards.sectionTitle'), value:
                t(enquiry.valuationStandards, {
                    ns: "formik",
                    defaultValue: `${t(
                        "noRequirement"
                    )}`,
                })
        },
        {
            key: t('newEnquiry.valuationSection.valuationPurpose.sectionTitle'), value: enquiry.valuationPurposes.map((purpose: string) => `${t(purpose,
                {
                    ns: "formik",
                    defaultValue: purpose,
                })}`).join(', ')
        },
        { key: t('newEnquiry.overview.completionExpectation'), value: enquiry.completionDate },
    ];

    const valuerRequirementPairs = [
        {
            key: t('newEnquiry.overview.valuer.minExperience'), value:
                t(
                    `newEnquiry.overview.valuerRequirements.minValuerExperience.${enquiry.valuerExperience}`,
                    {
                        ns: "enquiryform",
                        defaultValue: `${t(
                            "noRequirement"
                        )}`,
                    }
                ),
        },
        {
            key: t('newEnquiry.valuerSection.requirements.minPICover'), value:
                enquiry.valuerMinimumPICover ? `€ ${delimitNumber(
                    enquiry.valuerMinimumPICover
                )}`
                    : enquiry.valuerMinimumPICoverPercentage === 0
                        ? t("noRequirement")
                        : `${enquiry.valuerMinimumPICoverPercentage} %`

        },
        {
            key: t('newEnquiry.valuerSection.requirements.minPartners'), value:
                t(
                    `newEnquiry.overview.valuerRequirements.minPartners.${enquiry.valuerMinimumPartners}`,
                    {
                        ns: "enquiryform",
                        defaultValue: `${t(
                            "noRequirement"
                        )}`,
                    }
                ),
        },
        {
            key: t('newEnquiry.overview.valuer.maxDistance'), value:
                t(
                    `newEnquiry.overview.valuerRequirements.maxDistance.${enquiry.valuerMaxOfficeDistance}`,
                    {
                        ns: "enquiryform",
                        defaultValue: `${t(
                            "noRequirement"
                        )}`,
                    }
                ),
        },
    ];


    const enquiryOverview = [
        {
            title: t('newEnquiry.overview.valuation.requestedTitle'),
            listItems: valuationRequirementPairs,
        },
        {
            title: t('newEnquiry.overview.valuer.requirements'),
            listItems: valuerRequirementPairs,
        },
    ];

    return (
        <>
            <Grid
                templateColumns={{
                    base: '1fr',
                    lg: 'repeat(2, 1fr)',
                }}
                gap={6}
                borderRadius="10"
                width="100%"
            >
                {enquiryOverview.map(({ title, listItems }) => (
                    <GridItem key={title}>
                        <ViewEnquiryInfoItem
                            title={title}
                            listItems={listItems}
                        />
                    </GridItem>
                ))}

            </Grid>

            <VStack
                p={5}
                borderRadius="lg"
                align="start"
                width="100%"
                gap={6}
                bg="white"
            >
                <WVWTitle level="2" color="black" content={t('newEnquiry.propertyDetailsSection.title')} />

                {enquiry.properties.map((property) => (
                    <AccordionContainer
                        title={`${property.street}, ${property.city}`}
                        key={`${property.street} ${property.city}`}
                    >
                        <PropertyOverview
                            property={property}
                        />
                    </AccordionContainer>
                ))}
            </VStack>
        </>
    );
};

CaseEnquirySummary.defaultProps = {
    enquiry: {},
};

type ViewEnquiryInfoItemProps = {
    title: string;
    listItems: { key: string; value: string }[];
};

const ViewEnquiryInfoItem = (props: ViewEnquiryInfoItemProps) => {
    const { title, listItems } = props;

    return (
        <Box
            p={5}
            borderRadius="lg"
            bg="white"
            minH="100%"
        >
            <WVWTitle level="2" color="black" content={title} />
            <List spacing={2} mt={3}>
                {listItems.map(item => {
                    const itemTitle = `${item.key}: `
                    const itemValue = item.value;

                    return (
                        <HStack>
                            <Box
                                w="50%"
                            >
                                <WvwText key={item.key} children={itemTitle} />
                            </Box>

                            <Box
                                w="50%"
                            >
                                <WvwText key={item.value} children={itemValue} />
                            </Box>
                        </HStack>
                    )
                })}
            </List>
        </Box>
    );
};

export default CaseEnquirySummary;
