export type LoginNamespace = typeof loginEN;

export const loginEN = {
    instruction: {
        email: 'your $t(email, { "ns": "common" })',
        password: 'your $t(password, { "ns": "common" })',
        pleaseCheck: "Please check {{item}}",
        pleaseEnter: "Please enter {{item}}",
    },
    link: {
        register: "Register",
        resetPassword: "Forgot password?",
    },
    loginHeading: "Login",
    noAccountQ: "Don't have an account?",
    rememberMe: "Remember me",
    welcomeBlurb: "Welcome back, you have been missed!",
    wvwBlurb:
        '$t(wvw,  { "ns": "common" }) offers valuers a customised, state-of-the-art procurement and process management system for valuers, and those in need of a valuation.',
    wvwWelcome: "Hello again!",
};
