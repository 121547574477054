import {
    Flex,
    List,
    ListItem,
    Text,
} from '@chakra-ui/react';
import { useSmartTranslation } from '../../../../common/hooks';
import { UserType } from '../../../../types';
import FormSectionProfileEdit from './FormSectionProfileEdit';
import { WvwIcon } from '../../../../common/components/display';

type PropsType = {
    user: UserType;
};

const ProfileValuationTypes = (props: PropsType) => {
    const { user } = props;

    const {
        valuationTypes = [],
        specialValuationTypes = [],
        otherValuationTypes = [],
    } = user;

    const t = useSmartTranslation('profile');

    return (
        <FormSectionProfileEdit header={t('heading.valuationScope')}>
            <Flex
                alignItems="flex-start"
                minH="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('valuationCompetence.valuationScope')}
                </Text>

                <List>
                    {valuationTypes.length === 0 && (
                        <ListItem color="wvwGrey40">
                            <WvwIcon icon="warning" />
                            {' '}
                            {t('none', { context: 'female', ns: 'common' })}
                        </ListItem>
                    )}

                    {valuationTypes?.sort()?.map(valuation => (
                        <ListItem key={valuation}>
                            {t(valuation, { ns: 'formik', defaultValue: valuation })}
                        </ListItem>
                    ))}
                </List>
            </Flex>

            <Flex
                alignItems="flex-start"
                minH="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('valuationCompetence.specificValuationScopes')}
                </Text>

                <List>
                    {specialValuationTypes.length === 0 && (
                        <ListItem color="wvwGrey40">
                            {t('none', { context: 'female', ns: 'common' })}
                        </ListItem>
                    )}

                    {specialValuationTypes.sort().map(valuation => (
                        <ListItem key={valuation}>
                            {t(valuation, { ns: 'formik', defaultValue: valuation })}
                        </ListItem>
                    ))}
                </List>
            </Flex>

            <Flex
                alignItems="flex-start"
                minH="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('valuationCompetence.otherValuationScopes')}
                </Text>

                <List>
                    {otherValuationTypes.length === 0 && (
                        <ListItem color="wvwGrey40">
                            {t('none', { context: 'female', ns: 'common' })}
                        </ListItem>
                    )}

                    {otherValuationTypes.sort()?.map(valuation => (
                        <ListItem key={valuation}>
                            {valuation}
                        </ListItem>
                    ))}
                </List>
            </Flex>
        </FormSectionProfileEdit>
    );
};

export default ProfileValuationTypes;
