import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import DataTable, {
    DataTableColumnType,
} from "../../../common/components/datatable/DataTable";
import PageWithTitleLayout from "../../../common/components/display/PageWithTitleLayout";
import useAdminRequestDocuments from "../../../utils/api/hooks/admin/useAdminRequestDocuments";
import StatusBadge from "../../../common/components/StatusBadge";
import { DocumentRequestDocumentInRequestType } from "../../../types";

const AdminDocuments = () => {
    const { t } = useTranslation("dashboard");

    const { data: documents = [], isLoading } = useAdminRequestDocuments({});

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const headers: DataTableColumnType<DocumentRequestDocumentInRequestType>[] =
        [
            {
                title: t("documentsTable.header.title"),
                maxWidth: "15rem",
                render: (document) => `${document.name}`,
                sorter: (a, b) => a.name.localeCompare(b.name),
            },
            {
                title: t("documentsTable.header.request"),
                maxWidth: "15rem",
                render: (document) => `${document?.documentRequest?.name}`,
                sorter: (a, b) =>
                    a.documentRequest.name.localeCompare(
                        b.documentRequest.name
                    ),
            },
            {
                title: t("enquiriesTable.header.status"),
                maxWidth: "10rem",
                render: (document) => <StatusBadge status={document.status} />,
                sorter: (a, b) => a.status.localeCompare(b.status),
            },
            {
                title: t("documentsTable.header.lastUpdated"),
                maxWidth: "10rem",
                render: (document) => {
                    const date = new Date(document.updatedAt);
                    return new Intl.DateTimeFormat("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    }).format(date);
                },
                sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
            },
        ];

    const documentsFilters = [
        {
            filterName: "Documents status",
            placeholder: "Status",
            options: [
                {
                    label: t("filters.Documnents.status.ACCEPTED", {
                        ns: "admin",
                    }),
                    value: "ACCEPTED",
                },
                {
                    label: t("filters.Documnents.status.SUBMITTED", {
                        ns: "admin",
                    }),
                    value: "SUBMITTED",
                },
                {
                    label: t("filters.Documnents.status.REJECTED", {
                        ns: "admin",
                    }),
                    value: "REJECTED",
                },
                {
                    label: t("filters.Documnents.status.UNAVAILABLE", {
                        ns: "admin",
                    }),
                    value: "UNAVAILABLE",
                },
                {
                    label: t("filters.Documnents.status.DELETED", {
                        ns: "admin",
                    }),
                    value: "DELETED",
                },
            ],
            filterFunction: (
                i: DocumentRequestDocumentInRequestType,
                filterValue?: string
            ) => i.status === filterValue,
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Documents">
            <DataTable
                columns={headers}
                data={documents}
                filters={documentsFilters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminDocuments;
