import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
} from '@chakra-ui/react';
import WVWLogoWhite from '../../../assets/icons/WVWLogoWhite';
import { wvwPalette } from '../../../theme';

type PropTypes = {
    children: React.ReactElement;
    isOpen: boolean;
    onClose: () => void;
};

const MobiMenuBarDrawer = (props: PropTypes) => {
    const { children, isOpen, onClose } = props;

    return (
        <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            size="xl"
            isFullHeight
        >
            <DrawerOverlay />

            <DrawerContent bgGradient={wvwPalette.wvwGradients.blue}>
                <DrawerCloseButton />
                <DrawerHeader>
                    <WVWLogoWhite width="4rem" />
                </DrawerHeader>

                <DrawerBody padding="0">
                    {children}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default MobiMenuBarDrawer;
