import {
    Button,
    Heading,
    HStack,
    Link as ChakraLink,
    VStack,
    Text,
} from "@chakra-ui/react";
import {
    Cards,
    Document,
    House2,
    InfoCircle,
    SaveAdd,
    TaskSquare,
    UserOctagon,
    WalletMoney,
} from "iconsax-react";
import { useTranslation } from "react-i18next";
import {
    Link as ReactLink,
    Outlet,
    useLocation,
    useParams,
} from "react-router-dom";

const {
    REACT_APP_FEATURE_ENABLE_DMS = "false",
    REACT_APP_FEATURE_ENABLE_INSPECTION = "false",
    REACT_APP_FEATURE_ENABLE_VALUATION_REPORT = "false",
    REACT_APP_FEATURE_ENABLE_CONTRACT = "false",
} = process.env;

const navItems = [
    {
        icon: <InfoCircle size="1.3rem" />,
        label: "summary",
        path: "",
    },
    {
        icon: <SaveAdd size="1.3rem" />,
        label: "Enquiry",
        path: "enquiry",
    },
    {
        icon: <WalletMoney size="1.3rem" />,
        label: "offer",
        path: "offer",
    },
    {
        icon: <TaskSquare size="1.3rem" />,
        label: "contract",
        path: "contract",
        isDisabled: REACT_APP_FEATURE_ENABLE_CONTRACT !== "true",
    },
    {
        icon: <Document size="1.3rem" />,
        label: "documents",
        path: "documents",
        isDisabled: REACT_APP_FEATURE_ENABLE_DMS !== "true",
    },
    {
        icon: <Cards size="1.3rem" />,
        label: "payments",
        path: "payments",
        isDisabled: true,
    },
    {
        icon: <UserOctagon size="1.3rem" />,
        label: "inspection",
        path: "inspection",
        isDisabled: REACT_APP_FEATURE_ENABLE_INSPECTION !== "true",
    },
    {
        icon: <House2 size="1.3rem" />,
        label: "Valuation",
        path: "report-delivery",
        isDisabled: REACT_APP_FEATURE_ENABLE_VALUATION_REPORT !== "true",
    },
];

const Case = () => {
    const { t } = useTranslation(["common", "offer"]);

    const { caseId } = useParams();

    const location = useLocation();

    const currentPath = location.pathname.split("/")[4] || "";

    return (
        <VStack
            align="left"
            spacing="4"
            w="100%"
        >
            <Heading
                as="h2"
                size="xl"
            >
                {`${t("case")} - ${caseId}`}
            </Heading>

            <HStack
                align="left"
                alignItems="flex-start"
                w="100%"
            >
                <VStack>
                    {navItems.map((i) => {
                        let isActive = false;

                        if (currentPath === i.path) {
                            isActive = true;
                        }

                        if (currentPath === "" && i.label === "summary") {
                            isActive = true;
                        }

                        return (
                            <ChakraLink
                                as={ReactLink}
                                key={i.label}
                                to={`./${i.path}`}
                            >
                                <Button
                                    h="6rem"
                                    variant={
                                        isActive ? "primary" : "primaryWhite"
                                    }
                                    w="6rem"
                                    isDisabled={i.isDisabled}
                                >
                                    <VStack spacing="1">
                                        {i.icon}

                                        <Text fontWeight="light">
                                            {t(i.label, {
                                                defaultValue: i.label,
                                            })}
                                        </Text>
                                    </VStack>
                                </Button>
                            </ChakraLink>
                        );
                    })}
                </VStack>

                <Outlet />
            </HStack>
        </VStack>
    );
};

export default Case;
