import { Heading, HStack } from '@chakra-ui/react';
import ProfilePictureCompany from '../../../../../common/components/display/ProfilePictureCompany';
import { WvwIcon } from '../../../../../common/components/display';
import { EntityType } from '../../../../../types';
import { useSmartTranslation } from '../../../../../common/hooks';

type PropsType = {
    entity: EntityType;
};

const CompanyDetailsHeading = (props: PropsType) => {
    const { entity } = props;

    const t = useSmartTranslation('account');

    const {
        name,
    } = entity;

    return (
        <HStack
            align="left"
            paddingTop="1rem"
            alignItems="center"
            spacing={4}
        >
            <ProfilePictureCompany size="8rem" />

            <Heading
                color={name ? undefined : 'wvwGrey40'}
            >
                {!entity.name && (
                    <>
                        <WvwIcon icon="warning" />
                        {' '}
                        {t('companyDetails.noNameProvided')}
                    </>
                )}

                {name !== '' && name}
            </Heading>
        </HStack>
    );
};

export default CompanyDetailsHeading;
