import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    VStack,
} from "@chakra-ui/react";
import * as Yup from "yup";
import FormikForm from "../../../../../common/forms/FormikForm";
import { WvwButton } from "../../../../../common/components/inputs";
import { WVWTitle } from "../../../../../common/components/typography";
import AccessDetails from "../../../../create-client-enquiry/enquiry-property-info/property-forms/AccessDetails";
import { useCaseGetCase } from "../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { FormikSubmitButton } from "../../../../../common/forms";
import { useSmartTranslation } from "../../../../../common/hooks";

type PropTypes = {
    onClose: () => void;
    onConfirm: (values: any) => void;
    isOpen: boolean;
    caseId: number;
};

const validationSchema = Yup.object({
    firstName: Yup.string().required("errors.required"),
    lastName: Yup.string().required("errors.required"),
    emailAddress: Yup.string().required("errors.required"),
    phoneDialcode: Yup.string().required("errors.required"),
    phoneNumber: Yup.string().required("errors.required"),
});

const InspectionAccessDetailsModal = (props: PropTypes) => {
    const t = useSmartTranslation("inspection");

    const { onClose, onConfirm, isOpen, caseId } = props;

    const { data: caseData, isLoading: caseLoading } = useCaseGetCase(caseId);

    if (caseLoading || !caseData) return <LoadingSpinner />;

    const client = caseData.clientUser;

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            size="xl"
        >
            <ModalOverlay />

            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    firstName: "",
                    lastName: "",
                    emailAddress: "",
                    phoneDialcode: "",
                    phoneNumber: "",
                    note: "",
                }}
                onSubmit={(values) => {
                    onConfirm(values);
                }}
            >
                <ModalContent>
                    <ModalHeader>
                        <WVWTitle
                            content={t("modal.accessDetails.title")}
                            level="2"
                            color="black"
                        />
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody paddingTop="0">
                        <VStack width="100%">
                            <AccessDetails
                                client={client}
                                inspection
                                renderUseMyDetailsCheckbox
                            />
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Flex width="100%">
                            <WvwButton
                                onClick={onClose}
                                content={t("button.cancel", { ns: "common" })}
                                width="max"
                                variant="danger"
                            />

                            <Spacer />

                            <FormikSubmitButton
                                content={t("button.save", { ns: "common" })}
                                width="max"
                            />
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </FormikForm>
        </Modal>
    );
};

export default InspectionAccessDetailsModal;
