import { AddIcon } from "@chakra-ui/icons";
import { VStack, Box, Center } from "@chakra-ui/react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import PropertyForm from "../../../../common/components/PropertyForm";
import { Property } from "../PropertyFormPropertyType";
import PropertyPreview from "./components/PropertyPreview";
import { WvwButton } from "../../../../common/components/inputs";
import { WvwText, WVWTitle } from "../../../../common/components/typography";
import WvwHomePin from "../../../../assets/icons/custom-icons/WvwHomePin";

type PropsType = {
    renderUseMyDetailsCheckbox: boolean;
};

const Properties = (props: PropsType) => {
    const { renderUseMyDetailsCheckbox } = props;
    const { t } = useTranslation([
        "enquiryform",
        "common",
        "enquiries",
        "formik",
    ]);

    const [field, meta] = useField<Property[]>("properties");

    const properties = meta.value;

    const [editingProperty, setEditingProperty] = useState<
        Property | undefined
    >(undefined);
    const [deleteProp, setDeleteProp] = useState<Property | undefined>(
        undefined
    );

    useEffect(() => {
        if (properties.length === 0) {
            setEditingProperty({} as Property);
        }
    }, []);

    return (
        <Box>
            <VStack
                spacing="4"
                paddingBlock="1rem"
                paddingInline="2.5rem"
            >
                {properties?.map((property) => (
                    <PropertyPreview
                        key={`${property.street} ${property.city}`}
                        property={property}
                        setEditingProperty={setEditingProperty}
                        setDeleteProp={setDeleteProp}
                    />
                ))}

                {properties.length === 0 && (
                    <Center
                        width="100%"
                        height="16rem"
                        border="1px dashed #E2E8F0"
                        borderRadius="4"
                    >
                        <VStack spacing="6">
                            <WvwHomePin width="4rem" />

                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <WVWTitle
                                    content={t(
                                        "newEnquiry.addingProperty.sectionTitle.noProperties",
                                        { ns: "enquiryform" }
                                    )}
                                    color="black"
                                    level="2"
                                />

                                <WvwText>
                                    {t(
                                        "newEnquiry.addingProperty.sectionTitle.mustAddProperty",
                                        { ns: "enquiryform" }
                                    )}
                                </WvwText>
                            </Box>

                            <WvwButton
                                variant="default"
                                icon={<AddIcon />}
                                onClick={() =>
                                    setEditingProperty({} as Property)
                                }
                                content={t("button.addNewProperty", {
                                    ns: "common",
                                })}
                            />
                        </VStack>
                    </Center>
                )}

                {properties.length > 0 && (
                    <Box w="15rem">
                        <WvwButton
                            variant="default"
                            icon={<AddIcon />}
                            onClick={() => setEditingProperty({} as Property)}
                            content={t("button.addNewProperty", {
                                ns: "common",
                            })}
                        />
                    </Box>
                )}

                {meta.error && (
                    <WvwText color="red">
                        {t(meta.error, {
                            ns: "formik",
                            defaultValue: meta.error,
                        })}
                    </WvwText>
                )}
            </VStack>

            <PropertyForm
                onCancel={() => setEditingProperty(undefined)}
                property={editingProperty}
                renderUseMyDetailsCheckbox={renderUseMyDetailsCheckbox}
                saveProperty={(initialProp, updatedProp) => {
                    if (updatedProp === undefined) {
                        setEditingProperty(undefined);
                        return;
                    }

                    const newProperties = [...properties];

                    const index = newProperties.findIndex(
                        (p) => JSON.stringify(p) === JSON.stringify(initialProp)
                    );

                    if (index !== -1) {
                        newProperties[index] = updatedProp;
                    } else {
                        newProperties.push(updatedProp);
                    }

                    field.onChange({
                        target: { name: "properties", value: newProperties },
                    });

                    setEditingProperty(undefined);
                }}
            />

            <ConfirmationModal
                isOpen={deleteProp !== undefined}
                title={t("warning.deleteProperty.title", { ns: "common" })}
                content={t("warning.deleteProperty.prompt", { ns: "common" })}
                onCancel={() => setDeleteProp(undefined)}
                onContinue={() => {
                    const newProperties = properties.filter(
                        (i) => JSON.stringify(i) !== JSON.stringify(deleteProp)
                    );

                    field.onChange({
                        target: { name: "properties", value: newProperties },
                    });

                    setDeleteProp(undefined);
                }}
            />
        </Box>
    );
};

export default Properties;
