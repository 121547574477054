import { documentRequestGetFolders } from '../../endpoints';
import { GetFoldersDto } from '../../dto';
import useQueryHook from '../useQueryHook';

const DOCUMENT_REQUEST_GET_FOLDERS = 'DOCUMENT_REQUEST_GET_FOLDERS';

const useDocumentRequestGetFolders = (dto: GetFoldersDto) => {
    const { caseId } = dto;

    const query = useQueryHook({
        uniqueName: DOCUMENT_REQUEST_GET_FOLDERS,
        dependancies: [JSON.stringify(dto)],
        requiredParams: [caseId],
        queryFunction: () => documentRequestGetFolders(dto),
    });

    return query;
};

export default useDocumentRequestGetFolders;
