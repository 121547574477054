import { caseGetCaseReports } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const CASE_REPORT_GET_REPORTS = 'CASE_REPORT_GET_REPORTS';

const useCaseGetCaseReports = (caseId: number | string) => {
    const query = useQueryHook({
        uniqueName: CASE_REPORT_GET_REPORTS,
        dependancies: [caseId],
        requiredParams: [caseId],
        queryFunction: () => caseGetCaseReports(caseId),
    });

    return query;
};

export default useCaseGetCaseReports;
