import { documentRequestGetUserFileList } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const DOCUMENT_REQUEST_GET_USER_FILE_LIST = 'DOCUMENT_REQUEST_GET_USERS_FILE_LIST';

const useDocumentRequestGetUserFileList = (caseId: string | number) => {
    const query = useQueryHook({
        uniqueName: DOCUMENT_REQUEST_GET_USER_FILE_LIST,
        dependancies: [caseId],
        requiredParams: [caseId],
        queryFunction: () => documentRequestGetUserFileList(caseId),
    });

    return query;
};

export default useDocumentRequestGetUserFileList;
