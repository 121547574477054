/* eslint-disable max-len */
import { Flex, SimpleGrid } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import WvwVStack from '../../../../../common/components/display/WvwVStack';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import { useRole, useSmartTranslation } from '../../../../../common/hooks';
import {
    FormikFileUploadLocal,
    FormikForm,
    FormikSubmitButton,
} from '../../../../../common/forms';
import { WVWLabel } from '../../../../../common/components/typography';
import { useCaseCreateUploadedContract, useCaseUploadSignedContracts } from '../../../../../utils/api/hooks';
import { CaseCreateUploadedContractDto, CaseUploadSignedContract } from '../../../../../utils/api/dto/case-contract';

type PropTypes = {
    caseId: number;
    isOpen: boolean;
    onClose: () => void;
};

const validationSchema = Yup.object().shape({
    documents: Yup.array().min(1, 'errors.required'),
});

const AddContractModal = (props: PropTypes) => {
    const {
        caseId,
        isOpen,
        onClose,
    } = props;

    const { contractId } = useParams();
    const numberContractId = Number(contractId);

    const t = useSmartTranslation('casecontract');

    const { userIsClient, userIsValuer } = useRole();

    const { update: submitContractForSigning, busy: signingContractBusy } = useCaseCreateUploadedContract({ caseId, onSuccess: () => { onClose(); } });
    const { update: submitContractForReview, busy: reviewingContractBusy } = useCaseUploadSignedContracts({ caseId, onSuccess: () => { onClose(); } });

    return (
        <ModalDynamic
            header={t('title.addContract')}
            isOpen={isOpen}
            onClose={onClose}
        >
            <FormikForm
                validationSchema={validationSchema}
                submitting={userIsClient ? reviewingContractBusy : signingContractBusy}
                initialValues={{
                    documents: [],
                }}
                onSubmit={values => {
                    let dto: CaseCreateUploadedContractDto | CaseUploadSignedContract;

                    if (userIsValuer) {
                        dto = {
                            caseId,
                            file: values.documents[0].file,
                        };
                        submitContractForSigning(dto);
                    } else {
                        dto = {
                            contractId: numberContractId,
                            file: values.documents[0].file,
                        };
                        submitContractForReview(dto);
                    }
                }}
            >
                <SimpleGrid gap="6">
                    <WvwVStack>
                        <WVWLabel content={t('documents', { ns: 'common' })} />
                        <FormikFileUploadLocal name="documents" />
                    </WvwVStack>

                    <Flex justifyContent="flex-end">
                        <FormikSubmitButton content={t('button.submit', { ns: 'common' })} />
                    </Flex>
                </SimpleGrid>
            </FormikForm>
        </ModalDynamic>
    );
};

export default AddContractModal;
