import { useParams } from 'react-router-dom';
import { ContentBox, WvwIcon } from '../../../../../../common/components/display';
import WvwVStack from '../../../../../../common/components/display/WvwVStack';
import { CaseReportDocumentType } from '../../../../../../types';
import CaseDocumentModal from '../../document/CaseDocumentModal';
import { WvwLink } from '../../../../../../common/components/inputs';
import WvwHStack from '../../../../../../common/components/display/WvwHStack';

type PropsType = {
    documents: CaseReportDocumentType[];
};

const renderDocument = (document: CaseReportDocumentType) => (
    <ContentBox key={document.id}>
        <WvwHStack>
            <WvwIcon icon="document" />

            <WvwLink
                to={`document/${document.id}`}
                label={document.name}
            />
        </WvwHStack>
    </ContentBox>
);

const CaseReportDocumentList = (props: PropsType) => {
    const { documents } = props;

    const { documentId: documentIdString } = useParams();

    const documentId = Number(documentIdString);

    const openDocument = documents.find(document => document.id === documentId);

    return (
        <>
            <WvwVStack>
                {documents.map(document => renderDocument(document))}
            </WvwVStack>

            <CaseDocumentModal document={openDocument} />
        </>
    );
};

export default CaseReportDocumentList;
