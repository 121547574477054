import { useParams } from "react-router-dom";
import CaseModuleContainer from "../common/CaseModuleContainer";
import { WvwText, WVWTitle } from "../../../../common/components/typography";
import WvwVStack from "../../../../common/components/display/WvwVStack";
import { useRole, useSmartTranslation } from "../../../../common/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { ContentBox } from "../../../../common/components/display";
import ContractList from "./common/ContractList";
import AddContractBanner from "./common/AddContractBanner";
import { useCaseGetUploadedContracts } from "../../../../utils/api/hooks";
import CaseContractDetailsModal from "./view-contract/CaseContractDetailsModal";

const CaseContract = () => {
    const { caseId, contractId: contractIdString } = useParams();

    const numberCaseId = Number(caseId);
    const viewingContractId = Number(contractIdString);

    const t = useSmartTranslation("casecontract");
    const { userIsClient, userIsValuer } = useRole();

    const { data: contractList = [], isLoading: contractsLoading } =
        useCaseGetUploadedContracts(numberCaseId);

    const openContract = contractList.find(
        (contract) => contract.id === viewingContractId
    );

    if (contractsLoading) return <LoadingSpinner />;

    const renderAddContractForSigning = () => {
        const barringContract = contractList.some(
            (contract) =>
                contract.signedContractReview === "AWAITING_REVIEW" ||
                contract.signedContractReview === "ACCEPTED" ||
                contract.signedContractReview === "AWAITING_SIGNING"
        );

        if (userIsClient) return null;

        if (barringContract) return null;

        return <AddContractBanner caseId={numberCaseId} />;
    };

    const renderNoContracts = () => {
        if (userIsValuer) return null;
        if (contractList.length > 0) return null;

        return (
            <ContentBox color="wvwYellow10">
                {t("details.noContracts")}
            </ContentBox>
        );
    };

    return (
        <>
            <CaseModuleContainer>
                <WVWTitle
                    content={t("title.caseContract")}
                    level="2"
                />

                <WvwVStack>
                    <WvwText>{t("details.description")}</WvwText>

                    {renderAddContractForSigning()}

                    {renderNoContracts()}

                    <ContractList
                        caseId={numberCaseId}
                        contractList={contractList}
                    />
                </WvwVStack>
            </CaseModuleContainer>

            <CaseContractDetailsModal
                contract={openContract}
                caseId={numberCaseId}
            />
        </>
    );
};

export default CaseContract;
