import { MISSING_TRANSLATION } from "../../common/MISSING_TRANSLATION";

export const translations = {
    de: {
        archive: "Archivieren",
        daysLeft: "{{count}} Tage übrig",
        datePassed: "Datum vergangen",
        enquiryId: "Anfrage #{{id}}",
        purpose: "Zweck der Wertermittlung",
        status: {
            title: "Status:",
            ACCEPTED: "ANGENOMMEN",
            CANCELLED: "ABGEBROCHEN",
            DORMANT: "RUHEND",
            DRAFT: "ENTWURF",
            PENDING: "SCHWEBEND",
            REJECTED: "ABGELEHNT",
            WITHDRAWN: "ZURÜCKGENOMMEN",
        },
        requestedValuationTitle: "Angeforderte Bewertung",
        valuationRequirementsTitle: MISSING_TRANSLATION,
        valuationRequirements: {
            standardAndType: MISSING_TRANSLATION,
            expectedDelivery: MISSING_TRANSLATION,
            specificScopes: MISSING_TRANSLATION,
            purpose: MISSING_TRANSLATION,
            additionalRequirements: MISSING_TRANSLATION,
        },
        valuerRequirementsTitle: "Anforderungen an den Gutachter",
        valuerRequirements: {
            qualification: "Qualifikation",
            minExperience: "Mindesterfahrung des Gutachters (in Jahren)",
            minPartners: "Mindestanzahl von Partnern im Unternehmen",
            maxDistance: "Maximale Entfernung vom Büro",
            minPiCover: "Haftpflicht Mindestdeckungssumme",
            noRequirement: "Keine Anforderung",
        },
    },
    en: {
        archive: "Archive",
        daysLeft: "{{count}} days left",
        datePassed: "Date passed",
        enquiryId: "Enquiry #{{id}}",
        purpose: "Purpose of Valuation",

        status: {
            title: "Status:",
            ACCEPTED: "ACCEPTED",
            CANCELLED: "CANCELLED",
            DORMANT: "DORMANT",
            PENDING: "PENDING",
            REJECTED: "REJECTED",
            WITHDRAWN: "WITHDRAWN",
        },
        requestedValuationTitle: "Requested Valuation",
        valuationRequirementsTitle: "Valuation Requirements",
        valuationRequirements: {
            standardAndType: "Standard & Type",
            expectedDelivery: "Expected delivery",
            specificScopes: "Specific Scopes",
            purpose: "Purpose of Valuation",
            additionalRequirements: "Additional Requirements",
        },
        valuerRequirementsTitle: "Valuer Requirements",
        valuerRequirements: {
            qualification: "Qualification",
            minExperience: "Min. Post-Qualification",
            minPartners: "Min. Partners in Firm",
            maxDistance: "Max. Distance",
            minPiCover: "Min. PI Cover",
            noRequirement: "No requirement specified",
        },
    },
};
