import { VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    children: ReactNode | ReactNode[];
};

const WvwVStack = (props: PropTypes) => {
    const { children } = props;

    return (
        <VStack
            align="left"
            w="100%"
        >
            {children}
        </VStack>
    );
};

export default WvwVStack;
