import { VStack } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikForm } from '../../../../../common/forms';
import { useMyEntity } from '../../../../../utils/api/hooks';
import EditCompanyMarketingDocs from '../../../account/documents/common/EditCompanyMarketingDocs';
import EditCompanySampleValuations from '../../../account/documents/common/EditCompanySampleValuations';
import { FlowContext } from '../../../../../common/components/display/ProcessFlowRouted';
import ProfileCompletionFormFooter from './ProfileCompletionFormFooter';
import { useSmartTranslation } from '../../../../../common/hooks';
import FormSectionProfileEdit from '../../../profile/components/FormSectionProfileEdit';

const ProfileCompletionExtras = () => {
    const t = useSmartTranslation('profile');

    const { goNext } = useContext(FlowContext);

    const [saveAndClose, setSaveAndClose] = useState(false);

    const navigate = useNavigate();

    const {
        data: entity,
        updateEntity,
    } = useMyEntity({
        onUpdateSuccess: () => {
            if (saveAndClose) {
                navigate('/dashboard', { replace: true });
            } else {
                goNext();
            }
        },
    });

    return (
        <FormikForm
            initialValues={{
                valuationTypes: entity?.valuationTypes || [],
            }}
            onSubmit={values => {
                updateEntity({
                    valuationTypes: values.valuationTypes,
                });
            }}
        >
            <VStack spacing={4}>
                <FormSectionProfileEdit
                    header={t('profileCompletionWizard.extraDocs.valuationSamples.title')}
                    subHeader={t('profileCompletionWizard.extraDocs.valuationSamples.subtitle')}
                >
                    <EditCompanySampleValuations />
                </FormSectionProfileEdit>

                <FormSectionProfileEdit
                    header={t('profileCompletionWizard.extraDocs.marketingAwards.title')}
                    subHeader={t('profileCompletionWizard.extraDocs.marketingAwards.subtitle')}
                >
                    <EditCompanyMarketingDocs />
                </FormSectionProfileEdit>

                <ProfileCompletionFormFooter
                    handleSaveAndClose={() => setSaveAndClose(true)}
                />
            </VStack>
        </FormikForm>
    );
};

export default ProfileCompletionExtras;
