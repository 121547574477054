import {
    Flex,
    List,
    ListItem,
    Text,
} from '@chakra-ui/react';
import { useSmartTranslation } from '../../../../common/hooks';
import { UserType } from '../../../../types';
import FormSectionProfileEdit from './FormSectionProfileEdit';

type PropsType = {
    user: UserType;
};

const ProfileValuationOccasions = (props: PropsType) => {
    const { user } = props;

    const {
        valuationOccasions = [],
        otherValuationOccasions = [],
    } = user;

    const t = useSmartTranslation('profile');

    return (
        <FormSectionProfileEdit header={t('heading.valuationOccasions')}>
            <Flex
                alignItems="flex-start"
                minH="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('valuationCompetence.valuationOccasions')}
                </Text>

                <List>
                    {valuationOccasions.length === 0
                        && otherValuationOccasions.length === 0 && (
                        <ListItem color="wvwGrey40">
                            {t('none', { context: 'female', ns: 'common' })}
                        </ListItem>
                    )}

                    {valuationOccasions.sort().map(valuation => (
                        <ListItem key={valuation}>
                            {t(valuation, { ns: 'formik', defaultValue: valuation })}
                        </ListItem>
                    ))}

                    {otherValuationOccasions.sort().map(valuation => (
                        <ListItem key={valuation}>
                            {valuation}
                        </ListItem>
                    ))}
                </List>
            </Flex>
        </FormSectionProfileEdit>
    );
};

export default ProfileValuationOccasions;
