import {
    Button,
    Center,
    Heading,
    Link as ChakraLink,
    Text,
    VStack,
    Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { OfferType } from '../../../../../types';
import { useOfferGetByEnquiryId } from '../../../../../utils/api/hooks';
import DataTable from '../../../../../common/components/datatable/DataTable';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import delimitNumber from '../../../../../common/functions/delimitNumber';
import StatusBadgeOffer from '../../../../../common/components/display/StatusBadgeOffer';

const ClientOffersOverview = () => {
    const { t } = useTranslation(['offer']);

    const { enquiryId } = useParams();

    const {
        data: clientOffers = [],
        isLoading: clientOffersLoading,
        error: clientOffersError,
    } = useOfferGetByEnquiryId(Number(enquiryId));

    if (clientOffersLoading) return <LoadingSpinner />;

    if (clientOffersError) return (<Center>Something went wrong</Center>);

    const headers = [
        {
            title: t('offerTable.header.company', { ns: 'dashboard' }),
            render: (i: OfferType) => i.fromEntity.name,
            sorter: (a: OfferType, b: OfferType) => (
                a.fromEntity.name.localeCompare(b.fromEntity.name)
            ),
        },
        {
            title: t('offerTable.header.valuer', { ns: 'dashboard' }),
            dataIndex: 'valuer',
            render: (i: OfferType) => `${i.fromValuer.firstName} ${i.fromValuer.lastName}`,
            sorter: (a: OfferType, b: OfferType) => {
                const aName = `${a.fromValuer.firstName} ${a.fromValuer.lastName}`;
                const bName = `${b.fromValuer.firstName} ${b.fromValuer.lastName}`;

                return aName.localeCompare(bName);
            },
        },
        {
            title: t('offerTable.header.price', { ns: 'dashboard' }),
            dataIndex: 'price',
            sorter: (a: OfferType, b: OfferType) => a.price - b.price,
            render: (i: OfferType) => (
                <Text textAlign="left">
                    {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(i.price)}`}
                </Text>
            ),
        },
        {
            title: t('offerTable.header.availability', { ns: 'dashboard' }),
            dataIndex: 'availability',
            sorter: (a: OfferType, b: OfferType) => {
                if (b.availability.replace(/-/g, '') < a.availability.replace(/-/g, '')) {
                    return -1;
                }
                if (b.availability.replace(/-/g, '') > a.availability.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: t('offerTable.header.status', { ns: 'dashboard' }),
            dataIndex: 'status',
            render: (i: OfferType) => <StatusBadgeOffer status={i.status} />,
            sorter: (a: OfferType, b: OfferType) => a.status.localeCompare(b.status),
        },
        {
            title: t('offerTable.header.action', { ns: 'dashboard' }),
            render: (i: OfferType) => (
                <ChakraLink
                    as={ReactLink}
                    to={`/dashboard/enquiry/${enquiryId}/offer/${i.id}`}
                >
                    <Button
                        borderRadius="30"
                        size="sm"
                        variant="primaryYellow"
                    >
                        {t('button.viewOffer', { ns: 'common' })}
                    </Button>
                </ChakraLink>
            ),
        },
    ];

    return (
        <VStack
            spacing="0"
            w="100%"
        >
            {clientOffers.length === 0 && (
                <VStack
                    align="left"
                    bg="wvwYellow10"
                    borderRadius="10"
                    outline="wvwYellow"
                    paddingBlock="1.2rem"
                    paddingInline="1rem"
                    w="100%"
                >
                    <Heading
                        as="h4"
                        color="wvwYellow"
                        size="md"
                    >
                        {t('awaitingOffers.sectionTitle', { ns: 'enquiries' })}
                    </Heading>

                    <Text>
                        {t('awaitingOffers.blurb', { ns: 'enquiries' })}
                    </Text>
                </VStack>
            )}

            {clientOffers.length > 0 && (
                <Box w="100%">
                    <DataTable
                        columns={headers}
                        data={clientOffers}
                        noDataText={t('noOffers', { ns: 'enquiries' })}
                    />
                </Box>
            )}
        </VStack>
    );
};

export default ClientOffersOverview;
