import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../../utils/firebase";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";

const Logout = () => {
    const t = useSmartTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        navigate("/login");
    }, []);

    return <div>{t("common.loading")}</div>;
};

export default Logout;
