import { VStack } from '@chakra-ui/react';
import { WvwText } from '../../../../../common/components/typography';
import { FormikCheckboxGroup } from '../../../../../common/forms';
import { useSmartTranslation } from '../../../../../common/hooks';
import {
    agriculturePropertyTypes,
    commercialPropertyTypes,
    developmentPropertyTypes,
    landPropertyTypes,
    residentialPropertyFlatTypes,
    residentialPropertyHouseTypes,
    residentialPropertyOtherTypes,
    specialPropertyTypes,
} from '../../../../../common/vars/valuationsAndMembershipTypes';

type CheckboxListProps = {
    data: {
        label?: string;
        name: string;
        options: string[];
    };
};

const CheckboxList = (props: CheckboxListProps) => {
    const t = useSmartTranslation('formik');

    const { data } = props;

    return (
        <VStack
            width="100%"
            align="left"
            padding="1rem"
        >
            <WvwText
                color="wvwGreen"
            >
                {data.label}
            </WvwText>

            <FormikCheckboxGroup
                variant="outline"
                name={data.name}
                options={data.options.map(
                    item => ({
                        label: t(item, { ns: 'formik', defaultValue: item }),
                        value: item,
                    }),
                )}
            />
        </VStack>
    );
};

type PropTypes = {
    propertyType: string;
};

const PropertyTypesChecklist = (props: PropTypes) => {
    const t = useSmartTranslation('enquiryform');

    const { propertyType } = props;

    const residentialHouse = {
        label: t('newEnquiry.addingProperty.propertyDescription.type.residentialTypes.house'),
        name: 'residential',
        options: residentialPropertyHouseTypes,
    };

    const residentialFlat = {
        label: t('newEnquiry.addingProperty.propertyDescription.type.residentialTypes.flat'),
        name: 'residential',
        options: residentialPropertyFlatTypes,
    };

    const residentialOther = {
        label: t('newEnquiry.addingProperty.propertyDescription.type.residentialTypes.other'),
        name: 'residential',
        options: residentialPropertyOtherTypes,
    };

    const commercial = {
        name: 'commercial',
        options: commercialPropertyTypes,
    };

    const land = {
        name: 'land',
        options: landPropertyTypes,
    };

    const agricultural = {
        name: 'agricultural',
        options: agriculturePropertyTypes,
    };

    const development = {
        name: 'development',
        options: developmentPropertyTypes,
    };

    const special = {
        name: 'special',
        options: specialPropertyTypes,
    };

    switch (propertyType) {
        case ('Residential'):
            return (
                <VStack w="100%">
                    <CheckboxList
                        data={residentialHouse}
                    />

                    <CheckboxList
                        data={residentialFlat}
                    />

                    <CheckboxList
                        data={residentialOther}
                    />
                </VStack>
            );
        case ('Commercial'):
            return (
                <CheckboxList
                    data={commercial}
                />
            );
        case ('Land'):
            return (
                <CheckboxList
                    data={land}
                />
            );
        case ('Agricultural'):
            return (
                <CheckboxList
                    data={agricultural}
                />
            );
        case ('Development'):
            return (
                <CheckboxList
                    data={development}
                />
            );
        case ('Special'):
            return (
                <CheckboxList
                    data={special}
                />
            );
        default:
            return (
                null
            );
    }
};

export default PropertyTypesChecklist;
