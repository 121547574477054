import { useSmartTranslation } from '../../common/hooks';
import SuccessScreen from '../wizard-success/SuccessScreen';

const RegisterSuccess = () => {
    const t = useSmartTranslation('common');

    return (
        <SuccessScreen
            bodyText={t('registerSuccess')}
            buttonText={t('button.goToDashboard')}
            navLink="../dashboard"
        />
    );
};

export default RegisterSuccess;
