import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { ContentBox, WvwIcon } from '../../../../../common/components/display';
import { WvwText } from '../../../../../common/components/typography';
import { WvwButton } from '../../../../../common/components/inputs';
import { useSmartTranslation } from '../../../../../common/hooks';
import AddReportModal from './AddReportModal';

type PropTypes = {
    caseId: number;
};

const AddReportBanner = (props: PropTypes) => {
    const { caseId } = props;

    const t = useSmartTranslation('casereport');

    const [addReportModalOpen, setAddReportModalOpen] = useState(false);

    return (
        <>
            <ContentBox color="wvwYellow10">
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <WvwText>
                        {t('details.addReportDescription')}
                    </WvwText>

                    <WvwButton
                        icon={<WvwIcon icon="add" color="white" />}
                        content={t('button.addReport')}
                        size="sm"
                        onClick={() => setAddReportModalOpen(true)}
                    />
                </Flex>
            </ContentBox>

            <AddReportModal
                isOpen={addReportModalOpen}
                caseId={caseId}
                onClose={() => setAddReportModalOpen(false)}
            />
        </>
    );
};

export default AddReportBanner;
