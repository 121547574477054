import { Route, Routes } from "react-router-dom";
import Register from "../../../../views/register/Register";
import { ClientScreens } from "../../screens";
import Logout from "../../screens/platform/logout/Logout";
import ForgotPassword from "../../screens/public/forgot-password/ForgotPassword";
import LoginFlow from "../../screens/public/login/LoginFlow";
import PublicScreen from "../../screens/public/screen/PublicScreen";
import { ThemeV1Wrapper } from "../ThemeV1Wrapper";
import { global_routes } from "./global";

const registration = (
    <Route
        path="/register"
        element={<ThemeV1Wrapper />}
    >
        <Route
            path=":registerAsType"
            element={<Register />}
        />
        <Route
            path=":inviteType/:token"
            element={<Register />}
        />
        <Route
            index
            element={<Register />}
        />
    </Route>
);

export const routes_unauthorized = (
    <Routes>
        {registration}
        
        <Route element={<PublicScreen />}>
            <Route
                path="/enquiries"
                element={<ClientScreens.EnquiryOverview />}
            >
                <Route
                    path="/enquiries/:enquiryId"
                    element={<ClientScreens.EnquiryOverview />}
                />
                <Route path="/enquiries/offer/:offerId" />
            </Route>

            <Route element={<ThemeV1Wrapper />}>
                <Route
                    path="*"
                    element={<LoginFlow />}
                />
            </Route>

            <Route
                path="/logout"
                element={<Logout />}
            />
        </Route>

        <Route element={<ThemeV1Wrapper />}>
            <Route
                path="forgot-password"
                element={<ForgotPassword />}
            />
        </Route>

        {global_routes}
    </Routes>
);
