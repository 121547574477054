import { UserType } from "../../../../types";
import { translations } from "./i18n";

export type User = UserType & {
    emailVerified: boolean;
};

export const User = {
    translations,
};
