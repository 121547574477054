import { Flex, Text } from '@chakra-ui/react';
import { useSmartTranslation } from '../../../../common/hooks';
import { UserType } from '../../../../types';
import FormSectionProfileEdit from './FormSectionProfileEdit';
import { WvwIcon } from '../../../../common/components/display';
import GoogleMap from '../../../../common/components/GoogleMap';

type PropsType = {
    user: UserType;
    userIsValuer: boolean;
};

const ProfileOfficeDetails = (props: PropsType) => {
    const { user, userIsValuer } = props;

    const t = useSmartTranslation('profile');

    const renderFieldData = (
        field: string,
        data: (string | number)[],
        config?: {
            required?: true;
            joinWith?: string;
        },
    ) => {
        const { required, joinWith = ' ' } = config || {};

        if (data.every(item => item)) {
            return (
                <Text>
                    {data.join(joinWith)}
                </Text>
            );
        }

        return (
            <Text color="wvwGrey40">
                {required && (
                    <WvwIcon icon="warning" />
                )}
                {' '}
                {t(`fieldNotProvided.${field}`, { ns: 'common', defaultValue: field })}
            </Text>
        );
    };

    return (
        <FormSectionProfileEdit
            header={t('heading.officeLocation')}
        >
            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t('profileDetails.location')}
                </Text>

                {renderFieldData('location', [user?.street, user?.postalCode, user?.city, user?.country], { joinWith: ', ', required: true })}
            </Flex>

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t('profileDetails.officeTelNumber')}
                </Text>

                {renderFieldData('officeTelNumber', [user?.officeTelDialCode, user?.officeTel])}
            </Flex>

            <Flex
                alignItems="center"
                h="2rem"
                w="100%"
            >
                <Text
                    maxW="17rem"
                    w="100%"
                >
                    {t('profileDetails.officeFaxNumber')}
                </Text>

                {renderFieldData('officeFaxNumber', [user?.officeFaxDialCode, user?.officeFax])}
            </Flex>

            {userIsValuer && (
                <GoogleMap
                    latitude={user?.latitude}
                    longitude={user?.longitude}
                />
            )}
        </FormSectionProfileEdit>
    );
};

export default ProfileOfficeDetails;
