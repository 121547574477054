import { useSmartTranslation } from '../../../../common/hooks';
import { CaseCreateUploadedContractDto } from '../../dto/case-contract';
import { caseCreateUploadedContract } from '../../endpoints';
import useMutationHook from '../useMutationHook';
import useCaseGetUploadedContracts from './useCaseGetUploadedContracts';

type ParamTypes = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useCaseCreateUploadedContract = (params: ParamTypes) => {
    const { caseId, onSuccess, onError } = params;

    const t = useSmartTranslation('casecontract');

    const { refresh: refreshContractList } = useCaseGetUploadedContracts(caseId);

    const mutation = useMutationHook({
        mutationFunction: (dto: CaseCreateUploadedContractDto) => caseCreateUploadedContract(dto),
        refreshes: [refreshContractList],
        successMessage: t('toast.contractUploaded'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useCaseCreateUploadedContract;
