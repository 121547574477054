import {
    MenuButton,
    Button,
    MenuList,
    MenuItem,
    Menu,
    HStack,
    Text,
    Link as ChakraLink,
    Tooltip,
} from '@chakra-ui/react';
import { MouseEventHandler, ReactNode } from 'react';
import { Link as ReactLink } from 'react-router-dom';

type PropTypes = {
    active?: string;
    menu: {
        menuTitle?: string;
        rightIcon?: JSX.Element;
        leftIcon?: JSX.Element;
        size?: string;
        buttonVariant?: string;
        menuList: {
            onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
            key: string;
            nav?: string;
            element?: ReactNode;
            icon?: JSX.Element;
            isDisabled?: boolean;
            name?: string;
            tooltip?: string;
        }[];
    };
};

const DropdownMenu = (props: PropTypes) => {
    const {
        active,
        menu,
    } = props;

    const menuActiveState = () => {
        if (active === menu.menuTitle) {
            return 'var(--action-disabledBackground, #00000014)';
        }
        return 'transparent';
    };

    return (
        <Menu variant="none">
            <MenuButton
                as={Button}
                rightIcon={menu.rightIcon}
                leftIcon={menu.leftIcon}
                size={menu.size}
                color="white"
                bgColor={menuActiveState()}
                borderRadius="8"
                paddingInline=".8rem"
                paddingBlock=".4rem"
                h="min-content"
                _hover={{
                    bgColor: 'var(--action-disabledBackground, #00000014)',
                }}
                _active={{
                    bgColor: 'var(--action-disabledBackground, #00000014)',
                }}
            >
                {menu.menuTitle}
            </MenuButton>

            <MenuList
                margin="1rem"
                color="wvwGrey80"
                boxShadow="0 0 8px lightwvwGrey"
                borderRadius="10"
            >
                {menu.menuList.map(listItem => (
                    <Tooltip
                        key={listItem.key}
                        label={listItem.tooltip}
                    >
                        <ChakraLink
                            as={ReactLink}
                            to={listItem.nav}
                            onClick={listItem.onClick}
                        >
                            <MenuItem
                                paddingBlock=".8rem"
                                isDisabled={listItem.isDisabled}
                            >
                                <HStack spacing="2">
                                    {listItem.icon}

                                    <Text>
                                        {listItem.name}
                                    </Text>
                                </HStack>
                            </MenuItem>
                        </ChakraLink>
                    </Tooltip>
                ))}
            </MenuList>
        </Menu>
    );
};

DropdownMenu.defaultProps = {
    active: '',
};

export default DropdownMenu;
