import {
    Box,
    Button,
    Highlight,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from "@chakra-ui/react";
import { DocumentUpload } from "iconsax-react";
import { DragEvent, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { allowedFileSize } from "../../../../../common/vars/file-uploads/file-standards";
import FormikForm from "../../forms/FormikForm";
import FormikInput from "../../forms/FormikInput";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";

type SupportedFileType =
    | ".csv"
    | ".gif"
    | ".jpeg"
    | ".jpg"
    | ".pdf"
    | ".png"
    | ".xls"
    | ".xlsx";

type PropTypes = {
    customUploadButton?: React.ReactNode;
    defaultFileName?: string;
    fileTypes?: SupportedFileType[];
    noRename?: boolean;
    onChange: (files: File[]) => void;
};

const FileUploadInput = (props: PropTypes) => {
    const t = useSmartTranslation();

    const {
        customUploadButton,
        defaultFileName,
        fileTypes,
        noRename,
        onChange,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [dragActive, setDragActive] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [files, setFiles] = useState<File[]>([]);

    const name = Math.random().toString(36).substring(2, 15);

    const checkFile = (f: File) => {
        const fileExtension =
            `.${f.name.split(".").pop()?.toLowerCase()}` || "";

        if (fileTypes && !fileTypes.some((i) => i === fileExtension)) {
            setError(t("fileUpload.fileTypeNotAllowed"));
            setFiles([]);
            return false;
        }

        if (allowedFileSize && f.size > allowedFileSize) {
            setError(t("fileUpload.fileTooLarge"));
            setFiles([]);
            return false;
        }

        setError(undefined);
        return true;
    };

    const handleDrag = (e: DragEvent<HTMLFormElement | HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (!checkFile(e.dataTransfer.files[0])) {
                return;
            }

            const fList = [];

            for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
                fList.push(e.dataTransfer.files[i]);
            }

            setFiles(fList);
        }
    };

    useEffect(() => {
        if (!noRename) return;
        if (files.length === 0) return;

        onChange(files);
    }, [files]);

    useEffect(() => {
        if (inputRef.current) {
            const dataTransfer = new DataTransfer();
            files.forEach((file) => dataTransfer.items.add(file));
            inputRef.current.files = dataTransfer.files;
        }
    }, [files]);

    return (
        <>
            <div
                style={{ width: customUploadButton ? "auto" : "100%" }}
                onDragEnter={handleDrag}
            >
                <label
                    id={`label-file-upload-${name}`}
                    htmlFor={`input-file-upload-${name}`}
                >
                    {customUploadButton || (
                        <Box
                            border={`${dragActive ? 2 : 1}px dashed`}
                            borderColor="wvwGreen"
                            borderRadius="4"
                            marginBlock={dragActive ? "1px" : "2px"}
                            paddingBlock="1rem"
                            w="100%"
                            _hover={{
                                borderWidth: "2px",
                                cursor: "pointer",
                                marginBlock: "1px",
                            }}
                        >
                            <VStack
                                align="center"
                                spacing="2"
                                w="100%"
                            >
                                <DocumentUpload />

                                <Text align="center">
                                    <Highlight
                                        query={t("fileUpload.browseKeyword")}
                                        styles={{
                                            color: "wvwGreen",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("fileUpload.dragDropBrowse")}
                                    </Highlight>
                                </Text>

                                <Text variant="secondary">
                                    {t("fileUpload.maxFileSize")}
                                </Text>
                            </VStack>
                        </Box>
                    )}

                    {error && <Text color="red">{error}</Text>}

                    <input
                        id={`input-file-upload-${name}`}
                        accept={fileTypes ? fileTypes.join(",") : undefined}
                        ref={inputRef}
                        hidden
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        onChange={(event) => {
                            event.preventDefault();

                            if (event.target.files && event.target.files[0]) {
                                if (!checkFile(event.target.files[0])) {
                                    return;
                                }

                                const fList = [];

                                for (
                                    let i = 0;
                                    i < event.target.files.length;
                                    i += 1
                                ) {
                                    if (
                                        i === 0 &&
                                        defaultFileName !== undefined
                                    ) {
                                        let file = event.target.files[i];
                                        const extention = `.${file?.name
                                            .split(".")
                                            .pop()}`.toLowerCase();

                                        file = new File(
                                            [file as Blob],
                                            `${defaultFileName}${extention}`,
                                            {
                                                type: file?.type,
                                            }
                                        );

                                        fList.push(file);
                                    } else {
                                        fList.push(event.target.files[i]);
                                    }
                                }

                                setFiles(fList);
                            }
                        }}
                    />
                </label>

                {dragActive && (
                    <div
                        id="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            borderRadius: "1rem",
                            top: "0px",
                            right: "0px",
                            bottom: "0px",
                            left: "0px",
                        }}
                    />
                )}
            </div>

            <Modal
                isOpen={!noRename && files.length > 0}
                onClose={() => setFiles([])}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>
                        {t("fileUpload.button.uploadFile")}
                    </ModalHeader>

                    <FormikForm
                        initialValues={{
                            fileNames: files.map((f) => f.name),
                        }}
                        onSubmit={(values) => {
                            const { fileNames } = values;

                            const fList = files;

                            files.forEach((file, index) => {
                                let formattedFilename = fileNames[index].trim();
                                let uploadFile = file as File;

                                if (formattedFilename !== file?.name) {
                                    const extention =
                                        file?.name
                                            ?.split(".")
                                            ?.pop()
                                            ?.toLowerCase() || "";

                                    if (
                                        formattedFilename
                                            .split(".")
                                            .pop()
                                            .toLowerCase() !== extention
                                    ) {
                                        formattedFilename += `.${extention}`;
                                    }

                                    uploadFile = new File(
                                        [file as Blob],
                                        formattedFilename as string,
                                        {
                                            type: file?.type,
                                        }
                                    );
                                }

                                files[index] = uploadFile;
                            });

                            onChange(fList);

                            setFiles([]);
                        }}
                        validationSchema={Yup.object().shape({
                            fileNames: Yup.array().of(
                                Yup.string().required(
                                    t("fileUpload.fileNameRequired")
                                )
                            ),
                        })}
                    >
                        <ModalBody>
                            <VStack>
                                {files.map((i, index) => (
                                    <VStack
                                        key={i.name}
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="100%"
                                        >
                                            {t("fileUpload.file")}
                                            {` #${index + 1}`}
                                        </Text>

                                        <FormikInput
                                            name={`fileNames.${index}`}
                                        />
                                    </VStack>
                                ))}
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                mr={3}
                                variant="ghost"
                                onClick={() => setFiles([])}
                            >
                                {t("common.button.cancel")}
                            </Button>

                            <Button
                                colorScheme="wvwGreen"
                                type="submit"
                                variant="primary"
                            >
                                {t("fileUpload.button.upload")}
                            </Button>
                        </ModalFooter>
                    </FormikForm>
                </ModalContent>
            </Modal>
        </>
    );
};

FileUploadInput.defaultProps = {
    customUploadButton: undefined,
    defaultFileName: undefined,
    fileTypes: undefined,
    noRename: false,
};

export default FileUploadInput;
