import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    children: ReactNode | ReactNode[];
};

const CaseModuleContainer = ({ children }: PropTypes) => (
    <Box
        bg="white"
        borderRadius="10"
        paddingBlock="1rem"
        paddingInline="1.5rem"
        w="100%"
    >
        {children}
    </Box>
);

export default CaseModuleContainer;
