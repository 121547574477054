import axios from "axios";
import { auth } from "../../firebase";

const { REACT_APP_API_URL } = process.env;

const get = async (path: string, params?: { [index: string]: unknown }) => {
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
        throw new Error(`No token: ${path}`);
    }

    const result = await axios
        .get(`${REACT_APP_API_URL}${path}`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .catch((error) => {
            throw new Error(
                error?.response?.data?.message ||
                    error?.response?.statusText ||
                    "Unknown error"
            );
        });

    return result?.data;
};

export default get;
