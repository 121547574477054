import { DocumentRequestCreateCommentDto } from '../../dto';
import { documentRequestCreateComment } from '../../endpoints';
import useDocumentRequestGetComments from './useDocumentRequestGetComments';
import useMutationHook from '../useMutationHook';

type ParamsType = {
    requestId: string | number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useDocumentRequestCreateComment = (params: ParamsType) => {
    const {
        requestId,
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshComments } = useDocumentRequestGetComments(requestId);

    const mutation = useMutationHook({
        mutationFunction:
            (dto: DocumentRequestCreateCommentDto) => documentRequestCreateComment(dto),
        refreshes: [refreshComments],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useDocumentRequestCreateComment;
