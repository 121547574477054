import { CheckIcon } from '@chakra-ui/icons';
import { Clock, InfoCircle } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRole } from '../../../../../common/hooks';
import { InspectionType } from '../../../../../types';
import InspectionLayout from './InspectionLayout';
import { DropdownMenu } from '../../../../../common/components/inputs';
import { useInspectionReportOutcome, useInspectionReportProblem } from '../../../../../utils/api/hooks';
import InspectionConfirmationModal from './InspectionConfirmationModal';

type PropTypes = {
    inspection: InspectionType;
};

const InspectionInspection = (props: PropTypes) => {
    const { inspection } = props;

    const [event] = inspection.cause.calendarEntries;

    const { t, i18n } = useTranslation('inspection');
    const { userIsValuer } = useRole();

    const [confirmModalState, setConfirmModalState] = useState<'CLOSED' | 'COMPLETE' | 'RESCHEDULE'>('CLOSED');

    const { update: reportOutcome } = useInspectionReportOutcome({ caseId: inspection.caseId });
    const { update: reportProblem } = useInspectionReportProblem({ caseId: inspection.caseId });

    const actionButton = useMemo(() => {
        let options = [
            {
                icon: <Clock />,
                text: t('button.reschedule'),
                onClick: () => setConfirmModalState('RESCHEDULE'),
            },
            {
                icon: <InfoCircle />,
                text: t('link.reportAProblem', { ns: 'common' }),
                onClick: () => reportProblem({
                    comment: 'Die Besichtigung hatte ein Problem',
                    target: inspection.id,
                }),
            },
        ];

        if (userIsValuer) {
            options = [
                {
                    icon: <CheckIcon boxSize="1.2rem" />,
                    text: t('button.markComplete'),
                    onClick: () => setConfirmModalState('COMPLETE'),
                },
                ...options,
            ];
        }

        return <DropdownMenu options={options} />;
    }, [i18n.language]);

    return (
        <>
            <InspectionLayout
                actionButton={actionButton}
                color="orange"
                inspection={inspection}
            />

            <InspectionConfirmationModal
                content={confirmModalState === 'COMPLETE' ? t('modal.markComplete.content') : t('modal.rescheduleAppointment.content')}
                eventDate={new Date(event.start)}
                eventDuration={event.duration}
                isOpen={confirmModalState !== 'CLOSED'}
                onClose={() => setConfirmModalState('CLOSED')}
                onConfirm={() => {
                    if (confirmModalState === 'COMPLETE') {
                        reportOutcome({
                            outcome: {
                                success: true,
                                comment: 'Die Besichtigung war erfolgreich',
                            },
                            target: inspection.id,
                        });
                    } else if (confirmModalState === 'RESCHEDULE') {
                        reportOutcome({
                            outcome: {
                                success: false,
                                comment: 'Die Besichtigung muss neu terminiert werden',
                            },
                            target: inspection.id,
                        });
                    }
                }}
                property={inspection.property}
                title={confirmModalState === 'COMPLETE' ? t('modal.markComplete.title') : t('modal.rescheduleAppointment.title')}
            />
        </>
    );
};

export default InspectionInspection;
