import {
    Flex, Grid, SimpleGrid, VStack, Box, Icon, Tooltip,
} from '@chakra-ui/react';
import {
    FaFileInvoiceDollar,
    FaCalendarCheck,
    FaCalendarDay,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { AiFillCalendar } from 'react-icons/ai';
import { WvwText, WVWTitle } from '../../../../common/components/typography';
import { ContentBox } from '../../../../common/components/display';

const CaseCalendarInfo = () => {
    const { t } = useTranslation('activecases');

    const calendarEvents = [
        {
            date: '01/08/2024',
            day: 1,
            type: t('caseOverview.calendar.inspectionTypes.tentativeInspectionDate'),
            icon: FaCalendarDay,
        },
        {
            date: '09/08/2024',
            day: 9,
            type: t('caseOverview.calendar.inspectionTypes.fixedInspectionDate'),
            icon: FaCalendarCheck,
        },
        {
            date: '15/08/2024',
            day: 15,
            type: t('caseOverview.calendar.inspectionTypes.expectedReportDelivery'),
            icon: AiFillCalendar,
        },
        {
            date: '20/08/2024',
            day: 20,
            type: t('caseOverview.calendar.inspectionTypes.expectedPaymentRelease'),
            icon: FaFileInvoiceDollar,
        },
    ];

    const daysOfWeek = [
        t('caseOverview.calendar.daysOfWeek.sun'),
        t('caseOverview.calendar.daysOfWeek.mon'),
        t('caseOverview.calendar.daysOfWeek.tue'),
        t('caseOverview.calendar.daysOfWeek.wed'),
        t('caseOverview.calendar.daysOfWeek.thu'),
        t('caseOverview.calendar.daysOfWeek.fri'),
        t('caseOverview.calendar.daysOfWeek.sat'),
    ];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

    const getEventForDay = (day: number) => calendarEvents.find(event => event.day === day);

    return (
        <ContentBox color="white">
            <WVWTitle
                level="2"
                content={t('caseOverview.calendar.importantDates')}
                color="black"
            />

            <WVWTitle
                level="3"
                content={`(${currentMonth + 1}/${currentYear})`}
                color="black"
            />

            <Flex
                direction={{ base: 'column', lg: 'row' }}
                gap={6}
                paddingTop="1rem"
            >
                <Box flex="1">
                    <Grid
                        templateColumns="repeat(7, 1fr)"
                        gap={1}
                        mb={3}
                    >
                        {daysOfWeek.map(day => (
                            <Box key={day} p={2} textAlign="center">
                                {day}
                            </Box>
                        ))}
                    </Grid>

                    <SimpleGrid columns={7} spacing={1}>
                        {Array.from({ length: firstDayOfMonth }).map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Box key={index} />
                        ))}
                        {Array.from({ length: daysInMonth }).map((_, index) => {
                            const day = index + 1;
                            const event = getEventForDay(day);
                            const isToday = day === currentDay;
                            let backgroundColor;
                            if (event) {
                                backgroundColor = 'wvwYellow';
                            } else if (isToday) {
                                backgroundColor = 'wvwGreen';
                            } else {
                                backgroundColor = 'wvwGrey10';
                            }

                            return (
                                <Tooltip key={day} label={event?.type} aria-label="A tooltip">
                                    <Box
                                        p={3}
                                        borderRadius="lg"
                                        h="5rem"
                                        textAlign="center"
                                        bg={backgroundColor}
                                    >
                                        <WvwText color={event || isToday ? 'white' : 'black'}>
                                            {day}
                                        </WvwText>
                                        {event && <Icon as={event.icon} w={5} h={5} color={event || isToday ? 'white' : 'black'} />}
                                    </Box>
                                </Tooltip>
                            );
                        })}
                    </SimpleGrid>
                </Box>

                <Box flex="1">
                    <VStack spacing={4} align="stretch">
                        {calendarEvents.map(event => (
                            <Box
                                key={event.date}
                                p={4}
                                borderWidth={1}
                                borderRadius="lg"
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Icon as={event.icon} w={8} h={8} color="wvwGreen" mr={4} />
                                <VStack align="flex-start">
                                    <WvwText color="black">
                                        {event.date}
                                    </WvwText>
                                    <WvwText>
                                        {event.type}
                                    </WvwText>
                                </VStack>
                            </Box>
                        ))}
                    </VStack>
                </Box>
            </Flex>
        </ContentBox>
    );
};

export default CaseCalendarInfo;
