import {
    VStack,
} from '@chakra-ui/react';
import CaseOverviewInfo from './CaseOverviewInfo';
import CaseCalendarInfo from './CaseCalendarInfo';
import CaseEnquirySummary from './components/CaseEnquirySummary';
import { useParams } from 'react-router-dom';
import { EnquiryType } from '../../../../types';
import { useCaseGetCase, useEnquiry } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import CaseOfferSummary from './components/CaseOfferSummary';

const ReducedCaseOverview = () => {
    const { caseId = '' } = useParams();

    const {
        data: caseData,
        isLoading: caseIsLoading,
    } = useCaseGetCase(caseId);

    if (caseIsLoading) return <LoadingSpinner />;

    return (
        <VStack
            align="start"
            width="100%"
            spacing="1rem"
        >
            <CaseEnquirySummary enquiry={caseData?.enquiry} />

            <CaseOfferSummary />
        </VStack>
    );
};

export default ReducedCaseOverview;
