// Draggable object types as string constants
export const DOCUMENT_IN_ACTION_AREA = 'DOCUMENT_IN_ACTION_AREA';
export const DOCUMENT_IN_REQUEST = 'DOCUMENT_IN_REQUEST';

// Draggable object data payload types
export type DocumentInActionAreaType = {
    documentId: number;
    originalIndex: number;
    type: typeof DOCUMENT_IN_ACTION_AREA;
};

export type DocumentInRequestType = {
    documentId: number;
    originalIndex: number;
    type: typeof DOCUMENT_IN_REQUEST;
};

export const dragTargetTypes = {
    DOCUMENT_FROM_ACTION_AREA: 'DOCUMENT_FROM_ACTION_AREA',
    DOCUMENT_MOVE: 'DOCUMENT_MOVE', // Move document from one request to another
    REQUEST_IMPORT: 'REQUEST_IMPORT', // Move a request from the Action Area into a Document Request
    REQUEST_MOVE: 'REQUEST_MOVE', // Move a request from one folder to another
    TEMPLATE_IMPORT: 'TEMPLATE_IMPORT', // Move a template from the Action Area into a Document Request
    TEMPLATE_REQUEST_IMPORT: 'TEMPLATE_REQUEST_IMPORT', // Move a template request from the Action Area into a Document Request
};

// Action Area Document
export type DocumentFromActionAreaDragType = {
    documentId: number;
    index: number;
    type: typeof dragTargetTypes.DOCUMENT_FROM_ACTION_AREA;
};

// Source
export type DocumentDragSourceType = {
    documentId: number;
};

export type RequestDragSourceType = {
    requestId: number;
};

export type TemplateDragSourceType = {
    templateId: number;
};

// Targets
// Anything that will accept a document drop
export type ActionAreaDocumentListDropTarget = {
    type: 'ACTION_AREA_DOCUMENT_LIST';
};

export type FolderDropTargetType = {
    folderId: number;
};

export type RequestDropTargetType = {
    folderId: number;
    requestId: number;
    type: 'REQUEST';
};

// All new for drag and drop sort
