import {
    Box,
    Heading,
    Button,
    Container,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/components/Footer';

type PropTypes = {
    bodyText?: string;
    buttonText?: string;
    navLink: string;
};

const SuccessScreen = (props: PropTypes) => {
    const navigate = useNavigate();

    const { t } = useTranslation('common');

    const {
        bodyText,
        buttonText,
        navLink,
    } = props;

    return (
        <Box
            bgColor="wvwGreen"
            paddingTop="1.5rem"
            h="100vh"
            w="100%"
        >
            <Container
                maxW="container.lg"
                bg="white"
                borderRadius="lg"
                p="2em"
                w="100%"
                justifyContent="center"
                alignContent="center"
            >
                <VStack
                    paddingBlock="12rem"
                    spacing="4"
                >
                    <CheckCircleIcon boxSize="3rem" color="#38A169" />

                    <Heading
                        as="h2"
                        size="lg"
                        maxW="18rem"
                        textAlign="center"
                    >
                        {t('congratulations')}
                    </Heading>

                    <Heading
                        as="h4"
                        size="sm"
                        color="wvwGrey80"
                        maxW="18rem"
                        textAlign="center"
                    >
                        {bodyText}
                    </Heading>

                    {buttonText && (
                        <Button
                            variant="primaryYellow"
                            width="16rem"
                            onClick={() => navigate(navLink)}
                        >
                            {buttonText}
                        </Button>
                    )}
                </VStack>
            </Container>

            <Footer invertColor />
        </Box>
    );
};

SuccessScreen.defaultProps = {
    bodyText: '',
    buttonText: 'Take me there!',
};

export default SuccessScreen;
