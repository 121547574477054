import { Flex } from '@chakra-ui/react';
import * as Yup from 'yup';
import { useDocumentRequestUploadDocuments } from '../../../../../utils/api/hooks';
import { FormikFileUploadLocal, FormikForm, FormikSubmitButton } from '../../../../../common/forms';
import { useSmartTranslation } from '../../../../../common/hooks';
import { FormikFileUploadDataType } from '../../../../../common/forms/FormikFileUploadLocal';
import WvwVStack from '../../../../../common/components/display/WvwVStack';

type PropTypes = {
    caseId: number;
    requestId: number;
};

const validationSchema = Yup.object().shape({
    files: Yup.array().min(1, 'errors.required'),
});

const UploadDocumentRequestDocuments = (props: PropTypes) => {
    const { caseId, requestId } = props;

    const t = useSmartTranslation('documentmanagement');

    const {
        update: uploadDocuments,
        busy,
    } = useDocumentRequestUploadDocuments({
        caseId: Number(caseId),
        requestId,
    });

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{ files: [] }}
            submitting={busy}
            onSubmit={values => {
                // TODO: automatically type formik values properly
                const filesWithComments = values.files as FormikFileUploadDataType[];

                const files: File[] = [];
                const submissionComments: string[] = [];

                filesWithComments.forEach(file => {
                    files.push(file.file);
                    submissionComments.push(file.comment);
                });

                uploadDocuments({
                    caseId,
                    requestId,
                    files,
                    submissionComments,
                });
            }}
        >
            <WvwVStack>
                <FormikFileUploadLocal
                    name="files"
                    withComment
                />

                <Flex
                    justifyContent="flex-end"
                    w="100%"
                >
                    <FormikSubmitButton
                        block
                        content={t('button.submit', { ns: 'common' })}
                        mustBeClicked
                    />
                </Flex>
            </WvwVStack>
        </FormikForm>
    );
};

export default UploadDocumentRequestDocuments;
