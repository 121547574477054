import FormikForm from "./FormikForm";
import FormikInput from "./FormikInput";
import FormikPassword from "./FormikPassword";
import FormikPinInput from "./FormikPinInput";
import { translations } from "./i18n";

export const Forms = {
    FormikForm,
    FormikInput,
    FormikPassword,
    FormikPinInput,
    translations,
};
