
import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { WVWTitle } from '../../../../../common/components/typography';
import CaseOffer from '../../offer/CaseOffer';

const CaseOfferSummary = () => {
    const { t } = useTranslation('offer');

    return (
        <VStack
            p={5}
            borderRadius="lg"
            bg="white"
            width="100%"
            spacing="1rem"
            alignItems="start"
        >
            <WVWTitle level="2" color="black" content={t('title')} />

            <CaseOffer />
        </VStack>
    );
};

export default CaseOfferSummary;
