/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import InfoWidget from './InfoWidget';
import useWidgetInformation from './useWidgetInformation';

const ActiveEnquiriesWidget = () => {
    const { t } = useTranslation('dashboard');

    const {
        numberOfDataValues: numberOfEnquiries,
        dataDifferencePercentage: enquiryDifferencePercentage,
        currentMonthCount,
        previousMonthCount,
    } = useWidgetInformation('enquiry');

    const currentMonth = new Date().toLocaleString('default', { month: 'long' });

    const previousMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        .toLocaleString('default', { month: 'long' });

    return (
        <InfoWidget
            title={t('section.activeEnquiries')}
            info={numberOfEnquiries}
            infoPercentage={enquiryDifferencePercentage}
            buttonText={t('enquiriesTable.button.viewAll')}
            buttonLink="enquiries"
            tooltipPercentageLabel={`${currentMonth}: (${currentMonthCount}) / ${previousMonth}: (${previousMonthCount})`}
        />
    );
};

export default ActiveEnquiriesWidget;
