import { VStack, Flex, Text } from '@chakra-ui/react';
import { Fragment } from 'react';
import { WVWTitle } from '../../../typography';

type PropTypes = {
    variant: string | undefined;
    content: {
        header: string | undefined;
        tableData: {
            title: string | undefined;
            data: React.ReactElement,
        }[];
    }[];
};

const EnquiryOverviewSectionContainer = (props: PropTypes) => {
    const { content, variant } = props;

    return (
        <VStack
            w="100%"
            spacing="6"
        >
            {content.map(section => {
                if (section.header === undefined) return null;

                return (
                    <VStack
                        key={section.header}
                        w="100%"
                        align="left"
                        bg={variant === 'white' ? 'white' : 'wvwGrey05'}
                        borderRadius="10"
                        paddingTop="1rem"
                        paddingBottom="1.5rem"
                        paddingInline="2.5rem"
                        spacing="6"
                    >
                        <WVWTitle
                            content={section.header}
                            color="black"
                            level="1"
                        />

                        {section.tableData.filter(r => r.title !== undefined).map(row => {
                            if (row.title === 'properties' || row.title === 'additionalComments') {
                                return (
                                    <Fragment key={row.title}>
                                        {row.data}
                                    </Fragment>
                                );
                            }

                            return (
                                <VStack key={row.title}>
                                    <Flex
                                        alignItems="flex-start"
                                        w="100%"
                                    >
                                        <Text
                                            maxW="20rem"
                                            w="100%"
                                        >
                                            {row.title}
                                        </Text>

                                        {row.data}
                                    </Flex>
                                </VStack>
                            );
                        })}

                    </VStack>
                );
            })}
        </VStack>
    );
};

export default EnquiryOverviewSectionContainer;
