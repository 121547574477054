import { t } from 'i18next';
import { EntityType, UserType } from '../../types';

export const obscureEntity = (entity: EntityType): EntityType => ({
    ...entity,
    name: t('obscuredCompany.name', { ns: 'common' }),
});

export const obscureUser = (user: UserType): UserType => ({
    ...user,
    firstName: t('obscuredClient.name', { ns: 'common' }),
    lastName: '',
    entity: obscureEntity(user.entity),
});
