import { Flex } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
    FormikForm,
    FormikTextarea,
    FormikListInput,
    FormikConditionalSection,
    FormikRadioGroup,
    FormikSubmitButton,
} from '../../../../../../common/forms';
import { useDocumentRequestCreateUnavailableDocuments, useDocumentRequestMarkRequestUnavailable } from '../../../../../../utils/api/hooks';
import ModalDynamic from '../../../../../../common/components/ModalDynamic';
import WvwVStack from '../../../../../../common/components/display/WvwVStack';
import { useSmartTranslation } from '../../../../../../common/hooks';

const UNAVAILABLE = 'unavailable';
const INCOMPLETE = 'incomplete';

const validationSchema = Yup.object({
    unavailableOrIncomplete: Yup.string()
        .required('errors.required'),
    reason: Yup.string()
        .test('reason', 'errors.required', function (value) {
            if (this.parent.unavailableOrIncomplete !== UNAVAILABLE) {
                return true;
            }

            return value ? value !== '' : false;
        }),
    unavailableDocuments: Yup.array()
        .test('unavailableDocuments', 'errors.required', function (value) {
            if (this.parent.unavailableOrIncomplete !== INCOMPLETE) {
                return true;
            }

            return value ? value.length > 0 : false;
        }),
});

const RequestUnavailableOrIncomplete = () => {
    const params = useParams();

    const caseId = Number(params.caseId);
    const requestId = Number(params.requestId);

    const t = useSmartTranslation('documentmanagement');

    const navigate = useNavigate();

    const {
        update: markUnavailable,
        busy: busyMarkingUnavailable,
    } = useDocumentRequestMarkRequestUnavailable({
        caseId,
        onSuccess: () => navigate('../', { replace: true }),
    });

    const {
        update: uploadUnavailableDocuments,
        busy: busyUploadingUnavailableDocuments,
    } = useDocumentRequestCreateUnavailableDocuments({
        caseId,
        onSuccess: () => navigate('../', { replace: true }),
    });

    return (
        <ModalDynamic
            header={t('modal.title.markIncompleteOrUnavailable', { ns: 'documentmanagement' })}
            isOpen
            variant="small"
            onClose={() => navigate('../', { replace: true })}
        >
            <FormikForm
                submitting={busyMarkingUnavailable || busyUploadingUnavailableDocuments}
                validationSchema={validationSchema}
                initialValues={{
                    unavailableOrIncomplete: '',
                    reason: '',
                    unavailableDocuments: [],
                }}
                onSubmit={values => {
                    if (values.unavailableOrIncomplete === UNAVAILABLE) {
                        markUnavailable({
                            requestId: Number(requestId),
                            reason: values.reason,
                        });
                    }

                    if (values.unavailableOrIncomplete === INCOMPLETE) {
                        const missingDocuments = values.unavailableDocuments.map(
                            (missingDocument: string) => (
                                { name: missingDocument }
                            ),
                        );

                        uploadUnavailableDocuments({
                            requestId: Number(requestId),
                            documents: missingDocuments,
                        });
                    }
                }}
            >
                <WvwVStack>
                    <FormikRadioGroup
                        width="100%"
                        name="unavailableOrIncomplete"
                        options={[
                            {
                                label: t('noDocumentsAvailable', { ns: 'documentmanagement' }),
                                value: UNAVAILABLE,
                            },
                            {
                                label: t('requestIncomplete', { ns: 'documentmanagement' }),
                                value: INCOMPLETE,
                            },
                        ]}
                    />

                    <FormikConditionalSection
                        condition={value => value === UNAVAILABLE}
                        formDataTarget="unavailableOrIncomplete"
                    >
                        <FormikTextarea
                            name="reason"
                            placeholder={t('reason')}
                        />
                    </FormikConditionalSection>

                    <FormikConditionalSection
                        condition={value => value === INCOMPLETE}
                        formDataTarget="unavailableOrIncomplete"
                    >
                        <FormikListInput
                            name="unavailableDocuments"
                            placeholder={t('missingDocument')}
                        />
                    </FormikConditionalSection>

                    <Flex justifyContent="flex-end">
                        <FormikSubmitButton
                            block
                            content={t('button.save', { ns: 'common' })}
                        />
                    </Flex>
                </WvwVStack>
            </FormikForm>
        </ModalDynamic>
    );
};

export default RequestUnavailableOrIncomplete;
