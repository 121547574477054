import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Heading,
    Text,
    VStack,
    createStandaloneToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useDocumentsSendEmail, useMyEntity } from '../../../../../utils/api/hooks';
import {
    FormikCheckboxList,
    FormikForm,
    FormikInput,
    FormikTextarea,
} from '../../../../../common/forms';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';

const validateFilesArrays = function (this: Yup.TestContext<any>) {
    const {
        awards,
        marketing,
        pi,
        samples,
        other,
    } = this.parent;

    if (awards.length > 0) return true;
    if (marketing.length > 0) return true;
    if (pi.length > 0) return true;
    if (samples.length > 0) return true;
    if (other.length > 0) return true;

    return false;
};

const validationSchema = Yup.object().shape({
    to: Yup.string().required('Required'),
    subject: Yup.string().required('Required'),
    awards: Yup.array().test('file-selected', 'Required', validateFilesArrays),
    marketing: Yup.array().test('file-selected', 'Required', validateFilesArrays),
    pi: Yup.array().test('file-selected', 'Required', validateFilesArrays),
    samples: Yup.array().test('file-selected', 'Required', validateFilesArrays),
    other: Yup.array().test('file-selected', 'Required', validateFilesArrays),
});

const EmailSharedDocs = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation(['account', 'common', 'profile']);

    const { toast } = createStandaloneToast();

    const {
        entityCertificates = [],
        entityCertificatesIsLoading,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
    } = useMyEntity();

    const { update: sendEmail } = useDocumentsSendEmail({
        onSuccess: () => {
            toast({
                title: t('success.success', { ns: 'common' }),
                description: t('emailSent', { ns: 'common' }),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            navigate(pathname.split('/').slice(0, -1).join('/'), { replace: true });
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const awardCerts = entityCertificates.filter(i => i.type.toLowerCase() === 'award');
    const marketingDocs = entityCertificates.filter(i => i.type.toLowerCase() === 'marketing');
    const piCerts = entityCertificates.filter(cert => cert.type.toLowerCase() === 'pi');
    const otherCerts = entityCertificates.filter(i => i.type.toLowerCase() === 'other');

    return (
        <Drawer
            isOpen
            placement="right"
            onClose={() => navigate(pathname.split('/').slice(0, -1).join('/'), { replace: true })}
            size="md"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader
                    color="wvwGreen"
                >
                    {t('emailDocs', { ns: 'account' })}
                </DrawerHeader>

                <FormikForm
                    initialValues={{
                        to: '',
                        subject: '',
                        message: '',
                        awards: [],
                        marketing: [],
                        pi: [],
                        samples: [],
                        other: [],
                    }}
                    onSubmit={values => {
                        const {
                            to,
                            subject,
                            message,
                            awards,
                            marketing,
                            pi,
                            samples,
                            other,
                        } = values;

                        let attachments = [
                            ...awards,
                            ...marketing,
                            ...pi,
                            ...samples,
                            ...other,
                        ];

                        const filesList = [
                            ...entityCertificates,
                            ...entitySampleValuations,
                        ];

                        attachments = attachments.map(url => {
                            const file = filesList.find(i => i.url === url);

                            return {
                                url,
                                name: file?.label,
                                type: file?.meta.contentType,
                            };
                        });

                        sendEmail({
                            to,
                            subject,
                            message,
                            attachments,
                        });
                    }}
                    validationSchema={validationSchema}
                >
                    <DrawerBody>
                        {(entityCertificatesIsLoading || entitySampleValuationsIsLoading) && (
                            <LoadingSpinner noText />
                        )}

                        <VStack spacing="3">
                            <FormikInput
                                name="to"
                                placeholder={t('to', { ns: 'common' })}
                                type="email"
                            />

                            <FormikInput
                                name="subject"
                                placeholder={t('subject', { ns: 'common' })}
                            />

                            <FormikTextarea
                                name="message"
                                placeholder={t('message', { ns: 'common' })}
                            />

                            <Box w="100%">
                                <Heading
                                    size="sm"
                                    w="100%"
                                >
                                    {t('sharedDocuments.piDocs', { ns: 'profile' })}
                                </Heading>

                                <FormikCheckboxList
                                    name="pi"
                                    options={[
                                        ...piCerts.map(({ meta, url }) => ({
                                            label: meta.name,
                                            value: url,
                                        })),
                                    ]}
                                />

                                {piCerts.length === 0 && (
                                    <Text color="wvwGrey40">
                                        {t('noRecords', { ns: 'common' })}
                                    </Text>
                                )}
                            </Box>

                            <Box w="100%">
                                <Heading
                                    size="sm"
                                    w="100%"
                                >
                                    {t('sharedDocuments.valuationSamples', { ns: 'profile' })}
                                </Heading>

                                <FormikCheckboxList
                                    name="samples"
                                    options={[
                                        ...entitySampleValuations.map(({ meta, url }) => ({
                                            label: meta.name,
                                            value: url,
                                        })),
                                    ]}
                                />

                                {entitySampleValuations.length === 0 && (
                                    <Text color="wvwGrey40">
                                        {t('noRecords', { ns: 'common' })}
                                    </Text>
                                )}
                            </Box>

                            <Box w="100%">
                                <Heading
                                    size="sm"
                                    w="100%"
                                >
                                    {t('sharedDocuments.awards', { ns: 'profile' })}
                                </Heading>

                                <FormikCheckboxList
                                    name="awards"
                                    options={[
                                        ...awardCerts.map(({ meta, url }) => ({
                                            label: meta.name,
                                            value: url,
                                        })),
                                    ]}
                                />

                                {awardCerts.length === 0 && (
                                    <Text color="wvwGrey40">
                                        {t('noRecords', { ns: 'common' })}
                                    </Text>
                                )}
                            </Box>

                            <Box w="100%">
                                <Heading
                                    size="sm"
                                    w="100%"
                                >
                                    {t('sharedDocuments.marketing', { ns: 'profile' })}
                                </Heading>

                                <FormikCheckboxList
                                    name="marketing"
                                    options={[
                                        ...marketingDocs.map(({ meta, url }) => ({
                                            label: meta.name,
                                            value: url,
                                        })),
                                    ]}
                                />

                                {marketingDocs.length === 0 && (
                                    <Text color="wvwGrey40">
                                        {t('noRecords', { ns: 'common' })}
                                    </Text>
                                )}
                            </Box>

                            <Box w="100%">
                                <Heading
                                    size="sm"
                                    w="100%"
                                >
                                    {t('sharedDocuments.other', { ns: 'profile' })}
                                </Heading>

                                <FormikCheckboxList
                                    name="other"
                                    options={[
                                        ...otherCerts.map(({ meta, url }) => ({
                                            label: meta.name,
                                            value: url,
                                        })),
                                    ]}
                                />

                                {otherCerts.length === 0 && (
                                    <Text color="wvwGrey40">
                                        {t('noRecords', { ns: 'common' })}
                                    </Text>
                                )}
                            </Box>
                        </VStack>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            mr={3}
                            onClick={() => navigate(pathname.split('/').slice(0, -1).join('/'), { replace: true })}
                        >
                            {t('button.close', { ns: 'common' })}
                        </Button>

                        <Button
                            type="submit"
                            variant="primaryYellow"
                        >
                            {t('button.send', { ns: 'common' })}
                        </Button>
                    </DrawerFooter>
                </FormikForm>
            </DrawerContent>
        </Drawer>
    );
};

export default EmailSharedDocs;
