import { adminUploadedContracts } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const ADMIN_UPLOADED_CONTRACTS = 'ADMIN_UPLOADED_CONTRACTS';

const useAdminCaseReports = () => {
    const query = useQueryHook({
        uniqueName: ADMIN_UPLOADED_CONTRACTS,
        dependancies: [],
        requiredParams: [],
        queryFunction: () => adminUploadedContracts(),
    });

    return query;
};

export default useAdminCaseReports;
