export const legal = {
    de: {
        copyright: "Copyright",
        privacy: "Datenschutzerklärung",
        privacyHeader: "Datenschutzerklärung",
        terms: "AGB",
        termsHeader: "Allgemeine Geschäftsbedingungen",
        imprint: "Impressum",
        imprintExtended: "Impressum",
    },
    en: {
        copyright: "Copyright",
        privacy: "Privacy",
        privacyHeader: "Privacy",
        terms: "Terms",
        termsHeader: "Terms & Conditions",
        imprint: "Imprint",
        imprintExtended: "Impressum",
    },
};
