import { CaseCommentOnReportDto } from '../../dto/case-report';
import { caseCommentOnReport } from '../../endpoints';
import useMutationHook from '../useMutationHook';
import useCaseGetCaseReports from './useCaseReportGetReports';

type ParamsType = {
    caseId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useCaseCommentOnReport = (params: ParamsType) => {
    const { caseId, onSuccess, onError } = params;

    const { refresh: refreshReports } = useCaseGetCaseReports(caseId);

    const mutation = useMutationHook({
        mutationFunction: (dto: CaseCommentOnReportDto) => caseCommentOnReport(dto),
        refreshes: [refreshReports],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useCaseCommentOnReport;
