import { Flex, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import delimitNumber from '../../../../../common/functions/delimitNumber';
import { EntityType } from '../../../../../types';
import { displayDateWithCurrentLocale } from '../../../../../common/functions/displayDateInLocale';
import { WvwIcon } from '../../../../../common/components/display';
import FormSectionProfileEdit from '../../../profile/components/FormSectionProfileEdit';

type PropTypes = {
    entity: EntityType;
};

const CompanyDetailsPiCover = (props: PropTypes) => {
    const { entity } = props;

    const { t } = useTranslation('account');

    return (
        <FormSectionProfileEdit header={t('heading.piCover')}>
            <Flex
                alignItems="flex-start"
                w="100%"
            >
                <Text w="17rem">
                    {t('valuationCapabilities.piCover.piCover')}
                </Text>

                <HStack
                    h="2rem"
                    spacing="1"
                >
                    <Text>
                        {!entity?.piCoverValidityDate && (
                            <>
                                <WvwIcon icon="warning" />
                                {' '}
                            </>
                        )}
                        {t('valuationCapabilities.piCover.or', {
                            coverValue: entity?.piCoverValue && Number(entity.piCoverValue) > 0 ? (`${entity?.piCoverCurrency || t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(entity?.piCoverValue.toString())}`) : ('-'),
                            coverPercentage: entity?.piCoverPercentage === 0 || entity?.piCoverPercentage === null ? ('-') : (`maximum ${entity?.piCoverPercentage}${t('valuationCapabilities.piCover.p_of_value')},`),
                            interpolation: { escapeValue: false },
                        })}
                    </Text>

                    {entity?.piCoverValidityDate ? (
                        <Text>
                            {t('valuationCapabilities.piCover.validUntil', { date: displayDateWithCurrentLocale(entity.piCoverValidityDate) })}
                        </Text>
                    ) : (
                        <div />
                    )}
                </HStack>
            </Flex>

            <Flex
                alignItems="flex-start"
                w="100%"
            >
                <Text w="17rem">
                    {t('valuationCapabilities.piCover.specialArrangementsAvailable')}
                </Text>

                <Text>
                    {entity?.piCoverSpecialArrangementsAvailable ? t('yes', { ns: 'common' }) : t('no', { ns: 'common' })}
                </Text>
            </Flex>
        </FormSectionProfileEdit>
    );
};

export default CompanyDetailsPiCover;
