import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DirectEnquiryDataType } from './types';
import { FormikForm } from '../../common/forms';
import EnquiryFormBox from '../create-client-enquiry/common/EnquiryFormBox';
import DirectGeneralEnquiryOverview from '../../common/components/display/enquiry-form/overview/DirectGeneralEnquiryOverview';
import { FlowContextStateful } from '../../common/components/display/ProcessFlowStateful';
import { DIRECT_ENQUIRY_STEPS, DIRECT_ENQUIRY_STEPS_OVERVIEW } from './DirectEnquirySteps';
import EnquiryContainer from '../../common/components/display/enquiry-form/display/EnquiryContainer';

type PropTypes = {
    enquiryData: DirectEnquiryDataType;
    onContinue: (values: any, goNext: () => void) => void;
    valuerName: string;
};

const DirectEnquiryOverview = (props: PropTypes) => {
    const {
        enquiryData,
        onContinue,
        valuerName,
    } = props;

    const { clientType = 'individual' } = useParams();

    const { goBack, goNext } = useContext(FlowContextStateful);

    const individualVarient = clientType === 'individual';

    return (
        <EnquiryContainer
            currentStep={DIRECT_ENQUIRY_STEPS_OVERVIEW}
            steps={DIRECT_ENQUIRY_STEPS}
        >
            <FormikForm
                initialValues={{
                    additionalRequirements: '',
                }}
                onSubmit={values => onContinue(values, goNext)}
            >
                <EnquiryFormBox
                    variant="none"
                    onBack={goBack}
                    nextButtonTextKey="button.submit"
                    content={[
                        {
                            key: 'enquiryOverview',
                            header: undefined,
                            element: (
                                <DirectGeneralEnquiryOverview
                                    overviewData={enquiryData}
                                    valuerName={valuerName}
                                    individualVarient={individualVarient}
                                />
                            ),
                        },
                    ]}
                />
            </FormikForm>
        </EnquiryContainer>
    );
};

export default DirectEnquiryOverview;
