import { sendEmailVerification, User } from "firebase/auth";
import useMutationHook from "../useMutationHook";
import { useSmartTranslation } from "../../../../common/hooks";

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

type DtoType = {
    firebaseUser: User;
    redirectUrl: string;
};

const useSendEmailVerification = (params?: ParamsType) => {
    const { onSuccess, onError } = params || {};
    const t = useSmartTranslation("common");

    const mutation = useMutationHook({
        mutationFunction: ({ firebaseUser, redirectUrl }: DtoType) =>
            sendEmailVerification(firebaseUser, {
                url: `${window.location.origin}${redirectUrl}`,
            }),
        refreshes: [],
        successMessage: t("emailVerificationSent"),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useSendEmailVerification;
