import { Center, Flex, Spacer, VStack, Text } from "@chakra-ui/react";
import { Section } from "../../../../layout/Section";
import Statistics from "../../../../common/features/statistics/Statistics";
import DataTable from "../../../../common/components/data-table/DataTable";
import CustomizeExperience from "../../client/customize-experience/CustomizeExperience";
import {
    columns as enquiryColumns,
    createRecentMatchingsData,
} from "./data-table/EnquiryTable";
import { wvwPalette } from "../../../../theme/theme-v2";
import {
    allActiveCases,
    allMatchingEnquiriesStatistic,
    allOffersSentStatistic,
    allPendingEnquiriesStatistic,
    allRejectedOffersStatistic,
    caseConversionRate,
} from "./EnquiryAndCaseStatistics";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { columns as caseColumns } from "./data-table/CasesTable";
import {
    useCaseActiveCases,
    useEnquiryGetAll,
    useMyEntity,
    useOfferGetAllMyOffers,
} from "../../../../../../utils/api/hooks";
import useMe from "../../../../app/useMeV2";
import { useRole } from "../../../../../../common/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import ProfileCompletionBanner from "../../profile-completion/ProfileCompletionBanner";

// helpers

const UnactiveTableBanner = (props: { text: string }) => (
    <Center
        w="100%"
        h="8em"
        bg={wvwPalette.wvwBackground.container}
    >
        <Text color="GrayText">{props.text}</Text>
    </Center>
);

const Dashboard = () => {
    const { data: enquiries = [], isLoading: enquiriesLoading } =
        useEnquiryGetAll();
    const { data: offers = [], isLoading: offersLoading } =
        useOfferGetAllMyOffers();
    const { data: entity, isLoading: entityLoading } = useMyEntity();
    const { data: cases = [], isLoading: casesLoading } = useCaseActiveCases();
    const { data: user, isLoading: userLoading } = useMe();
    const { userIsValuer, userIsClient, userIsCompanyAdmin, roleLoading } =
        useRole();

    const t = useSmartTranslation();

    if (userLoading || roleLoading) return <LoadingSpinner />;

    if (
        !user ||
        enquiriesLoading ||
        offersLoading ||
        entityLoading ||
        casesLoading ||
        userLoading
    )
        return <LoadingSpinner />;

    // profile completion
    const profileCompletionBanner = (
        <ProfileCompletionBanner
            user={user}
            entity={entity}
            userRoles={{
                userIsClient,
                userIsCompanyAdmin,
                userIsValuer,
            }}
        />
    );

    // statistics

    const numPendingEnquiries = allPendingEnquiriesStatistic(enquiries);
    const numMatchingEnquiries = allMatchingEnquiriesStatistic(enquiries);
    const numAllOffersSent = allOffersSentStatistic(offers, enquiries);
    const numAllRejectedOffers = allRejectedOffersStatistic(offers, enquiries);
    const numAllActiveCases = allActiveCases(cases, enquiries);
    const percentageOfCompletedCases = caseConversionRate(cases, enquiries);
    const enquiryStatsData = [
        numPendingEnquiries,
        numMatchingEnquiries,
        numAllOffersSent,
        numAllRejectedOffers,
    ];
    const caseStatsData = [numAllActiveCases, percentageOfCompletedCases];

    // statistics
    const enquiryStats = (
        <Section
            title="Enquries"
            content={<Statistics stats={enquiryStatsData} />}
        />
    );

    const caseStats = (
        <Section
            title="Cases"
            content={<Statistics stats={caseStatsData} />}
        />
    );

    // enquiries
    const recentMatchingsData = createRecentMatchingsData({
        enquiries,
        offers,
    });

    const recentMatchings = (
        <Section
            title="Recent Matchings: 1"
            content={
                enquiries.length > 0 ? (
                    <DataTable
                        data={recentMatchingsData}
                        columns={enquiryColumns}
                    />
                ) : (
                    <UnactiveTableBanner text="No recent matchings." />
                )
            }
        />
    );

    // cases
    const ongoingCases = (
        <Section
            title="Active Cases: 0"
            content={
                cases.length > 0 ? (
                    <DataTable
                        data={cases}
                        columns={caseColumns}
                    />
                ) : (
                    <UnactiveTableBanner text="No accepted and started valuations." />
                )
            }
        />
    );

    // account and profile
    const experienceCustomization = (
        <Section
            title={t(
                "valuerDashboard.customizeExperience.customizeYourExperience"
            )}
            content={
                <CustomizeExperience
                    customizeList={[
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.completeProfile.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.completeProfile.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "profile",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.addTeamMembers.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.addTeamMembers.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "profile/team",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadMarketing.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadMarketing.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "/dashboard/account/documents",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadPiCover.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadPiCover.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "/dashboard/account",
                            },
                        },
                        {
                            title: t(
                                "valuerDashboard.customizeExperience.uploadValuationSample.title"
                            ),
                            subtext: t(
                                "valuerDashboard.customizeExperience.uploadValuationSample.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "/dashboard/account/documents",
                            },
                        },
                    ]}
                />
            }
        />
    );
    return (
        <VStack
            w="100%"
            padding=".5em"
        >
            {profileCompletionBanner}
            <Flex
                w="100%"
                gap={1}
            >
                {enquiryStats}
                <Spacer />
                {caseStats}
            </Flex>

            {recentMatchings}

            {ongoingCases}

            {experienceCustomization}
        </VStack>
    );
};

export default Dashboard;
