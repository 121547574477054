import { WarningTwoIcon } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import {
    BackSquare,
    CloseCircle,
    TickCircle,
    DocumentCloud,
    DocumentDownload,
    DirectInbox,
    DocumentText,
    Clock,
    Edit,
    Trash,
    HambergerMenu,
    Location,
    Map1,
} from "iconsax-react";
import { HiPlus } from "react-icons/hi";

const icons = {
    add: HiPlus,
    back: BackSquare,
    burgerMenu: HambergerMenu,
    cancel: CloseCircle,
    delete: Trash,
    document: DocumentText,
    documentRequest: DirectInbox,
    documentToUpload: DocumentCloud,
    documentToDownload: DocumentDownload,
    edit: Edit,
    location: Location,
    map: Map1,
    pending: Clock,
    success: TickCircle,
    warning: WarningTwoIcon,
};

export type WvwIconKeys = keyof typeof icons;

type PropTypes = {
    icon: WvwIconKeys;
    color?: "wvwGreen" | "wvwGrey60" | "black" | "white";
    size?: "md" | "lg" | "xl";
};

const WvwIcon = (props: PropTypes) => {
    const { icon, color, size } = props;

    let boxSize = "6";

    if (size === "lg") {
        boxSize = "12";
    }

    if (size === "xl") {
        boxSize = "20";
    }

    return (
        <Icon
            as={icons[icon]}
            color={color}
            boxSize={boxSize}
        />
    );
};

WvwIcon.defaultProps = {
    color: "wvwGreen",
    size: "md",
};

export default WvwIcon;
