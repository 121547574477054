import {
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './LoadingSpinner';

const LoadingModal = () => {
    const { t } = useTranslation('common');

    return (
        <Modal
            isOpen
            onClose={() => {}}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalBody>
                    <VStack
                        marginBlock="5rem"
                        spacing="4"
                    >
                        <LoadingSpinner noText />

                        <Heading
                            color="wvwGreen"
                            size="lg"
                        >
                            {t('loading')}
                        </Heading>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
export default LoadingModal;
