import caseGetCaseContracts from '../../endpoints/case-contract/case-getCaseContracts';
import useQueryHook from '../useQueryHook';

const CASE_REPORT_GET_CONTRACTS = 'CONTRACTS';

const useCaseGetUploadedContracts = (caseId: number | string) => {
    const query = useQueryHook({
        uniqueName: CASE_REPORT_GET_CONTRACTS,
        dependancies: [caseId],
        requiredParams: [caseId],
        queryFunction: () => caseGetCaseContracts(caseId),
    });

    return query;
};

export default useCaseGetUploadedContracts;
