import { Box, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import MenuBar from '../menu-bar/MenuBar';
import Footer from '../Footer';

type PropTypes = {
    children: ReactNode;
};

const MainLayout = (props: PropTypes) => {
    const { children } = props;

    return (
        <Box
            minH="100vh"
            minWidth="600px"
            display="flex"
            flexDirection="column"
            bg="#f8f8f8"
            w="100%"
            overflowX="scroll"
        >
            <MenuBar />

            <VStack
                alignItems="space-between"
                paddingBlock="1rem"
                paddingInline="2rem"
                spacing="2"
                w="100%"
                minWidth="600px"
                maxWidth="1600px"
                marginLeft="auto"
                marginRight="auto"
            >
                {children}
            </VStack>

            <Box
                marginTop="auto"
            >
                <Footer />
            </Box>
        </Box>
    );
};

export default MainLayout;
