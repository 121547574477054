import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Spacer,
    VStack,
    Text,
    Divider,
    Link as ChakraLink,
} from '@chakra-ui/react';
import { ArrangeVertical } from 'iconsax-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, Link as ReactRouterLink } from 'react-router-dom';
import { useMyEntity } from '../../../../utils/api/hooks';
import EditCompanyDocsSlider from './common/EditCompanyDocsSlider';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import ConfirmationModal from '../../../../common/components/ConfirmationModal';
import storage from '../../../../utils/storage/storage';
import toTitleCase from '../../../../common/functions/toTitleCase';
import { useRole } from '../../../../common/hooks';
import { displayDateWithCurrentLocale } from '../../../../common/functions/displayDateInLocale';
import AccountAccessDeniedAlert from '../../../../common/components/display/AccountAccessDeniedAlert';
import WvwDeleteIconButton from '../../../../common/components/WvwDeleteIconButton';

type PropTypes = {
    subHeading?: string;
};

const CompanyDocuments = (props: PropTypes) => {
    const { subHeading } = props;

    const { pathname } = useLocation();
    const { t } = useTranslation(['profile', 'common']);

    const [deleteFile, setDeleteFile] = useState('');
    const [openSlider, setOpenSlider] = useState({
        type: '',
        isOpen: false,
    });

    const { userIsCompanyAdmin, userIsClient, roleLoading } = useRole();

    const {
        data: entity,
        entityCertificates = [],
        entityCertificatesIsLoading,
        entityCertificatesRefresh,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
        entitySampleValuationsRefresh,
    } = useMyEntity();

    if (entityCertificatesIsLoading || entitySampleValuationsIsLoading) return <LoadingSpinner />;

    const awardCerts = entityCertificates.filter(i => i.type.toLowerCase() === 'award');
    const marketingDocs = entityCertificates.filter(i => i.type.toLowerCase() === 'marketing');
    const piCerts = entityCertificates.filter(cert => cert.type.toLowerCase() === 'pi');
    const otherCerts = entityCertificates.filter(i => i.type.toLowerCase() === 'other');

    if (roleLoading) {
        return <LoadingSpinner noText />;
    }

    if (userIsClient) {
        return <AccountAccessDeniedAlert />;
    }

    const onAccountPage = pathname.includes('account');

    return (
        <>
            <VStack
                w="100%"
                align="left"
                paddingInline=".5rem"
                paddingBlock="2rem"
                spacing="10"
            >
                <Flex>
                    <VStack
                        alignSelf="flex-start"
                        spacing="4"
                        w="25%"
                    >
                        <Heading
                            as="h4"
                            size="md"
                            fontWeight="light"
                            w="100%"
                        >
                            {t('sharedDocuments.sectionTitle')}
                        </Heading>

                        <Text color="wvwGrey">
                            {t('sharedDocuments.blurb')}
                        </Text>

                        <Text color="wvwGrey">
                            {subHeading}
                        </Text>

                        <ChakraLink
                            as={ReactRouterLink}
                            to="email"
                        >
                            <Button
                                variant="primaryYellow"
                                fontWeight="light"
                            >
                                {t('emailDocs', { ns: 'account' })}
                            </Button>
                        </ChakraLink>
                    </VStack>

                    <Spacer />

                    <VStack
                        w="70%"
                        align="left"
                        spacing="3"
                    >
                        <Box>
                            <Flex
                                h="3rem"
                                w="100%"
                            >
                                <Heading
                                    alignSelf="center"
                                    size="sm"
                                    paddingLeft="1rem"
                                >
                                    {t('sharedDocuments.piDocs')}
                                </Heading>

                                <Spacer />

                                {(userIsCompanyAdmin && onAccountPage) && (
                                    <Button
                                        variant="none"
                                        color="wvwGreen60"
                                        onClick={() => setOpenSlider({
                                            type: 'pi',
                                            isOpen: true,
                                        })}
                                    >
                                        {piCerts.length > 0 ? (
                                            t('button.editSlashUpload', { ns: 'common' })
                                        ) : (
                                            t('button.upload', { ns: 'common' })
                                        )}
                                    </Button>
                                )}
                            </Flex>

                            <HStack
                                w="100%"
                                paddingBlock=".8rem"
                                bg="wvwGrey05"
                                borderRadius="10"
                            >
                                <Box
                                    w="40%"
                                    paddingLeft="1rem"
                                >
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.name')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="30%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.uploadDate')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="17%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.size')}
                                        </Text>
                                    </HStack>
                                </Box>
                            </HStack>

                            {piCerts.map(i => (
                                <VStack key={i.url}>
                                    <HStack
                                        w="100%"
                                        paddingBlock=".4rem"
                                    >
                                        <Text
                                            paddingLeft="2.4rem"
                                            w="43%"
                                        >
                                            <ChakraLink
                                                href={i.url}
                                                isExternal
                                                target="_blank"
                                                color="wvwGreen"
                                            >
                                                {i.label}
                                            </ChakraLink>
                                        </Text>

                                        <Text w="30%">
                                            {displayDateWithCurrentLocale((i.meta.updated))}
                                        </Text>

                                        <Text>
                                            {`${(i.meta.size / 1000000).toFixed(2)} MB`}
                                        </Text>

                                        <Spacer />

                                        {userIsCompanyAdmin && onAccountPage && (
                                            <WvwDeleteIconButton
                                                onClick={() => setDeleteFile(i.url)}
                                                color="black"
                                            />
                                        )}
                                    </HStack>

                                    <Divider />
                                </VStack>
                            ))}

                            {piCerts.length === 0 && (
                                <Text
                                    color="wvwGrey"
                                    paddingLeft="2.4rem"
                                    paddingTop=".4rem"
                                >
                                    {t('sharedDocuments.noDocs')}
                                </Text>
                            )}
                        </Box>

                        <Box>
                            <Flex
                                h="3rem"
                                w="100%"
                            >
                                <Heading
                                    alignSelf="center"
                                    size="sm"
                                    paddingLeft="1rem"
                                >
                                    {t('sharedDocuments.valuationSamples')}
                                </Heading>

                                <Spacer />

                                {userIsCompanyAdmin && onAccountPage && (
                                    <Button
                                        variant="none"
                                        color="wvwGreen60"
                                        onClick={() => setOpenSlider({
                                            type: 'sampleValuations',
                                            isOpen: true,
                                        })}
                                    >
                                        {entitySampleValuations.length > 0 ? (
                                            t('button.editSlashUpload', { ns: 'common' })
                                        ) : (
                                            t('button.upload', { ns: 'common' })
                                        )}
                                    </Button>
                                )}
                            </Flex>

                            <HStack
                                w="100%"
                                paddingBlock=".8rem"
                                bg="wvwGrey05"
                                borderRadius="10"
                            >
                                <Box
                                    w="40%"
                                    paddingLeft="1rem"
                                >
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.name')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="30%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.uploadDate')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="17%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.size')}
                                        </Text>
                                    </HStack>
                                </Box>
                            </HStack>

                            {entitySampleValuations
                                .filter(i => entity.valuationTypes
                                    .includes(i?.type?.toLocaleUpperCase() as string))
                                .map(i => (
                                    <VStack key={i.url}>
                                        <HStack
                                            w="100%"
                                            paddingBlock=".4rem"
                                        >
                                            <Text
                                                paddingLeft="2.4rem"
                                                w="43%"
                                            >
                                                <ChakraLink
                                                    href={i.url}
                                                    isExternal
                                                    target="_blank"
                                                    color="wvwGreen"
                                                >
                                                    {`${toTitleCase(i.type || '')}: ${i.label}`}
                                                </ChakraLink>
                                            </Text>

                                            <Text w="30%">
                                                {displayDateWithCurrentLocale(i.meta.updated)}
                                            </Text>

                                            <Text>
                                                {`${(i.meta.size / 1000000).toFixed(2)} MB`}
                                            </Text>

                                            <Spacer />

                                            {userIsCompanyAdmin && onAccountPage && (
                                                <WvwDeleteIconButton
                                                    onClick={() => setDeleteFile(i.url)}
                                                    color="black"
                                                />
                                            )}
                                        </HStack>

                                        <Divider />
                                    </VStack>
                                ))}

                            {entitySampleValuations.length === 0 && (
                                <Text
                                    color="wvwGrey"
                                    paddingLeft="2.4rem"
                                    paddingTop=".4rem"
                                >
                                    {t('sharedDocuments.noDocs')}
                                </Text>
                            )}
                        </Box>

                        <Box>
                            <Flex
                                h="3rem"
                                w="100%"
                            >
                                <Heading
                                    alignSelf="center"
                                    size="sm"
                                    paddingLeft="1rem"
                                >
                                    {t('sharedDocuments.awards')}
                                </Heading>

                                <Spacer />

                                {userIsCompanyAdmin && onAccountPage && (
                                    <Button
                                        variant="none"
                                        color="wvwGreen60"
                                        onClick={() => setOpenSlider({
                                            type: 'awards',
                                            isOpen: true,
                                        })}
                                    >
                                        {awardCerts.length > 0 ? (
                                            t('button.editSlashUpload', { ns: 'common' })
                                        ) : (
                                            t('button.upload', { ns: 'common' })
                                        )}
                                    </Button>
                                )}
                            </Flex>

                            <HStack
                                w="100%"
                                paddingBlock=".8rem"
                                bg="wvwGrey05"
                                borderRadius="10"
                            >
                                <Box
                                    w="40%"
                                    paddingLeft="1rem"
                                >
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.name')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="30%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.uploadDate')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="17%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.size')}
                                        </Text>
                                    </HStack>
                                </Box>
                            </HStack>

                            {awardCerts.map(i => (
                                <VStack key={i.url}>
                                    <HStack
                                        w="100%"
                                        paddingBlock=".4rem"
                                    >
                                        <Text
                                            paddingLeft="2.4rem"
                                            w="43%"
                                        >
                                            <ChakraLink
                                                href={i.url}
                                                isExternal
                                                target="_blank"
                                                color="wvwGreen"
                                            >
                                                {i.label}
                                            </ChakraLink>
                                        </Text>

                                        <Text w="30%">
                                            {displayDateWithCurrentLocale(i.meta.updated)}
                                        </Text>

                                        <Text>
                                            {`${(i.meta.size / 1000000).toFixed(2)} MB`}
                                        </Text>

                                        <Spacer />

                                        {userIsCompanyAdmin && onAccountPage && (
                                            <WvwDeleteIconButton
                                                onClick={() => setDeleteFile(i.url)}
                                                color="black"
                                            />
                                        )}
                                    </HStack>

                                    <Divider />
                                </VStack>
                            ))}

                            {awardCerts.length === 0 && (
                                <Text
                                    color="wvwGrey"
                                    paddingLeft="2.4rem"
                                    paddingTop=".4rem"
                                >
                                    {t('sharedDocuments.noDocs')}
                                </Text>
                            )}
                        </Box>

                        <Box>
                            <Flex
                                h="3rem"
                                w="100%"
                            >
                                <Heading
                                    alignSelf="center"
                                    size="sm"
                                    paddingLeft="1rem"
                                >
                                    {t('sharedDocuments.marketing')}
                                </Heading>

                                <Spacer />

                                {userIsCompanyAdmin && onAccountPage && (
                                    <Button
                                        variant="none"
                                        color="wvwGreen60"
                                        onClick={() => setOpenSlider({
                                            type: 'marketing',
                                            isOpen: true,
                                        })}
                                    >
                                        {marketingDocs.length > 0 ? (
                                            t('button.editSlashUpload', { ns: 'common' })
                                        ) : (
                                            t('button.upload', { ns: 'common' })
                                        )}
                                    </Button>
                                )}
                            </Flex>

                            <HStack
                                w="100%"
                                paddingBlock=".8rem"
                                bg="wvwGrey05"
                                borderRadius="10"
                            >
                                <Box
                                    w="40%"
                                    paddingLeft="1rem"
                                >
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />

                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.name')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="30%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.uploadDate')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="17%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.size')}
                                        </Text>
                                    </HStack>
                                </Box>
                            </HStack>

                            {marketingDocs.map(i => (
                                <VStack key={i.url}>
                                    <HStack
                                        w="100%"
                                        paddingBlock=".4rem"
                                    >
                                        <Text
                                            paddingLeft="2.4rem"
                                            w="43%"
                                        >
                                            <ChakraLink
                                                href={i.url}
                                                isExternal
                                                target="_blank"
                                                color="wvwGreen"
                                            >
                                                {i.label}
                                            </ChakraLink>
                                        </Text>

                                        <Text w="30%">
                                            {displayDateWithCurrentLocale(i.meta.updated)}
                                        </Text>

                                        <Text>
                                            {`${(i.meta.size / 1000000).toFixed(2)} MB`}
                                        </Text>

                                        <Spacer />

                                        {userIsCompanyAdmin && onAccountPage && (
                                            <WvwDeleteIconButton
                                                onClick={() => setDeleteFile(i.url)}
                                                color="black"
                                            />
                                        )}
                                    </HStack>

                                    <Divider />
                                </VStack>
                            ))}

                            {marketingDocs.length === 0 && (
                                <Text
                                    color="wvwGrey"
                                    paddingLeft="2.4rem"
                                    paddingTop=".4rem"
                                >
                                    {t('sharedDocuments.noDocs')}
                                </Text>
                            )}
                        </Box>

                        <Box>
                            <Flex
                                h="3rem"
                                w="100%"
                            >
                                <Heading
                                    alignSelf="center"
                                    size="sm"
                                    paddingLeft="1rem"
                                >
                                    {t('sharedDocuments.other')}
                                </Heading>

                                <Spacer />

                                {userIsCompanyAdmin && onAccountPage && (
                                    <Button
                                        variant="none"
                                        color="wvwGreen60"
                                        onClick={() => setOpenSlider({
                                            type: 'other',
                                            isOpen: true,
                                        })}
                                    >
                                        {otherCerts.length > 0 ? (
                                            t('button.editSlashUpload', { ns: 'common' })
                                        ) : (
                                            t('button.upload', { ns: 'common' })
                                        )}
                                    </Button>
                                )}
                            </Flex>

                            <HStack
                                w="100%"
                                paddingBlock=".8rem"
                                bg="wvwGrey05"
                                borderRadius="10"
                            >
                                <Box
                                    w="40%"
                                    paddingLeft="1rem"
                                >
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.name')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="30%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.uploadDate')}
                                        </Text>
                                    </HStack>
                                </Box>

                                <Box w="17%">
                                    <HStack>
                                        <ArrangeVertical size=".9rem" color="wvwGrey" />
                                        <Text
                                            as="b"
                                            color="wvwGrey80"
                                            fontSize=".8rem"
                                        >
                                            {t('sharedDocuments.document.size')}
                                        </Text>
                                    </HStack>
                                </Box>
                            </HStack>

                            {otherCerts.map(i => (
                                <VStack key={i.url}>
                                    <HStack
                                        w="100%"
                                        paddingBlock=".4rem"
                                    >
                                        <Text
                                            paddingLeft="2.4rem"
                                            w="43%"
                                        >
                                            <ChakraLink
                                                href={i.url}
                                                isExternal
                                                target="_blank"
                                                color="wvwGreen"
                                            >
                                                {i.label}
                                            </ChakraLink>
                                        </Text>

                                        <Text w="30%">
                                            {displayDateWithCurrentLocale(i.meta.updated)}
                                        </Text>

                                        <Text>
                                            {`${(i.meta.size / 1000000).toFixed(2)} MB`}
                                        </Text>

                                        <Spacer />

                                        {userIsCompanyAdmin && onAccountPage && (
                                            <WvwDeleteIconButton
                                                onClick={() => setDeleteFile(i.url)}
                                                color="black"
                                            />
                                        )}
                                    </HStack>

                                    <Divider />
                                </VStack>
                            ))}

                            {otherCerts.length === 0 && (
                                <Text
                                    color="wvwGrey"
                                    paddingLeft="2.4rem"
                                    paddingTop=".4rem"
                                >
                                    {t('sharedDocuments.noDocs')}
                                </Text>
                            )}
                        </Box>
                    </VStack>
                </Flex>
            </VStack>

            {/* To render modals and sliders */}
            <Outlet />

            <EditCompanyDocsSlider
                isOpen={openSlider.isOpen}
                onClose={() => setOpenSlider({
                    type: '',
                    isOpen: false,
                })}
                type={openSlider.type}
            />

            <ConfirmationModal
                isOpen={deleteFile !== ''}
                onCancel={() => setDeleteFile('')}
                onContinue={async () => {
                    await storage.deleteEntityFile(deleteFile);

                    entityCertificatesRefresh();
                    entitySampleValuationsRefresh();

                    setDeleteFile('');
                }}
                title={t('warning.deleteFile.title', { ns: 'common' })}
                content={t('warning.deleteFile.prompt', { ns: 'common' })}
            />
        </>
    );
};

CompanyDocuments.defaultProps = {
    subHeading: undefined,
};

export default CompanyDocuments;
