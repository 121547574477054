import {
    Center,
    Circle,
} from '@chakra-ui/react';
import { useEntityPublicEntityGetById } from '../../utils/api/hooks';
import usePublicUserProfilePicture from '../../utils/api/hooks/usePublicUserProfilePicture';
import LoadingSpinner from './LoadingSpinner';
import ProfilePicture from './display/ProfilePicture';
import ProfilePictureDefault from './display/ProfilePictureDefault';

type PropTypes = {
    entityId: number | string,
    userId: number | string,
    size: string,
};

const PublicUserProfilePicture = (props: PropTypes) => {
    const { entityId, userId, size } = props;

    const {
        data: entity,
        isLoading: entityIsLoading,
    } = useEntityPublicEntityGetById(entityId);

    const {
        data: profilePictureUrl,
        isLoading: profilePictureUrlIsLoading,
    } = usePublicUserProfilePicture(userId);

    if (profilePictureUrlIsLoading || entityIsLoading) {
        return (
            <Circle
                size={size}
                margin="0"
            >
                <Center
                    w="100%"
                    h="100%"
                >
                    <LoadingSpinner noText />
                </Center>
            </Circle>
        );
    }

    if (profilePictureUrl) {
        return (
            <ProfilePicture
                url={profilePictureUrl}
                size={size}
            />
        );
    }
    return (
        <ProfilePictureDefault
            accountType={entity?.accountType === 'VALUER' ? 'VALUER' : 'CLIENT'}
            userType="USER"
            size={size}
        />
    );
};

export default PublicUserProfilePicture;
