import { CaseReportWithCaseAndClientAndValuerType } from '../../../../types';
import { adminCaseReport } from '../../endpoints';
import useQueryHook from '../useQueryHook';

const ADMIN_CASE_REPORT = 'ADMIN_CASE_REPORT';

const useAdminCaseReports = (reportId: number) => {
    const query = useQueryHook<CaseReportWithCaseAndClientAndValuerType>({
        uniqueName: ADMIN_CASE_REPORT,
        dependancies: [],
        requiredParams: [],
        queryFunction: () => adminCaseReport(reportId),
    });

    return query;
};

export default useAdminCaseReports;
