import { VStack, Flex, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AccordionContainer from '../../../../../common/components/AccordionContainer';
import PropertyOverview from '../../../../../common/components/PropertyOverview';
import { Property } from '../../PropertyFormPropertyType';

type PropTypes = {
    property: Property;
    setEditingProperty: (property: Property) => void;
    setDeleteProp: (property: Property) => void;
};

const PropertyPreview = (props: PropTypes) => {
    const { t } = useTranslation(['enquiryform', 'common', 'enquiries', 'formik']);

    const {
        property,
        setEditingProperty,
        setDeleteProp,
    } = props;

    return (
        <AccordionContainer
            title={`${property.street}, ${property.city}`}
            key={`${property.street} ${property.city}`}
        >
            <VStack
                align="left"
                spacing="2"
            >
                <PropertyOverview property={property} />

                <Flex
                    alignSelf="flex-end"
                    gap="2"
                >
                    <Button
                        variant="danger"
                        onClick={() => setDeleteProp(property)}
                    >
                        {t('button.delete', { ns: 'common' })}
                    </Button>

                    <Button
                        variant="primary"
                        onClick={() => setEditingProperty(property)}
                    >
                        {t('button.edit', { ns: 'common' })}
                    </Button>
                </Flex>
            </VStack>
        </AccordionContainer>
    );
};

export default PropertyPreview;
