import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    HStack,
    IconButton,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
} from '@chakra-ui/react';
import { Eye } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useEnquiriesByStatus } from '../../../utils/api/hooks';
import { EnquiryType } from '../../../types';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import delimitNumber from '../../../common/functions/delimitNumber';
import DataTable from '../../../common/components/datatable/DataTable';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const DraftEnquiries = () => {
    const { t } = useTranslation(['dashboard', 'common']);
    const navigate = useNavigate();

    const {
        enquiryList = [],
        isLoading: enquiryLoading,
    } = useEnquiriesByStatus('DRAFT');

    const headers = [
        {
            title: t('enquiriesTable.header.client'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aName = `${a.invoiceFName} ${a.invoiceLName}`;
                const bName = `${b.invoiceFName} ${b.invoiceLName}`;

                return aName.localeCompare(bName);
            },
            render: (i: EnquiryType) => {
                const clientName = `${i.invoiceFName} ${i.invoiceLName}`;

                if (clientName === ' ') {
                    return (
                        <Text color="wvwGrey40">
                            {t('enquiriesTable.cellValue.Awaiting Completion')}
                        </Text>
                    );
                }

                return clientName;
            },
        },
        {
            title: t('enquiriesTable.header.property'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const aProperty = a?.properties?.[0]?.street || `${a?.properties?.length || 0} properties`;
                const bProperty = b?.properties?.[0]?.street || `${b?.properties?.length || 0} properties`;

                return aProperty.localeCompare(bProperty);
            },
            render: (i: EnquiryType) => {
                const { properties = [] } = i || {} as EnquiryType;

                if (properties.length === 0) {
                    return (
                        <Text color="wvwGrey40">
                            {t('enquiriesTable.cellValue.Awaiting Completion')}
                        </Text>
                    );
                }

                if (properties.length === 1) {
                    return (
                        <Text>
                            {properties[0].street}
                        </Text>
                    );
                }

                return (
                    <Text>
                        {t('property_wc', { count: properties.length, ns: 'common' })}
                    </Text>
                );
            },
        },
        {
            title: t('enquiriesTable.header.estimateValue'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                const { properties: aProperties = [] } = a;
                const { properties: bProperties = [] } = b;

                let aEstimateValue = aProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                let bEstimateValue = bProperties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                if (aProperties.length === 0) aEstimateValue = -1;
                if (bProperties.length === 0) bEstimateValue = -1;

                return aEstimateValue - bEstimateValue;
            },
            render: (i: EnquiryType) => {
                const { properties = [] } = i;

                const estimateValue = properties
                    .reduce((acc, property) => acc + property.estimateValue, 0);

                if (properties.length === 0) {
                    return (
                        <Text color="wvwGrey40">
                            {t('enquiriesTable.cellValue.Awaiting Completion')}
                        </Text>
                    );
                }

                return (
                    <Text>
                        {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(estimateValue)}`}
                    </Text>
                );
            },
        },
        {
            title: t('enquiriesTable.header.dueDate'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                if (b.completionDate.replace(/-/g, '') < a.completionDate.replace(/-/g, '')) {
                    return -1;
                }
                if (b.completionDate.replace(/-/g, '') > a.completionDate.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
            render: (i: EnquiryType) => {
                if (!i.completionDate) {
                    return (
                        <Text color="wvwGrey40">
                            {t('enquiriesTable.cellValue.Awaiting Completion')}
                        </Text>
                    );
                }

                return i.completionDate;
            },
        },
        {
            title: t('enquiriesTable.header.lastUpdate'),
            sorter: (a: EnquiryType, b: EnquiryType) => {
                if (b.updatedAt.replace(/-/g, '') < a.updatedAt.replace(/-/g, '')) {
                    return -1;
                }
                if (b.updatedAt.replace(/-/g, '') > a.updatedAt.replace(/-/g, '')) {
                    return 1;
                }
                return 0;
            },
            render: (i: EnquiryType) => i.updatedAt.slice(0, 10),
        },
        {
            render: (i: EnquiryType) => (
                <HStack
                    justifyContent="flex-end"
                    spacing="2"
                >
                    <Menu autoSelect={false}>
                        <MenuButton
                            as={IconButton}
                            borderRadius="30"
                            icon={<ChevronDownIcon />}
                            size="sm"
                            variant="primary"
                        />

                        <MenuList>
                            <MenuItem
                                color="wvwGreen"
                                icon={<Eye size="1.2rem" />}
                                onClick={() => {
                                    navigate(`/dashboard/enquiry/${i.id}/overview`);
                                }}
                                width="100%"
                            >
                                {t('enquiriesTable.button.view')}
                            </MenuItem>

                            {/* <MenuItem
                                color="wvwGreen"
                                icon={<Trash size="1.2rem" />}
                                width="100%"
                            >
                                {t('enquiriesTable.button.remove')}
                            </MenuItem> */}
                        </MenuList>
                    </Menu>

                    <ChakraLink
                        as={ReactLink}
                        to={`/create-client-enquiry/enquiry-valuation-info/${i.id}`}
                    >
                        <Button
                            borderRadius="30"
                            size="sm"
                            variant="primaryYellow"
                        >
                            {t('enquiriesTable.button.resume')}
                        </Button>
                    </ChakraLink>
                </HStack>
            ),
        },
    ];

    if (enquiryLoading) return <LoadingSpinner />;

    return (
        <PageWithTitleLayout title={t('section.draftEnquiries')}>
            <DataTable
                columns={headers}
                data={enquiryList}
                noDataText={t('noDraftEnquiries', { ns: 'enquiries' })}
            />
        </PageWithTitleLayout>
    );
};

export default DraftEnquiries;
