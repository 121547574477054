import {
    Input,
    InputGroup,
    InputRightElement,
    Button,
    Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import WvwDeleteIconButton from '../components/WvwDeleteIconButton';
import WvwVStack from '../components/display/WvwVStack';
import { WVWLabel, WvwText } from '../components/typography';

type PropTypes = {
    name: string;
    placeholder?: string;
    onChangeFunction?: () => void;
};

const FormikListInput = (props: PropTypes) => {
    const {
        name,
        placeholder,
        onChangeFunction,
    } = props;

    const [field, meta] = useField(props);

    const [inputValue, setInputValue] = useState('');

    const { t } = useTranslation(['common', 'formik']);

    const submitValues = () => {
        const inputList = [...field.value, inputValue];

        field.onChange({
            target: {
                name,
                value: inputList,
            },
        });

        setInputValue('');
    };

    return (
        <WvwVStack>
            {field.value?.map((inputContent: string) => (
                <Flex
                    height="3rem"
                    width="100%"
                    border="1px solid"
                    borderColor="#ebebeb"
                    borderRadius="8"
                    paddingLeft="1rem"
                    paddingRight="1rem"
                    justifyContent="space-between"
                    alignItems="center"
                    key={inputContent}
                >
                    <WVWLabel
                        noColor
                        content={inputContent}
                    />

                    <WvwDeleteIconButton
                        onClick={() => {
                            const newList = field.value.filter(
                                (i: string) => i !== inputContent,
                            );
                            field.onChange({
                                target: {
                                    name,
                                    value: newList,
                                },
                            });
                        }}
                        color="wvwGrey60"
                    />
                </Flex>
            ))}
            <InputGroup>
                <Input
                    bg="white"
                    name={name}
                    value={inputValue}
                    type="text"
                    placeholder={placeholder}
                    onChange={
                        e => {
                            setInputValue(e.target.value);
                            if (onChangeFunction) {
                                onChangeFunction();
                            }
                        }
                    }
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();

                            submitValues();
                        }
                    }}
                />

                <InputRightElement width="fit-content">
                    <Button
                        borderLeftRadius="0"
                        type="button"
                        onClick={() => {
                            if (inputValue === '') return;
                            submitValues();
                        }}
                    >
                        {t('button.add')}
                    </Button>
                </InputRightElement>
            </InputGroup>

            {meta.touched
                && meta.error
                && (
                    <WvwText color="red">
                        {t(meta.error, { ns: 'formik', defaultValue: meta.error })}
                    </WvwText>
                )}
        </WvwVStack>
    );
};

FormikListInput.defaultProps = {
    placeholder: '',
    onChangeFunction: () => { },
};

export default FormikListInput;
