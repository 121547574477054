const standardSystemStandardsOffered: string[] = [
    "RICS - Red Book",
    "Tegova - Blue Book",
    "IVSC - White Book",
    "HypZert",
    "ValueV",
    "BDSF",
    "DESAG",
    "TAF",
    "AI",
    "ASA",
    "DIN 17024",
];

export default standardSystemStandardsOffered;
