import { Box, Center, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Footer from "../../Footer";
import { wvwPalette } from "../../../theme/theme-v2";

const PublicScreen = () => {
    return (
        <VStack
            w="100%"
            h="100vh"
            bgGradient={wvwPalette.wvwGradients.blue}
        >
            <Center
                h="90vh"
                minH="fit-content"
            >
                <VStack>
                    <Outlet />
                    <Footer invertColor />
                </VStack>
            </Center>
        </VStack>
    );
};

export default PublicScreen;
