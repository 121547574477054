import { HStack, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

type PropTypes = {
    right: ReactNode;
    left: ReactNode;
};

const TwoColumn = (props: PropTypes) => {
    const { right, left } = props;

    return (
        <HStack
            w={"100%"}
            align={"top"}
        >
            <VStack
                w="30%"
                minWidth="max"
            >
                {right}
            </VStack>

            <VStack
                w="70%"
                minWidth="max"
            >
                {left}
            </VStack>
        </HStack>
    );
};

export default TwoColumn;
