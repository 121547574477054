import {
    Button,
    Grid,
    GridItem,
    Heading,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../../common/components/Logo';
import { FlowContextStateful } from '../../../common/components/display/ProcessFlowStateful';
import RegisterFormsContainer from '../common/RegisterFormsContainer';

type PropTypes = {
    onContinue: (values: { [index: string]: string }) => void;
};

const SelectValuerOrClient = (props: PropTypes) => {
    const { t } = useTranslation(['account', 'common']);
    const { onContinue } = props;

    const { goBack, goNext } = useContext(FlowContextStateful);

    return (
        <RegisterFormsContainer goBack={goBack}>
            <VStack
                spacing="6"
                pb="9em"
                pt="6em"
                w="100%"
            >
                <Logo withColor />

                <Heading
                    as="h1"
                    color="wvwGreen"
                    size="xl"
                >
                    {t('howCanWeHelp')}
                </Heading>

                <Text align="center">
                    {t('WVWHasYouCovered')}
                </Text>

                <Grid
                    maxW="container.md"
                    gap="4em"
                    templateColumns="repeat(auto-fit, minmax(230px, 1fr))"
                    w="100%"
                    textAlign="center"
                >
                    <GridItem p="1em">
                        <Button
                            size="lg"
                            variant="primaryYellow"
                            w="100%"
                            onClick={() => {
                                onContinue({
                                    accountType: 'VALUER',
                                    companyOrIndividual: 'COMPANY',
                                });
                                goNext();
                            }}
                        >
                            {t('iAmAValuer')}
                        </Button>

                        <Text
                            paddingTop="1rem"
                            paddingBottom="1rem"
                        >
                            {t('offeringValuations')}
                        </Text>
                    </GridItem>

                    <GridItem p="1em">
                        <Button
                            size="lg"
                            variant="primaryYellow"
                            w="100%"
                            onClick={() => {
                                onContinue({ accountType: 'CLIENT' });
                                goNext();
                            }}
                        >
                            {t('lookingForValuation')}
                        </Button>

                        <Text
                            paddingTop="1rem"
                            paddingBottom="1rem"
                        >
                            {t('needingValuations')}
                        </Text>
                    </GridItem>
                </Grid>
            </VStack>
        </RegisterFormsContainer>
    );
};

export default SelectValuerOrClient;
