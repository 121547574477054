import { AxiosResponse } from 'axios';
import { ClientInvitesCreateDto } from '../dto';
import { clientInvitesCreate } from '../endpoints';
import { ClientInviteCreateResultType } from '../endpoints/register/client/client-invites-create';
import useMutationHook from './useMutationHook';
import useClientInvitesGetSent from './useClientInvitesGetSent';

type ParamsType = {
    onSuccess?: (data: AxiosResponse<ClientInviteCreateResultType, any>) => void;
    onError?: (error: Error) => void;
};

const useClientInvitesCreate = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { refresh: refeshInvitesList } = useClientInvitesGetSent();

    const mutation = useMutationHook({
        mutationFunction: (dto: ClientInvitesCreateDto) => clientInvitesCreate(dto),
        refreshes: [refeshInvitesList],
        onSuccess,
        onError,
    });

    return mutation;
};

export default useClientInvitesCreate;
