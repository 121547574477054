import { MultiFactorError } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../../../../../utils/firebase';
import Login from './Login';
import PhoneVerification from './PhoneVerification';

const steps = [
    'LOGIN',
    'VERIFY',
];

const [
    LOGIN,
    VERIFY,
] = steps;

const LoginFlow = () => {
    const navigate = useNavigate();
    const { redirectUrl = '/' } = useParams();

    const [user] = useAuthState(auth);

    const [authError, setAuthError] = useState<MultiFactorError>();
    const [step, setStep] = useState(LOGIN);

    useEffect(() => {
        if (step === LOGIN && user && redirectUrl === '/') {
            navigate('/');
        }
    }, [user]);

    if (step === LOGIN) {
        return (
            <Login
                onLogin={goToVerify => {
                    if (goToVerify) {
                        setStep(VERIFY);
                    } else {
                        navigate(redirectUrl);
                    }
                }}
                setAuthError={error => {
                    setAuthError(error);
                }}
            />
        );
    }

    return (
        <PhoneVerification
            authError={authError}
        />
    );
};

export default LoginFlow;
