import { t } from 'i18next';
import { useState } from 'react';
import { useMutation } from 'react-query';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import { enquiryCancel } from '../endpoints';

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
};

const useEnquiryCancel = (params: ParamsType) => {
    const {
        onSuccess,
        onError,
    } = params;

    const { mutate: cancel } = useMutation(
        async (enquiryId: number) => enquiryCancel(enquiryId),
        {
            onSuccess,
            onError,
        },
    );

    const [enquiyToBeCancelled, setEnquiryToBeCancelled] = useState<number | undefined>(undefined);

    const cancelEnquiry = (id: number) => setEnquiryToBeCancelled(id);

    const modal = (
        <ConfirmationModal
            cancelButton={t('button.exit', { ns: 'common' })}
            continueButton={t('button.cancelEnquiry', { ns: 'common' })}
            isOpen={enquiyToBeCancelled !== undefined}
            title={t('modal.cancelEnquiry.title', { ns: 'enquiries' })}
            content={t('modal.cancelEnquiry.content', { ns: 'enquiries' })}
            onCancel={() => setEnquiryToBeCancelled(undefined)}
            onContinue={() => {
                cancel(enquiyToBeCancelled as number);
                setEnquiryToBeCancelled(undefined);
            }}
        />
    );

    return {
        cancelEnquiry,
        modal,
    };
};

export default useEnquiryCancel;
