import { useRole } from "../../../common/hooks";
import { enquiryGetEnquiriesWithAssignments } from "../endpoints";
import useQueryHook from "./useQueryHook";

const ENQUIRY_GET_ENQUIRIES_WITH_ASSIGNMENTS =
    "ENQUIRY_GET_ENQUIRIES_WITH_ASSIGNMENTS";

const useEnquiryGetEnquiriesWithAssignments = () => {
    const { userIsCompanyAdmin } = useRole();

    const query = useQueryHook({
        uniqueName: ENQUIRY_GET_ENQUIRIES_WITH_ASSIGNMENTS,
        queryFunction: enquiryGetEnquiriesWithAssignments,
        dependancies: [],
        requiredParams: [userIsCompanyAdmin],
    });

    return query;
};

export default useEnquiryGetEnquiriesWithAssignments;
