import { Route } from "react-router-dom";
import CreateDirectEnquiry from "../../../../views/direct-enquiry-form/CreateDirectEnquiry";
import { ThemeV1Wrapper } from "../ThemeV1Wrapper";

export const directEnquiry = (
    <Route element={<ThemeV1Wrapper />}>
        <Route
            path="direct-enquiry-form/:clientType/:token"
            element={<CreateDirectEnquiry />}
        />
        <Route
            path="direct-enquiry-form/:token"
            element={<CreateDirectEnquiry />}
        />
    </Route>
);
