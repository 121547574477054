import {
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    Radio,
    RadioGroup,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import { useRole, useSmartTranslation } from "../../../../../common/hooks";
import { WvwText, WVWTitle } from "../../../../../common/components/typography";
import { WvwButton } from "../../../../../common/components/inputs";
import { FlowContext } from "../../../../../common/components/display/ProcessFlowRouted";
import ModalDynamic from "../../../../../common/components/ModalDynamic";
import Privacy from "../../../../../common/components/Privacy";
import WvwSecurityShield from "../../../../../assets/icons/custom-icons/WvwSecurityShield";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { useMe } from "../../../../../utils/api/hooks";
import ProfileDetailsDoesValuatons from "../../../profile/components/ProfileDetailsDoesValuations";
import { FormikForm } from "../../../../../common/forms";

const ProfileCompletionLanding = () => {
    const t = useSmartTranslation("profile");

    const { goNext } = useContext(FlowContext);

    const [modal, setModal] = useState(false);

    const { data: user, updateMe, isLoading: userLoading } = useMe();

    const {
        userIsClient,
        userIsCompanyAdmin,
        userIsValuer,
        userIsIndividual,
        roleLoading,
    } = useRole();

    if (!user || roleLoading || userLoading) return <LoadingSpinner />;

    const valuerDoesValuations = user.doesValuations;

    const userIsValuerAdmin = userIsValuer && userIsCompanyAdmin;

    const userIsValuerUser = userIsValuer && !userIsCompanyAdmin;

    const userIsClientAdmin = userIsClient && userIsCompanyAdmin;

    const userIsClientUser = userIsClient && !userIsCompanyAdmin;

    const keyBenefits = [
        t(
            "profileCompletionWizard.landing.keyBenefits.benefits.improvedMatchmaking"
        ),
        t(
            "profileCompletionWizard.landing.keyBenefits.benefits.enhancedTransparency"
        ),
        t(
            "profileCompletionWizard.landing.keyBenefits.benefits.increasedVisibility"
        ),
    ];

    const companyStep = t("profileCompletionWizard.steps.company");
    const profileStep = t("profileCompletionWizard.steps.personal");
    const qualificationsStep = t(
        "profileCompletionWizard.steps.qualifications"
    );
    const valuationOfferStep = t(
        "profileCompletionWizard.steps.valuationOffer"
    );
    const extraDocsStep = t("profileCompletionWizard.steps.extraDocs");

    const clientCompanyAdmin = [companyStep, profileStep];

    const clientUser = [profileStep];

    const valuerCompanyAdmin = [
        companyStep,
        profileStep,
        qualificationsStep,
        valuationOfferStep,
        extraDocsStep,
    ];

    const valuerCompanyAdminNoValuations = [
        companyStep,
        profileStep,
        extraDocsStep,
    ];

    const valuerUser = [profileStep, qualificationsStep, valuationOfferStep];

    const valuerUserNoValuations = clientUser;

    let steps;

    switch (true) {
        case userIsClientAdmin:
            steps = clientCompanyAdmin;
            break;
        case userIsClientUser || userIsIndividual:
            steps = clientUser;
            break;
        case userIsValuerUser && valuerDoesValuations:
            steps = valuerUser;
            break;
        case userIsValuerUser && !valuerDoesValuations:
            steps = valuerUserNoValuations;
            break;
        case userIsValuerAdmin && !valuerDoesValuations:
            steps = valuerCompanyAdminNoValuations;
            break;
        default:
            steps = valuerCompanyAdmin;
            break;
    }

    const update = (value: string) => {
        updateMe({
            doesValuations: value === "true",
        });
    };

    const doesValuationsRadioGroup = (
        <VStack
            width="100%"
            align="left"
        >
            <WVWTitle
                level="2"
                color="black"
                content={t("doesValuations", {
                    ns: "profile",
                })}
            />
            <RadioGroup
                defaultValue={user.doesValuations ? "true" : "false"}
                variant="outline"
                onChange={(value) => update(value)}
            >
                <HStack spacing={5}>
                    <Radio
                        value="true"
                        w="max"
                    >
                        {t("yes", { ns: "common" })}
                    </Radio>
                    <Radio
                        value="false"
                        w="max"
                    >
                        {t("no", { ns: "common" })}
                    </Radio>
                </HStack>
            </RadioGroup>
        </VStack>
    );

    return (
        <Center>
            <Flex
                padding="4rem"
                width="100%"
                gap={2}
                alignItems="center"
                flexWrap={{ base: "wrap-reverse", md: "nowrap" }}
            >
                <VStack
                    align="left"
                    spacing={8}
                    justifyContent="center"
                    width="100%"
                    maxWidth={{ md: "70%" }}
                >
                    <Heading
                        size="2xl"
                        color="black"
                    >
                        {t("profileCompletionWizard.landing.title")}
                    </Heading>

                    <Heading
                        size="1xl"
                        color="black"
                    >
                        {t("profileCompletionWizard.landing.subtitle")}
                    </Heading>

                    {userIsValuer && (
                        <VStack
                            align="left"
                            spacing={4}
                        >
                            <WVWTitle
                                level="3"
                                color="black"
                                content={t(
                                    "profileCompletionWizard.landing.keyBenefits.title"
                                )}
                            />
                            {keyBenefits.map((benefit) => (
                                <HStack key={benefit}>
                                    <CheckIcon color="green" />

                                    <WvwText>{benefit}</WvwText>
                                </HStack>
                            ))}

                            {doesValuationsRadioGroup}
                        </VStack>
                    )}

                    <Divider />

                    <HStack>
                        <WvwSecurityShield width="2rem" />

                        <Text>
                            {t(
                                "profileCompletionWizard.landing.securityDisclaimer"
                            )}{" "}
                            <WvwButton
                                variant="link"
                                content={t("privacyPolicy", {
                                    ns: "common",
                                })}
                                onClick={() => setModal(true)}
                            />
                        </Text>
                    </HStack>

                    <WvwButton
                        variant="primary"
                        content={t(
                            "profileCompletionWizard.landing.completeProfile"
                        )}
                        onClick={() => goNext()}
                    />
                </VStack>

                <VStack
                    align="left"
                    spacing="4"
                    justifyContent="center"
                    width="100%"
                    maxWidth={{ md: "30%" }}
                    flexWrap="wrap"
                >
                    <Heading
                        size="1xl"
                        color="black"
                    >
                        {t("profileCompletionWizard.landing.stepsTitle")}
                    </Heading>

                    <Flex
                        width="100%"
                        flexWrap="wrap"
                        gap={4}
                    >
                        {steps.map((step, index) => (
                            <HStack
                                key={step}
                                width="100%"
                            >
                                <Center
                                    bgColor="wvwGrey10"
                                    width="2rem"
                                    height="2rem"
                                    borderRadius="10"
                                >
                                    <Text textAlign="center">{index + 1}</Text>
                                </Center>

                                <WVWTitle
                                    level="3"
                                    color="black"
                                    content={step}
                                />
                            </HStack>
                        ))}
                    </Flex>
                </VStack>
            </Flex>

            <ModalDynamic
                variant="small"
                isOpen={modal}
                header={t("privacy", { ns: "common" })}
                onClose={() => setModal(false)}
            >
                <Privacy />
            </ModalDynamic>
        </Center>
    );
};

export default ProfileCompletionLanding;
