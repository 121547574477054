import {
    Box,
    ButtonGroup,
    Flex,
    Spacer,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import React from 'react';
import CancelEnquiryModal from './CancelEnquiryModal';
import { WVWTitle, WvwText } from '../../../common/components/typography';
import { WvwButton, WvwButtonBack } from '../../../common/components/inputs';
import { FormikSubmitButton } from '../../../common/forms';

type PropTypes = {
    variant?: 'white' | 'none';
    nextButtonTextKey?: string;
    onBack: () => void;
    content: {
        key: string;
        header: string | undefined;
        subHeader?: string;
        element: React.ReactNode;
    }[];
};

const EnquiryFormBox = (props: PropTypes) => {
    const {
        variant,
        onBack,
        nextButtonTextKey = 'button.next',
        content,
    } = props;

    const { enquiryId = '' } = useParams();

    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <VStack
            align="center"
            spacing={4}
        >
            {content.map(item => (
                <VStack
                    w="100%"
                    bg={variant === 'white' ? 'white' : 'transparent'}
                    borderRadius="10"
                    padding="1rem"
                    align="left"
                    key={item.key}
                >
                    <Box>
                        {item.header !== undefined && (
                            <WVWTitle
                                content={item.header}
                                color="black"
                                level="2"
                            />
                        )}

                        {item.subHeader !== undefined && (
                            <WvwText>
                                {item.subHeader}
                            </WvwText>
                        )}
                    </Box>
                    {item.element}
                </VStack>
            ))}

            <Flex
                alignItems="left"
                gap="2"
                w="100%"
                paddingBlock="1rem"
            >
                <ButtonGroup
                    width={{ xs: '4rem', md: '15rem' }}
                    alignSelf="flex-end"
                >
                    <WvwButtonBack
                        onClick={() => onBack()}
                        width="100%"
                    />

                    <WvwButton
                        width="100%"
                        onClick={onOpen}
                        variant="danger"
                        content={t('button.cancel', { ns: 'common' })}
                    />
                </ButtonGroup>

                <Spacer />

                <ButtonGroup
                    width={{ xs: '8rem', md: '15rem' }}
                    alignSelf="flex-end"
                >
                    <FormikSubmitButton
                        width="block"
                        content={t(nextButtonTextKey, { ns: 'common', defaultValue: nextButtonTextKey })}
                    />
                </ButtonGroup>
            </Flex>

            <CancelEnquiryModal
                content={enquiryId === '' ? t('newEnquiry.exitModalText_WithoutSavingDraft', { ns: 'enquiryform', defaultValue: 'Are you sure you want to exit? All your current changes will be lost.' }) : undefined}
                isOpen={isOpen}
                onCancel={onClose}
                onContinue={() => {
                    if (enquiryId === '') {
                        navigate('../', { replace: true });
                    } else {
                        navigate('/dashboard');
                    }
                }}
            />
        </VStack>
    );
};

EnquiryFormBox.defaultProps = {
    nextButtonTextKey: 'button.next',
    variant: 'white',
};

export default EnquiryFormBox;
