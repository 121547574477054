import { Box, Flex, Spacer, VStack } from "@chakra-ui/react";
import CustomizeExperience from "../customize-experience/CustomizeExperience";
import { EnquiryType } from "../../../../../../types";
import DataTable from "../../../../common/components/data-table/DataTable";
import Statistics from "../../../../common/features/statistics/Statistics";
import { Section } from "../../../../layout";
import {
    columns as enquiryColumns,
    createEnquiriesTableData,
} from "./EnquiryTableData";
import { columns as caseColumns } from "./CaseTableData";
import {
    activeEnquiriesStatistic,
    clientAverageOffersStatistic,
    activeCasesStatistic,
    completedCasesStatistic,
} from "./EnquiryAndCaseStatistics";
import {
    TranslationFunction,
    useSmartTranslation,
} from "../../../../common/hooks/useSmartTranslation";
// import { UserContext } from "../../../../app/AppV2";
// import { useContext } from "react";
import {
    useCaseActiveCases,
    useEnquiryGetAll,
    useMe,
    useMyEntity,
    useOfferGetAllMyOffers,
} from "../../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useRole } from "../../../../../../common/hooks";
import ProfileCompletionBanner from "../../profile-completion/ProfileCompletionBanner";

//filters
const filterActiveEnquiries = (enquiries: EnquiryType[]) => {
    const activeEnquiries = enquiries.filter(
        (enquiry) => enquiry.status === "PENDING"
    ).length;
    // TODO replace translation key with correct key, currently not in the dictionary (active)
    return {
        title: (t: TranslationFunction) => t("admin"),
        data: activeEnquiries,
    };
};

const Dashboard = () => {
    // const { user } = useContext(UserContext);

    const { data: enquiries = [], isLoading: enquiriesLoading } =
        useEnquiryGetAll();
    const { data: offers, isLoading: offersLoading } = useOfferGetAllMyOffers();
    const { data: cases = [], isLoading: casesLoading } = useCaseActiveCases();
    const { data: entity, isLoading: entityLoading } = useMyEntity();
    const { data: user, isLoading: userLoading } = useMe();
    const { userIsValuer, userIsClient, userIsCompanyAdmin, roleLoading } =
        useRole();

    const t = useSmartTranslation();

    if (
        !user ||
        enquiriesLoading ||
        offersLoading ||
        casesLoading ||
        entityLoading ||
        userLoading ||
        roleLoading
    )
        return <LoadingSpinner />;

    // profile completion
    const profileCompletionBanner = (
        <ProfileCompletionBanner
            user={user}
            entity={entity}
            userRoles={{
                userIsClient,
                userIsCompanyAdmin,
                userIsValuer,
            }}
        />
    );

    if (enquiriesLoading || offersLoading || casesLoading)
        return <LoadingSpinner />;

    // statistics
    const numActiveEnquiries = activeEnquiriesStatistic(enquiries);
    const numAvgOffers = clientAverageOffersStatistic(enquiries, offers);
    const enquiryStatsData = [numActiveEnquiries, numAvgOffers];
    const numActiveCases = activeCasesStatistic(cases);
    const numCompletedCases = completedCasesStatistic(cases);
    const caseStatsData = [numActiveCases, numCompletedCases];

    const enquiryStats = (
        <Section
            title="Enquries"
            content={<Statistics stats={enquiryStatsData} />}
        />
    );

    const caseStats = (
        <Section
            title="Cases"
            content={<Statistics stats={caseStatsData} />}
        />
    );

    // enquiries
    const enquiriesTableData = createEnquiriesTableData({
        enquiries,
        offers,
    });

    const recentEnquiries = (
        <Section
            title={t("clientDashboard.recentEnquiries.tableTitle", {
                count: enquiriesTableData.length,
            })}
            content={
                <DataTable
                    data={enquiriesTableData}
                    columns={enquiryColumns}
                    noDataText={t(
                        "clientDashboard.recentEnquiries.noDataPlaceholder"
                    )}
                />
            }
        />
    );

    // cases
    const ongoingCases = (
        <Section
            title="Ongoing Cases: 0"
            content={
                <DataTable
                    data={cases}
                    columns={caseColumns}
                />
            }
        />
    );

    // account and profile
    const experienceCustomization = (
        <Section
            title={t(
                "clientDashboard.customizeExperience.customizeYourExperience"
            )}
            content={
                <CustomizeExperience
                    customizeList={[
                        {
                            title: t(
                                "clientDashboard.customizeExperience.completeProfile.title"
                            ),
                            subtext: t(
                                "clientDashboard.customizeExperience.completeProfile.subtext"
                            ),
                            link: {
                                label: t("common.button.complete"),
                                nav: "profile",
                            },
                        },
                        {
                            title: t(
                                "clientDashboard.customizeExperience.addTeamMembers.title"
                            ),
                            subtext: t(
                                "clientDashboard.customizeExperience.addTeamMembers.subtext"
                            ),
                            link: {
                                label: t("clientDashboard.button.complete"),
                                nav: "profile/team",
                            },
                        },
                    ]}
                />
            }
        />
    );
    return (
        // pull out into OneColumn layout
        <VStack
            w="100%"
            padding=".5em"
        >
            {profileCompletionBanner}
            <Flex
                w="100%"
                gap={1}
            >
                {enquiryStats}
                <Spacer />
                {caseStats}
            </Flex>

            <Flex
                w="100%"
                gap={1}
            >
                <Box
                    w="100%"
                    maxW="50%"
                >
                    {recentEnquiries}
                </Box>
                <Spacer />
                <Box
                    w="100%"
                    maxW="50%"
                >
                    {ongoingCases}
                </Box>
            </Flex>

            {experienceCustomization}
        </VStack>
    );
};

export default Dashboard;
