import { Icon, IconProps, OmitCommonProps } from "@chakra-ui/react";
import { SVGProps } from "react";

type PropTypes = JSX.IntrinsicAttributes &
    OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps & { as?: "svg" | undefined };

export const MobileIcon = (props: PropTypes) => (
    <Icon
        viewBox="0 0 32 30"
        h="auto"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.03301 29.3C8.49967 29.7667 9.06979 30 9.74334 30H22.256C22.9296 30 23.4997 29.7667 23.9663 29.3C24.433 28.8333 24.6663 28.2632 24.6663 27.5897V4.41033C24.6663 3.73678 24.433 3.16667 23.9663 2.7C23.4997 2.23333 22.9296 2 22.256 2H9.74334C9.06979 2 8.49967 2.23333 8.03301 2.7C7.56634 3.16667 7.33301 3.73678 7.33301 4.41033V27.5897C7.33301 28.2632 7.56634 28.8333 8.03301 29.3ZM9.33301 24.2969V27.5897C9.33301 27.6923 9.37579 27.7863 9.46134 27.8717C9.54667 27.9572 9.64067 28 9.74334 28H22.256C22.3587 28 22.4527 27.9572 22.538 27.8717C22.6236 27.7863 22.6663 27.6923 22.6663 27.5897V23.6923H22.6533V21.6923H22.6663V7.66667H22.6533V5.66667H22.6663V4.41033C22.6663 4.30767 22.6236 4.21367 22.538 4.12833C22.4527 4.04278 22.3587 4 22.256 4H9.74334C9.64067 4 9.54667 4.04278 9.46134 4.12833C9.37579 4.21367 9.33301 4.30767 9.33301 4.41033V5.09896H9.27441V24.2969H9.33301ZM16.8343 26.6807C16.6043 26.9107 16.3261 27.0257 15.9997 27.0257C15.6732 27.0257 15.395 26.9107 15.165 26.6807C14.9352 26.4509 14.8203 26.1727 14.8203 25.846C14.8203 25.5196 14.9352 25.2414 15.165 25.0117C15.395 24.7817 15.6732 24.6667 15.9997 24.6667C16.3261 24.6667 16.6043 24.7817 16.8343 25.0117C17.0641 25.2414 17.179 25.5196 17.179 25.846C17.179 26.1727 17.0641 26.4509 16.8343 26.6807Z"
            fill="#A0AEC0"
        />
    </Icon>
);

MobileIcon.defaultProps = {
    width: "1.7em",
};
