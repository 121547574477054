import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";

export const translations = {
    de: {
        statistics: {
            enquiries: {
                active: "Aktiv",
                avgOffers: "Durchschn. Angebote",
                avgTime: "Durchschn. Auswahlzeit",
            },
            cases: {
                active: "Aktiv",
                completed: "Abgeschlossen",
            },
        },
        customizeExperience: {
            customizeYourExperience: "Passe deine Erfahrung an",
            completeProfile: {
                title: "Vervollständige dein Profil",
                subtext: MISSING_TRANSLATION,
            },
            addTeamMembers: {
                title: "Teammitglieder hinzufügen",
                subtext: MISSING_TRANSLATION,
            },
        },
        button: {
            complete: "Vervollständigen",
        },
        recentEnquiries: {
            tableTitle: "Aktuelle Anfragen: {{count}}",
            noDataPlaceholder: "Noch keine Anfragen erstellt.",
        }
    },
    en: {
        statistics: {
            enquiries: {
                active: "Active",
                avgOffers: "Avg. Offers",
                avgTime: "Avg. selection time",
            },
            cases: {
                active: "Active",
                completed: "Completed",
            },
        },
        customizeExperience: {
            customizeYourExperience: "Customize your experience",
            completeProfile: {
                title: "Complete your profile",
                subtext:
                    "You have missing mandatory information about your company, please complete all relevant fields.",
            },
            addTeamMembers: {
                title: "Add team members",
                subtext:
                    "Consider adding your team members to your company profile.",
            },
        },
        button: {
            complete: "Complete",
        },
        recentEnquiries: {
            tableTitle: "Recent Enquiries: {{count}}",
            noDataPlaceholder: "No enquiries created yet.",
        }
    },
};
